import React, { useState, useEffect } from 'react';
import { Card, Button, Row, Col, message, Checkbox, Typography, Collapse } from 'antd';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import { MailOutlined, PrinterOutlined } from '@ant-design/icons';
import envLocal from '../../env-local';
import NavBar from '../../Components/Basic/NavBar';
import Counter from '../../Components/Basic/Counter';
import socketIOClient from "socket.io-client";
import { useRestaurant } from '../../Context/ContextRestaurant';
import { axiosCall, groupBy, calculateExtraAll } from '../../Components/Basic/Utilities/tools';
import moment from 'moment';
import { useConfigContext } from '../../Context/ContextConfig';
import SquareInfo from '../../Components/Basic/SquareInfo';
import { IOptionsOutput } from '../../Components/Basic/Utilities/interfaces';
import ReactDOMServer from 'react-dom/server';

const { Text } = Typography;
const { Panel } = Collapse;

interface IOrders {
    idRestaurant: string;
    content: string;
    order: IOrder[];
    observations: string;
    createdAt: Date;
    _id: string;
    status: number;
    idTable?: string;
    idOrder?: string;
};

interface IOrder {
    name: string;
    quantity: number;
    options?: IOptionsOutput[][];
    _id: string;
    delete?: boolean;
    notified?: boolean;
    groupOrder?: number;
};

let socket: any;
let idTask: string;
const audioNewTask = new Audio("/audio/newtask.mp3")

const getItemsFromOrder = (inputOrder: IOrder[], country: string, language: number, idOrder: string, canNotify?: Function) => {
    const sortOrder = inputOrder.sort((a, b) => (a.groupOrder || 0) - (b.groupOrder || 0))
    let currentGroup = [sortOrder[0].groupOrder]
    return (
        <div>
            {sortOrder.map(item => {
                const exists = currentGroup.includes(item.groupOrder)
                if(!exists) currentGroup.push(item.groupOrder)
                return (
                    <div key={item._id}>
                        {!exists && <div className="red">{language === 0 ? "Grupo: " : "Group: "} {item.groupOrder}</div>}
                        <Text delete={canNotify ? item.delete === true : false}>
                            <div style={{ display: "flex", marginBottom: "8px" }}>
                                <span style={{ flexBasis: "10%" }}>
                                    <b>x{item.quantity}</b>
                                </span>
                                <span style={{ flexBasis: "83%" }}>
                                    <b>{item.name}</b>{item.options?.length ? calculateExtraAll(item.options, country, language).map((e, idx) => <div key={idx}>{idx > 0 && <div>{`${idx + 1}º ------`}</div>}{e.map(i => <div className="ml_10px" key={i}>{i}</div>)}</div>) : null}
                                </span>
                                {canNotify && <span style={{ flexBasis: "7%" }}>
                                    <Checkbox checked={item.delete} disabled={item.notified} onChange={(e) => canNotify(idOrder, item._id, e)}></Checkbox>
                                </span>}
                            </div>
                        </Text>
                    </div>
                )
            }
            )}
        </div>
    )
}

const openDrawer = (inputOrder: IOrder[], country: string, language: number, idOrder: string) => {
    const width = 1500
    const height = 1500
    const leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    const topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    var mywindow = window.open("", "print",
        "status=no,height=" + height + ",width=" + width + ",resizable=yes,left="
        + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY="
        + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
    if (mywindow) {
        mywindow.document.write(ReactDOMServer.renderToStaticMarkup(getItemsFromOrder(inputOrder, country, language, idOrder)));
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        //mywindow.close();
    }
}

const Kitchen: React.FC = () => {
    const [orders, setOrders] = useState<Array<IOrders>>([]);
    const [ordersFinished, setOrdersFinished] = useState<Array<IOrders>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [inputTask, setInputTask] = useState<IOrders>();
    const [idNotify, setIdNotify] = useState<Array<string>>([]);
    const { restaurant } = useRestaurant()!;
    const { configContext } = useConfigContext()!;
    let widthSize = window.innerWidth;

    useEffect(() => {
        fetchData();
        socket = socketIOClient(envLocal.endpointSocketServer, { transports: ['websocket'], query: { token: window.localStorage.getItem(envLocal.authComp) } });
        socket.emit("joinRoom", restaurant._id);
        socket.on("newOrderKitchen", (item: IOrders) => {
            if (item._id !== idTask) {
                idTask = item._id;
                setInputTask(item);
            };
        });
        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (inputTask !== undefined) {
            audioNewTask.play()
            setOrders([...orders, inputTask]);
        };
    }, [inputTask]);

    const fetchData = async () => {
        const response = await axiosCall("post", envLocal.endpointTask, envLocal.authComp, "restaurant", {
            status: [5, 6]
        });
        if (response.status === 200) {
            setOrders(response.data.filter((order: IOrders) => order.status === 5));
            setOrdersFinished(response.data.filter((order: IOrders) => order.status === 6 && moment(order.createdAt) > moment().startOf('day')));
        } else {
            message.error(configContext.language === 0 ? "Error recuperando los pedidos" : "Error retrieving orders")
        }
        setIsLoading(false);
    };

    const getAssigned = (idTable: string) => {
        switch (idTable) {
            case "-2":
                return configContext.language === 0 ? "Domicilio" : "Home";
            case "-1":
                return configContext.language === 0 ? "Recogida" : "Pickup";
            case "0":
                return configContext.language === 0 ? "Barra" : "Bar";
            default:
                return undefined;
        }
    }

    const notify = (inputOrder: IOrders) => {
        const newOrder: any = orders.map(order => {
            let itemsToNotify: IOrder[] = [];
            if (order._id === inputOrder._id) {
                order.order.map(item => {
                    if (inputOrder.order.filter(inputItem => inputItem._id === item._id && inputItem.delete && !inputItem.notified).length) {
                        item.notified = true;
                        socket.emit("notifyOrder", { orderId: inputOrder._id, itemId: item._id, delete: true, notified: true });
                        itemsToNotify.push(item)
                    }
                    return item
                })
                const newOrder = {
                    idRestaurant: order.idRestaurant,
                    content: (configContext.language === 0 ? "Preparado " : "Ready ") + order.content,
                    observations: order.observations,
                    order: itemsToNotify,
                    type: 3,
                    status: 0,
                    assignedTo: getAssigned(inputOrder.idTable!),
                    desiredStatus: 2,
                    idTable: inputOrder.idTable
                }
                socket.emit("dispatchOrder", newOrder);
                itemsToNotify.forEach(element => {
                    const index = idNotify.indexOf(element._id);
                    const tmpNotify = idNotify;
                    if (index > -1) {
                        tmpNotify.splice(index, 1);
                    }
                    setIdNotify(tmpNotify);
                });
            }
            if (order.order.filter(item => item.notified === true).length !== order.order.length) {
                return order;
            } else {
                setOrdersFinished([...ordersFinished, order]);
                socket.emit("updateTask", { _id: inputOrder._id, status: 6, type: 3, idRestaurant: inputOrder.idRestaurant, idTable: inputOrder.idTable, idOrder: inputOrder.idOrder, waiting: 0, inProgress: 0, closedDate: moment(inputOrder.createdAt).format('DD/MM/YYYY'), closedHour: moment(inputOrder.createdAt).hour() });
                message.success(configContext.language === 0 ? "Pedido Completado!" : "Order completed!");
            }
        })
        newOrder === undefined ? setOrders([]) : setOrders(newOrder.filter(Boolean));
    };

    const onDelete = (inputOrder: { idOrder: string; idItem: string; }) => {
        const newOrder = orders.map(order => {
            if (order._id === inputOrder.idOrder) {
                order.order.map(item => {
                    if (item._id === inputOrder.idItem) {
                        item.delete = !item.delete;
                    }
                    return item
                })
            }
            return order
        })
        setOrders(newOrder);
    };

    const canNotify = (idOrder: string, idItem: string, event: any) => {
        if (idNotify.includes(idItem) && !event.target.checked) {
            let index = idNotify.indexOf(idItem);
            const tmpIdNotify = idNotify;
            tmpIdNotify.splice(index, 1)
            setIdNotify(tmpIdNotify);
        }
        if (!idNotify.includes(idOrder) && event.target.checked) {
            setIdNotify([...idNotify, idItem]);
        }
        onDelete({ idOrder, idItem });
    }

    const getSquareTask = () => {
        const groupedOrders = groupBy(orders.map(e => e.order.filter(p => !p.notified)).flat(), "name");

        return [
            {
                squareStyle: { width: widthSize < 690 ? "100%" : 350 },
                title: configContext.language === 0 ? "Último Pedido" : "Last Order",
                body:
                    <div className="textCenter mt_25px">
                        {inputTask ?
                            <span style={{ color: "white", borderRadius: 4, background: "#4d8fcd", width: "fit-content", height: 30, padding: 9 }}>
                                {inputTask.content}
                            </span>
                            :
                            <strong>{configContext.language === 0 ? "Esperando..." : "Waiting..."}</strong>
                        }
                    </div>
            },
            {
                squareStyle: { width: widthSize < 690 ? "100%" : "70%", height: "auto" },
                title: configContext.language === 0 ? "Resumen" : "Summary",
                body:
                    orders.length ?
                        <div className="twoCols">
                            {Object.entries(groupedOrders).map((product: any) => <div key={product[0]}>{product[0]}: <strong>{product[1].reduce(function (s: number, a: any) { return s + a.quantity }, 0)}</strong></div>)}
                        </div>
                        :
                        <div className="textCenter mt_25px">
                            <strong >{configContext.language === 0 ? "No hay nada pendiente de cocinado" : "There is nothing left to cook"}</strong>
                        </div>
            }
        ]
    }

    return (
        <div className="basicContainerColor">
            <NavBar />
            <div className="titleSection">{configContext.language === 0 ? 'Cocina' : "Kitchen"}</div>
            {isLoading ?
                <LoadingSpin />
                :
                <div className="mb_30px">
                    <SquareInfo squares={getSquareTask()} />
                    <div className="sectionSquare">
                        <div className="sectionSquareTitle">{configContext.language === 0 ? "Pedidos Pendientes" : "Pending Orders"}</div>
                        <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                                {orders.length ?
                                    orders.map(order =>
                                        <Card
                                            title={<div><Button type="primary" onClick={() => openDrawer(order.order, restaurant.country, configContext.language!, order._id)} icon={<PrinterOutlined />} /><span> {order.content}</span></div>}
                                            key={order._id}
                                            extra={idNotify.some(r => order.order.map(item => item._id).flat().indexOf(r) >= 0) ? <Button type="link" className="colorWhite" icon={<MailOutlined />} onClick={() => notify(order)}>{configContext.language === 0 ? "Notificar" : "Notify"}</Button> : null}
                                            bodyStyle={{ padding: 10, backgroundColor: "#f1f1f1" }}
                                            headStyle={{ backgroundColor: "#4d8fcd", color: "white" }}
                                            style={{ width: 278, margin: 10, height: "fit-content" }}
                                        >
                                            {getItemsFromOrder(order.order, restaurant.country, configContext.language!, order._id, canNotify)}
                                            {order.observations && <span><b>{configContext.language === 0 ? "Observaciones: " : "Comments: "}</b>{order.observations}</span>}
                                            <b><Counter formatReduced={false} createdAt={Number(order.createdAt)} /></b>
                                        </Card>)
                                    :
                                    <div className="textCenter mb_20px">
                                        <strong>{configContext.language === 0 ? "No hay pedidos pendientes" : "No pending orders"}</strong>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="sectionSquare">
                        <div className="sectionSquareTitle">{configContext.language === 0 ? "Pedidos Completados" : "Complete Orders"}</div>
                        <div className="sectionSquareBody">
                            <Col xs={{ span: 24 }} md={{ span: 12, offset: 6 }}>
                                {ordersFinished.length ?
                                    <Collapse>
                                        {ordersFinished.map(order =>
                                            <Panel header={
                                                <Row>
                                                    <Col span={12}><b>{order.content}</b></Col>
                                                    <Col xs={{ span: 0 }} md={{ span: 6 }}>{configContext.language === 0 ? "Solicitado a las: " : "Requested at: "}</Col>
                                                    <Col xs={{ span: 6, offset: 6 }} md={{ span: 6, offset: 0 }}><b>{moment(order.createdAt).format('HH:mm')}</b></Col>
                                                </Row>
                                            } key={order._id}>
                                                {getItemsFromOrder(order.order, restaurant.country, configContext.language!, order._id)}
                                                {order.observations && <span><b>{configContext.language === 0 ? "Observaciones: " : "Comments: "}</b>{order.observations}</span>}
                                            </Panel>)}
                                    </Collapse>
                                    :
                                    <div className="textCenter mb_20px">
                                        <strong>{configContext.language === 0 ? "Hoy aún no se ha completado ningún pedido" : "No order has been completed today"}</strong>
                                    </div>}
                            </Col>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Kitchen;