import React, { Component } from 'react';
import { Error500 } from '../../Components/Basic/Results/Error500';
import envLocal from '../../env-local';
import { axiosCall } from '../../Components/Basic/Utilities/tools';

let waitSend: boolean = false;

class ErrorBoundary extends Component {
    state = { hasError: false }

    componentDidCatch(error: any, info: any) {
        this.setState({ hasError: true });
        // --> send to reporting service;
        const getToken = window.localStorage.getItem(envLocal.authComp) ? window.localStorage.getItem(envLocal.authComp) : window.localStorage.getItem(envLocal.authUser)
        if (!waitSend) {
            waitSend = true;
            const errorData = {
                href: window.location.href,
                error: error.toString(),
                info: info.componentStack.substring(0, 100),
                token: getToken ? getToken : undefined
            }
            axiosCall("post", envLocal.endpointError, "", "", errorData);
            if(process.env.NODE_ENV !== "development") setTimeout(function () { window.location.href = 'https://www.bliscop.app' }, 2000);
        }

    }

    render() {
        if (this.state.hasError) {
            return <Error500 title="Ha ocurrido un error" subTitle="Si el problema persiste, por favor contacta con un administrador." />
        }

        return this.props.children;
    }
}
export default ErrorBoundary;