import React, { useState } from 'react';
import { Row, Col, Modal, Timeline } from 'antd';
import image1 from '../../Basic/Utilities/Images/image1.png';
import image2 from '../../Basic/Utilities/Images/image2.png';
import image3 from '../../Basic/Utilities/Images/image3.png';
import image4 from '../../Basic/Utilities/Images/image4.png';
import { useConfigContext } from '../../../Context/ContextConfig';

interface IProps {
    showModal: boolean;
    setShowModal: Function;
};

const InstructionsMenu: React.FC<IProps> = (props) => {
    const [following, setFollowing] = useState<number>(1);
    const { configContext } = useConfigContext()!;

    const showNext = () => {
        if (following === 4) {
            props.setShowModal(false);
        };
        setFollowing(following + 1);
    };

    const setTitle = (following: number) => {
        switch (following) {
            case 1:
                return configContext.language === 0 ? "Añade todos los Platos y Bebidas en las secciones por defecto" : "Add all the Dishes and Drinks in the default sections";
            case 2:
                return configContext.language === 0 ? "Crea nuevas secciones personalizadas como 'Menú del día', 'Vinos', 'Postres', ect." : "Create new custom sections like 'Menu of the day', 'Wines', 'Desserts', ect.";
            case 3:
                return configContext.language === 0 ? "Añade los platos y bebidas creados a tus secciones" : "Add the dishes and drinks created to your sections";
            case 4:
                return configContext.language === 0 ? "Mueve y configura las secciones hasta que queden a tu gusto" : "Move and configure the sections until they are to your liking";
            default:
                return configContext.language === 0 ? "Sigue los siguiente pasos para crear tu Carta" : "Follow the next steps to create your Charter";
        }
    };

    return (
        <Modal
            width={600}
            closable
            title={setTitle(following)}
            visible={props.showModal}
            onOk={showNext}
            okText={configContext.language === 0 ? "Siguiente" : "Next"}
            onCancel={() => props.setShowModal(false)}
            cancelText={configContext.language === 0 ? "Entendido" : "I got it"}
        >
            <Row>
                <Col>
                    <Timeline style={{ marginTop: 20 }}>
                        <Timeline.Item color={following === 1 ? 'blue' : 'gray'}><br /></Timeline.Item>
                        <Timeline.Item color={following === 2 ? 'blue' : 'gray'}><br /></Timeline.Item>
                        <Timeline.Item color={following === 3 ? 'blue' : 'gray'}><br /></Timeline.Item>
                        <Timeline.Item color={following === 4 ? 'blue' : 'gray'}><br /></Timeline.Item>
                    </Timeline>
                </Col>
                <Col>
                    {following === 1 && <img src={image1} alt={configContext.language === 0 ? "Añade todos los Platos y Bebidas en las secciones por defecto" : "Add all the Dishes and Drinks in the default sections"} />}
                    {following === 2 && <img src={image2} alt={configContext.language === 0 ? "Crea nuevas secciones personalizadas como 'Menú del día', 'Vinos', 'Postres', ect." : "Create new custom sections like 'Menu of the day', 'Wines', 'Desserts', ect."} />}
                    {following === 3 && <img src={image3} alt={configContext.language === 0 ? "Añade los platos y bebidas creados a tus secciones" : "Add the dishes and drinks created to your sections"} />}
                    {following === 4 && <img src={image4} alt={configContext.language === 0 ? "Mueve y configura las secciones hasta que queden a tu gusto" : "Move and configure the sections until they are to your liking"} />}
                </Col>
            </Row>
        </Modal>
    )
};

export default InstructionsMenu;
