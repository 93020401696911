import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Col, Input, Button, Form, Checkbox, Modal, Row, message } from 'antd';
import { axiosCall } from './Utilities/tools';
import envLocal from '../../env-local';
import { useUser } from '../../Context/ContextUser';
import { layout } from './Utilities/styles';
import ReCAPTCHA from "react-google-recaptcha";
import { useConfigContext } from '../../Context/ContextConfig';
import Politics from '../../Pages/Common/Politics';
import { useRestaurant } from '../../Context/ContextRestaurant';

let mailIntroduced: string = '';
let captchaResolved: boolean = false;

interface IProps extends RouteComponentProps {
    company: boolean;
    originMainPage: boolean;
}

const RegisterForm: React.FC<IProps> = (props) => {
    const { setUser } = useUser()!;
    const { configContext } = useConfigContext()!;
    const [politicsModal, setPoliticsModal] = useState<boolean>(false);
    const { setRestaurant } = useRestaurant()!;

    const toRegister = async (values: any) => {
        if (!values.politics) {
            message.warning(configContext.language === 0 ? "Debes aceptar la Política de Privacidad" : "You must accept the Privacy Policy");
        } else if (!captchaResolved) {
            message.warning(configContext.language === 0 ? "No se ha resuelto el captcha con éxito" : "Captcha not successfully resolved");
        } else if (values.password.length < 8) {
            message.warning(configContext.language === 0 ? "La contraseña tiene que tener una longitud mínima de 8 caracteres" : "The password has to be at least 8 characters long");
        } else if (values.password !== values.confirmPassword) {
            message.error(configContext.language === 0 ? "Las contraseñas no coinciden" : "The passwords don't match")
        } else {
            if (mailIntroduced === values.email) {
                message.warning(configContext.language === 0 ? "El Email introducido ya está registrado" : "The email entered is already registered");
            } else {
                mailIntroduced = values.email;
                const response = await axiosCall(
                    "post",
                    props.company ? envLocal.endpointAuthenticationComp : envLocal.endpointAuthentication,
                    "",
                    "signup",
                    props.company ? { ...values, idRestaurant: "pending", userType: 1, color: "#0667c0", language: configContext.language } : { ...values, language: configContext.language }
                );
                if (response.status === 200 && response.data.auth) {
                    message.success(configContext.language === 0 ? "Usuario Creado!" : "User Created!");
                    if (props.originMainPage) {
                        if (props.company) {
                            setUser(JSON.parse(decodeURIComponent(escape(window.atob(response.data.token.split('.')[1])))).user);
                            setRestaurant(JSON.parse(decodeURIComponent(escape(window.atob(response.data.token.split('.')[1])))).company)
                            window.localStorage.setItem(envLocal.authComp, response.data.token);
                            props.history.push('/Home');
                        } else {
                            Modal.info({
                                title: configContext.language === 0 ? "Usuario Creado!" : "User Created!",
                                content: (
                                    <div>
                                        <p>{configContext.language === 0 ? "Hemos mandado un correo a la dirección indicada para que actives tu cuenta." : "We have sent an email to the address indicated so that you can activate your account."}</p>
                                    </div>
                                ),
                                onOk() {
                                    props.history.push('/');
                                },
                            });
                        }
                    }
                } else if (response.status === 400) {
                    message.warning(configContext.language === 0 ? "El Email introducido ya está registrado" : "The email entered is already registered");
                } else {
                    message.error("Error")
                }
            }
        }
    };

    const onChange = (value: any) => {
        if (value) captchaResolved = true;
    };

    return (
        <>
            <Form {...layout}
                name="basic"
                onFinish={toRegister}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, type: 'email', message: configContext.language === 0 ? 'Introduce tu correo electrónico' : "Enter your email" }]}
                >
                    <Input maxLength={50} placeholder={configContext.language === 0 ? "Email de registro" : "Email registration"} />
                </Form.Item>
                <Form.Item
                    label={configContext.language === 0 ? "Nombre de Usuario" : "User Name"}
                    name="username"
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce tu nombre de usuario' : "Enter your username" }]}
                >
                    <Input maxLength={15} placeholder="Nombre" />
                </Form.Item>
                <Form.Item
                    label={configContext.language === 0 ? "Contraseña" : "Password"}
                    name="password"
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce tu Contraseña' : "Enter your Password" }]}
                >
                    <Input.Password maxLength={15} placeholder={configContext.language === 0 ? "Tu Contraseña" : "Your Password"} />
                </Form.Item>
                <Form.Item
                    label={configContext.language === 0 ? "Repite tu Contraseña" : "Repeat your password"}
                    name="confirmPassword"
                    rules={[{ required: true, message: configContext.language === 0 ? 'Repite tu Contraseña' : "Repeat your password" }]}
                >
                    <Input.Password maxLength={15} placeholder={configContext.language === 0 ? "Tu Contraseña" : "Your Password"} />
                </Form.Item>
                <Col className="textCenter noWrap" xs={{ span: 24, offset: 0 }} sm={{ span: 18, offset: 6 }}>
                    <Form.Item name="politics" valuePropName="checked">
                        <Checkbox><span className="wrapMobile">{configContext.language === 0 ? "He leído y acepto la" : "I have read and accept the"}<Button type="link" onClick={() => setPoliticsModal(true)}>{configContext.language === 0 ? "Política de Privacidad" : "Privacy Policy"}</Button></span></Checkbox>
                    </Form.Item>
                </Col>
                <Form.Item className="centerButtonForm">
                    <Col xs={{ span: 24, offset: 0 }} md={{ span: 1, offset: 24 }}>
                        <Button type="primary" htmlType="submit">
                            {configContext.language === 0 ? "Registrarse" : "Register"}
                        </Button>
                    </Col>
                </Form.Item>
            </Form>
            <Row justify="center" className="captcha">
                <ReCAPTCHA
                    sitekey="6Ld7oaoZAAAAAEnLXFoueUYHHhK2YPlnQd8e3w60"
                    onChange={onChange}
                    hl={configContext.language === 0 ? "es" : "en"}
                />
            </Row>
            <Modal
                title={configContext.language === 0 ? "Condiciones" : "Conditions"}
                visible={politicsModal}
                onOk={() => setPoliticsModal(false)}
                okText={configContext.language === 0 ? "Cerrar" : "Close"}
                onCancel={() => setPoliticsModal(false)}
                cancelButtonProps={{ style: { display: "none" } }}
            >
                <Politics />
            </Modal>
        </>
    )
};

export default withRouter(RegisterForm);



