import React, { useState, useEffect } from 'react';
import NavBar from '../../Components/Basic/NavBar';
import CompMenu from '../../Components/Menu/CompMenu';
import Dish from '../../Components/Menu/Views/Dish';
import Drink from '../../Components/Menu/Views/Drink';
import AddItemSection from '../../Components/Menu/Views/AddItemSection';
import Section from '../../Components/Menu/Views/Section';
import EditSection from '../../Components/Menu/Views/EditSection';
import InstructionsMenu from '../../Components/Menu/Views/InstructionsMenu';
import { Row, Col, Modal, message } from 'antd';
import envLocal from '../../env-local';
import { ISections, IItems, IAddItem, ISecEditProps } from '../../Components/Basic/Utilities/interfaces';
import { useRestaurant } from '../../Context/ContextRestaurant';
import SkeletonLoad from '../../Components/Basic/SkeletonLoad';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

let menu: ISections[] = [];
let drinks: IItems[] = [];
let dishes: IItems[] = [];
let renderView: any = null;

const CreateMenu: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(window.innerWidth > 800);
  const [skeletonLoad, setSkeletonLoad] = useState<boolean>(false);
  const [showModalView, setShowModalView] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>("101");
  const { restaurant } = useRestaurant()!;
  const { configContext } = useConfigContext()!;

  useEffect(() => {
    fetchData();
  }, []);

  const createItem = async (section: IAddItem) => {
    setShowModalView(true)
    switch (section.sectionType) {
      case 1:
        section.idSection !== undefined && setCurrentTab(section.idSection);
        renderView = section.sectionTitle === undefined ? <Dish item={section.item} getDishes={getDishes} setShowModalView={setShowModalView} restaurant={restaurant} /> : <AddItemSection getMenu={getMenu} section={section} setShowModalView={setShowModalView} dishes={dishes} />;
        break;
      case 2:
        section.idSection !== undefined && setCurrentTab(section.idSection);
        renderView = section.sectionTitle === undefined ? <Drink item={section.item} getDrinks={getDrinks} setShowModalView={setShowModalView} restaurant={restaurant} /> : <AddItemSection getMenu={getMenu} section={section} setShowModalView={setShowModalView} drinks={drinks} />;
        break;
      case 3:
        renderView = <AddItemSection getMenu={getMenu} section={section} setShowModalView={setShowModalView} drinks={drinks} dishes={dishes} />;
        break;
      case 4:
        renderView = <Section getMenu={getMenu} positionSec={section.positionSec} sectionType={section.sectionType} setCurrentTab={setCurrentTab} setShowModalView={setShowModalView} restaurant={restaurant} />;
        break;
      case 5:
        renderView = <Section getMenu={getMenu} isMenu={true} idSection={section.idSection} positionSec={section.positionSec} sectionType={section.sectionType} setCurrentTab={setCurrentTab} setShowModalView={setShowModalView} restaurant={restaurant} />;
        break;
      default:
        renderView = null;
        break;
    };
  };

  const fetchData = async () => {
    const fetchDrinks = getDrinks();
    const fetchDishes = getDishes();
    const fetchMenu = getMenu();
    await Promise.all([fetchDrinks, fetchDishes, fetchMenu]);
    setSkeletonLoad(false);
  };

  const getMenu = async () => {
    setSkeletonLoad(true);
    menu = (await axiosCall("get", envLocal.endpointMenu, "", restaurant._id)).data;
    setSkeletonLoad(false);
  };

  const getDishes = async () => {
    dishes = (await axiosCall("post", envLocal.endpointDish, envLocal.authComp)).data;
  };

  const getDrinks = async () => {
    drinks = (await axiosCall("post", envLocal.endpointDrink, envLocal.authComp)).data;
  };

  const editSection = async (sectionProps: ISecEditProps) => {
    setShowModalView(true);
    renderView = <EditSection getMenu={getMenu} setShowModalView={setShowModalView} setCurrentTab={setCurrentTab} sectionProps={sectionProps} />
  };

  const deleteItem = async (idItem: string, itemType: number) => {
    let sections: string[] = [];
    if (menu.length) {
      menu.forEach(section => {
        if (section.sectionType === 3 && section.itemsMenu.length) {
          section.itemsMenu.forEach((subSection: any) => {
            if (subSection.itemsMenu.length) {
              subSection.itemsMenu.forEach((e: any) => {
                if (e._id === idItem) sections.push(section.title)
              })
            }
          })
        }
        if (section.sectionType !== 3 && section.itemsMenu.length) {
          section.itemsMenu.forEach(e => {
            if (e._id === idItem) sections.push(section.title)
          })
        }
      })
    }
    if (sections.length) {
      message.warning(configContext.language === 0 ? `Elimina antes el producto de la sección ${sections.join(', ')}` : `You must first remove the product from the ${sections.join(', ')} section`)
    } else {
      confirm({
        title: configContext.language === 0 ? '¿Quieres eliminar el producto?' : "Do you want to delete the item?",
        icon: <ExclamationCircleOutlined />,
        okText: configContext.language === 0 ? 'Sí' : "Yes",
        okType: 'primary',
        cancelText: 'No',
        async onOk() {
          const response = await axiosCall("delete", envLocal.endpointItemMenu, envLocal.authComp, idItem);
          if (response.status === 200) {
            setSkeletonLoad(true);
            itemType === 1 ? await getDishes() : await getDrinks();
            setCurrentTab(itemType === 1 ? "dishes" : "drinks");
            setSkeletonLoad(false);
            message.success(configContext.language === 0 ? 'Eliminado!' : "Deleted!")
          } else {
            message.error("Error")
          }
        },
        onCancel() {
        },
      })
    }
  };


  return (
    <div className="basicContainer">
      <NavBar />
      <Row className="createMenu">
        {showModal ?
          <InstructionsMenu showModal={showModal} setShowModal={setShowModal} />
          : <Col xs={{ span: 24, offset: 0 }} sm={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
            {skeletonLoad ?
              <div style={{ backgroundColor: "white", padding: 40 }}>
                <SkeletonLoad title={true} quantity={9} marginTop={10} />
              </div>
              :
              <CompMenu createItem={createItem} deleteItem={deleteItem} editSection={editSection} getMenu={getMenu} menu={menu} dishes={dishes} drinks={drinks} currentTab={currentTab} setCurrentTab={setCurrentTab} />
            }
          </Col>
        }
        <Modal
          className="modalMenu"
          visible={showModalView}
          destroyOnClose={true}
          onOk={() => setShowModalView(false)}
          onCancel={() => setShowModalView(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          okText={configContext.language === 0 ? "Cancelar" : "Cancel"}
          bodyStyle={{ padding: 0 }}
        >
          {renderView}
        </Modal>
      </Row>
    </div>
  );
};

export default CreateMenu;