import React from 'react';
import { Row, Dropdown, Button, Menu } from 'antd';
import { useConfigContext } from '../../Context/ContextConfig';
import { DownOutlined } from '@ant-design/icons';
import es_32 from './Utilities/Images/es_32.png';
import gb_32 from './Utilities/Images/gb_32.png';
import cat from './Utilities/Images/cat.png';

const Header: React.FC = () => {
    const { configContext, setConfigContext } = useConfigContext()!;

    return (
        <Row className="bliscopTitle">
            <div>
                <b className="bliscopName textCenter"><a className="colorWhite" href="https://www.bliscop.com">Bliscop</a></b>
            </div>
            <div>
                <div className="colorWhite textCenter">{configContext.language === 0 ? "Digitaliza tu negocio de restauración" : "Digitalize your restaurant with us"}</div>
            </div>
            <div className="textLeft boldLarge textShadowNone">
                <Dropdown overlay={
                    <Menu>
                        <Menu.Item key="0">
                            <Button className="colorBlack" type="link" onClick={() => setConfigContext({ language: 0 })}>{configContext.isCatalan ? "Catalán" : "Español"}</Button>
                        </Menu.Item>
                        <Menu.Item key="1">
                            <Button className="colorBlack" type="link" onClick={() => setConfigContext({ language: 1 })}>English</Button>
                        </Menu.Item>
                    </Menu>
                } trigger={['click']}>
                    <a className="ant-dropdown-link " onClick={e => e.preventDefault()}>
                        {configContext.language === 0 ? (configContext.isCatalan ? <img src={cat} /> : <img src={es_32} />) : <img src={gb_32} />} <DownOutlined style={{ fontSize: 15, color: "white" }} />
                    </a>
                </Dropdown>
            </div>
        </Row>
    )
};

export default Header;