import React from 'react';
import { Menu } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { SettingTwoTone, ReadOutlined, UserOutlined, EditOutlined, DesktopOutlined, ExperimentOutlined, ShoppingCartOutlined, AreaChartOutlined, PoweroffOutlined, FormOutlined } from '@ant-design/icons';
import Auth from '../Auth/Auth';
import { logout } from "../Auth/AuthService";
import { useUser } from '../../Context/ContextUser';
import { useConfigContext } from '../../Context/ContextConfig';

const { SubMenu } = Menu;

interface IProps extends RouteComponentProps { };

const NavBar: React.FC<IProps> = (props) => {
    const { user } = useUser()!;
    const { configContext } = useConfigContext()!;

    const pushea = (path: string) => {
        Auth.login(() => {
            props.history.push({
                pathname: `/${path}`
            })
        });
    };

    if(user.userType === 6) return null

    return (
        <>
            <Menu style={{ background: "#F8F8F8", textAlign: "center" }} selectedKeys={[props.location.pathname.split('/')[1]]} mode="horizontal">
                {[1, 3, 4, 5].includes(user.userType) && <Menu.Item key="Home" onClick={() => pushea('Home')}>
                    <DesktopOutlined style={{ color: "#1890FF" }} />
                    {configContext.language === 0 ? "Mi Restaurante" : "My Restaurant"}
                </Menu.Item>}
                <Menu.Item key="TaskBoard" onClick={() => pushea('TaskBoard')}>
                    <EditOutlined style={{ color: "#1890FF" }} />
                    {configContext.language === 0 ? "Servicios" : "Services"}
                </Menu.Item>
                {[1, 2, 4, 5].includes(user.userType) && <Menu.Item key="Kitchen" onClick={() => pushea('Kitchen')}>
                    <ExperimentOutlined style={{ color: "#1890FF" }} />
                    {configContext.language === 0 ? "Cocina" : "Kitchen"}
                </Menu.Item>}
                {[1, 3, 4, 5].includes(user.userType) && <Menu.Item key="Bookings" onClick={() => pushea('Bookings')}>
                    <FormOutlined style={{ color: "#1890FF" }} />
                    {configContext.language === 0 ? "Reservas" : "Bookings"}
                </Menu.Item>}
                <Menu.Item key="Employees" onClick={() => pushea('Employees')}>
                    <UserOutlined style={{ color: "#1890FF" }} />
                    {configContext.language === 0 ? "Empleados" : "Employees"}
                </Menu.Item>
                {[1, 5].includes(user.userType) && <Menu.Item key="Stock" onClick={() => pushea('Stock')}>
                    <ShoppingCartOutlined style={{ color: "#1890FF" }} />
                    {configContext.language === 0 ? "Inventario" : "Stock"}
                </Menu.Item>}
                {[1, 5].includes(user.userType) && <Menu.Item key="Reports" onClick={() => pushea('Reports')}>
                    <AreaChartOutlined style={{ color: "#1890FF" }} />
                    {configContext.language === 0 ? "Informes" : "Reports"}
                </Menu.Item>}
                {[1].includes(user.userType) && <Menu.Item key="CreateMenu" onClick={() => pushea('CreateMenu')}>
                    <ReadOutlined style={{ color: "#1890FF" }} />
                    {configContext.language === 0 ? "Editar Carta" : "Edit Menu"}
                </Menu.Item>}
                {![5].includes(user.userType) && <SubMenu
                    title={<span>
                        <SettingTwoTone />
                        {configContext.language === 0 ? "Configuración" : "Configuration"}
                    </span>
                    }>
                    {[1].includes(user.userType) && <Menu.ItemGroup title={configContext.language === 0 ? "Mi Restaurante" : "My Restaurant"}>
                        <Menu.Item key="RestaurantData" onClick={() => pushea('RestaurantData')}>
                            {configContext.language === 0 ? "Datos Restaurante" : "Restaurant Details"}
                        </Menu.Item>
                        <Menu.Item key="Config" onClick={() => pushea('Config')}>
                            {configContext.language === 0 ? "Configuración" : "Configuration"}
                        </Menu.Item>
                        <Menu.Item key="QR" onClick={() => pushea('QRCodes')}>
                            {configContext.language === 0 ? "Códigos QR" : "QR"}
                        </Menu.Item>
                    </Menu.ItemGroup>}
                    <Menu.ItemGroup title={configContext.language === 0 ? "Usuario" : "User"}>
                        <Menu.Item key="Profile" onClick={() => pushea('Profile')}>
                            {configContext.language === 0 ? "Datos Usuario" : "User Details"}
                        </Menu.Item>
                    </Menu.ItemGroup>
                </SubMenu>}
                <Menu.Item key="logout" onClick={() => logout()}>
                    <PoweroffOutlined style={{ color: "#1890FF" }} />
                    {configContext.language === 0 ? "Salir" : "Exit"}
                </Menu.Item>
            </Menu>
            {[5].includes(user.userType) && <div className="infoSection">{selectInfo(configContext.language!)}</div>}
        </>
    )
};

const selectInfo = (language: number) => {
    switch (window.location.pathname) {
        case "/Home":
            return language === 0 ? 
            "Esta es la sección principal. Puedes ver el nivel de ocupación del negocio, crear pedidos o tareas para tus empleados, ver el último pedido entrante y un resumen del inventario. También puedes ver los pedidos en curso e interactuar con ellos. Un resumen de las tareas que hay. Por último los pedidos completados, pudiendo filtrar por día" 
            : "This is the main section. You can see the business' occupancy level, create orders or tasks for your employees, see the last incoming order and a summary of the inventory. You can also view current orders and interact with them. A summary of the tasks that are available. Finally the completed orders, being able to filter by day";
        case "/TaskBoard":
            return language === 0 ? 
            "Esta es la sección de tareas, estas se agrupan en función de su estado. Puedes ver la última tarea, los empleados que están trabajando actualmente, para que aparezcan aquí en la sección 'Empleados' tienen que haber registrado su entrada. Pinchando en un empleado pasa a estar inactivo, por lo que no se le asignan tareas. Las tareas van entrando al sistema y se asignan automáticamente entre los empleados en función de las mesas que tengan asignadas y el número de tareas que tienen pendientes" 
            : "This is the task section, these are grouped according to their status. You can see the last task, the employees who are currently working, in order to appear here in the 'Employees' section they must have registered their entry. By clicking on an employee he becomes inactive, so no tasks are assigned to him. The tasks are entered into the system and are automatically assigned among the employees according to the tables they have assigned and the number of tasks they have pending"
        case "/Kitchen":
            return language === 0 ? 
            "Esta es la sección cocina. Puedes ver el último pedido entrante, un resumen con la agrupación de los platos solicitados y más abajo los pedidos pendientes. Cuando un plato esté cocinado se marca su respectivo check y se selecciona notificar lo que crea una tarea para que el camarero venga a recogerlo y lo lleve a la mesa correspondiente" 
            : "This is the kitchen section. You can see the last incoming order, a summary with the grouping of the requested dishes and below the pending orders. When a dish is cooked its respective check is marked and you select to notify what creates a task for the waiter to come and pick it up and take it to the corresponding table";
        case "/Bookings":
            return language === 0 ? 
            "Esta es la sección reservas. Podemos crear nuevas reservas, ver un resumen con el número de reservas agrupadas por hora y más abajo las existentes. Podremos filtrar por día, hora, número de clientes y mesa, así como tachar las reservas si ya han llegado los comensales o directamente borrarlas si hay una cancelación, por ejemplo" 
            : "This is the reservation section. We can create new reservations, see a summary with the number of reservations grouped by hour and below the existing ones. We can filter by day, time, number of clients and table, as well as cross out the reservations if the guests have already arrived or directly delete them if there is a cancellation, for example";
        case "/Employees":
            return language === 0 ? 
            "Esta es la sección empleados. Puedes ver un histórico con los horarios de entrada y salida de los empleados, consultar quien ha trabajado hoy y más abajo ver el total de empleados así como registrar su entrada y salida" 
            : "Esta es la sección empleados. Puedes ver un histórico con los horarios de entrada y salida de los empleados, consultar quien ha trabajado hoy y más abajo ver el total de empleados así como registrar su entrada y salida";
        case "/Stock":
            return language === 0 ? 
            "Esta es la sección inventario. Puedes ver los productos básicos asociados a los elementos de tu carta. Cuando se solicita un plato, automáticamente se descuenta del stock en las proporciones que tengamos configuradas. Puedes registrar proveedores y asociarlos a los productos para contactar con ellos con más facilidad" 
            : "This is the inventory tab. You can see the basic products associated with the items on your menu. When you order a dish, it is automatically deducted from the stock in the proportions we have set up. You can register suppliers and associate them with the products to contact them more easily";
        case "/Reports":
            return language === 0 ? 
            "Esta es la sección Informes. Cuando se cierra un pedido automáticamente se registran sus datos y puedes filtrar por el rango de fechas que desees. Tienes gráficos donde te muestra los ingresos diarios (pinchando en la lupa puedes buscar por rango de fechas), los ingresos de los menús frente a la carta, los ingresos por tipo de pedido, el número de pedidos de cada tipo realizados, en número de pedidos agrupados por hora y la cantidad demandada de cada plato" 
            : "This is the Reports section. When an order is closed, its data is automatically recorded and you can filter by the date range you wish. You have graphics where you can see the daily income (clicking on the magnifying glass you can search by date range), the income of the menus in front of the menu, the income by type of order, the number of orders of each type made, in number of orders grouped by hour and the quantity demanded of each dish";
        default:
            return "";
    }
}

export default withRouter(NavBar);