import React, { useState } from 'react';
import { Col, Form, Input, Button } from 'antd';
import { useConfigContext } from '../../Context/ContextConfig';
import Header from '../../Components/Basic/Header';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import { WhatsAppOutlined } from '@ant-design/icons';
import envLocal from '../../env-local';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const Contact: React.FC = () => {
    const { configContext } = useConfigContext()!;
    return (
        <div>
            <Header />
            <Col xs={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }}>
                <h1><p className="textCenter mt_20px">{configContext.language === 0 ? "Contacte con Nosotros" : "Contact with us"}</p></h1>
                <ContactExp language={configContext.language!} />
                <h4 className="textCenter m_20x0">
                    <p>{configContext.language === 0 ? "Puede encontar más información en nuesta página web:" : "You can find more information on our website:"}</p>
                    <p>{configContext.language === 0 ? <a href="https://www.bliscop.com">www.bliscop.com</a> : <a href="https://www.bliscop.com/en">www.bliscop.com</a>}</p>
                </h4>
            </Col>
        </div>
    )
};

export default Contact;


type ILanguage = {
    language: number
}


export const ContactExp = ({ language }: ILanguage) => {
    const [sended, setSended] = useState(false);

    const onFinish = (values: any) => {
        axiosCall("post", envLocal.endpointContact, "", "", values)
        setSended(true);
    };

    return (
        <>
            <h3 className="standardText textCenter mb_30px">
                {language === 0 ? "Si tiene alguna duda con respecto a la aplicación puede contactar con nosotros rellenando el siguiente formulario:" : "If you have any questions regarding the application you can contact us by filling out the following form:"}
            </h3>
            {sended ?
                <h3 className="standardText textCenter mb_30px">
                    {language === 0 ? "Gracias por enviarnos tu consulta, le responderemos tan pronto como sea posible." : "Thank you for sending us your question, we will answer it as soon as possible."}
                </h3 >
                :
                <Form {...layout} name="contactForm" onFinish={onFinish}>
                    <Form.Item name="email" label={language === 0 ? "Tu Email" : "Your Email"} rules={[{ type: 'email', required: true, message: language === 0 ? "Introduce tu email" : "Enter your Email" }]}>
                        <Input maxLength={50} />
                    </Form.Item>
                    <Form.Item name="country" label={language === 0 ? "País" : "Country"} rules={[{ required: true, message: language === 0 ? "Introduce tu País" : "Enter your country" }]}>
                        <Input maxLength={50} />
                    </Form.Item>
                    <Form.Item name="question" label={language === 0 ? "Consulta" : "Question"} rules={[{ required: true, message: language === 0 ? "Introduce una pregunta" : "Enter a question" }]}>
                        <Input.TextArea maxLength={500} className="textAreaContact" />
                    </Form.Item>
                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button type="primary" htmlType="submit">
                            Enviar
                        </Button>
                    </Form.Item>
                </Form>
            }
            <h3 className="standardText textCenter">
                {language === 0 ? "También puede contactarnos por WhatsApp en el siguiente número:" : "You can also contact us through WhatsApp at the following number:"}
            </h3>
            <div className="large bold textCenter mt_30px"><WhatsAppOutlined /> (+0034) 665 69 09 23</div>
        </>
    )
}

