import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useConfigContext } from '../../Context/ContextConfig';
import { useRestaurant } from '../../Context/ContextRestaurant';
import { useUser } from '../../Context/ContextUser';
import envLocal from '../../env-local';
import { axiosCall } from '../../Components/Basic/Utilities/tools';

const PreRegister: React.FC<RouteComponentProps> = (props) => {
    const { setConfigContext } = useConfigContext()!;
    const { setUser } = useUser()!;
    const { setRestaurant } = useRestaurant()!;

    useEffect(() => {
        let url = (new URL(window.location.href)).searchParams;
        let token = "";
        let language = 0;
        if (url) {
            try {
                window.localStorage.removeItem("restaurant");
                language = Number(url.get("language")!);
                token = url.get("token")!;
                setUser(JSON.parse(decodeURIComponent(escape(window.atob(token.split('.')[1])))).user);
                setRestaurant(JSON.parse(decodeURIComponent(escape(window.atob(token.split('.')[1])))).company)
                window.localStorage.setItem(envLocal.authComp, token);
                window.localStorage.removeItem(envLocal.authUser);
                setConfigContext({ language, showCaptcha: false });
                props.history.push('/Home');
            } catch (e) {
                axiosCall("post", envLocal.endpointError, "", "", {
                    href: window.location.href,
                    error: e.toString(),
                    info: undefined,
                    token: token ? token : undefined
                });
                props.history.push('/');
            }
        } else {
            props.history.push('/');
        }
    }, []);

    return (<div>Redirect</div>);
};

export default PreRegister;