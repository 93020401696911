import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'antd';
import { Success } from '../../Components/Basic/Results/Success';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import envLocal from '../../env-local';
import moment from 'moment';
import SkeletonLoad from '../../Components/Basic/SkeletonLoad';
import { IStateCreateMenu } from '../../Components/Basic/Utilities/interfaces';
import { axiosCall, getDataTokenOrder } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';
import Header from '../../Components/Basic/Header';


interface IValuesOrder {
    status: number;
    timeToPrepare: Date;
    idTable: string;
};

const mapStatus = (input: { status: number, language: number }) => {
    switch (input.status) {
        case -2:
            return input.language === 0 ? "Validando Pedido..." : "Validating Order...";
        case -1:
            return input.language === 0 ? "Validando Pedido..." : "Validating Order...";
        case 0:
            return input.language === 0 ? "Pedido Aceptado" : "Order accepted";
        case 1:
            return input.language === 0 ? "Pedido Aceptado" : "Order accepted";
        case 2:
            return input.language === 0 ? "Pedido Tramitado" : "Order Processing";
        case 9:
            return input.language === 0 ? "Pedido Cancelado" : "Order Canceled";
        default:
            return input.language === 0 ? "Validando Pedido..." : "Validating Order...";
    }
}

const Waiting: React.FC<RouteComponentProps> = (props) => {
    const [valuesOrder, setValuesOrder] = useState<IValuesOrder>();
    const [showSuccess, setShowSuccess] = useState<boolean>(true);
    const [skeletonLoad, setSkeletonLoad] = useState<boolean>(true);
    const { configContext } = useConfigContext()!;
    const [orderParams] = useState<IStateCreateMenu | null>(getDataTokenOrder());

    useEffect(() => {
        setTimeout(() => setShowSuccess(false), 2000);
        if (orderParams) {
            fetchData();
        } else {
            props.history.push('/');
        }
        setSkeletonLoad(false)
    }, []);

    const fetchData = async () => {
        const response = (await axiosCall("get", envLocal.endpointOrder, envLocal.tokenOrder)).data;
        if (response._id !== undefined) {
            const { status, timeToPrepare, idTable } = response;
            if (!orderParams!.remoteOrder && (status === 1 || status === 0)) {
                props.history.push("/Order")
            } else if (!orderParams!.remoteOrder && status === 2) {
                props.history.push("/Opinion")
            } else {
                setValuesOrder({ status, timeToPrepare, idTable });
                evaluateStatus();
            }
        } else {
            props.history.push('/');
        };
    };

    const evaluateStatus = () => {
        const interval: any = setInterval(
            async () => {
                const response = (await axiosCall("get", envLocal.endpointOrder, envLocal.tokenOrder)).data;
                setValuesOrderFunction(response) && clearInterval(interval);
            }, 30000);
    };

    const setValuesOrderFunction = (response: any) => {
        if ([0, 1, 2, 9].includes(response.status)) {
            if (!orderParams!.remoteOrder && (response.status === 0 || response.status === 1)) {
                props.history.push("/Order")
            }
            if (!orderParams!.remoteOrder && response.status === 2) {
                props.history.push("/Opinion")
            }
            const { status, timeToPrepare, idTable } = response;
            setValuesOrder({ status, timeToPrepare, idTable });
            return true;
        }
        if ([-2, -1].includes(response.status)) {
            const { status, timeToPrepare, idTable } = response;
            setValuesOrder({ status, timeToPrepare, idTable });
            return false;
        }
        return true;
    };

    return (
        showSuccess ?
            <Success title={configContext.language === 0 ? "Pedido Recibido!" : "Order received!"} subTitle={configContext.language === 0 ? "Tu pedido está siendo validado." : "Your order is being validated."} />
            :
            <>
                <Header />
                <div className="basicContainerColor">
                    <div className="sectionSquare" style={{ margin: "30px 25px 5px 25px" }}>
                        <div className="sectionSquareTitle">{configContext.language === 0 ? "Estado del Pedido" : "Order Status"}</div>
                        <div className="sectionSquareBody" style={{ padding: "15px 0px" }}>
                            {skeletonLoad ?
                                <Row>
                                    <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }}><SkeletonLoad title={true} quantity={2} marginTop={10} /></Col>
                                </Row>
                                :
                                valuesOrder === undefined ?
                                    orderParams!.telephoneRestaurant && <div className="textCenter m_20px">{configContext.language === 0 ? "Ha ocurrido un error, puedes contactar con nosotros para conocer el estado de tu pedido en el número" : "An error has occurred, you can contact us for the status of your order at"}: <b>{orderParams!.telephoneRestaurant}</b></div>
                                    :
                                    <Row justify="center">
                                        <div>
                                            <div className="boldLarge m_20x0 textCenter">{mapStatus({ status: valuesOrder.status, language: configContext.language || 0 })}</div>
                                            {orderParams!.telephoneRestaurant && <div className="textCenter m_20px">{configContext.language === 0 ? "Si nos necesitas puedes contactar con nosotros en el siguiente número" : "If you need us, you can contact us at the following number"}: <b>{orderParams!.telephoneRestaurant}</b></div>}
                                            {valuesOrder?.timeToPrepare && <div className="textCenter m_20px">{valuesOrder.idTable === "-2" ? (configContext.language === 0 ? "Tu pedido saldrá de nuesto restaurante para su envío a las " : "Your order will leave our restaurant for shipment at ") : (configContext.language === 0 ? "Puedes recoger aquí tu pedido a las " : "You can pick up your order here at ")}<b>{moment(valuesOrder.timeToPrepare).format("HH:mm")}.</b></div>}
                                            {[0, 1, 2].includes(valuesOrder.status) && <div className="textCenter">
                                                <div className="m_4x10">{configContext.language === 0 ? "Agradeceríamos que compartieras tu opinión en el siguiente enlace" : "We would appreciate it if you shared your opinion in the following link"}: </div>
                                                <div><Button style={{ fontSize: "160%", fontWeight: "bold" }} onClick={() => props.history.push("/Opinion")} type="link">{configContext.language === 0 ? "Tu Opinión" : "Your Opinion"}</Button></div>
                                            </div>}
                                        </div>
                                    </Row>}
                        </div>
                    </div>
                </div>
            </>
    )
};

export default withRouter(Waiting);