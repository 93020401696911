import React from 'react';
import './index.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ProtectedRoute from "./Components/Auth/ProtectedRoute";
import ErrorBoundary from './Components/Basic/ErrorBoundary';
import Footer from './Components/Basic/Footer';

import Login from './Pages/Common/Login'
import Profile from './Pages/Common/Profile';
import ValidateUser from './Pages/Common/ValidateUser';
import ErrorPage from './Pages/Common/ErrorPage';
import Politics from './Pages/Common/Politics';
import Contact from './Pages/Common/Contact';
import LanguageRedirect from './Pages/Common/LanguageRedirect';
import PreRegister from './Pages/Common/PreRegister'

import HomeClient from './Pages/Client/HomeClient';
import MenuClient from './Pages/Client/MenuClient';
import OrderClient from './Pages/Client/OrderClient';
import Waiting from './Pages/Client/Waiting';
import Opinion from './Pages/Client/Opinion';
import ChangePass from './Pages/Client/ChangePass'

import HomeCompany from './Pages/Company/HomeCompany';
import CreateRestaurant from './Pages/Company/CreateRestaurant';
import CreateMenu from './Pages/Company/CreateMenu';
import Employees from './Pages/Company/Employees';
import CreateUserEmp from './Pages/Company/CreateUserEmp';
import TaskBoard from './Pages/Company/TaskBoard';
import TimeHistoric from './Components/Employees/TimeHistoric';
import Kitchen from './Pages/Company/Kitchen';
import Stock from './Pages/Company/Stock';
import StockTable from './Components/Stock/StockTable';
import Config from './Pages/Company/Config';
import Reports from './Pages/Company/Reports';
import Bookings from './Pages/Company/Bookings';
import Payments from './Pages/Company/Payments';
import Packs from './Pages/Company/Packs';
import QRCodes from './Pages/Company/QRCodes';
import Schedules from './Pages/Company/Schedules';
import StockStats from './Components/Stock/StockStats';
import SuccessPay from './Pages/Company/SuccessPay';

import ToPrint from './Components/Basic/ToPrint';

import { RestaurantProvider } from './Context/ContextRestaurant';
import { UserProvider } from './Context/ContextUser';
import { ConfigProvider } from './Context/ContextConfig';

function App(): JSX.Element {
  return (
    <ErrorBoundary>
      <ConfigProvider>
        <Router>
          <UserProvider>
            <Route path='/' exact component={HomeClient} />
            <Route path='/Validate' component={ValidateUser} />
            <Route path='/ChangePass' component={ChangePass} />
            <Route path='/ErrorPage' exact component={ErrorPage} />
            <Route path='/Contact' exact component={Contact} />
            <Route path='/Politics' exact component={Politics} />
            <Route path='/(en|es)' exact component={LanguageRedirect} />
            <Route path='/Menu' exact component={MenuClient} />
            <Route path='/Order' exact component={OrderClient} />
            <Route path='/Waiting' exact component={Waiting} />
            <Route path='/Profile' exact component={Profile} />
            <Route path='/ToPrint' exact component={ToPrint} />
            <RestaurantProvider>
              <Route path='/PreRegister' exact component={PreRegister} />
              <Route path='/Login' exact component={Login} />
              <Route path='/Opinion' exact component={Opinion} />
              <ProtectedRoute path='/Home' exact component={HomeCompany} />
              <ProtectedRoute path='/RestaurantData' exact component={CreateRestaurant} />
              <ProtectedRoute path='/CreateMenu' exact component={CreateMenu} />
              <ProtectedRoute path='/Employees' exact component={Employees} />
              <ProtectedRoute path='/CreateUserEmp' exact component={CreateUserEmp} />
              <ProtectedRoute path='/TaskBoard' exact component={TaskBoard} />
              <ProtectedRoute path='/TimeHistoric' exact component={TimeHistoric} />
              <ProtectedRoute path='/Kitchen' exact component={Kitchen} />
              <ProtectedRoute path='/Stock' exact component={Stock} />
              <ProtectedRoute path='/StockTable' exact component={StockTable} />
              <ProtectedRoute path='/Config' exact component={Config} />
              <ProtectedRoute path='/Reports' exact component={Reports} />
              <ProtectedRoute path='/Bookings' exact component={Bookings} />
              <ProtectedRoute path='/Payments' exact component={Payments} />
              <ProtectedRoute path='/Packs' exact component={Packs} />
              <ProtectedRoute path='/QRCodes' exact component={QRCodes} />
              <ProtectedRoute path='/Schedules' exact component={Schedules} />
              <ProtectedRoute path='/StockStats' exact component={StockStats} />
              <ProtectedRoute path='/pay-ok' exact component={SuccessPay} />
            </RestaurantProvider>
          </UserProvider>
        </Router>
        <Footer />
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export default App;