import React, { useState, useEffect } from 'react';
import { List, Button, Col, Tooltip, message, Modal } from 'antd';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import { EditTwoTone, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { RouteComponentProps } from 'react-router-dom';
import envLocal from '../../env-local';
import NavBar from '../../Components/Basic/NavBar';
import { IUsers } from '../../Components/Basic/Utilities/interfaces';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import { useUser } from '../../Context/ContextUser';
import { useConfigContext } from '../../Context/ContextConfig';
import SquareInfo from '../../Components/Basic/SquareInfo';
import { PlusOutlined, SearchOutlined, CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';

const { confirm } = Modal;

const Employees: React.FC<RouteComponentProps> = (props) => {
    const [users, setUsers] = useState<Array<IUsers>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [buttonLoading, setButtonLoading] = useState<string>("-");
    const myUser = useUser()!.user;
    const { configContext } = useConfigContext()!;
    let widthSize = window.innerWidth;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        if ([1, 4, 5].includes(myUser.userType)) {
            setUsers((await axiosCall("post", envLocal.endpointAuthenticationComp, envLocal.authComp, "findUsers")).data);
        } else {
            setUsers([(await axiosCall("get", envLocal.endpointAuthenticationComp, envLocal.authComp, "findUsers")).data]);
        }
        setIsLoading(false);
    };

    const pushUser = (user?: IUsers) => {
        props.history.push({
            pathname: '/CreateUserEmp',
            state: { user }
        });
    };

    const deleteUser = async (idUser: string) => {
        confirm({
            title: configContext.language === 0 ? '¿Quieres borrar el usuario?' : "Do you want to delete the user?",
            icon: <ExclamationCircleOutlined />,
            okText: configContext.language === 0 ? 'Sí' : "Yes",
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                const responseStatus = (await axiosCall("delete", envLocal.endpointAuthenticationComp, envLocal.authComp, "actions/" + idUser)).status;
                if (responseStatus === 200) {
                    message.success(configContext.language === 0 ? "Usuario Eliminado!" : "User deleted!");
                } else {
                    message.error(configContext.language === 0 ? "Error eliminando el usuario" : "Error removing the user");
                };
                await fetchData();
            },
            onCancel() {
            },
        });

    };

    const timeRegister = async (input: { idUser: string; status: number; }) => {
        const now = new Date();       
        const day = moment().format('DD/MM/YYYY');
        const hours = moment().format('HH:mm');
        const register = {
            status: input.status,
            day,
            registerDate: now,
            [input.status === 1 ? "entry" : "close"]: hours
        };
        const responseStatus = (await axiosCall("put", envLocal.endpointTimeRegister, envLocal.authComp, "user/" + input.idUser, register)).status;
        if (responseStatus === 200) {
            message.success(input.status === 1 ? (configContext.language === 0 ? "Entrada Registrada a las " : "Entry registered at ") + hours : (configContext.language === 0 ? "Salida Registrada a las " : "Departure recorded at ") + hours);
        } else {
            message.error("Error");
        }
        await fetchData();
        setButtonLoading("-");
    };

    const getSquareTask = () => {
        const workToday = users.filter(user => moment(user.entry, 'HH:mm DD/MM/YYYY') > moment().startOf('day') || moment(user.close, 'HH:mm DD/MM/YYYY') > moment().startOf('day')).map(user => user.username);

        return [
            {
                squareStyle: { width: widthSize < 690 ? "100%" : 350, height: 175 },
                title: configContext.language === 0 ? "Acciones" : "Actions",
                body:
                    <>
                        {[1, 4, 5].includes(myUser.userType) && <div className="buttonActions">
                            <Button
                                style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white" }}
                                type="primary"
                                onClick={() => props.history.push('/TimeHistoric')}
                                icon={<SearchOutlined />}
                            >
                                {configContext.language === 0 ? "Ver Histórico" : "View History"}
                            </Button>
                        </div>}
                        {myUser.userType === 1 && <div className="buttonActions">
                            <Button
                                style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white" }}
                                type="primary"
                                onClick={() => pushUser()}
                                icon={<PlusOutlined />}
                            >
                                {configContext.language === 0 ? "Nuevo Empleado" : "New Employee"}
                            </Button>
                        </div>}
                        <div className="buttonActions">
                            <Button
                                style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white" }}
                                type="primary"
                                icon={<CalendarOutlined />}
                                onClick={() => props.history.push('/Schedules')}
                            >
                                {configContext.language === 0 ? "Ver Turnos" : "View Shifts"}
                            </Button>
                        </div>
                    </>
            },
            {
                squareStyle: { width: widthSize < 690 ? "100%" : "70%", height: "auto" },
                title: configContext.language === 0 ? "¿Quién ha trabajado hoy?" : "Who has worked today?",
                body:
                    workToday.length ?
                        <div className="mt_20px">
                            <b>{workToday.join(', ')}</b>
                        </div>
                        :
                        <div className="textCenter mt_25px">
                            <strong>{configContext.language === 0 ? "No se ha realizado ningún registro hoy" : "No registration today"}</strong>
                        </div>
            }
        ]
    }

    return (
        <div className="basicContainerColor">
            <NavBar />
            <div className="titleSection">{configContext.language === 0 ? "Listado de Empleados" : "Employee List"}</div>
            {isLoading ?
                <LoadingSpin />
                :
                <div className="mb_30px">
                    <SquareInfo squares={getSquareTask()} />
                    <div className="sectionSquare" style={{ margin: widthSize < 690 ? "30px 0px 5px 0px" : "30px 15px 5px 15px", padding: widthSize < 690 ? "15px 0px" : 15 }}>
                        <div className="sectionSquareTitle">{configContext.language === 0 ? "Registros" : "Registers"}</div>
                        <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}>
                            <Col xs={{ span: 23, offset: 1 }} sm={{ span: 14, offset: 5 }}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={users}
                                    renderItem={user => (
                                        <List.Item
                                            actions={[
                                                user.status === 3 ? <Button loading={user._id === buttonLoading} type="link" onClick={() => { timeRegister({ idUser: user._id, status: 1 }); setButtonLoading(user._id) }}>{configContext.language === 0 ? "Entrada" : "Entry"}</Button> : null,
                                                (user.status === 1 || user.status === 2) ? <Button loading={user._id === buttonLoading} type="link" onClick={() => { timeRegister({ idUser: user._id, status: 3 }); setButtonLoading(user._id) }}>{configContext.language === 0 ? "Salida" : "Exit"}</Button> : null
                                            ]}
                                        >
                                            <List.Item.Meta
                                                avatar={<Button style={{ margin: "0px 3px", color: "white", backgroundColor: user.color }}>{user.username}</Button>}
                                                title={<React.Fragment>{user.username}
                                                    {user.userType !== 1 && myUser.userType === 1 && <Tooltip placement="topRight" title={configContext.language === 0 ? "Editar Usuario" : "Edit User"}><Button type="link" icon={<EditTwoTone />} onClick={() => pushUser(user)} /></Tooltip>}
                                                    {user.userType !== 1 && myUser.userType === 1 && <Tooltip placement="topRight" title={configContext.language === 0 ? "Borrar Usuario" : "Delete User"}><Button type="link" style={{ color: "red" }} icon={<CloseOutlined />} onClick={() => deleteUser(user._id)} /></Tooltip>}
                                                </React.Fragment>}
                                                description={<React.Fragment>{user.entry !== undefined ? <div>{configContext.language === 0 ? "Entrada: " : "Entry: "}{<b>{user.entry}</b>}. </div> : ""}{user.close !== undefined ? <div>{configContext.language === 0 ? "Salida: " : "Exit: "}{<b>{user.close}</b>}</div> : ""}</React.Fragment>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Employees;