import React from 'react';
import { Table } from 'antd';
import { IStats, IBasicProduct, IBasicMeal } from '../../Components/Basic/Utilities/interfaces';
import DownloadExcel from '../../Components/Basic/ExportExcel';

interface IProducts {
    dishes: IBasicMeal[]
    drinks: IBasicMeal[]
    basicProduct: IBasicProduct[]
    stats: IStats[]
    language: number
    coin: string
}

const getColumnsProducts = (coin: string, language: number) => {
    return [
        {
            title: language === 0 ? "Producto" : "Product",
            dataIndex: 'name',
            key: 'name',
            label: language === 0 ? "Producto" : "Product",
        },
        {
            title: language === 0 ? "Número Ventas" : "Number Sales",
            dataIndex: 'numSales',
            key: 'numSales',
            label: language === 0 ? "Número Ventas" : "Number Sales",
            sorter: (a: any, b: any) => a.numSales - b.numSales,
        },
        {
            title: language === 0 ? `Precio Venta (${coin})` : `Price Sale (${coin})`,
            dataIndex: 'price',
            key: 'price',
            label: language === 0 ? `Precio Venta (${coin})` : `Price Sale (${coin})`,
            sorter: (a: any, b: any) => a.price - b.price,
        },
        {
            title: language === 0 ? `Coste Producción (${coin})` : `Production Cost (${coin})`,
            dataIndex: 'cost',
            key: 'cost',
            label: language === 0 ? `Coste Producción (${coin})` : `Production Cost (${coin})`,
            sorter: (a: any, b: any) => a.cost - b.cost,
        },
        {
            title: language === 0 ? `Margen Beneficios (${coin})` : `Profit Margin (${coin})`,
            dataIndex: 'margin',
            key: 'margin',
            label: language === 0 ? `Margen Beneficios (${coin})` : `Profit Margin (${coin})`,
            sorter: (a: any, b: any) => a.margin - b.margin,
        }
        ,
        {
            title: language === 0 ? "% Margen" : "% Profit",
            dataIndex: 'marginPerCent',
            key: 'marginPerCent',
            label: language === 0 ? "% Margen" : "% Profit",
            sorter: (a: any, b: any) => a.marginPerCent - b.marginPerCent,
        }
    ];
}

const GetProducts: React.FC<IProducts> = ({ dishes, drinks, basicProduct, stats, language, coin }) => {
    const consume: { name: string, item: string, quantity: number, type: number }[] = []
    stats.forEach(day => day.itemOrdered.forEach(item => {
        let exists = false
        consume.forEach(product => {
            if (product.item === item.idItem.split("#").pop()) {
                exists = true
                product.quantity += item.quantity
            }
        })
        if (item.idItem !== undefined && !exists) consume.push({ name: item.name.replace("(Menú) ", "").replace("(Menu) ", ""), item: item.idItem.split("#").pop()!, quantity: item.quantity, type: item.type })
    }))

    const allProducts = [...dishes, ...drinks]
    const products: { name: string, numSales: number, price: number, cost?: number, margin?: string, marginPerCent?: string }[] = []

    allProducts.forEach(item => {
        const cost = item.components.map(e => Number((((basicProduct.find(i => i._id === e.idProduct)?.pricePerUnit || 0) * e.quantity)).toFixed(2)))
        const costReduced = cost.length ? cost.reduce((x, y) => x + y) : undefined
        products.push({
            name: item.name,
            numSales: consume.find(e => e.item === item._id)?.quantity || 0,
            price: item.price,
            cost: costReduced,
            margin: costReduced ? (item.price - costReduced).toFixed(2) : undefined,
            marginPerCent: costReduced ? ((100 * (item.price - costReduced)) / item.price).toFixed(2) : undefined
        })
    })

    if (products.length) {
        return (
            <>
                <DownloadExcel columns={getColumnsProducts(coin, language!)} dataSource={products} name={language === 0 ? "Productos" : "Products"} />
                <Table
                    style={{ overflowX: "auto", maxWidth: 1200, margin: "auto" }}
                    columns={getColumnsProducts(coin, language!)}
                    dataSource={products}
                />
            </>
        )
    }
    return <div className="p_10px">{language === 0 ? 'No se han registrado consumos para las fechas seleccionadas' : 'No intakes have been registered for the selected dates.'}</div>
}

export default GetProducts;