import React, { useState } from 'react';
import { Row, Col, Form, Input, InputNumber, Button, Select, Checkbox, Tooltip, message } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { layout } from '../../Basic/Utilities/styles';
import envLocal from '../../../env-local';
import { IRestaurant } from '../../../Components/Basic/Utilities/interfaces';
import { axiosCall } from '../../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../../Context/ContextConfig';

const { TextArea } = Input;

interface IProps {
  getMenu: Function;
  isMenu?: boolean;
  idSection?: string;
  positionSec?: number;
  sectionType: number;
  setCurrentTab: Function;
  setShowModalView: Function;
  restaurant: IRestaurant;
};

const Section: React.FC<IProps> = (props) => {
  const [sectionSelected, setSectionSelected] = useState<any>();
  const { configContext } = useConfigContext()!;

  const onFinish = async (values: any) => {
    const response = (await axiosCall("post", envLocal.endpointSection, envLocal.authComp, "createSection", {
      positionSec: props.positionSec,
      idSection: props.idSection,
      ...values
    }));
    if (response.status === 200) {
      message.success(configContext.language === 0 ? "Sección creada con éxito!" : "Section successfully created!");
      props.setCurrentTab(response.data);
      props.setShowModalView(false);
      props.idSection !== undefined && props.setCurrentTab(props.idSection);
      props.getMenu();
    } else {
      message.success(configContext.language === 0 ? "Se ha producido un error" : "An error has occurred");
    }
  };

  return (
    <div className="pt_3em">
      <Row className="editMenuTitle">
        <Col><p className="titleAnt_3">{configContext.language === 0 ? "Datos de la sección" : "Section data"}</p></Col>
      </Row>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
          <Form {...layout}
            onFinish={onFinish}>
            <Form.Item
              label={configContext.language === 0 ? 'Nombre de la Sección' : "Section Name"}
              name='title'
              rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el nombre de la sección de la carta' : "Enter the name of the section of the Menu" }]}
            >
              <Input maxLength={25} />
            </Form.Item>
            <Form.Item label={configContext.language === 0 ? "Tipo de Sección" : "Section type"}
              name='sectionType'
              rules={[{ required: true, message: configContext.language === 0 ? 'Indica el tipo de sección' : "Choose the section type" }]}
            >
              <Select onChange={(e) => setSectionSelected(e)}><Select.Option value={props.isMenu ? 4 : 1}>{configContext.language === 0 ? "Cocina" : "Kitchen"}</Select.Option><Select.Option value={props.isMenu ? 5 : 2}>Bar</Select.Option>{props.sectionType !== 5 && <Select.Option value={3}>{configContext.language === 0 ? "Menú Cerrado" : "Menu"}</Select.Option>}</Select>
            </Form.Item>
            {sectionSelected === 3 &&
              <React.Fragment>
                <Form.Item
                  label={configContext.language === 0 ? 'Precio del Menú' : "Menu Price"}
                  name='menuPrice'
                  rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el precio del Menú' : "Enter menu price" }]}
                >
                  <InputNumber maxLength={10} />
                </Form.Item>
                <Form.Item
                  label={configContext.language === 0 ? 'Información Adicional' : "Additional Information"}
                  name='additionalInfo'
                >
                  <TextArea maxLength={100} placeholder={configContext.language === 0 ? 'Ej: "Incluye café, pan, etc". "Sólo disponible de lunes a viernes."' : 'E.g: "Includes coffee, bread, etc." "Only available Monday to Friday."'} />
                </Form.Item>
                <Form.Item
                  label={<span>
                    {configContext.language === 0 ? 'Días válidos' : "Valid days"}&nbsp;
                    <Tooltip title={configContext.language === 0 ? 'Introduce los días en los que se puede consumir el menú' : "Enter the days on which the menu can be consumed"}>
                      <QuestionCircleTwoTone />
                    </Tooltip>
                  </span>}
                  name="menuValidDays"
                >
                  <Checkbox.Group>
                    <div className="displayGrid">
                      <Checkbox value={1} style={{ lineHeight: '32px', marginLeft: 8 }}>{configContext.language === 0 ? 'Lunes' : "Monday"}</Checkbox>
                      <Checkbox value={2} className="lineHeight_32">{configContext.language === 0 ? 'Martes' : "Tuesday"}</Checkbox>
                      <Checkbox value={3} className="lineHeight_32">{configContext.language === 0 ? 'Miércoles' : "Wednesday"}</Checkbox>
                      <Checkbox value={4} className="lineHeight_32">{configContext.language === 0 ? 'Jueves' : "Thursday"}</Checkbox>
                      <Checkbox value={5} className="lineHeight_32">{configContext.language === 0 ? 'Viernes' : "Friday"}</Checkbox>
                      <Checkbox value={6} className="lineHeight_32">{configContext.language === 0 ? 'Sábado' : "Saturday"}</Checkbox>
                      <Checkbox value={0} className="lineHeight_32">{configContext.language === 0 ? 'Domingo' : "Sunday"}</Checkbox>
                    </div>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      {configContext.language === 0 ? 'Desde la hora' : "From hour"}&nbsp;
                      <Tooltip title={configContext.language === 0 ? 'Hora a la que se puede empezar a solicitar el menú' : "Time to start requesting the menu"}>
                        <QuestionCircleTwoTone />
                      </Tooltip>
                    </span>}
                  name='menuStartHour'
                >
                  <InputNumber min={0} max={24} />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      {configContext.language === 0 ? 'Hasta' : "To"}&nbsp;
                      <Tooltip title={configContext.language === 0 ? 'Hora a partir de la cual ya no podemos solicitar el menú' : "Time after which we can no longer order the menu"}>
                        <QuestionCircleTwoTone />
                      </Tooltip>
                    </span>}
                  name='menuEndHour'
                >
                  <InputNumber min={0} max={24} />
                </Form.Item>
              </React.Fragment>}
            <Form.Item>
              <Col xs={{ span: 8, offset: 8 }} sm={{ span: 1, offset: 24 }}>
                <Button type='primary' htmlType='submit'>
                  {configContext.language === 0 ? 'Guardar' : "Save"}
                </Button>
              </Col>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
};

export default Section;
