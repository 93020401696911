import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Tabs, Input, Button, Modal, notification, Collapse, message } from 'antd';
import { Error404 } from '../../Components/Basic/Results/Error404';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ISections, IMenuOrder, IStateCreateMenu, IDiscounts, IOptionsOutput } from '../../Components/Basic/Utilities/interfaces';
import SkeletonLoad from '../../Components/Basic/SkeletonLoad';
import ListMenu from '../../Components/Menu/ListMenu';
import envLocal from '../../env-local';
import { axiosCall, getDataTokenAuth, numerToDay, decideOrderType } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';
import moment from 'moment';
import Bill from '../../Components/Basic/Bill';
import Hammer from 'hammerjs';
import Header from '../../Components/Basic/Header';
import GroupOrder from '../../Components/Menu/GroupOrder';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Panel } = Collapse;

interface IOrder {
    order?: {
        [key: string]: {
            idSection?: string;
            idItem?: string;
            quantity?: number;
            point?: boolean;
            type?: number;
        }[];
    }[];
};

interface IData {
    idItem: string;
    quantity: number;
    name: string;
    price: number;
    point: boolean;
    type: number;
    options?: IOptionsOutput[][];
    groupOrder?: number;
};

interface ISaveItemMenu {
    idItem: string;
    quantity: number;
    point: boolean;
    name: string;
    price: number;
    type: number;
    options?: IOptionsOutput[];
};

let prevOrder: IMenuOrder[] = [];
let myDiscounts: IDiscounts[] = [];
let keysMenu: string[] = [];
let waitSwipe: boolean = false;
let groupOrder: number = 1;

interface Props extends RouteComponentProps<
    {}, // this.props.match.params.myParamProp
    any, // history
    IStateCreateMenu
// this.props.location.state.myStateProp
> { }

const MenuClient: React.FC<Props> = (props) => {
    const [order, setOrder] = useState<Array<IOrder>>([]);
    const [visibleOk, setVisibleOk] = useState<boolean>(false);
    const [modalData, setModalData] = useState<Array<IData>>([]);
    const [menu, setMenu] = useState<Array<ISections>>([]);
    const [menuOrdered, setMenuOrdered] = useState<Array<IMenuOrder>>([]);
    const [observations, setObservations] = useState<string>();
    const [skeletonLoad, setSkeletonLoad] = useState<boolean>(true);
    const [showDiscounts, setShowDiscounts] = useState<boolean>(false);
    const { configContext } = useConfigContext()!;
    const elm = useRef(null);
    const [keySelected, setKeySelected] = useState<string>("");
    const [discountSelected, setDiscountSelected] = useState<IDiscounts | undefined>(undefined);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

    useLayoutEffect(() => {

        if (elm.current) {
            const positionCurrentKey = keysMenu.indexOf(keySelected);
            const h = new Hammer(elm.current!);
            h.get('swipe').set({ enable: true, direction: Hammer.DIRECTION_HORIZONTAL });
            h.on("swipeleft", () => {
                if (!waitSwipe) {
                    waitSwipe = true;
                    if (positionCurrentKey === keysMenu.length - 1) {
                        setKeySelected(keysMenu[0]);
                    } else {
                        setKeySelected(keysMenu[positionCurrentKey + 1]);
                    }
                    setTimeout(function () { waitSwipe = false }, 500);
                }
            })
            h.on("swiperight", () => {
                if (!waitSwipe) {
                    waitSwipe = true;
                    if (positionCurrentKey === 0) {
                        setKeySelected(keysMenu[keysMenu.length - 1]);
                    } else {
                        setKeySelected(keysMenu[positionCurrentKey - 1]);
                    }
                    setTimeout(function () { waitSwipe = false }, 500);
                }
            })
        }
    })

    useEffect(() => {
        if (props.history.action === "POP" || props.location.state.idRestaurant === undefined) props.history.push('/');
        getMenu();
        findDiscounts();
    }, []);

    const getMenu = async () => {
        if (props.location.state.idOrder !== undefined) {
            const responseOrder = (await axiosCall(
                props.location.state.email === "internal" ? "post" : "get",
                envLocal.endpointOrder,
                props.location.state.email === "internal" ? envLocal.authComp : envLocal.tokenOrder,
                props.location.state.email === "internal" ? "actions/" + props.location.state.idOrder : "")).data;
            if (responseOrder.status > 1) {
                props.history.push('/');
            } else {
                prevOrder = responseOrder.order.map((e: IMenuOrder) => ({ ...e, options: (e.options !== undefined && e.options.length) ? e.options : undefined }));
                if (props.location.state.idDiscount !== undefined) {
                    const tmpDiscount = await axiosCall("get", envLocal.endpointDiscounts, envLocal.tokenOrder, "findOneDiscount/" + props.location.state.idDiscount);
                    if (tmpDiscount.data && tmpDiscount.status === 200) {
                        setDiscountSelected(tmpDiscount.data);
                    }
                }
            }
        } else {
            prevOrder = [];
        }
        const tmpMenu = (await axiosCall("get", envLocal.endpointMenu, "", props.location.state.idRestaurant)).data;
        setMenu(tmpMenu);
        keysMenu = tmpMenu.map((section: any) => section.idSection);
        setKeySelected(keysMenu[0]);
        setSkeletonLoad(false);
    };

    const findDiscounts = async () => {
        if (getDataTokenAuth() && props.location.state.idOrder === undefined && !props.location.state.menuOnlyRead) {
            const responseData: IDiscounts[] = (await axiosCall("get", envLocal.endpointDiscounts, envLocal.authUser, "findDiscounts")).data;
            myDiscounts = responseData.filter(restaurantDiscount =>
                restaurantDiscount.idRestaurant === props.location.state.idRestaurant &&
                moment() < moment(restaurantDiscount.expiryDate).endOf('day') &&
                restaurantDiscount.validDays.includes(moment().day()));
            myDiscounts.length && setShowDiscounts(true);
        }
    }

    const saveItemMenu = (i: ISaveItemMenu) => {
        let inputItem = { ...i, groupOrder };
        let hash = order;
        if ((discountSelected !== undefined && discountSelected.discountValue > 0) || props.location.state.priceSpecialTable) {
            if (discountSelected) {
                inputItem.price = inputItem.price * (1 - (discountSelected.discountValue / 100))
                if (inputItem.options?.length) {
                    inputItem.options.forEach(e => { if (e.priceOption) e.priceOption = e.priceOption * (1 - (discountSelected.discountValue / 100)) })
                }
            }
            if (props.location.state.priceSpecialTable) {
                inputItem.price = inputItem.price * (1 + (props.location.state.priceSpecialTable / 100))
                if (inputItem.options?.length) {
                    inputItem.options.forEach(e => { if (e.priceOption) e.priceOption = e.priceOption * (1 + (props.location.state.priceSpecialTable! / 100)) })
                }
            }
        };
        if (hash[inputItem.idItem] === undefined) {
            if (inputItem.options !== undefined) {
                hash[inputItem.idItem] = { ...inputItem, options: [inputItem.options] }
            } else {
                hash[inputItem.idItem] = inputItem
            }
        } else {
            if (hash[inputItem.idItem].options !== undefined) {
                if (hash[inputItem.idItem].quantity > i.quantity) {

                    hash[inputItem.idItem] = { ...inputItem, options: hash[inputItem.idItem].options.length === 1 ? [[]] : [hash[inputItem.idItem].options.pop()] }
                } else {
                    hash[inputItem.idItem] = { ...inputItem, options: [...hash[inputItem.idItem].options, inputItem.options].filter(e => e?.length) }
                }
            } else {
                hash[inputItem.idItem] = inputItem
            }
        }
        const datas: any = Object.keys(hash).map(e => hash[e]);
        setOrder(hash);
        setModalData(datas);
    };

    const changeGroupOrder = (newValue: number) => groupOrder = newValue

    const showOn = async () => {
        let menuOrder: IMenuOrder[] = [];
        const countMenus = menu.filter(menu => menu.sectionType === 3).map((menu: any) => { return { idItem: menu.idSection, title: menu.title, menuPrice: menu.menuPrice, itemsMenu: menu.itemsMenu.map((section: any) => section.idSection) } });
        if (modalData.length === 0 || modalData.reduce((s: number, a: any) => s + a.quantity, 0) === 0) {
            notification["error"]({
                message: 'Error!',
                description: configContext.language === 0 ? 'No has introducido ningún pedido' : "You haven't entered any orders",
            });
        } else {
            let error = false;
            if (countMenus.length) {
                loop1:
                for (let i = 0; i < countMenus.length; i++) {
                    let countItemSection = 0;
                    for (let j = 0; j < countMenus[i].itemsMenu.length; j++) {
                        const currentSection: any = Array.from(document.getElementsByClassName(countMenus[i].itemsMenu[j]));
                        const total = currentSection.reduce(function (s: number, a: any) {
                            return s + Number(a.innerHTML);
                        }, 0);
                        if (j === 0) { countItemSection = total };
                        if (countItemSection !== total) {
                            notification["error"]({
                                message: configContext.language === 0 ? `El menú: ${countMenus[i].title} no tiene un plato de cada apartado.` : `The menu: ${countMenus[i].title} does not have a dish from each section.`
                            });
                            error = true;
                            break loop1;
                        };
                    };
                    if (countItemSection > 0) {
                        menuOrder = [
                            ...menuOrder,
                            {
                                idItem: countMenus[i].idItem,
                                name: countMenus[i].title,
                                quantity: countItemSection,
                                price: countMenus[i].menuPrice * (discountSelected?.applyToMenu ?
                                    (1 - ((discountSelected?.discountValue - (props.location.state.priceSpecialTable || 0)) / 100))
                                    : (props.location.state.priceSpecialTable ? (1 + (props.location.state.priceSpecialTable / 100)) : 1))
                            }
                        ];
                    };
                };
                setMenuOrdered(menuOrder);
            };
            if (!error) {
                setVisibleOk(true);
            };
        };
    };

    const saveRequest = async (request: IMenuOrder[]) => {
        setConfirmLoading(true)
        let response;
        let foodRequested: any = [];
        let drinkRequested: any = [];
        let taskAssociated: any = [];
        modalData.length && modalData.forEach(item => {
            if (item.quantity > 0) {
                if (item.type === 1) {
                    foodRequested = [...foodRequested, { name: item.name, options: item.options, quantity: item.quantity, groupOrder: item.groupOrder }]
                }
                if (item.type === 2) {
                    drinkRequested = [...drinkRequested, { name: item.name, options: item.options, quantity: item.quantity }]
                };
            };
        });
        if (foodRequested.length && (!props.location.state.remoteOrder || (props.location.state.remoteOrder && props.location.state.email === "internal")) && (!props.location.state.manualValidation || props.location.state.idOrder !== undefined)) {
            taskAssociated.push({
                content: (configContext.language === 0 ? "Pedido " : "Order ") + decideOrderType({ idTable: props.location.state.idTable, language: configContext.language! }),
                observations: observations,
                order: foodRequested,
                type: 1,
                status: 0,
                desiredStatus: 5,
                idTable: props.location.state.idTable
            })
        };
        if (drinkRequested.length && !props.location.state.remoteOrder && (!props.location.state.manualValidation || props.location.state.idOrder !== undefined)) {
            taskAssociated.push({
                content: configContext.language === 0 ? (`Bebidas ${props.location.state.idTable === "0" ? " barra" : ("mesa " + props.location.state.idTable)}`) : (`Drinks ${props.location.state.idTable === "0" ? " bar" : ("table " + props.location.state.idTable)}`),
                observations: observations,
                order: drinkRequested,
                type: 2,
                status: 0,
                desiredStatus: 2,
                idTable: props.location.state.idTable,
                assignedTo: props.location.state.idTable === "0" ? (configContext.language === 0 ? "Barra" : "Bar") : undefined
            })
        };

        if (props.location.state.idOrder === undefined) {
            response = await axiosCall("post", envLocal.endpointOrder, props.location.state.email === "internal" ? envLocal.authComp : props.location.state.preToken, "", {
                idTable: props.location.state.idTable,
                email: props.location.state.email,
                status: props.location.state.manualValidation ? -2 : 0,
                order: request,
                observations: observations,
                coin: props.location.state.coin,
                remoteOrder: props.location.state.remoteOrder,
                manualValidation: props.location.state.manualValidation,
                telephone: props.location.state?.telephone,
                address: props.location.state?.address,
                telephoneRestaurant: props.location.state?.telephoneRestaurant,
                discountValue: props.location.state?.discountValue,
                taskAssociated,
                stockDiscount: modalData,
                idUser: props.location.state?.idUser,
                restaurantName: props.location.state?.restaurantName,
                discountValueToApply: discountSelected?.discountValue,
                applyToMenu: discountSelected?.applyToMenu,
                idDiscount: discountSelected?._id,
                minConsumption: props.location.state?.minConsumption,
                language: configContext.language,
                priceSpecialTable: props.location.state?.priceSpecialTable,
                noShowRate: props.location.state?.noShowRate,
            });
        } else {
            response = await axiosCall("put",
                envLocal.endpointOrder,
                props.location.state.email === "internal" ? envLocal.authComp : envLocal.tokenOrder,
                "actions/" + props.location.state.idOrder,
                { order: request, taskAssociated, stockDiscount: modalData });
        };

        if (response.status === 200 && props.location.state.idOrder === undefined) {
            window.localStorage.setItem(envLocal.tokenOrder, response.data.token);
            props.history.push({
                pathname: props.location.state?.origin !== undefined ? props.location.state?.origin
                    : props.location.state.manualValidation ? '/Waiting'
                        : '/Order'
            });
        } else if (response.status === 200) {
            props.history.push({
                pathname: props.location.state?.origin === undefined ? '/Order' : props.location.state?.origin
            });
        } else {
            message.error(configContext.language === 0 ? "Ha ocurrido un error" : "There has been an error")
        }
        setConfirmLoading(false)
    };

    const selectDiscount = (item: IDiscounts) => {
        setDiscountSelected(item);
        setShowDiscounts(false);
        message.success(configContext.language === 0 ? "Descuento seleccionado" : "Discount selected");
    }

    let request: any = []
    if (visibleOk && modalData.length) request = [...prevOrder, ...modalData.filter((item: any) => item.quantity > 0), ...menuOrdered]

    return (
        <>
            <Header />
            {skeletonLoad ?
                <div style={{ padding: 15 }}>
                    <div className="titleCard">{configContext.language === 0 ? 'Menú' : 'Menu'}</div>
                    <SkeletonLoad title={true} quantity={9} marginTop={10} />
                </div>
                :
                <div className="basicContainer" style={{ paddingTop: 5, backgroundColor: "#EEE" }}>
                    <div className="order">
                        {(typeof menu !== 'undefined' && menu.length) ?
                            <Tabs
                                hideAdd
                                type="card"
                                animated
                                tabPosition="top"
                                size="large"
                                onChange={(e) => setKeySelected(e)}
                                activeKey={keySelected}
                                style={{ height: "100%" }}
                            >
                                {menu.map((newCard: ISections) =>
                                    (newCard.sectionType !== 3 || (((newCard.menuValidDays === undefined || newCard.menuValidDays.length === 0) || newCard.menuValidDays.includes(moment().day())) && ((newCard.menuStartHour === undefined || newCard.menuStartHour <= moment().hours()) && (newCard.menuEndHour === undefined || newCard.menuEndHour > moment().hours())))) ?
                                        <TabPane tab={newCard.title} key={newCard.idSection}>
                                            <div className="cardSquare" ref={elm}>
                                                <div key={newCard.idSection} className="cardSquareTitle">
                                                    {newCard.title}
                                                </div>
                                                {newCard.sectionType === 3 ?
                                                    <React.Fragment>
                                                        {newCard.itemsMenu.map((item: any, index: number) =>
                                                            <div key={index}>
                                                                <div className="heading">{item.title}</div>
                                                                <ListMenu coin={props.location.state.coin} country={props.location.state.country!} isClient={true} sectionType={item.sectionType} idSection={item.idSection} saveItemMenu={saveItemMenu} dataSource={item.itemsMenu} menuOnlyRead={props.location.state.menuOnlyRead} noShowRate={props.location.state.noShowRate} rate={item.sectionType === 4 ? true : false} />
                                                            </div>)}
                                                        <div className="footerMenu pt_7px">{newCard.additionalInfo}</div>
                                                        <div className="footerMenu p_5px">Precio: {newCard.menuPrice} {props.location.state.coin}</div>
                                                    </React.Fragment>
                                                    : <ListMenu coin={props.location.state.coin} country={props.location.state.country!} isClient={true} sectionType={newCard.sectionType} idSection={newCard.idSection} saveItemMenu={saveItemMenu} dataSource={newCard.itemsMenu} menuOnlyRead={props.location.state.menuOnlyRead} noShowRate={props.location.state.noShowRate} rate={newCard.sectionType === 1 ? true : false} />}
                                                {!props.location.state.menuOnlyRead ? <Button type="primary" style={{ height: 40, marginBottom: 10, fontWeight: "bold" }} block onClick={() => setKeySelected("0")}>{configContext.language === 0 ? 'Finalizar Pedido' : 'Finish Order'}</Button> : null}
                                            </div>
                                        </TabPane>
                                        :
                                        null
                                )}
                                {!props.location.state.menuOnlyRead && <TabPane key="0" tab={<div className="boldCenter">{configContext.language === 0 ? 'Finalizar' : 'Finish'}</div>}>
                                    <div className="m_5px boldCenter">{configContext.language === 0 ? 'Introduce aquí tus observaciones acerca del pedido' : 'Enter your comments about the order here'}:</div>
                                    <TextArea style={{ margin: 15, width: "92%", borderRadius: 6 }} maxLength={100} placeholder={configContext.language === 0 ? 'Observaciones (Opcional)' : 'Comments (Optional)'} onChange={(e: any) => setObservations(e.target.value)} />
                                    <Button type="primary" style={{ margin: "5px 30%" }} size="large" onClick={() => showOn()}>{configContext.language === 0 ? 'Realizar Pedido' : 'Place Order'}</Button>
                                </TabPane>}
                            </Tabs>
                            : <Error404
                                title={configContext.language === 0 ? "Carta Digital pendiente de configurar" : "Digital Menu to be configured"}
                                subTitle={configContext.language === 0 ? "Configure la Carta Digital en el partado Editar Carta" : "Configure the Digital Menu in the Edit Menu tab"}
                            />}
                        <Modal
                            title={<div className="textCenter">{configContext.language === 0 ? "Confirma tu Pedido" : "Confirm your order"}</div>}
                            visible={visibleOk}
                            onOk={() => saveRequest(request)}
                            onCancel={() => setVisibleOk(false)}
                            okText={configContext.language === 0 ? "Pedir" : "Order"}
                            confirmLoading={confirmLoading}
                            cancelText={configContext.language === 0 ? "Atrás" : "Back"}
                            centered
                        >
                            <Bill
                                order={request}
                                coin={props.location.state.coin}
                                discount={discountSelected?.discountValue}
                                applyToMenu={discountSelected?.applyToMenu}
                                priceSpecialTable={props.location.state?.priceSpecialTable}
                                extraText={props.location.state?.idUser && props.location.state?.minConsumption && props.location.state?.discountValue ? (configContext.language === 0 ? `Si realiza un consumo mayor de ${props.location.state.minConsumption + props.location.state.coin}, obtendrá un descuento de ${props.location.state.discountValue + "%"} en su próxima visita.` : `If you consume more than ${props.location.state.minConsumption + props.location.state.coin}, you will get a discount of ${props.location.state.discountValue + "%"} on your next visit.`) : undefined}
                            />
                        </Modal>
                        <Modal
                            title={configContext.language === 0 ? "Mis descuentos" : "My discounts"}
                            visible={showDiscounts}
                            onOk={() => setShowDiscounts(false)}
                            okText={configContext.language === 0 ? "Cerrar" : "Close"}
                            onCancel={() => setShowDiscounts(false)}
                            cancelButtonProps={{ style: { display: "none" } }}
                        >
                            <Collapse>
                                {myDiscounts.map((item, index) =>
                                    <Panel header={<div>{item.restaurant} <b className="dateOrderHistory">{moment(item.expiryDate).format('DD/MM/YYYY')}</b></div>} key={index}>
                                        <p><b>{configContext.language === 0 ? "Válido hasta: " : "Valid until: "}</b> {moment(item.expiryDate).format('DD/MM/YYYY')}</p>
                                        <p><b>{configContext.language === 0 ? "Descuento: " : "Discount: "}</b> {item.discountValue}%</p>
                                        <p><b>{configContext.language === 0 ? "Aplicable a menús: " : "Applies to menus: "}</b> {item.applyToMenu ? (configContext.language === 0 ? "Sí" : "Yes") : "No"}</p>
                                        <p><b>{configContext.language === 0 ? "Válido los días: " : "Valid on days: "}</b></p>
                                        {item.validDays.map(element =>
                                            <p key={element}> {numerToDay({ day: element, language: configContext.language! })}</p>
                                        )}
                                        <div className="textCenter">
                                            <Button type="primary" onClick={() => selectDiscount(item)}>{configContext.language === 0 ? "Canjear" : "Redeem"}</Button>
                                        </div>
                                    </Panel>
                                )}
                            </Collapse>
                        </Modal>
                    </div>
                    {props.location.state.email === "internal" && <GroupOrder changeGroupOrder={changeGroupOrder} value={groupOrder} language={configContext.language!} />}
                </div>
            }
        </>
    )
};

export default withRouter(MenuClient);