import React, { useState, useCallback } from 'react';
import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { IItems, IOptionsOutput } from '../Basic/Utilities/interfaces';
import { Modal, Radio, Checkbox, Form } from 'antd';

interface IProps {
    idSection?: string;
    sectionType?: number;
    item?: IItems;
    saveItemMenu?: Function;
    type?: number;
    point?: boolean;
    element?: any;
    language: number;
    coin: string;
    country: string;
}

interface CollectionCreateFormProps {
    visible: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
    language: number;
    coin: string;
    country: string;
    item: IItems;
}

const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
    visible,
    onCreate,
    onCancel,
    language,
    coin,
    country,
    item
}) => {
    const [form] = Form.useForm();
    const { options, multiOption, point } = item;
    return (
        <Modal
            visible={visible}
            title={language === 0 ? "Rellena las opciones" : "Fill the options"}
            okText={language === 0 ? "Aceptar" : "Ok"}
            cancelText={language === 0 ? 'Cancelar' : "Cancel"}
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        let valuesFormDestructured: IOptionsOutput[] = []
                        Object.keys(values).forEach((element: any) => {
                            if (values[element]) {
                                switch (element) {
                                    case "multiOptions":
                                        valuesFormDestructured.push(...values[element].map((prod: string) => ({ typeOption: element, valueOption: prod, priceOption: item.options.find(e => e.productOption === prod)!.priceOption })))
                                        break;
                                    case "singleOption":
                                        valuesFormDestructured.push({ typeOption: element, valueOption: values[element].productOption, priceOption: values[element].priceOption })
                                        break;
                                    case "pointCook":
                                        valuesFormDestructured.push({ typeOption: element, valueOption: values[element] })
                                        break;
                                    default:
                                        break;
                                }
                            }
                        });
                        onCreate(valuesFormDestructured);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="formOptions"
            >
                {options?.length && multiOption ?
                    <>
                        <p className="bold">{language === 0 ? "Puedes elegir algunos de los siguientes extras:" : "You can choose some of the following extras:"}</p>
                        <Form.Item
                            name="multiOptions"
                        >
                            <Checkbox.Group style={{ display: 'grid' }} options={options.map(e => ({ value: e.productOption, label: `${e.productOption}: ${e.priceOption} ${coin}` }))} />
                        </Form.Item>
                    </>
                    : null}
                {options?.length && !multiOption ?
                    <>
                        <p className="bold">{language === 0 ? "Elige una opción:" : "Choose an option:"}</p>
                        <Form.Item
                            name="singleOption"
                            rules={[{ required: true, message: language === 0 ? 'Introduce una opcion' : "Enter an option" }]}
                        >
                            <Radio.Group>
                                {options.map(i => <Radio key={i._id} style={radioStyle} value={i}>{i.productOption}: {i.priceOption} {coin}</Radio>)}
                            </Radio.Group>
                        </Form.Item>
                    </>
                    : null}
                {point &&
                    <>
                        <p className="bold">{language === 0 ? "Elige el punto de cocinado:" : "Choose the cooking point:"}</p>
                        <Form.Item
                            name="pointCook"
                            rules={[{ required: true, message: language === 0 ? 'Introduce el punto de cocinado' : "Enter the cooking point" }]}
                        >
                            <Radio.Group>
                                <Radio style={radioStyle} value={"0"}>{country === "Costa Rica" ? "Rojo" : (language === 0 ? 'Poco hecho' : 'Undercooked')}</Radio>
                                <Radio style={radioStyle} value={"1"}>{country === "Costa Rica" ? "Término Medio" : (language === 0 ? 'Al Punto' : 'Medium')}</Radio>
                                <Radio style={radioStyle} value={"2"}>{country === "Costa Rica" ? "Tres Cuartos" : (language === 0 ? 'Hecho' : 'Medium well')}</Radio>
                                <Radio style={radioStyle} value={"3"}>{country === "Costa Rica" ? "Muy Cocido" : (language === 0 ? 'Muy hecho' : 'Well done')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </>}
            </Form>
        </Modal>
    );
};

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

const CustomInputNumber: React.FC<IProps> = (props) => {
    const [value, setValue] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);

    const saveItem = useCallback((e: number, type: string, inpProps: IProps) => {
        let newValue = 0;
        if (type === "inc") newValue = e + 1;
        if (type === "dec") {
            if (e === 0) return
            newValue = e - 1;
        }
        if ((inpProps.item?.options?.length || inpProps.item?.point) && type === "inc") {
            setShowModal(true)
        } else {
            addItem(newValue, undefined)
        }
    }, []);


    const addItem = useCallback((quantity: number, options: IOptionsOutput[] | undefined) => {
        setValue(quantity);
        setShowModal(false);
        props.saveItemMenu && props.saveItemMenu({
            options,
            idItem: props.idSection + "#" + props.item!._id,
            quantity,
            point: props.item!.point,
            name: ((props.sectionType === 4 || props.sectionType === 5) ? "(Menú) " : "") + props.item!.name,
            price: (props.sectionType === 4 || props.sectionType === 5) ? (props.item!.supplementMenu || 0) : props.item!.price,
            type: props.type
        })
    }, []);

    return (
        <>
            <span>
                <MinusCircleTwoTone className="fs_20px" onClick={() => saveItem(value, "dec", props)} />
                <span className="m_0x10"><span className={props.idSection} style={{ fontWeight: "bold", fontSize: 20, color: value > 0 ? "green" : "rgba(0, 0, 0, 0.65)" }}>{value}</span></span>
                <PlusCircleTwoTone className="fs_20px" onClick={() => saveItem(value, "inc", props)} />
            </span>
            {(props.item?.options?.length || props.item?.point) ?
                <CollectionCreateForm
                    visible={showModal}
                    onCreate={e => addItem(value + 1, e)}
                    onCancel={() => setShowModal(false)}
                    language={props.language}
                    coin={props.coin}
                    country={props.country}
                    item={props.item!}
                /> : null}
        </>
    )
};

export default CustomInputNumber;