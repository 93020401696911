import React, { useState, useEffect } from 'react';
import { Form, Input, Menu, Button, Row, Col, Tooltip, InputNumber, Modal, Collapse, message } from 'antd';
import { KeyOutlined, PoweroffOutlined, DollarCircleOutlined, FileSearchOutlined } from '@ant-design/icons';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { tailFormItemLayout, formItemLayout } from '../../Components/Basic/Utilities/styles'
import ChangePassModal from '../../Components/Users/ChangePassModal'
import { axiosCall, useWindowSize, numerToDay } from '../../Components/Basic/Utilities/tools';
import { logout } from '../../Components/Auth/AuthService';
import envLocal from '../../env-local';
import { useUser } from '../../Context/ContextUser';
import { useConfigContext } from '../../Context/ContextConfig';
import moment from 'moment';
import Header from '../../Components/Basic/Header';
import { IDiscounts } from '../../Components/Basic/Utilities/interfaces';
import NavBar from '../../Components/Basic/NavBar';

const { Panel } = Collapse;

interface IOrderHistory {
    restaurantName: string;
    createdAt: Date;
    order: {
        name: string;
        quantity: number;
    }[]
}

let orderHistory: IOrderHistory[] = [];
let discounts: IDiscounts[] = [];

const Profile: React.FC = () => {
    const [isCompany] = useState<boolean>(window.localStorage.getItem(envLocal.authComp) !== null && window.localStorage.getItem(envLocal.authComp) !== undefined);
    const [changePassModal, setChangePassModal] = useState<boolean>(false);
    const { user, setUser } = useUser()!;
    const { configContext } = useConfigContext()!;
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const currentWidth = useWindowSize()[0];
    const [showMyOrders, setShowMyOrders] = useState<boolean>(false);
    const [showMyDiscounts, setShowMyDiscounts] = useState<boolean>(false);

    useEffect(() => {
        if (!window.localStorage.getItem(envLocal.authComp) && !window.localStorage.getItem(envLocal.authUser)) {
            window.history.go(-1);
        }
    }, []);

    const onFinish = async (values: any) => {
        const response = await axiosCall(
            "put",
            isCompany ? envLocal.endpointAuthenticationComp : envLocal.endpointAuthentication,
            isCompany ? envLocal.authComp : envLocal.authUser,
            "update",
            values
        );
        if (response.status === 200) {
            message.success(configContext.language === 0 ? "Actualizado con éxito!" : "Successfully updated");
            setUser(values);
        } else {
            message.error("Error")
        }
    };

    const findMyOrders = async () => {
        const responseData = (await axiosCall("get", envLocal.endpointOrder, envLocal.authUser, "find/myOrders")).data;
        orderHistory = responseData;
        setShowMyOrders(true);
    };

    const findMyDiscounts = async () => {
        const responseData = (await axiosCall("get", envLocal.endpointDiscounts, envLocal.authUser, "findDiscounts")).data;
        discounts = responseData;
        setShowMyDiscounts(true);
    };

    return (
        <div className="basicContainer" style={{ overflowX: "hidden" }}>
            {!isCompany && <Header />}
            {isCompany && <NavBar />}
            <p className="titleAnt_3 textCenter mt_20px">{configContext.language === 0 ? "Mi Perfil" : "My Profile"}</p>
            <div style={{ display: "flex" }}>
                <div style={{ width: collapsed ? 90 : (currentWidth > 750 ? 300 : "100%"), flexShrink: 0 }}>
                    <Button type="primary" onClick={() => setCollapsed(!collapsed)}>
                        {configContext.language === 0 ? "Mostrar" : "Options"}
                    </Button>
                    <Menu
                        mode="inline"
                        inlineCollapsed={collapsed}
                    >
                        {!isCompany && <Menu.Item key="orders" icon={<FileSearchOutlined />} onClick={() => findMyOrders()}>
                            {configContext.language === 0 ? "Pedidos" : "Orders"}
                        </Menu.Item>}
                        {!isCompany && <Menu.Item key="discounts" icon={<DollarCircleOutlined />} onClick={() => findMyDiscounts()}>
                            {configContext.language === 0 ? "Descuentos" : "Discounts"}
                        </Menu.Item>}
                        <Menu.Item key="password" icon={<KeyOutlined />} onClick={() => setChangePassModal(true)}>
                            {configContext.language === 0 ? "Cambiar Contraseña" : "Change Password"}
                        </Menu.Item>
                        <Menu.Item key="exit" icon={<PoweroffOutlined />} onClick={() => logout()}>
                            {configContext.language === 0 ? "Desconectarse" : "Log out"}
                        </Menu.Item>
                    </Menu>
                </div>
                <div style={{ width: "100%" }}>
                    {isCompany ?
                        <Row>
                            <Col xs={{ span: 20, offset: 2 }}>
                                <Form {...formItemLayout}
                                    onFinish={onFinish}
                                    initialValues={{ email: user.email || "", username: user.username || "", color: user.color || "" }}
                                    style={{ width: "90%" }}
                                >
                                    <Form.Item
                                        label='Email'
                                        name='email'
                                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el Correo Electrónico' : "Enter Email" }]}
                                    >
                                        <Input disabled={true} maxLength={50} />
                                    </Form.Item>
                                    <Form.Item
                                        label={configContext.language === 0 ? 'Nombre de Usuario' : "User Name"}
                                        name='username'
                                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce un nombre de Usuario' : "Enter a user name" }]}
                                    >
                                        <Input maxLength={10} />
                                    </Form.Item>
                                    <Form.Item
                                        label={configContext.language === 0 ? 'Color del Usuario' : "User Color"}
                                        name='color'
                                    >
                                        <Input type="color" />
                                    </Form.Item>
                                    <Form.Item {...tailFormItemLayout}>
                                        <Button type='primary' htmlType='submit'>
                                            {configContext.language === 0 ? 'Actualizar' : "Update"}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Form {...formItemLayout}
                                onFinish={onFinish}
                                initialValues={{ email: user.email || "", username: user.username || "", telephone: user.telephone || "", address: user.address || "" }}
                                style={{ width: "90%" }}
                            >
                                <Form.Item
                                    label='Email'
                                    name='email'
                                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el Correo Electrónico' : "Enter Email" }]}
                                >
                                    <Input disabled={true} maxLength={50} />
                                </Form.Item>
                                <Form.Item
                                    label={configContext.language === 0 ? 'Nombre de Usuario' : "User Name"}
                                    name='username'
                                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce un nombre de Usuario' : 'Enter a user name' }]}
                                >
                                    <Input maxLength={10} />
                                </Form.Item>
                                <Form.Item
                                    label={<span>
                                        {configContext.language === 0 ? 'Teléfono' : "Telephone"}&nbsp;
                                                <Tooltip title={configContext.language === 0 ? 'Introduce tu teléfono para los pedidos a domicilio' : "Enter your phone number for home orders"}>
                                            <QuestionCircleTwoTone />
                                        </Tooltip>
                                    </span>}
                                    name='telephone'
                                >
                                    <InputNumber maxLength={15} style={{ width: "50%" }} />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <span>
                                            {configContext.language === 0 ? 'Dirección' : "Address"}&nbsp;
                                                    <Tooltip title={configContext.language === 0 ? 'Introduce tu dirección para los pedidos a domicilio' : "Enter your address for home delivery"}>
                                                <QuestionCircleTwoTone />
                                            </Tooltip>
                                        </span>}
                                    name='address'
                                >
                                    <Input maxLength={25} />
                                </Form.Item>
                                <Form.Item {...tailFormItemLayout}>
                                    <Button type='primary' htmlType='submit'>
                                        {configContext.language === 0 ? 'Actualizar' : "Update"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Row>
                    }
                    <Modal
                        title={configContext.language === 0 ? "Historial de Pedidos" : "Order History"}
                        visible={showMyOrders}
                        onOk={() => setShowMyOrders(false)}
                        okText={configContext.language === 0 ? "Cerrar" : "Close"}
                        onCancel={() => setShowMyOrders(false)}
                        cancelButtonProps={{ style: { display: "none" } }}
                    >
                        {orderHistory.length ?
                            <Collapse>
                                {orderHistory.map((item, index) =>
                                    <Panel header={<div>{item.restaurantName} <b className="dateOrderHistory">{moment(item.createdAt).format('DD/MM/YYYY')}</b></div>} key={index}>
                                        {item.order.map((element, index) =>
                                            <p key={index}><b>x{element.quantity}</b> {element.name}</p>
                                        )}
                                    </Panel>
                                )}
                            </Collapse>
                            : <div>{configContext.language === 0 ? "No ha realizado ningún pedido" : "You have not placed an order"}</div>
                        }
                    </Modal>
                    <Modal
                        title={configContext.language === 0 ? "Mis descuentos" : "My discounts"}
                        visible={showMyDiscounts}
                        onOk={() => setShowMyDiscounts(false)}
                        okText={configContext.language === 0 ? "Cerrar" : "Close"}
                        onCancel={() => setShowMyDiscounts(false)}
                        cancelButtonProps={{ style: { display: "none" } }}
                    >
                        {discounts.length ?
                            <Collapse>
                                {discounts.map((item, index) =>
                                    <Panel header={<div>{item.restaurant} <b className="dateOrderHistory">{moment(item.expiryDate).format('DD/MM/YYYY')}</b></div>} key={index}>
                                        <p><b>{configContext.language === 0 ? "Válido hasta: " : "Valid until: "}</b> {moment(item.expiryDate).format('DD/MM/YYYY')}</p>
                                        <p><b>{configContext.language === 0 ? "Descuento: " : "Discount: "}</b> {item.discountValue}%</p>
                                        <p><b>{configContext.language === 0 ? "Aplicable a menús: " : "Applies to menus: "}</b> {item.applyToMenu ? (configContext.language === 0 ? "Sí" : "Yes") : "No"}</p>
                                        <p><b>{configContext.language === 0 ? "Válido los días: " : "Valid on days: "}</b></p>
                                        {item.validDays.map(element =>
                                            <p key={element}> {numerToDay({ day: element, language: configContext.language! })}</p>
                                        )}
                                    </Panel>
                                )}
                            </Collapse>
                            : <div>{configContext.language === 0 ? "No dispone de ningún descuento actualmente" : "No discounts currently available"}</div>
                        }
                    </Modal>
                    <ChangePassModal isVisible={changePassModal} setIsVisible={setChangePassModal} isCompany={isCompany} />
                </div>
            </div>
        </div >
    );
};

export default Profile;