import React, { useState } from "react";
import { IStats, IGraph } from '../Components/Basic/Utilities/interfaces';
import { dateWithoutYear, axiosCall, parsedDateString } from '../Components/Basic/Utilities/tools';
import Line from './Basic/Line';
import { Button, Modal, DatePicker, ConfigProvider } from 'antd';
import envLocal from '../env-local';
import { SearchOutlined } from '@ant-design/icons';
import { useConfigContext } from '../Context/ContextConfig';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';
import localeEn from 'antd/lib/locale/en_GB';

const { RangePicker } = DatePicker;

const getIncome = (stats: IStats[]) => {
    const ret = [{
        "id": "Facturación",
        "data": stats.map(item => {
            return {
                "x": dateWithoutYear(item.day),
                "y": Number((item.totalIncome || 0).toFixed(2))
            }
        }).sort((a: any, b: any) => { return moment(a.x, "DD/MM/YYYY").valueOf() - moment(b.x, "DD/MM/YYYY").valueOf() })
    }]
    return ret;
}

let total: number = 0;
let rangeDate: string = "";

interface IProps extends RouteComponentProps, IGraph { }

const LineIncome: React.FC<IProps> = (props) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [stats, setStats] = useState<Array<{ day: string, totalIncome: number }>>([]);
    const { configContext } = useConfigContext()!;

    const changeRange = async (inputDates: any, stringRange: any) => {
        rangeDate = stringRange;
        if (inputDates !== null) {
            if (inputDates[0] && inputDates[1]) {
                const response = await axiosCall("post", envLocal.endpointStats, envLocal.authComp, "findIncome", { inputDates: [parsedDateString(inputDates[0]), parsedDateString(inputDates[1])] });
                total = response.data.reduce(function (s: number, a: any) { return s + Number(a.totalIncome) }, 0)
                setStats(response.data);
            };
        };
    };

    return (
        <div className="sectionSquareGraph">
            <div className="sectionSquareTitleGraph">
                <span>{props.title}</span>
                <span><Button icon={<SearchOutlined />} type='primary' onClick={() => setShowModal(true)}></Button></span>
            </div>
            <div className="sectionSquareBodyGraph">
                <Line {...props} dataTable={getIncome(props.data)} />
                <Modal
                    title={configContext.language === 0 ? "Seleccione el rango a buscar" : "Select the range to search"}
                    visible={showModal}
                    onOk={() => props.history.push({
                        pathname: '/ToPrint',
                        state: { stats, coin: props.coin, language: configContext.language, rangeDate, type: 3 }
                    })}
                    okText={configContext.language === 0 ? "Imprimir" : "Print"}
                    onCancel={() => setShowModal(false)}
                    zIndex={1000}
                >
                    <div className="section-to-print textCenter">
                        <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                            <RangePicker
                                inputReadOnly
                                style={{ marginBottom: 20 }}
                                format="DD/MM/YYYY"
                                onCalendarChange={(value, rangeStr) => {
                                    changeRange(value, rangeStr);
                                }} />
                        </ConfigProvider>
                        {stats.length ? stats.map(date => <div key={date.day}><b>{date.day}:  </b>{date.totalIncome.toFixed(2) + ' ' + props.coin}</div>) : null}
                        <br />
                        {stats.length ? <div><b>Total: </b>{total.toFixed(2) + props.coin}</div> : null}
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default withRouter(LineIncome);