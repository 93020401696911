import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Row, Col, Button, Form, Modal, Input, InputNumber, Popconfirm, Typography, message, ConfigProvider } from 'antd';
import SkeletonLoad from '../../Components/Basic/SkeletonLoad';
import { RouteComponentProps } from 'react-router-dom';
import envLocal from '../../env-local';
import NavBar from '../../Components/Basic/NavBar';
import { axiosCall, groupBy } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';
import moment from 'moment';
import SquareInfo from '../../Components/Basic/SquareInfo';
import { PlusOutlined } from '@ant-design/icons';
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';
import localeEn from 'antd/lib/locale/en_GB';

const { Text } = Typography;

interface IBookings {
    _id: string;
    idRestaurant: string;
    date: Date;
    hour: Date;
    name: string;
    clientQuantity: number;
    contactTelephone?: number;
    comments?: string;
    striked?: boolean;
}

interface IAddBooking {
    visible: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
}

let filterDate = moment().format('DD/MM/YYYY');

const Bookings: React.FC<RouteComponentProps> = (props) => {
    const [skeletonLoad, setSkeletonLoad] = useState<boolean>(false);
    const [bookings, setBookings] = useState<Array<IBookings>>([]);
    const [newBooking, setNewBooking] = useState<boolean>(false);
    const { configContext } = useConfigContext()!;
    const [bookingSelected, setBookingSelected] = useState<IBookings | undefined>();
    const [columns, setColumns] = useState<any>();
    let widthSize = window.innerWidth;

    useEffect(() => {
        fetchData();
    }, []);

    const AddBooking: React.FC<IAddBooking> = ({
        visible,
        onCreate,
        onCancel,
    }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={configContext.language === 0 ? "Datos de la reserva" : "Booking details"}
                okText={configContext.language === 0 ? "Guardar" : "Save"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    name="proveedoresList"
                    initialValues={bookingSelected !== undefined ?
                        {
                            _id: bookingSelected._id,
                            date: moment(bookingSelected.date),
                            name: bookingSelected.name,
                            clientQuantity: bookingSelected.clientQuantity,
                            contactTelephone: bookingSelected.contactTelephone,
                            comments: bookingSelected.comments
                        }
                        : {}
                    }
                >
                    <Form.Item
                        name="_id"
                        label="_id"
                        style={{ display: "none" }}
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                    <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                        <Form.Item
                            name="date"
                            label={configContext.language === 0 ? "Día" : "Day"}
                            rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el día de la reserva' : "Enter the day of the booking" }]}
                        >
                            <DatePicker inputReadOnly format="DD/MM/YYYY HH:mm" showTime />
                        </Form.Item>
                    </ConfigProvider>
                    <Form.Item
                        name="clientQuantity"
                        label={configContext.language === 0 ? "Clientes" : "Clients"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el número de comensales' : "Enter the number of guests" }]}
                    >
                        <InputNumber min={0} maxLength={3} style={{ width: "40%" }} />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label={configContext.language === 0 ? "Nombre" : "Name"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el nombre del cliente' : "Enter the name of the client" }]}
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                    <Form.Item
                        name="contactTelephone"
                        label={configContext.language === 0 ? "Teléfono" : "Telephone"}
                    >
                        <InputNumber min={0} maxLength={15} style={{ width: "40%" }} />
                    </Form.Item>
                    <Form.Item
                        name="comments"
                        label={configContext.language === 0 ? "Comentarios" : "Comments"}
                    >
                        <Input maxLength={50} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const fetchData = async () => {
        const parsedDate = moment(filterDate, "DD/MM/YYYY")
        const response = await axiosCall("post", envLocal.endpointBookings, envLocal.authComp, "findBookings", { low: parsedDate.startOf('day').toDate(), high: parsedDate.endOf('day').toDate() });
        if (response.status === 200) saveColumns(response.data);
        if (response.status === 200) setBookings(response.data);
        setSkeletonLoad(false);
    };

    const saveColumns = (bookings: IBookings[]) => {
        let tableList: any = [];
        bookings.map((x: any) => tableList.filter((a: any) => a.value === x.name).length > 0 ? null : tableList.push({ text: x.name, value: x.name }));
        tableList.sort(function (a: any, b: any) { return parseFloat(a.value) - parseFloat(b.value) });
        let clientsList: any = [];
        bookings.map((x: any) => clientsList.filter((a: any) => a.value === x.clientQuantity).length > 0 ? null : clientsList.push({ text: x.clientQuantity, value: x.clientQuantity }));
        clientsList.sort(function (a: any, b: any) { return parseFloat(a.value) - parseFloat(b.value) });
        let hourList: any = [];
        bookings.map((x: any) => hourList.filter((a: any) => a.value === moment(x.date).hours()).length > 0 ? null : hourList.push({ text: moment(x.date).hours(), value: moment(x.date).hours() }));
        const tmpColumns = [
            {
                title: configContext.language === 0 ? 'Hora' : "Hour",
                dataIndex: 'hour',
                key: 'hour',
                filters: [...hourList],
                onFilter: (value: any, record: any) => moment(record.date).hours() === value,
                render: (text: any, e: IBookings) => <Text delete={e.striked}>{text}</Text>
            },
            {
                title: configContext.language === 0 ? 'Clientes' : "Clients",
                dataIndex: 'clientQuantity',
                key: 'clientQuantity',
                filters: [...clientsList],
                onFilter: (value: any, record: any) => record.clientQuantity === value,
                render: (text: any, e: IBookings) => <Text delete={e.striked}>{text}</Text>
            },
            {
                title: configContext.language === 0 ? 'Nombre' : "Name",
                dataIndex: 'name',
                key: 'name',
                filters: [...tableList],
                onFilter: (value: any, record: any) => record.name === value,
                render: (text: any, e: IBookings) => <Text delete={e.striked}>{text}</Text>
            },
            {
                title: configContext.language === 0 ? 'Teléfono' : "Telephone",
                dataIndex: 'contactTelephone',
                key: 'contactTelephone',
                style: { display: "none" },
                responsive: ['md'],
                render: (text: any, e: IBookings) => <Text delete={e.striked}>{text}</Text>
            },
            {
                title: configContext.language === 0 ? 'Comentarios' : "Comments",
                dataIndex: 'comments',
                key: 'comments',
                responsive: ['md'],
                render: (text: any, e: IBookings) => <Text delete={e.striked}>{text}</Text>
            },
            {
                title: configContext.language === 0 ? 'Acciones' : "Actions",
                dataIndex: '',
                key: '',
                render: (text: any, record: any) =>
                    <>
                        <div>
                            {(record.striked === false || record.striked === undefined) ?
                                <Popconfirm title={configContext.language === 0 ? 'Tachar Reserva?' : "Sure to Strikethrough?"} onConfirm={() => strikeBooking(record._id, true)}>
                                    <a>{configContext.language === 0 ? 'Tachar' : "Strike"}</a>
                                </Popconfirm>
                                :
                                <Popconfirm title={configContext.language === 0 ? 'No tachar?' : "No Strikethrough?"} onConfirm={() => strikeBooking(record._id, false)}>
                                    <a className="noWrap">{configContext.language === 0 ? 'No Tachar' : "No Strike"}</a>
                                </Popconfirm>
                            }
                        </div>
                        <div>
                            <Popconfirm title={configContext.language === 0 ? '¿Eliminar Reserva?' : "Sure to delete?"} onConfirm={() => deleteBooking(record.key)}>
                                <a>{configContext.language === 0 ? 'Eliminar' : "Delete"}</a>
                            </Popconfirm>
                        </div>
                    </>
            }
        ];
        setColumns(tmpColumns);
    };

    const onChange = async (inputDate: any, dateString: any) => {
        if (dateString) {
            filterDate = dateString;
            const response = await axiosCall("post", envLocal.endpointBookings, envLocal.authComp, "findBookings", { low: inputDate.startOf('day').toDate(), high: inputDate.endOf('day').toDate() });
            if (response.status === 200) saveColumns(response.data);
            if (response.status === 200) setBookings(response.data);
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setBookingSelected(bookings.find(item => item._id === selectedRowKeys[0]));
            setNewBooking(true);
        }
    };

    const onCreateBooking = async (values: any) => {
        let response: number;
        if (values._id === undefined || values._id === "") {
            response = (await axiosCall("post", envLocal.endpointBookings, envLocal.authComp, "", values)).status;
        } else {
            response = (await axiosCall("put", envLocal.endpointBookings, envLocal.authComp, "actions/" + values._id, values)).status;
        }
        if (response === 200 && (values._id === undefined || values._id === "")) {
            message.success(configContext.language === 0 ? "Reserva Creada!" : "Booking Created!");
        } else if (response === 200) {
            message.success(configContext.language === 0 ? "Reserva Actualizada!" : "Booking Updated!");
        } else {
            message.error("Error");
        };
        await fetchData();
        setBookingSelected(undefined);
        setNewBooking(false);
    }

    const onCancelBooking = () => {
        setBookingSelected(undefined);
        setNewBooking(false);
    }

    const deleteBooking = async (id: any) => {
        const response = (await axiosCall("delete", envLocal.endpointBookings, envLocal.authComp, "actions/" + id)).status;
        if (response === 200) {
            message.success(configContext.language === 0 ? "Reserva Eliminada" : "Booking Deleted");
        } else {
            message.error("Error");
        };
        fetchData();
    }

    const strikeBooking = async (id: string, striked: boolean) => {
        await axiosCall("put", envLocal.endpointBookings, envLocal.authComp, "actions/" + id, { striked });
        fetchData();
    }

    const getSquareTask = () => {
        return [
            {
                squareStyle: { width: widthSize < 690 ? "100%" : 350 },
                title: configContext.language === 0 ? "Añadir Reserva" : "Add Booking",
                body:
                    <div className="buttonActions">
                        <Button
                            style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white", marginTop: 15 }}
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setNewBooking(true)}
                        >
                            {configContext.language === 0 ? "Reservar" : "Booking"}
                        </Button>
                    </div>

            },
            {
                squareStyle: { width: widthSize < 690 ? "100%" : "70%", height: "auto" },
                title: configContext.language === 0 ? "Resumen" : "Summary",
                body:
                    bookings.length ?
                        <div className="twoCols">
                            {Object.entries(groupBy(bookings.map(book => { return { hour: moment(book.date).format("HH"), quantity: book.clientQuantity } }).flat(), "hour")).sort().map((hour: any) => <div key={hour[0]}>{configContext.language === 0 ? "Reservas a las " : "Bookings at"}{hour[0]} h: <b className="noWrap">{hour[1].reduce(function (s: number, a: any) { return s + a.quantity }, 0)} {configContext.language === 0 ? "personas" : "people"}</b></div>)}
                        </div>
                        :
                        <div className="textCenter mt_25px">
                            <strong>{configContext.language === 0 ? "No hay reservas pendientes" : "No pending bookings"}</strong>
                        </div>
            }
        ]
    }

    return (
        <div className="basicContainerColor">
            <NavBar />
            <div className="titleSection">{configContext.language === 0 ? "Gestión de Reservas" : "Booking Management"}</div>
            {skeletonLoad ?
                <div style={{ backgroundColor: "white", padding: 40 }}>
                    <SkeletonLoad title={true} quantity={9} marginTop={10} />
                </div>
                :
                <div className="mb_30px">
                    <SquareInfo squares={getSquareTask()} />
                    <div className="sectionSquare" style={{ margin: widthSize < 690 ? "30px 0px 5px 0px" : "30px 15px 5px 15px", padding: widthSize < 690 ? "15px 0px" : 15 }}>
                        <div className="sectionSquareTitle">{configContext.language === 0 ? "Reservas" : "Bookings"}</div>
                        <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}>
                            <div className="mb_20px large textCenter">{configContext.language === 0 ? "Reservas del día " : "Bookings of day "} <b>{filterDate}</b></div>
                            <div className="textCenter">
                                <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                                    <DatePicker inputReadOnly onChange={onChange} format="DD/MM/YYYY" style={{ margin: "20px auto" }} />
                                </ConfigProvider>
                            </div>
                            <Row>
                                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
                                    <Table
                                        columns={columns}
                                        dataSource={bookings.map(e => { return { ...e, key: e._id, hour: moment(e.date).format("HH:mm") } })}
                                        rowSelection={{
                                            type: "radio",
                                            ...rowSelection,
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            }
            <AddBooking
                visible={newBooking}
                onCreate={onCreateBooking}
                onCancel={() => onCancelBooking()}
            />
        </div>
    );
};

export default Bookings;