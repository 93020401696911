export const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    }
};

export const formItemLayout = {
    labelCol: {
        xs: { span: 20 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 9 },
    }
};

export const layout = {
    labelCol: {
        xs: { span: 20 },
        sm: { span: 12 },
    },
    wrapperCol: {
        xs: { span: 20 },
        sm: { span: 12 },
    }
};

export const twoColAlign = {
    span: 9,
    offset: 3
}

export const layoutHorizontal = {
    labelCol: {
        xs: { span: 8, offset: 0 },
    },
};
