import React from "react";
import { ResponsiveLine } from '@nivo/line';
import { IGraph } from '../../Components/Basic/Utilities/interfaces';

const Line: React.FC<IGraph> = (props) => {
    const { dataTable, margin, colors, leyendInfo = true, leyendX = "", leyendY = "", coin } = props;
    return (
        <ResponsiveLine
            data={dataTable}
            margin={{ top: margin?.top || 40, right: margin?.right || 30, bottom: margin?.bottom || 60, left: margin?.left || 60 }}
            colors={colors || { scheme: 'nivo' }}
            enableArea={true}
            animate={true}
            areaBaselineValue={0}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: leyendX,
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: leyendY,
                legendOffset: -40,
                legendPosition: 'middle',
                tickValues: 10,
                format: value =>
                `${Number(value).toLocaleString('de-DE', {
                    minimumFractionDigits: 0,
                })} ${coin || ''}`
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            legends={leyendInfo ? [
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ] : []}
        />
    )
};

export default Line;