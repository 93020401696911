
import React from 'react';
import { Carousel, Alert } from 'antd';
import { useConfigContext } from '../../../Context/ContextConfig';

const CarouselInstructions: React.FC = () => {
    const { configContext } = useConfigContext()!;

    return (
        <Carousel style={{ padding: 8, textAlign: "center" }} dots={false} autoplay>
            <div>
                <Alert message={configContext.language === 0 ? "Añade tus platos en la sección 'Platos'." : "Add your dishes in the 'Dishes' section."} type="info" showIcon />
            </div>
            <div>
                <Alert message={configContext.language === 0 ? "Añade tus bebidas en la sección 'Bebidas'." : "Add your drinks in the 'Drinks' section."} type="info" showIcon />
            </div>
            <div>
                <Alert message={configContext.language === 0 ? "Posteriormente crea las distintas secciones de tu carta." : "Then create the various sections of your menu."} type="info" showIcon />
            </div>
            <div>
                <Alert message={configContext.language === 0 ? "Algunas pueden ser: 'Menú del Día', 'Bebidas', etc." : "Some may be: 'Menu of the Day', 'Drinks', etc."} type="info" showIcon />
            </div>
            <div>
                <Alert message={configContext.language === 0 ? "Añade los elementos a las distintas secciones." : "Add the elements to the sections."} type="info" showIcon />
            </div>
            <div>
                <Alert message={configContext.language === 0 ? "Mueve las secciones hasta que quede a tu gusto." : "Move the sections until it's to your liking."} type="info" showIcon />
            </div>
        </Carousel>
    );
};

export default CarouselInstructions;


