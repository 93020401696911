import React, { useEffect, useState, useCallback } from 'react';
import NavBar from '../../Components/Basic/NavBar';
import { useConfigContext } from '../../Context/ContextConfig';
import { useRestaurant } from '../../Context/ContextRestaurant';
import { RouteComponentProps } from 'react-router-dom';
import { Calendar, Badge, ConfigProvider, Button, Form, Modal, Input, Popconfirm, Select, DatePicker, message } from 'antd';
import { IUsers } from '../../Components/Basic/Utilities/interfaces';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import envLocal from '../../env-local';
import moment from "moment";
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';
import localeEn from 'antd/lib/locale/en_GB';
import SquareInfo from '../../Components/Basic/SquareInfo';
import { PlusOutlined, EditTwoTone, DeleteOutlined } from '@ant-design/icons';
import { layoutHorizontal } from '../../Components/Basic/Utilities/styles'
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import { useUser } from '../../Context/ContextUser';

interface CollectionCreateFormProps {
    visible: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
}

interface ISchedules {
    _id: string,
    idRestaurant: string,
    description: string,
    color: string,
}

interface IWorkSchedules {
    _id: string;
    day: string;
    idRestaurant: string;
    employees: {
        [key: string]: string
    }
}

let dayToCopy: any;

const Schedules: React.FC<RouteComponentProps> = (props) => {
    const [users, setUsers] = useState<Array<IUsers>>([]);
    const [userFiltered, setUserFiltered] = useState<IUsers>();
    const [customSchedules, setCustomSchedules] = useState<ISchedules[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showShiftsModal, setShowShiftsModal] = useState<boolean>(false);
    const [showModalDay, setShowModalDay] = useState<boolean>(false)
    const { configContext } = useConfigContext()!;
    const { restaurant } = useRestaurant()!;
    const [selectedDate, setSelectedDate] = useState<any>()
    const [shiftSelected, setShiftSelected] = useState<string>("")
    const [workSchedules, setWorkSchedules] = useState<IWorkSchedules[]>([])
    const [showCopyDay, setShowCopyDay] = useState<boolean>(false);
    let widthSize = window.innerWidth;
    const myUser = useUser()!.user;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const fetchUsers = getUsers();
        const fetchSchedules = getSchedules();
        const fetchWorkSchedules = getWorkSchedules();
        await Promise.all([fetchUsers, fetchSchedules, fetchWorkSchedules])
        setIsLoading(false);
    };

    const getUsers = async () => {
        const response = await axiosCall("post", envLocal.endpointAuthenticationComp, envLocal.authComp, "findUsers")
        if (response.status === 200) {
            setUsers(response.data);
        } else {
            message.error(configContext.language === 0 ? "Error recuperando los usuarios" : "Error retrieving users")
        }
    }

    const getSchedules = async () => {
        const response = await axiosCall("get", envLocal.endpointSchedules, envLocal.authComp, 'getSchedules');
        if (response.status === 200) {
            const constSchedules = [
                { _id: "Holiday", idRestaurant: restaurant._id, color: "green", description: configContext.language === 0 ? "Vacaciones" : "Holiday" },
                { _id: "Absence", idRestaurant: restaurant._id, color: "red", description: configContext.language === 0 ? "Ausencia Justificada" : "Excused Absence" },
            ]
            setCustomSchedules([...constSchedules, ...response.data]);
        } else {
            message.error(configContext.language === 0 ? "Error recuperando los horarios" : "Error retrieving schedules")
        }
    }

    const getWorkSchedules = async (monthFinded?: string) => {
        const response = await axiosCall("post", envLocal.endpointWorkSchedules, envLocal.authComp, "getSchedules", { month: monthFinded || moment().format("MM/YYYY") });
        if (response.status === 200) {
            setWorkSchedules(response.data)
        } else {
            message.error(configContext.language === 0 ? "Error recuperando los horarios de trabajo" : "Error retrieving work schedules")
        }
    }

    const renderDay = (e: any) => {
        const value = workSchedules.find(ele => ele.day === moment(e).format("DD/MM/YYYY"))
        if (value && value.employees) {
            if (userFiltered) {
                if (Object.keys(value.employees).includes(userFiltered._id)) {
                    return <div className="displayGrid"><Badge color={customSchedules.find(sch => sch._id === value.employees[userFiltered._id])?.color} text={userFiltered.username.substring(0, widthSize < 500 ? 1 : 10)} /></div>
                } else {
                    return null
                }
            } else {
                return <div className="displayGrid">{Object.keys(value.employees).map(emp => <Badge key={emp} color={customSchedules.find(sch => sch._id === value.employees[emp])?.color} text={users.find(user => user._id === emp)?.username.substring(0, widthSize < 500 ? 1 : 10)} />)}</div>
            }
        }
        return null
    }

    const showAddTurn = () => {
        setShiftSelected("")
        setShowShiftsModal(true)
    }

    const addTurn = async (values: { _id?: string, description: string, color: string }) => {
        let response;
        if (values._id !== undefined) {
            response = await axiosCall("put", envLocal.endpointSchedules, envLocal.authComp, `actions/${values._id}`, values)
        } else {
            response = await axiosCall("post", envLocal.endpointSchedules, envLocal.authComp, 'addSchedule', values)
        }

        if (response.status === 200) {
            await getSchedules()
        } else {
            message.error("Error")
        }
        setShowShiftsModal(false)
    }

    const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
        visible,
        onCreate,
        onCancel
    }) => {
        const [form] = Form.useForm();
        const defaultValues = customSchedules.find(e => e._id === shiftSelected)
        return (
            <Modal
                visible={visible}
                title={configContext.language === 0 ? 'Introduce los datos del turno' : "Enter the shift data"}
                okText={configContext.language === 0 ? "Guardar" : "Save"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modalShifts"
                    initialValues={{ description: defaultValues?.description, _id: defaultValues?._id, color: defaultValues?.color || "#000000" }}
                >
                    <Form.Item
                        name="_id"
                        style={{ display: "none" }}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={configContext.language === 0 ? "Descripción" : "Description"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Tienes que introducir una descripción' : 'You must enter a description' }]}
                    >
                        <Input maxLength={35} />
                    </Form.Item>
                    <Form.Item
                        label='Color'
                        name='color'
                    >
                        <Input type="color" />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const CollectionCreateFormDay: React.FC<CollectionCreateFormProps> = ({
        visible,
        onCreate,
        onCancel
    }) => {
        const [form] = Form.useForm();
        const findDay = workSchedules.find(e => e.day === moment(selectedDate).format("DD/MM/YYYY"))
        let defaultValues = {}
        if (findDay && findDay.employees) {
            Object.keys(findDay.employees).forEach(e => defaultValues[e] = findDay.employees[e])
        }
        return (
            <Modal
                visible={visible}
                title={configContext.language === 0 ? `Día: ${moment(selectedDate).format("DD/MM/YYYY")}` : `Day: ${moment(selectedDate).format("MM/DD/YYYY")}`}
                okText={configContext.language === 0 ? "Guardar" : "Save"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <>
                    <div className="buttonsCalendar mb_20px">
                        <span>
                            <Button type="primary" onClick={() => setShowCopyDay(!showCopyDay)}>{configContext.language === 0 ? 'Copiar Día' : "Copy Day"}</Button>
                            {showCopyDay && <div>
                                <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                                    <DatePicker inputReadOnly defaultValue={dayToCopy && moment(dayToCopy, "DD/MM/YYYY")} onChange={(a, e) => dayToCopy = e} format="DD/MM/YYYY" style={{ margin: "20px auto" }} />
                                </ConfigProvider>
                                <Button type="link" onClick={() => copyFromDay(selectedDate)}>{configContext.language === 0 ? "Copiar" : "Copy"}</Button>
                            </div>}
                        </span>
                        {findDay?._id &&
                            <Popconfirm title={configContext.language === 0 ? 'Borrar día?' : "Delete day?"} onConfirm={() => deleteDay(findDay._id)}>
                                <Button style={{ color: "white", borderColor: "#ff4d4f", backgroundColor: "#ff4d4f" }}>{configContext.language === 0 ? "Borrar Día" : "Delete Day"}</Button>
                            </Popconfirm>
                        }
                    </div>
                    <Form
                        {...layoutHorizontal}
                        form={form}
                        name="form_in_modalDays"
                        initialValues={{ _id: findDay?._id, ...defaultValues }}
                    >
                        <Form.Item
                            name="_id"
                            style={{ display: "none" }}
                        >
                            <Input disabled={true} />
                        </Form.Item>
                        {users.map(user =>
                            <Form.Item
                                label={user.username}
                                name={user._id}
                                key={user._id}
                            >
                                <Select defaultValue={configContext.language === 0 ? "Libre" : "Free"}>
                                    <Select.Option value={"-"}>{configContext.language === 0 ? "Libre" : "Free"}</Select.Option>
                                    {customSchedules.map(sch =>
                                        <Select.Option key={sch._id} value={sch._id}>{sch.description}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        )}
                    </Form>
                </>
            </Modal>
        );
    };

    const getSquares = () => {
        return [
            {
                squareStyle: { width: widthSize < 690 ? "100%" : 350 },
                title: configContext.language === 0 ? "Acciones" : "Actions",
                body:
                    <div className="buttonActions">
                        <Button
                            style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white", marginTop: 15 }}
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={showAddTurn}
                        >
                            {configContext.language === 0 ? "Añadir Turno" : "Add Shift"}
                        </Button>
                        <CollectionCreateForm
                            visible={showShiftsModal}
                            onCreate={addTurn}
                            onCancel={() => {
                                setShowShiftsModal(false);
                            }}
                        />
                    </div>
            },
            {
                squareStyle: { width: widthSize < 690 ? "100%" : "70%", height: "auto" },
                title: configContext.language === 0 ? "Leyenda" : "Legend",
                body:
                    <div className={widthSize < 690 ? "" : "twoCols"}>
                        {customSchedules.map(sch =>
                            <div key={sch._id}>
                                <Badge color={sch.color} text={sch.description} />
                                {!["Absence", "Holiday"].includes(sch._id) && <span>
                                    <Popconfirm title={configContext.language === 0 ? 'Editar turno?' : "Edit shift?"} onConfirm={() => selectShiftToEdit(sch._id)}>
                                        <a><EditTwoTone style={{ color: "#1890ff", margin: "0px 7px" }} /></a>
                                    </Popconfirm>
                                    <Popconfirm title={configContext.language === 0 ? '¿Eliminar turno?' : "Sure to delete?"} onConfirm={() => deleteSchedule(sch._id)}>
                                        <a><DeleteOutlined style={{ color: "red", margin: "0px 7px" }} /></a>
                                    </Popconfirm>
                                </span>}
                            </div>
                        )}
                    </div>
            }
        ]
    }

    const deleteSchedule = async (idSchedule: string) => {
        const response = await axiosCall("delete", envLocal.endpointSchedules, envLocal.authComp, `actions/${idSchedule}`)
        if (response.status === 200) {
            await getSchedules()
        } else {
            message.error(configContext.language === 0 ? "Error eliminando el turno" : "Error deleting the shift")
        }
    }

    const selectShiftToEdit = (idShift: string) => {
        setShiftSelected(idShift)
        setShowShiftsModal(true)
    }

    const fillDay = (day: any) => {
        if ([1, 4, 5].includes(myUser.userType) && moment(selectedDate).format("MM/YYYY") === moment(day).format("MM/YYYY")) {
            setSelectedDate(day)
            setShowModalDay(true)
        }
    }

    const changeMonth = (day: any, type: any) => {
        if (type === "month") {
            getWorkSchedules(moment(day).format("MM/YYYY"))
            setSelectedDate(day)
        }
    }

    const addDay = async (values: any) => {
        const valuesToDelete = Object.keys(values).filter((e: any) => values[e] === "-")
        if(valuesToDelete.length) valuesToDelete.forEach(e => delete values[e])
        let response;
        if (values._id !== undefined) {
            response = await axiosCall("put", envLocal.endpointWorkSchedules, envLocal.authComp, `actions/${values._id}`, values)
        } else {
            response = await axiosCall("post", envLocal.endpointWorkSchedules, envLocal.authComp, "addSchedule", { day: moment(selectedDate).format("DD/MM/YYYY"), ...values })
        }

        if (response.status === 200) {
            await getWorkSchedules(moment(selectedDate).format("MM/YYYY"))
        } else {
            message.error(configContext.language === 0 ? "Error modificando el día" : "Error modifying the day")
        }
        setShowModalDay(false)
    }

    const deleteDay = async (idWorkSchedule: string) => {
        const response = await axiosCall("delete", envLocal.endpointWorkSchedules, envLocal.authComp, `actions/${idWorkSchedule}`)
        if (response.status === 200) {
            await getWorkSchedules(moment(selectedDate).format("MM/YYYY"))
        } else {
            message.error(configContext.language === 0 ? "Error eliminando el día" : "Error deleting the day")
        }
        setShowModalDay(false)
    }

    const copyFromDay = async (day: string) => {
        const response = await axiosCall("post", envLocal.endpointWorkSchedules, envLocal.authComp, "copyDay", { from: dayToCopy, to: moment(day).format("DD/MM/YYYY") })
        if (response.status === 200) {
            await getWorkSchedules(moment(selectedDate).format("MM/YYYY"))
        } else if (response.status === 404) {
            message.error(configContext.language === 0 ? "El día de origen no tiene datos" : "The day of origin has no data")
        } else {
            message.error(configContext.language === 0 ? "Error copiando el día" : "Error copying the day")
        }
        setShowModalDay(false)
    }

    const filterUser = (user: IUsers) => {
        if (user._id === userFiltered?._id) {
            setUserFiltered(undefined)
        } else {
            setUserFiltered(user)
        }
    }

    return (
        <div className="basicContainerColor">
            <NavBar />
            {isLoading ?
                <LoadingSpin />
                :
                <div className="mb_20px">
                   {[1, 4, 5].includes(myUser.userType) && <SquareInfo squares={getSquares()} />} 
                    <div className="sectionSquare mb_20px" style={{ margin: widthSize < 690 ? "30px 0px 5px 0px" : "30px 15px 5px 15px", padding: widthSize < 690 ? "15px 0px" : 15 }}>
                        <div className="sectionSquareTitle">{configContext.language === 0 ? "Registros" : "Records"}</div>
                        <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}>
                            <div className="textCenter m_20px">{users.map(user => <Button onClick={() => filterUser(user)} style={{ backgroundColor: userFiltered ? (userFiltered?._id === user._id ? user.color : "gray") : user.color, borderColor: user.color, color: "white", margin: 5 }} key={user._id}>{user.username}</Button>)}</div>
                            <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                                <Calendar dateCellRender={renderDay} onSelect={fillDay} onPanelChange={changeMonth} />
                            </ConfigProvider>
                        </div>
                    </div>
                    <CollectionCreateFormDay
                        visible={showModalDay}
                        onCreate={addDay}
                        onCancel={() => {
                            setShowModalDay(false);
                            setShowCopyDay(false);
                        }}
                    />
                </div>
            }
        </div>
    );
};

export default Schedules;