import React, { useState, useEffect } from 'react';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import envLocal from '../../env-local';
import { Success } from '../../Components/Basic/Results/Success';
import { Error404 } from '../../Components/Basic/Results/Error404';
import { useConfigContext } from '../../Context/ContextConfig';
import { Form, Input, Button, Col, message } from 'antd';
import Header from '../../Components/Basic/Header';
import { layout } from '../../Components/Basic/Utilities/styles';

let idRegister: string;
let email: string;
let company: boolean;

const ChangePass: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [success, setSuccess] = useState<number>(0);
    const { configContext } = useConfigContext()!;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        let params = (new URL(window.location.href)).searchParams;
        if (params) {
            idRegister = params.get("id")!;
            email = params.get("email")!;
            company = params.get("company") === "true";
            const existLink = (await axiosCall("post", envLocal.endpointChangePass, "", "existsLink", { idRegister, email })).status;
            if (existLink === 404) setSuccess(410);
            if (existLink === 500) setSuccess(500);
        } else {
            setSuccess(500);
        }
        setIsLoading(false);
    };

    const onFinish = async (values: any) => {
        if (values.password.length < 8) {
            message.warning(configContext.language === 0 ? "La contraseña tiene que tener una longitud mínima de 8 caracteres" : "The password has to be at least 8 characters long");
        } else if (values.password !== values.confirmedPassword) {
            message.error(configContext.language === 0 ? "Las contraseñas no coinciden" : "The passwords don't match");
        } else {
            const responseStatus = (await axiosCall("post", envLocal.endpointChangePass, "", "updatePass", { idRegister, email, company, newPassword: values.password })).status;
            if (responseStatus === 200) {
                setSuccess(200);
            }
        }
    }

    return (
        isLoading ?
            <LoadingSpin />
            :
            <>
                {success === 0 &&
                    <div className="basicContainer">
                        <Header />
                        <div className="editMenuTitle">
                            <p className="titleAnt_3 m_35x15">{configContext.language === 0 ? "Introduce tu nueva contraseña" : "Enter your new password"}</p>
                        </div>

                        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 14, offset: 3 }} md={{ span: 10, offset: 5 }} lg={{ span: 8, offset: 6 }} xl={{ span: 8, offset: 7 }}>
                            <Form
                                {...layout}
                                name="basic"
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label={configContext.language === 0 ? "Contraseña" : "Password"}
                                    name="password"
                                    rules={[{ required: true, message: configContext.language === 0 ? "Por favor, introduce tu contraseña" : 'Please input your password' }]}
                                >
                                    <Input.Password maxLength={15} placeholder={configContext.language === 0 ? "Tu Contraseña" : "Your Password"} />
                                </Form.Item>
                                <Form.Item
                                    label={configContext.language === 0 ? "Repite tu Contraseña" : "Repeat your Password"}
                                    name="confirmedPassword"
                                    rules={[{ required: true, message: configContext.language === 0 ? "Por favor, introduce tu contraseña" : 'Please input your password again' }]}
                                >
                                    <Input.Password maxLength={15} placeholder={configContext.language === 0 ? "Tu Contraseña" : "Your Password"} />
                                </Form.Item>
                                <Form.Item className="centerButtonForm">
                                    <Col xs={{ span: 24, offset: 0 }} md={{ span: 10, offset: 24 }}>
                                        <Button type="primary" htmlType="submit">
                                            {configContext.language === 0 ? "Enviar" : "Submit"}
                                        </Button>
                                    </Col>
                                </Form.Item>
                            </Form>
                        </Col>
                    </div>}
                {success === 200 && <Success title={configContext.language === 0 ? "Contraseña Actualizada" : "Password Updated"} subTitle={configContext.language === 0 ? "Ya puedes conectarte con tu nueva contraseña" : "You can now log in with your new password"} goTo={configContext.language === 0 ? "Ir a Bliscop" : "Go to Bliscop"} />}
                {success === 410 && <Error404 title={configContext.language === 0 ? "Enlace caducado" : "Link expired"} subTitle={configContext.language === 0 ? "Solicite un nuevo cambio de contraseña" : "Request a new password change"} goTo={configContext.language === 0 ? "Ir a Bliscop" : "Go to Bliscop"} />}
                {success === 500 && <Error404 title={configContext.language === 0 ? "Ha ocurrido un error" : "An error has occurred"} subTitle={configContext.language === 0 ? "Lo sentimos, inténtelo de nuevo más tarde" : "Sorry, try again later"} goTo={configContext.language === 0 ? "Ir a Bliscop" : "Go to Bliscop"} />}
            </>
    );
};

export default ChangePass;