import React, { useState } from 'react';
import { Button, ConfigProvider, DatePicker, message } from 'antd';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import envLocal from '../../env-local';
import NavBar from '../../Components/Basic/NavBar';
import { axiosCall, parsedDateString } from '../../Components/Basic/Utilities/tools';
import { IBills, IStats, IBasicProduct, IBasicMeal, IDeletedProducts } from '../../Components/Basic/Utilities/interfaces';
import { useConfigContext } from '../../Context/ContextConfig';
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';
import localeEn from 'antd/lib/locale/en_GB';
import GetBills from './GetBills'
import GetSuppliers from './GetSuppliers'
import GetIntake from './GetIntake'
import GetBalance from './GetBalance'
import GetProducts from './GetProducts'
import GetCategories from './GetCategories'
import coinList from '../../Components/Basic/Utilities/coinList';
import { useRestaurant } from '../../Context/ContextRestaurant';

const { RangePicker } = DatePicker;

let drinks: IBasicMeal[] = [];
let dishes: IBasicMeal[] = [];
let basicProduct: IBasicProduct[] = [];

const StockStats: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bills, setBills] = useState<IBills[]>([]);
    const [stats, setStats] = useState<IStats[]>([]);
    const [deletedProducts, setDeletedProducts] = useState<IDeletedProducts[]>([]);
    const [index, setIndex] = useState<number>(0);
    const { configContext } = useConfigContext()!;
    const [title, setTitle] = useState(configContext.language === 0 ? "Compras" : "Purchases")
    let widthSize = window.innerWidth;
    const { restaurant } = useRestaurant()!;
    const coin = coinList.find(coin => coin.value === restaurant.coin)?.symbol;

    React.useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        const fetchBasics = getBasicProducts();
        const fetchDrinks = getDrinks();
        const fetchDishes = getDishes();
        await Promise.all([fetchBasics, fetchDrinks, fetchDishes])
    };

    const getBasicProducts = async () => {
        basicProduct = (await axiosCall("post", envLocal.endpointBasicProduct, envLocal.authComp, "getProducts")).data;
    };

    const getDishes = async () => {
        dishes = (await axiosCall("post", envLocal.endpointDish, envLocal.authComp)).data;
    };

    const getDrinks = async () => {
        drinks = (await axiosCall("post", envLocal.endpointDrink, envLocal.authComp)).data;
    };

    const onChange = async (inputDates: any) => {
        setIsLoading(true)
        if (inputDates !== null && inputDates[0] && inputDates[1]) {
            const responseBill = await axiosCall("post", envLocal.endpointBill, envLocal.authComp, "getBills", { inputDates });
            const responseStats = await axiosCall("post", envLocal.endpointStats, envLocal.authComp, "findItemOrdered", { inputDates: [parsedDateString(inputDates[0]), parsedDateString(inputDates[1])] });
            const responseDeleted = await axiosCall("post", envLocal.endpointBasicProduct, envLocal.authComp, "getDeleted", { inputDates })
            if (responseBill.status === 200 && responseStats.status === 200 && responseDeleted.status === 200) {
                setBills(responseBill.data)
                setStats(responseStats.data)
                setDeletedProducts(responseDeleted.data)
            } else {
                message.error(configContext.language === 0 ? "Error recuperando los datos" : "Error recovering datas")
            }
        };
        setIsLoading(false)
    }

    const getTitle = (index: number) => {
        switch (index) {
            case 0:
                setIndex(0)
                setTitle(configContext.language === 0 ? "Compras" : "Purchases")
                break
            case 1:
                setIndex(1)
                setTitle(configContext.language === 0 ? "Proveedores" : "Suppliers")
                break
            case 2:
                setIndex(2)
                setTitle(configContext.language === 0 ? "Categorias" : "Categories")
                break
            case 3:
                setIndex(3)
                setTitle(configContext.language === 0 ? "Consumos" : "Intake")
                break
            case 4:
                setIndex(4)
                setTitle("Balances")
                break
            case 5:
                setIndex(5)
                setTitle(configContext.language === 0 ? "Productos" : "Products")
                break

        }
    }

    return (
        <div className="basicContainerColor">
            <NavBar />
            <div className="mb_30px mt_60px textCenter">
                <div className="mb_20px boldLarge">{configContext.language === 0 ? "Introduce el rango de fechas de la búsqueda" : "Enter the date range of the search"}</div>
                <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                    <RangePicker
                        inputReadOnly
                        onChange={onChange}
                        format="DD/MM/YYYY"
                    />
                </ConfigProvider>
                <div className="paymentsOptions mt_20px mb_50px">
                    <Button className="mt_10px" style={{ backgroundColor: index === 0 ? "#1890ff" : "#94bde3" }} type="primary" onClick={() => getTitle(0)}>{configContext.language === 0 ? "Compras" : "Purchases"}</Button>
                    <Button className="mt_10px" style={{ backgroundColor: index === 1 ? "#1890ff" : "#94bde3" }} type="primary" onClick={() => getTitle(1)}>{configContext.language === 0 ? "Proveedores" : "Suppliers"}</Button>
                    <Button className="mt_10px" style={{ backgroundColor: index === 2 ? "#1890ff" : "#94bde3" }} type="primary" onClick={() => getTitle(2)}>{configContext.language === 0 ? "Categorias" : "Categories"}</Button>
                    <Button className="mt_10px" style={{ backgroundColor: index === 3 ? "#1890ff" : "#94bde3" }} type="primary" onClick={() => getTitle(3)}>{configContext.language === 0 ? "Consumos" : "Intake"}</Button>
                    <Button className="mt_10px" style={{ backgroundColor: index === 4 ? "#1890ff" : "#94bde3" }} type="primary" onClick={() => getTitle(4)}>Balances</Button>
                    <Button className="mt_10px" style={{ backgroundColor: index === 5 ? "#1890ff" : "#94bde3" }} type="primary" onClick={() => getTitle(5)}>{configContext.language === 0 ? "Productos" : "Products"}</Button>
                </div>
                <div className="sectionSquare" style={{ margin: widthSize < 690 ? "30px 0px 5px 0px" : "30px 15px 5px 15px", padding: widthSize < 690 ? "15px 0px" : 15 }}>
                    <div className="sectionSquareTitle">{title}</div>
                    <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}>
                        {isLoading ?
                            <LoadingSpin />
                            :
                            <>
                                {index === 0 && <GetBills coin={coin!} bills={bills} basicProduct={basicProduct} language={configContext.language!} />}
                                {index === 1 && <GetSuppliers coin={coin!} bills={bills} language={configContext.language!} />}
                                {index === 2 && <GetCategories coin={coin!} bills={bills} basicProduct={basicProduct} language={configContext.language!} />}
                                {index === 3 && <GetIntake coin={coin!} deletedProducts={deletedProducts} stats={stats} dishes={dishes} drinks={drinks} basicProduct={basicProduct} language={configContext.language!} />}
                                {index === 4 && <GetBalance coin={coin!} deletedProducts={deletedProducts} stats={stats} bills={bills} basicProduct={basicProduct} language={configContext.language!} />}
                                {index === 5 && <GetProducts coin={coin!} stats={stats} dishes={dishes} drinks={drinks} basicProduct={basicProduct} language={configContext.language!} />}
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="textCenter mb_20px">
                <Button type='primary' onClick={() => window.history.go(-1)}>{configContext.language === 0 ? 'Atrás' : "Back"}</Button>
            </div>
        </div >
    );
};

export default StockStats;