import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useConfigContext } from '../../Context/ContextConfig';

const LanguageRedirect: React.FC<RouteComponentProps> = (props) => {
    const { setConfigContext } = useConfigContext()!;

    useEffect(() => {
        const language = window.location.href.split('/').pop();
        if (language?.toLocaleLowerCase() === 'es') {
            localStorage.setItem("configContext", JSON.stringify({ language: 0, showCaptcha: false }));
            setConfigContext({ language: 0 });
        }
        if (language?.toLocaleLowerCase() === 'en'){
            localStorage.setItem("configContext", JSON.stringify({ language: 1, showCaptcha: false }));
            setConfigContext({ language: 1 });
        } 
        props.history.push('/');
    }, []);

    return (<div>Redirect</div>);
};

export default LanguageRedirect;