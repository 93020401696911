import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Popover, Button, Col, Row, Dropdown, Menu, message } from 'antd';
import Counter from '../../Components/Basic/Counter';
import { CloseOutlined } from '@ant-design/icons';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import envLocal from '../../env-local';
import socketIOClient from "socket.io-client";
import NavBar from '../../Components/Basic/NavBar';
import { InfoCircleOutlined, RightOutlined, FormOutlined } from '@ant-design/icons';
import { useRestaurant } from '../../Context/ContextRestaurant';
import { IUsers, ITask } from '../../Components/Basic/Utilities/interfaces';
import moment from 'moment';
import { axiosCall, calculateExtra } from '../../Components/Basic/Utilities/tools';
import { useUser } from '../../Context/ContextUser';
import { useConfigContext } from '../../Context/ContextConfig';
import SquareInfo from '../../Components/Basic/SquareInfo';
import { RouteComponentProps } from 'react-router-dom';

interface IColumns {
  [key: string]: {
    name?: string;
    items: ITask[];
  };
};

interface IAssTask {
  id: string;
  name: string;
  status: number;
  index: number;
  idRestaurant: string;
};

let socket: any;
const columnName = ["ColumnsLabel", "noPriority", "priority", "inProgress", "done"];
let idTask: string;
const audioNewTask = new Audio("/audio/newtask.mp3")


const TaskBoard: React.FC<RouteComponentProps> = (props) => {
  const [columns, setColumns] = useState<IColumns>({});
  const [showPop, setShowPop] = useState<string>("-");
  const [showObs, setShowObs] = useState<string>("-");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<Array<IUsers>>([]);
  const [inputTask, setInputTask] = useState<ITask>();
  const { restaurant } = useRestaurant()!;
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const myUser = useUser()!.user;
  const { configContext } = useConfigContext()!;
  let widthSize = window.innerWidth;

  const setColumnsData = (data: any) => {
    const result: IColumns = {
      [columnName[1]]: {
        name: configContext.language === 0 ? "No Prioritarias" : "Non priority",
        items: data.response[0]
      },
      [columnName[2]]: {
        name: configContext.language === 0 ? "Prioritarias" : "Priority",
        items: data.response[1]
      },
      [columnName[3]]: {
        name: configContext.language === 0 ? "En Progreso" : "In Progress",
        items: data.response[2]
      },
      [columnName[4]]: {
        name: configContext.language === 0 ? "Hechas" : "Done",
        items: data.response[3]
      }
    };
    data.setColumns(result);
  };

  const onDragEnd = (result: any, columns: any, setColumns: any) => {
    if (!result.destination) return;
    if (result.source.droppableId === "done") {
      message.error(configContext.language === 0 ? 'Esa tarea ya ha sido completada' : 'That task has already been completed');
      return;
    }
    const { source, destination, draggableId } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      sourceItems.splice(source.index, 1);
      const destItems = [...destColumn.items];
      const currentStatus = columnName.indexOf(source.droppableId);
      const nextStatus = columnName.indexOf(destination.droppableId)
      const newItem = columns[source.droppableId].items.filter((item: ITask) => item._id === draggableId)[0];
      let newTask: ITask;
      const now = Date.now()
      if (currentStatus === 1 || currentStatus === 2) {
        newTask = { ...newItem, status: nextStatus, updatedAt: now, waiting: now - newItem.updatedAt };
      } else if (currentStatus === 3) {
        newTask = { ...newItem, status: nextStatus, updatedAt: now, inProgress: now - newItem.updatedAt };
      } else {
        newTask = { ...newItem, status: nextStatus, updatedAt: now };
      };
      socket.emit("updateTask", nextStatus === 4 ? { ...newTask, language: configContext.language, closedDate: moment(newTask.createdAt).format('DD/MM/YYYY'), closedHour: moment(newTask.createdAt).hour() } : newTask);
      destItems.splice(destination.index, 0, newTask);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };

  useEffect(() => {
    socket = socketIOClient(envLocal.endpointSocketServer, { transports: ['websocket'], query: { token: window.localStorage.getItem(envLocal.authComp) } });
    fetchData();
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      socket.emit("joinRoom", restaurant._id);
      socket.on("newTask", (item: ITask) => {
        if (item._id !== idTask && ([1, 4, 5].includes(myUser.userType) || myUser.username === item.assignedTo || restaurant.config.taskVisibleAll)) {
          idTask = item._id;
          setInputTask(item);
        };
      });
      socket.on("forceUpdate", () => {
        fetchTasks();
      });
      socket.on("forceUpdateManager", () => {
        if ([1, 4, 5].includes(myUser.userType) || restaurant.config.taskVisibleAll) {
          fetchTasks();
        }
      });
    };
  }, [isLoading]);

  useEffect(() => {
    if (inputTask !== undefined) {
      audioNewTask.play()
      getNewTask(inputTask);
    };
  }, [inputTask]);

  const fetchData = async () => {
    await fetchTasks();
    await setActiveUsers();
    setIsLoading(false);
  };

  const fetchTasks = async () => {
    const response = await getTasks([1, 2, 3, 4]);
    if (response && response.length) {
      setColumnsData({
        response: [
          response.filter((data: any) => data.status === 1),
          response.filter((data: any) => data.status === 2),
          response.filter((data: any) => data.status === 3),
          response.filter((data: any) => data.status === 4)
        ],
        setColumns
      });
    } else {
      setColumnsData({
        response: [[], [], [], []],
        setColumns
      });
    }
  }

  const getNewTask = async (task: ITask) => {
    await setColumns({
      ...columns,
      [columnName[task.desiredStatus]]: {
        ...columns[columnName[task.desiredStatus]],
        items: [...columns[columnName[task.desiredStatus]].items, { ...task, status: task.desiredStatus }]
      }
    });
  };

  const setActiveUsers = async () => {
    if ([1, 4, 5].includes(myUser.userType) || restaurant.config.taskVisibleAll) {
      setUsers((await axiosCall("post", envLocal.endpointAuthenticationComp, envLocal.authComp, "findUsers", { status: [1, 2], userType: [1, 3] })).data);
    } else {
      setUsers([(await axiosCall("get", envLocal.endpointAuthenticationComp, envLocal.authComp, "findUsers")).data]);
    }
  };

  const changeState = (input: IUsers) => {
    const userUpdated = users.map((user: IUsers) => {
      if (user._id === input._id) {
        user.status = input.status === 1 ? 2 : 1;
        socket.emit("updateStatus", { idUser: user._id, status: user.status });
        message.info(configContext.language === 0 ? `${user.username} pasa a estar ${input.status === 1 ? "Activo!" : "Ausente!"}` : `${user.username} is now ${input.status === 1 ? "Active!" : "Absent!"}`);
        return user;
      } else {
        return user;
      }
    });
    setUsers(userUpdated);
  };

  const getTasks = async (status: number[]) => {
    const response = await axiosCall("post", envLocal.endpointTask, envLocal.authComp, "restaurant", {
      status: status,
      assignedTo: [1, 4, 5].includes(myUser.userType) || restaurant.config.taskVisibleAll ? undefined : myUser.username
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  };

  const assignTask = async (item: IAssTask) => {
    if (item.status === 4) {
      message.error(configContext.language === 0 ? 'No puedes reasignar tareas completadas' : "You can't reassign completed tasks");
    } else {
      socket.emit("assignTask", { _id: item.id, assignedTo: item.name, idRestaurant: item.idRestaurant });
      let newArray: IColumns = { ...columns };
      newArray[columnName[item.status]].items[item.index].assignedTo = item.name;
      await setColumns(newArray);
    };
  };

  const advanceItem = async (item: ITask, index: number) => {
    setIsLoadingButton(true);
    const now = Date.now();
    let newTask: ITask;
    if (item.status === 1 || item.status === 2) {
      newTask = { ...item, status: item.status + 1, updatedAt: now, waiting: now - item.updatedAt };
    } else if (item.status === 3) {
      newTask = { ...item, status: item.status + 1, updatedAt: now, inProgress: now - item.updatedAt, closedDate: moment(item.createdAt).format('DD/MM/YYYY'), closedHour: moment(item.createdAt).hour() };
    } else {
      newTask = { ...item, status: item.status + 1, updatedAt: now };
    };
    socket.emit("updateTask", { ...newTask, language: configContext.language });
    const sourceColumn = columns[columnName[item.status]];
    const destColumn = columns[columnName[item.status + 1]];
    const sourceItems = [...sourceColumn.items];
    sourceItems.splice(index, 1);
    const destItems = [...destColumn.items];
    destItems.splice(item.status + 1, 0, newTask);
    setColumns({
      ...columns,
      [columnName[item.status]]: {
        ...sourceColumn,
        items: sourceItems
      },
      [columnName[item.status + 1]]: {
        ...destColumn,
        items: destItems
      }
    });
    setIsLoadingButton(false);
  };

  const getSquareTask = () => {
    return [
      {
        squareStyle: { width: widthSize < 690 ? "100%" : 350 },
        title: configContext.language === 0 ? "Última Tarea" : "Last Task",
        body:
          <div className="textCenter mt_25px">
            {inputTask ?
              <span style={{ color: "white", borderRadius: 4, background: "#4d8fcd", maxWidth: 350, width: "fit-content", height: 30, padding: 9 }}>
                {inputTask.content}
              </span>
              :
              <strong>{configContext.language === 0 ? "Esperando..." : "Waiting..."}</strong>
            }
          </div>
      },
      {
        squareStyle: { width: widthSize < 690 ? "100%" : "70%" },
        title: configContext.language === 0 ? "Empleados" : "Employees",
        body:
          users.length > 4 && widthSize < 900 ?
            <div className="textCenter mt_25px">
              <Dropdown trigger={["click"]} overlay={
                <Menu>
                  {users.map(user =>
                    <Menu.Item key={user._id}>
                      <div className="buttonActions">
                        <Button
                          style={{ color: "white", backgroundColor: user.status === 1 ? user.color : "gray" }}
                          onClick={() => ([1, 4, 5].includes(myUser.userType) || user.username === myUser.username) && changeState(user)}
                        >{user.username}
                        </Button>
                      </div>
                    </Menu.Item>
                  )}
                </Menu>
              } placement="bottomCenter">
                <Button type="primary">{configContext.language === 0 ? 'Empleados' : "Employees"}</Button>
              </Dropdown>
            </div>
            :
            <div className="textCenter mt_25px">
              {users.map(user =>
                <Button key={user.username}
                  style={{ margin: "0px 3px", color: "white", backgroundColor: user.status === 1 ? user.color : "gray" }}
                  onClick={() => ([1, 4, 5].includes(myUser.userType) || user.username === myUser.username) && changeState(user)}
                >{user.username}
                </Button>)}
            </div>
      }
    ]
  }

  const printBill = async (idOrder: string) => {
    const response = await axiosCall("post", envLocal.endpointOrder, envLocal.authComp, "actions/" + idOrder);
    if (response.status === 200) {
      props.history.push({
        pathname: '/ToPrint',
        state: { billModal: response.data, coin: response.data.coin, restaurant, language: configContext.language, type: 1 }
      })
    } else {
      message.error("Error");
    }
  }

  const nullNoPriority = !isLoading && columns.noPriority.items?.length === 0;

  return (
    <div className="basicContainerColor">
      <NavBar />
      <div className="titleSection">{configContext.language === 0 ? 'Tareas' : "Tasks"}</div>
      {isLoading ?
        <LoadingSpin />
        :
        <div className="mb_30px">
          <SquareInfo squares={getSquareTask()} />
          <div className="kanbanBackground sectionSquare">
            <div className="sectionSquareTitle">{configContext.language === 0 ? "Tareas" : "Task"}</div>
            <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}>
              <div className="kanbanColumns">
                <DragDropContext onDragEnd={result => onDragEnd(result, columns, setColumns)}>
                  {Object.entries(columns).map(([columnId, column]) => {
                    return (
                      (columnId === columnName[1] && nullNoPriority) ?
                        null
                        : <div className="kanbanColumn" key={columnId}>
                          <Row style={{ display: "block", textAlign: "center" }}><div className="kanbanTitle">{column.name}</div></Row>
                          <Row style={{ textAlign: "center" }}>
                            <Droppable droppableId={columnId} key={columnId}>
                              {(provided, snapshot,) => {
                                return (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                      background: snapshot.isDraggingOver
                                        ? "lightblue"
                                        : "inherit",
                                      borderRadius: 4,
                                      margin: "auto",
                                      width: 310,
                                      display: "block"
                                    }}
                                  >
                                    {column.items?.map((item, index) => {
                                      return (
                                        <Draggable
                                          key={item._id}
                                          draggableId={item._id}
                                          index={index}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                  userSelect: "none",
                                                  display: "inline-block",
                                                  borderRadius: 4,
                                                  padding: 5,
                                                  margin: 5,
                                                  height: 80,
                                                  width: 300,
                                                  backgroundColor: snapshot.isDragging
                                                    ? "#A396BE"
                                                    : "#F2F8FF",
                                                  color: "#5926BE",
                                                  ...provided.draggableProps.style
                                                }}
                                              >
                                                <div style={{ padding: "5px", fontWeight: "bold", fontSize: "110%" }}>{item.content}</div>
                                                <Row>
                                                  <Col span={7}>
                                                    <Popover
                                                      title={<React.Fragment>
                                                        <strong>{configContext.language === 0 ? 'Asignar a: ' : "Assign to: "}</strong>
                                                        <Button type="link" style={{ position: "absolute", right: "0px", top: "0px", color: "red" }} icon={<CloseOutlined />} onClick={() => setShowPop("-")} />
                                                      </React.Fragment>}
                                                      visible={showPop === item._id}
                                                      content={[users.map(user =>
                                                        <Button key={user.username}
                                                          style={{ margin: "0px 3px", color: "white", backgroundColor: user.color }}
                                                          onClick={() => { assignTask({ id: item._id, name: user.username, status: item.status, index, idRestaurant: item.idRestaurant }); setShowPop("-"); }}
                                                        >{user.username}
                                                        </Button>)
                                                      ]}
                                                    >
                                                      <Button
                                                        style={{ color: "white", margin: "2px", backgroundColor: users.filter(user => user.username === item.assignedTo).length ? users.find(user => user.username === item.assignedTo)?.color : "red" }}
                                                        onClick={() => { if ([1, 4, 5].includes(myUser.userType)) showPop === "-" ? setShowPop(item._id) : setShowPop("-") }}
                                                      >{item.assignedTo.length > 7 ? item.assignedTo.substring(0, 9) : item.assignedTo}
                                                      </Button>
                                                    </Popover>
                                                  </Col>
                                                  <Col span={11}>
                                                    <Counter formatReduced={false} createdAt={item.createdAt} />
                                                  </Col>
                                                  <Col span={6} style={{ display: "flex", justifyContent: "center" }}>
                                                    {item.type === 5 ?
                                                      <Popover
                                                        title={<React.Fragment>
                                                          <strong>{configContext.language === 0 ? 'La cuenta' : "Bill"}</strong>
                                                          <Button type="link" style={{ position: "absolute", right: "0px", top: "0px", color: "red" }} icon={<CloseOutlined />} onClick={() => setShowObs("-")} />
                                                        </React.Fragment>}
                                                        visible={showObs === item._id}
                                                        content={<>
                                                          {item.observations && <div><b>{configContext.language === 0 ? 'Observaciones:' : "Comments:"}</b> {item.observations}</div>}
                                                          <span className="textCenter block"><Button type="link" onClick={() => printBill(item.idOrder!)}>{configContext.language === 0 ? 'Imprimir Cuenta' : "Print Bill"}</Button></span>
                                                        </>}
                                                      >
                                                        <Button
                                                          style={{ color: "white", margin: "2px", backgroundColor: "#ff7c00" }}
                                                          icon={<FormOutlined />}
                                                          onClick={() => showObs === "-" ? setShowObs(item._id) : setShowObs("-")}
                                                        />
                                                      </Popover>
                                                      :
                                                      (item.observations || item.order?.length) ? <Popover
                                                        title={<React.Fragment>
                                                          <strong>{configContext.language === 0 ? 'Detalles' : "Details"}</strong>
                                                          <Button type="link" style={{ position: "absolute", right: "0px", top: "0px", color: "red" }} icon={<CloseOutlined />} onClick={() => setShowObs("-")} />
                                                        </React.Fragment>}
                                                        visible={showObs === item._id}
                                                        content={<>
                                                          {item.order && item.order.map(item => <div key={item.name}><b>x{item.quantity} {item.name}</b> {item.options?.length ? calculateExtra(item.options).map((e, idx) => <div key={idx}>{idx > 0 && <div>{`${idx + 1}º ------`}</div>}{e.map(i => <div className="ml_10px" key={i}>{i}</div>)}</div>) : null}</div>)}
                                                          {item.observations && <div><b>{configContext.language === 0 ? 'Observaciones:' : "Comments:"}</b> {item.observations}</div>}
                                                        </>}
                                                      >
                                                        <Button
                                                          style={{ color: "white", margin: "2px", backgroundColor: "#1890FF" }}
                                                          icon={<InfoCircleOutlined />}
                                                          onClick={() => showObs === "-" ? setShowObs(item._id) : setShowObs("-")}
                                                        />
                                                      </Popover> : null
                                                    }
                                                    {item.status < 4 ?
                                                      <Button
                                                        style={{ color: "white", margin: "2px", backgroundColor: "#06AE04" }}
                                                        icon={<RightOutlined />}
                                                        onClick={() => advanceItem(item, index)}
                                                        loading={isLoadingButton}
                                                      /> : null}
                                                  </Col>
                                                </Row>
                                              </div>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    })}
                                    {provided.placeholder}
                                  </div>
                                );
                              }}
                            </Droppable>
                          </Row>
                        </div>
                    );
                  })}
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default TaskBoard;