import React from 'react';
import { Form, Input, Tooltip, Select, Button, Row, Col, Rate, InputNumber, message } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { layout } from '../../Basic/Utilities/styles';
import envLocal from '../../../env-local';
import { IItems, IRestaurant } from '../../../Components/Basic/Utilities/interfaces';
import { axiosCall } from '../../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../../Context/ContextConfig';
import OptionLisProducts from './OptionLisProducts';

const { TextArea } = Input;

interface IProps {
  idSection?: string;
  getDishes: Function;
  item?: IItems;
  restaurant: IRestaurant;
  setShowModalView: Function;
};

const Dish: React.FC<IProps> = (props) => {
  const { configContext } = useConfigContext()!;

  const onFinish = async (values: any) => {
    const newItem = { ...values, type: 1 };
    if (props.item?._id !== undefined) {
      const restStatus = (await axiosCall("put", envLocal.endpointItemMenu, envLocal.authComp, props.item._id, newItem)).status;
      if (restStatus === 200) {
        message.success(configContext.language === 0 ? "Plato actualizado con éxito!" : 'Dish updated successfully!');
      } else {
        message.error("Error");
      }
    } else {
      const restStatus = (await axiosCall("post", envLocal.endpointItemMenu, envLocal.authComp, "", newItem)).status;
      if (restStatus === 200) {
        message.success(configContext.language === 0 ? "Plato creado con éxito!" : 'Dish created successfully!')
      } else {
        message.error("Error");
      }
    };
    await props.getDishes();
    props.setShowModalView(false);
  };

  return (
    <div className="pt_3em">
      <Row className="editMenuTitle">
        <Col><p className="titleAnt_3">{configContext.language === 0 ? "Rellena los campos para guardar el plato" : 'Fill in the fields to save the dish'}</p></Col>
      </Row>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
          <Form {...layout}
            onFinish={onFinish}
            initialValues={{ ...props.item, point: props.item?.point === true ? 1 : 0 }}>
            <Form.Item
              label={configContext.language === 0 ? 'Nombre del Plato' : 'Dish Name'}
              name='name'
              rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el nombre del plato en la carta' : 'Enter the name of the dish on the menu' }]}
            >
              <Input maxLength={35} />
            </Form.Item>
            <Form.Item
              label={configContext.language === 0 ? 'Precio' : 'Price'}
              name='price'
              rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el precio del plato' : 'Enter the price of the dish' }]}
            >
              <InputNumber min={0} maxLength={5} />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {configContext.language === 0 ? 'Suplemento en menú' : 'Supplement to menu'}&nbsp;
                <Tooltip title={configContext.language === 0 ? 'Si se incluye en el menú, este plato tendrá un suplemento extra de la cantidad indicada' : 'If included in the menu, this dish will have an extra supplement of the indicated amount'}>
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </span>
              }
              name='supplementMenu'
            >
              <InputNumber min={0} maxLength={5} />
            </Form.Item>
            <Form.Item
              label={configContext.language === 0 ? 'Descripción del Plato' : 'Dish Description'}
              name='description'
              rules={[{ required: true, message: configContext.language === 0 ? 'Introduce la descripción del plato' : 'Enter the description of the dish' }]}
            >
              <TextArea maxLength={100} />
            </Form.Item>
            <Form.Item label={
              <span>
                {configContext.language === 0 ? '¿Punto de cocinado?' : 'Cooking point?'}&nbsp;
                <Tooltip title={configContext.language === 0 ? 'Servirá al cliente para indicar el punto de cocinado del plato. Ej: la carne' : 'It will serve the customer to indicate the cooking point of the dish. E.g: the meat'}>
                  <QuestionCircleTwoTone />
                </Tooltip>
              </span>
            }
              name='point'
              rules={[{ required: true, message: configContext.language === 0 ? 'Indica si el plato tiene punto de cocinado. Ej: la carne' : 'Indicates if the dish is cooked. E.g: the meat' }]}
            >
              <Select><Select.Option value={0}>No</Select.Option><Select.Option value={1}>{configContext.language === 0 ? 'Sí' : 'Yes'}</Select.Option></Select>
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {configContext.language === 0 ? 'Alérgenos' : 'Allergens'}&nbsp;
                  <Tooltip title={configContext.language === 0 ? 'Aquí puedes añadir información acerca de los posibles alérgenos. Ej: Marisco, Lácteos, etc.' : 'Here you can add information about possible allergens. E.g: Seafood, Dairy, etc.'}>
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </span>
              }
              name='alergenos'
            >
              <Input maxLength={40} />
            </Form.Item>
            {props.item === undefined && <Form.Item label={
              <span>
                {configContext.language === 0 ? 'Puntuación' : 'Score'}&nbsp;
                          <Tooltip title={configContext.language === 0 ? 'Aquí puedes añadir una puntuación al plato. Te servirá para recomendar platos al cliente' : 'Here you can add a score to the dish. It will help you to recommend dishes to the customer'}>
                  <QuestionCircleTwoTone />
                </Tooltip>
              </span>
            }
              name='rate'
            >
              <Rate allowHalf />
            </Form.Item>}
            <OptionLisProducts language={configContext.language!} />
            <Form.Item>
              <Col xs={{ span: 8, offset: 8 }} sm={{ span: 1, offset: 24 }}>
                <Button type='primary' htmlType='submit'>
                  {configContext.language === 0 ? 'Guardar' : 'Save'}
                </Button>
              </Col>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Dish;