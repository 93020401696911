import React from 'react';
import { Table } from 'antd';
import { IStats, IBasicProduct, IBasicMeal, IDeletedProducts } from '../../Components/Basic/Utilities/interfaces';
import DownloadExcel from '../../Components/Basic/ExportExcel';

interface IIntake {
    deletedProducts: IDeletedProducts[]
    stats: IStats[]
    dishes: IBasicMeal[]
    drinks: IBasicMeal[]
    basicProduct: IBasicProduct[]
    language: number,
    coin: string
}

const getColumnsIntake = (coin: string, language: number) => {
    return [
        {
            title: language === 0 ? "Producto" : "Product",
            dataIndex: 'name',
            key: 'name',
            label: language === 0 ? "Producto" : "Product",
        },
        {
            title: language === 0 ? "Cantidad" : "Quantity",
            dataIndex: 'quantity',
            key: 'quantity',
            label: language === 0 ? "Cantidad" : "Quantity",
            sorter: (a: any, b: any) => a.quantity - b.quantity,
        },
        {
            title: language === 0 ? `Importe (${coin})` : `Price (${coin})`,
            dataIndex: 'priceConsume',
            key: 'priceConsume',
            label: language === 0 ? `Importe (${coin})` : `Price (${coin})`,
            sorter: (a: any, b: any) => a.priceConsume - b.priceConsume,
        },
        {
            title: language === 0 ? "Mermas" : "Waste",
            dataIndex: 'quantityDeleted',
            key: 'quantityDeleted',
            label: language === 0 ? "Mermas" : "Waste",
            sorter: (a: any, b: any) => a.quantityDeleted - b.quantityDeleted,
        },
        {
            title: language === 0 ? `Importe Mermas (${coin})` : `Waste Price (${coin})`,
            dataIndex: 'priceDeleted',
            key: 'priceDeleted',
            label: language === 0 ? `Importe Mermas (${coin})` : `Waste Price (${coin})`,
            sorter: (a: any, b: any) => a.priceDeleted - b.priceDeleted,
        },
        {
            title: language === 0 ? "Motivo Pérdidas" : "Waste Reason",
            dataIndex: 'reasonDeleted',
            key: 'reasonDeleted',
            label: language === 0 ? "Motivo Pérdidas" : "Waste Reason",
        },
    ];
}

const GetIntake: React.FC<IIntake> = ({ deletedProducts, stats, dishes, drinks, basicProduct, language, coin }) => {

    const products: { name: string, item: string, quantity: number, type: number }[] = []

    stats.forEach(day => day.itemOrdered.forEach(item => {
        let exists = false
        products.forEach(product => {
            if (product.item === item.idItem.split("#").pop()) {
                exists = true
                product.quantity += item.quantity
            }
        })
        if (item.idItem !== undefined && !exists) products.push({ name: item.name, item: item.idItem.split("#").pop()!, quantity: item.quantity, type: item.type })
    }))

    const consume: { id?: string, name?: string | undefined, quantity?: number, priceConsume?: number, quantityDeleted?: number, priceDeleted?: number, reasonDeleted?: string }[] = []

    products.forEach(product => {
        if (product.type === 1) {
            const dishFinded = dishes.find(dish => dish._id === product.item)
            if (dishFinded && dishFinded.components?.length) {
                dishFinded.components.forEach(component => {
                    let exists = false
                    consume.forEach(cons => {
                        if (cons.id === component.idProduct) {
                            exists = true
                            cons.quantity = (cons.quantity || 0) + component.quantity
                        }
                    })
                    if (!exists) consume.push({ id: component.idProduct, name: basicProduct.find(e => e._id === component.idProduct)?.name, quantity: product.quantity * component.quantity, priceConsume: Number((product.quantity * component.quantity * (basicProduct.find(e => e._id === component.idProduct)?.pricePerUnit || 0)).toFixed(2)) })
                })
            }
        } else {
            const drinkFinded = drinks.find(drink => drink._id === product.item)
            if (drinkFinded && drinkFinded.components?.length) {
                drinkFinded.components.forEach(component => {
                    let exists = false
                    consume.forEach(cons => {
                        if (cons.id === component.idProduct) {
                            exists = true
                            cons.quantity = (cons.quantity || 0) + component.quantity
                        }
                    })
                    if (!exists) consume.push({ id: component.idProduct, name: basicProduct.find(e => e._id === component.idProduct)?.name, quantity: product.quantity * component.quantity, priceConsume: Number((product.quantity * component.quantity * (basicProduct.find(e => e._id === component.idProduct)?.pricePerUnit || 0)).toFixed(2)) })
                })
            }
        }
    })

    deletedProducts.forEach(del => {
        let exists = false
        consume.forEach(product => {
            if (product.id === del.idProduct) {
                exists = true
                product.quantityDeleted = (product.quantityDeleted || 0) + del.quantity
                product.priceDeleted = (product.priceDeleted || 0) + Number((del.quantity * (basicProduct.find(e => e._id === del.idProduct)?.pricePerUnit || 0)).toFixed(2))
                product.reasonDeleted = (product.reasonDeleted || "") + (", " + del.deleteReason)
            }
        })
        if (!exists) consume.push({ id: del.idProduct, name: basicProduct.find(e => e._id === del.idProduct)?.name, quantityDeleted: del.quantity, priceDeleted: Number((del.quantity * (basicProduct.find(e => e._id === del.idProduct)?.pricePerUnit || 0)).toFixed(2)), reasonDeleted: del.deleteReason })
    })

    if (consume.length) {
        return (
            <>
                <DownloadExcel columns={getColumnsIntake(coin, language!)} dataSource={consume} name={language === 0 ? "Consumos" : "Intake"} />
                <Table
                    style={{ overflowX: "auto", maxWidth: 1100, margin: "auto" }}
                    columns={getColumnsIntake(coin, language!)}
                    dataSource={consume}
                />
            </>
        )
    }
    return <div className="p_10px">{language === 0 ? 'No se han registrado consumos para las fechas seleccionadas' : 'No intakes have been registered for the selected dates.'}</div>
}

export default GetIntake;