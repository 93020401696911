import React from 'react';
import { useConfigContext } from '../../Context/ContextConfig';

const Footer: React.FC = () => {
    const { configContext } = useConfigContext()!;

    return (
        <div className="colFooter">
            <div><a className="colorWhite" type="link" href='/Contact'>{configContext.language === 0 ? "Contacta con nosotros" : "Contact with us"}</a></div>
            <div><a className="colorWhite" type="link" href='/Politics'>{configContext.language === 0 ? "Aviso Legal" : "Legal Notice"}</a></div>
            <div><a className="colorWhite" type="link" href='/Politics'>{configContext.language === 0 ? "Política de Privacidad" : "Privacy Policy"}</a></div>
        </div>
    )
};

export default Footer;