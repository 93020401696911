import React from 'react';
import { Skeleton } from 'antd';

interface Props {
    quantity?: number;
    title?: boolean;
    rows?: number;
    avatar?: boolean;
    marginTop?: number;
}

const list: any[] = [];

const SkeletonLoad: React.FC<Props> = (props) => {
    const { quantity = 1, title = false, avatar = false, rows = 1, marginTop = 0 } = props;

    for (let i = 0; i < quantity; i++) {
        list.push(<Skeleton key={i} title={title} avatar={avatar} paragraph={{ rows, style: { width: "170%" } }} active />)
    }

    return (
        <>
            {list.map((sk, index) => <div key={index} style={{ marginTop: marginTop }}>{sk}</div>)}
        </>
    )
};

export default SkeletonLoad;



