import React, { useEffect } from 'react';
import { Result, Col } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import envLocal from '../../env-local';

import { useConfigContext } from '../../Context/ContextConfig';

const SuccessPay: React.FC<RouteComponentProps> = () => {
    const { configContext } = useConfigContext()!;
    useEffect(() => window.localStorage.removeItem(envLocal.authComp), []);

    return (
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }}>
            <Result
                status="success"
                title={configContext.language === 0 ? "Pedido realizado con éxito!" : "Order placed successfully!"}
                subTitle={configContext.language === 0 ? "Te llegará un email confirmando la compra, si tienes cualquier duda estamos a tu disposición" : "You will receive an email confirming your purchase, if you have any questions we are at your disposal."}
                extra={[<a href="/">{configContext.language === 0 ? "Ir al Inicio" : "Ir al Inicio"}</a>]}
            />
        </Col>
    )
}

export default SuccessPay