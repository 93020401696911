import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, InputNumber, List, Checkbox, Tooltip, message } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { layout } from '../../Basic/Utilities/styles'
import envLocal from '../../../env-local';
import { ISecEditProps, IItemCheck, IItems } from '../../../Components/Basic/Utilities/interfaces';
import { axiosCall } from '../../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../../Context/ContextConfig';

const { TextArea } = Input;

interface IProps {
  getMenu: Function;
  sectionProps: ISecEditProps;
  setShowModalView: Function;
  setCurrentTab: Function;
};

const EditSection: React.FC<IProps> = (props) => {
  const [itemsChecked, setItemsChecked] = useState<Array<string>>(props.sectionProps.itemsMenu.map(item => item._id));
  const { configContext } = useConfigContext()!;
  const check = async (item: IItemCheck) => {
    item.event.target.checked && message.success(configContext.language === 0 ? `Se mostrará el ${itemsChecked.length + 1}º en la carta!` : `It will be shown on the position ${itemsChecked.length + 1}`)
    let newArray = item.event.target.checked ?
      [...itemsChecked, item.idItem] :
      [...itemsChecked].filter((e: string) => e !== item.idItem);
    await setItemsChecked(newArray);
  };

  const onFinish = async (values: any) => {
    const sectionEdited = {
      itemsMenu: props.sectionProps.sectionType !== 3 ? itemsChecked.map((item, index) => { return { idItem: item, position: index } }) : undefined,
      ...values
    }
    await axiosCall("put", envLocal.endpointSection, envLocal.authComp, "actions/" + props.sectionProps.idSection, sectionEdited);
    props.setShowModalView(false);
    props.setCurrentTab(props.sectionProps.idSectionMenu === undefined ? props.sectionProps.idSection : props.sectionProps.idSectionMenu);
    message.success(configContext.language === 0 ? "Modificaciones realizadas!" : "Modifications made!");
    await props.getMenu();
  };

  return (
    <div className="pt_3em">
      <Row className="editMenuTitle">
        <Col><p className="titleAnt_3">{configContext.language === 0 ? "Datos de la sección" : "Section details"}</p></Col>
      </Row>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
          <Form  {...layout}
            onFinish={onFinish}
            initialValues={{ ...props.sectionProps }}>
            <Form.Item
              label={configContext.language === 0 ? 'Nombre de la Sección' : "Section Name"}
              name='title'
              rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el nombre de la sección de la carta' : "Enter the name of the section of the Menu" }]}
            >
              <Input maxLength={25} />
            </Form.Item>

            {props.sectionProps.sectionType === 3 ?
              <React.Fragment>
                <Form.Item
                  label={configContext.language === 0 ? 'Precio del Menú' : "Menu Price"}
                  name='menuPrice'
                  rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el precio del Menú' : "Enter menu price" }]}
                >
                  <InputNumber maxLength={10} />
                </Form.Item>
                <Form.Item
                  label={configContext.language === 0 ? 'Información Adicional' : "Additional Information"}
                  name='additionalInfo'
                >
                  <TextArea maxLength={100} placeholder={configContext.language === 0 ? 'Ej: "Incluye café, pan, etc". "Sólo disponible de lunes a viernes."' : 'E.g: "Includes coffee, bread, etc." "Only available Monday to Friday."'} />
                </Form.Item>
                <Form.Item
                  label={<span>
                    {configContext.language === 0 ? 'Días válidos' : "Valid days"}&nbsp;
                    <Tooltip title={configContext.language === 0 ? 'Introduce los días en los que se puede consumir el menú' : "Enter the days on which the menu can be consumed"}>
                      <QuestionCircleTwoTone />
                    </Tooltip>
                  </span>}
                  name="menuValidDays"
                >
                  <Checkbox.Group>
                    <div className="displayGrid">
                      <Checkbox value={1} style={{ lineHeight: '32px', marginLeft: 8 }}>{configContext.language === 0 ? 'Lunes' : "Monday"}</Checkbox>
                      <Checkbox value={2} className="lineHeight_32">{configContext.language === 0 ? 'Martes' : "Tuesday"}</Checkbox>
                      <Checkbox value={3} className="lineHeight_32">{configContext.language === 0 ? 'Miércoles' : "Wednesday"}</Checkbox>
                      <Checkbox value={4} className="lineHeight_32">{configContext.language === 0 ? 'Jueves' : "Thursday"}</Checkbox>
                      <Checkbox value={5} className="lineHeight_32">{configContext.language === 0 ? 'Viernes' : "Friday"}</Checkbox>
                      <Checkbox value={6} className="lineHeight_32">{configContext.language === 0 ? 'Sábado' : "Saturday"}</Checkbox>
                      <Checkbox value={0} className="lineHeight_32">{configContext.language === 0 ? 'Domingo' : "Sunday"}</Checkbox>
                    </div>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      {configContext.language === 0 ? 'Desde la hora' : "From hour"}&nbsp;
                      <Tooltip title={configContext.language === 0 ? 'Hora a la que se puede empezar a solicitar el menú' : "Time to start requesting the menu"}>
                        <QuestionCircleTwoTone />
                      </Tooltip>
                    </span>}
                  name='menuStartHour'
                >
                  <InputNumber min={0} max={24} />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      {configContext.language === 0 ? 'Hasta' : "To"}&nbsp;
                      <Tooltip title={configContext.language === 0 ? 'Hora a partir de la cual ya no podemos solicitar el menú' : "Time after which we can no longer order the menu"}>
                        <QuestionCircleTwoTone />
                      </Tooltip>
                    </span>}
                  name='menuEndHour'
                >
                  <InputNumber min={0} max={24} />
                </Form.Item>
              </React.Fragment>
              :
              <List
                size="small"
                header={<strong>{configContext.language === 0 ? `Selecciona los productos que quieras mantener en la sección ${props.sectionProps.title}. Si no haces cambios permanecerá como está actualmente.` : `Select the items you want to keep in the section ${props.sectionProps.title}. If you don't make any changes it will remain as it is.`}</strong>}
                footer={<strong>Total: {itemsChecked.length}</strong>}
                bordered
                dataSource={props.sectionProps.itemsMenu}
                renderItem={(item: IItems) => <List.Item><Checkbox defaultChecked={true} onChange={(e: any) => check({ idItem: item._id, event: e })}></Checkbox>&nbsp;&nbsp;{item.name}</List.Item>}
              />}
            <Form.Item>
              <Col xs={{ span: 8, offset: 8 }} sm={{ span: 1, offset: 24 }}>
                <Button className="mt_15px" type='primary' htmlType='submit'>
                  {configContext.language === 0 ? 'Guardar' : "Save"}
                </Button>
              </Col>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
};

export default EditSection;
