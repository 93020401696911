import React from "react";
import { CSVLink } from "react-csv";
import moment from "moment"

class ExportExcel extends React.Component {
    render() {
        return (
            <div className="m_20px">
                <CSVLink
                    headers={this.props.columns}
                    data={this.props.dataSource}
                    separator={';'}
                    enclosingCharacter={''}
                    filename={`report_${this.props.name}_${moment().format("DD_MM_YYYY")}.csv`}
                >
                    Excel
            </CSVLink>
            </div>
        );
    }
}

export default ExportExcel;