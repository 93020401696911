import React from 'react';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Button, Space, Checkbox } from 'antd';

interface IProps {
    language: number
}

const OptionLisProducts: React.FC<IProps> = ({ language }) => {
    return (
        <>
            <p className="textCenter bold">{language === 0 ? "Si tu producto tiene algún extra opcional, añádelo aquí: " : "If your product has any optional extras, add them here:"}</p>
            <div className="displayTable marginAuto">
                <Form.List name="options">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(field => (
                                <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...field}
                                        name={[field.name, "productOption"]}
                                        fieldKey={[field.fieldKey, language === 0 ? "Producto" : "Product"]}
                                        rules={[{ required: true, message: language === 0 ? "Introduce un producto" : "Enter a product" }]}
                                    >
                                        <Input maxLength={30} placeholder={language === 0 ? "Opción" : "Option"} style={{ width: 200 }} />
                                    </Form.Item>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'priceOption']}
                                        fieldKey={[field.fieldKey, language === 0 ? "Importe" : "Price"]}
                                        rules={[{ required: true, message: language === 0 ? "Introduce el importe" : "Enter the price" }]}
                                    >
                                        <InputNumber placeholder={language === 0 ? "Precio" : "Price"} min={0} max={9999999} style={{ width: 70 }} />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" style={{ width: "auto" }} onClick={() => add()} block icon={<PlusOutlined />}>
                                    {language === 0 ? "Añadir opción" : "Add option"}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item name="multiOption" style={{ whiteSpace: "nowrap"}} valuePropName="checked">
                    <Checkbox>{language === 0 ? "Multiopción" : "Multi-option"}</Checkbox>
                </Form.Item>
            </div>
        </>
    )
}

export default OptionLisProducts