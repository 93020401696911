import React from 'react';
import QRCode from 'qrcode.react';

interface IProps {
    language: number
    numTables: number
    orderCode: string
}

const size = 150

const QR: React.FC<IProps> = (props) => {

    const getCodes = () => {
        let codes: { table: string, code: any }[] = []
        for (let i = 1; i <= props.numTables; i++) {
            codes.push({ table: (props.language === 0 ? "Mesa " : "Table ") + i, code: <QRCode value={`https://www.bliscop.app?code=${props.orderCode}${i}`} size={size} /> })
        }
        return codes;
    }

    return (
        <div>
            {getCodes().map(e =>
                <div key={e.table} className="noPageBreak textCenter pt_20px">
                    <div style={{fontSize: "large", padding: 10, fontWeight: "bold"}}>{e.table}</div>
                    <div>{e.code}</div>
                </div>
            )}
        </div>
    );
};

export default QR;