import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Row, Col, Tooltip, InputNumber, message } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import { tailFormItemLayout, formItemLayout } from '../../Components/Basic/Utilities/styles'
import countryList from '../../Components/Basic/Utilities/countryList';
import provincesList from '../../Components/Basic/Utilities/provincesList';
import coinList from '../../Components/Basic/Utilities/coinList';
import envLocal from '../../env-local';
import NavBar from '../../Components/Basic/NavBar';
import { IRestaurant } from '../../Components/Basic/Utilities/interfaces';
import { useRestaurant } from '../../Context/ContextRestaurant';
import { axiosCall, hasNumber } from '../../Components/Basic/Utilities/tools';
import { Success } from '../../Components/Basic/Results/Success';
import { useConfigContext } from '../../Context/ContextConfig';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const CreateRestaurant: React.FC<RouteComponentProps> = (props) => {
  const [countrySelected, setCountrySelected] = useState<string>();
  const [res, setRes] = useState<IRestaurant>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const { restaurant, setRestaurant } = useRestaurant()!;
  const { configContext } = useConfigContext()!;

  const restaurantTypes = [
    { type: 1, label: configContext.language === 0 ? "Comida Rápida" : "Fast Food" },
    { type: 2, label: configContext.language === 0 ? "Buffet" : "Buffet" },
    { type: 3, label: configContext.language === 0 ? "Temático" : "Thematic" },
    { type: 4, label: configContext.language === 0 ? "Para Llevar" : "Takeaway" },
    { type: 5, label: configContext.language === 0 ? "Fusión" : "Fusion" },
    { type: 7, label: configContext.language === 0 ? "Alta cocina" : "Haute Cuisine" }
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (restaurant._id !== undefined && restaurant._id !== "pending") {
      const response = (await axiosCall("post", envLocal.endpointRestaurant, envLocal.authComp, "findRestaurant"));
      if (response.status === 200) {
        setRes(response.data);
      } else {
        message.error("Error")
      }
    }
    setIsLoading(false);
  };

  const onFinish = async (values: any) => {
    if (hasNumber(values.orderCode)) {
      message.error(configContext.language === 0 ? "El código de Pedido no puede contener números." : "The Order Code cannot contain any numbers.")
    } else {
      const newRestaurant = {
        name: values.name,
        country: values.country,
        province: values.province,
        city: values.city,
        street: values.street,
        number: values.number,
        postalCode: values.postalCode,
        telephone: values.telephone,
        coin: values.coin,
        orderCode: values.orderCode.toLowerCase(),
        numTables: values.numTables,
        restaurantType: values.restaurantType,
        accountExpiry: values.accountExpiry,
        iva: values.iva,
        numIdRestaurant: values.numIdRestaurant
      };
      if (restaurant._id !== undefined && restaurant._id !== "pending") {
        const resStatus = (await axiosCall("put", envLocal.endpointRestaurant, envLocal.authComp, "", {
          ...newRestaurant
        })).status;
        if (resStatus === 400) {
          message.warning(configContext.language === 0 ? "Lo siento, el código de Restaurante ya está en uso" : "Sorry, the restaurant code is already in use");
        } else if (resStatus === 200) {
          message.success(configContext.language === 0 ? "Datos actualizados" : "Updated data");
        } else {
          message.error("Error");
        }
      } else {
        const resStatus = (await axiosCall("post", envLocal.endpointRestaurant, envLocal.authComp, "", {
          ...newRestaurant,
          friendCode: restaurant.friendCode ? restaurant.friendCode : undefined,
          language: configContext.language
        })).status;
        if (resStatus === 400) {
          message.warning(configContext.language === 0 ? "Lo siento, el código de Restaurante ya está en uso" : "Sorry, the restaurant code is already in use");
        } else if (resStatus === 200) {
          message.success(configContext.language === 0 ? "Datos guardados" : "Saved data");
          setShowSuccess(true);
          setTimeout(() => {
            window.localStorage.removeItem("tokenAuthComp");
            window.location.reload();
          }, 7000);
        } else {
          message.error("Error");
        }
      }
      setRestaurant(newRestaurant);
    }
  };

  return (
    <div className="basicContainer">
      {restaurant._id !== undefined && restaurant._id !== "pending" && <NavBar />}
      {showSuccess ? <Success title={configContext.language === 0 ? "Restaurante Creado!" : "Restaurant Created!"} subTitle={configContext.language === 0 ? "Te llegará un correo a la dirección indicada para validar tu cuenta." : "You will receive an email at the address provided to validate your account."} />
        :
        isLoading ?
          <LoadingSpin />
          :
          <React.Fragment>
            <div className="titleSection2">{configContext.language === 0 ? "Rellena los datos de tu Restaurante" : "Fill in your restaurant details"}</div>
            <Row className="mt_20px">
              <Col span={20} offset={2}>
                {restaurant._id !== undefined && restaurant._id !== "pending" &&
                  <div className="textCenter mb_10px">
                    <Button type="link" onClick={() => props.history.push('/packs')}>{configContext.language === 0 ? 'Ampliar periodo de validez' : 'Extend validity period'}</Button>
                  </div>}
                <Form {...formItemLayout}
                  onFinish={onFinish}
                  initialValues={{ name: res && res.name, country: res && res.country, province: res && res.province, city: res && res.city, street: res && res.street, number: res && res.number, postalCode: res && res.postalCode, telephone: res && res.telephone, coin: res && res.coin, orderCode: res && res.orderCode, numTables: res && res.numTables, restaurantType: res && res.restaurantType, accountExpiry: res ? moment(res.accountExpiry).format("DD/MM/YYYY") : moment().add(15, 'days').format("DD/MM/YYYY"), iva: res?.iva, numIdRestaurant: res?.numIdRestaurant }}
                >
                  <Form.Item
                    label={configContext.language === 0 ? 'Cuenta válida hasta' : "Account valid until"}
                    name='accountExpiry'
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? 'Nombre del Restaurante' : "Restaurant Name"}
                    name='name'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el nombre del Restaurante' : 'Enter the name of the restaurant' }]}
                  >
                    <Input maxLength={50} />
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? 'País en el que se ubica' : "Country where it is located"}
                    name='country'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el país en el que se ubica el Restaurante' : "Enter the country where the restaurant is located" }]}
                  >
                    <Select showSearch={true} onChange={(e: string) => setCountrySelected(e)}>{countryList.map((country: { value: string; label: string; }) => <Select.Option key={country.value} value={country.value}>{country.label}</Select.Option>)}</Select>
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? 'Provincia' : 'Province / State'}
                    name='province'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce la Provincia en la que se ubica el Restaurante' : "Enter the province where the restaurant is located" }]}
                  >
                    {countrySelected === 'España' || (countrySelected === undefined && res?.country === 'España') ?
                      <Select showSearch={true}>{provincesList.map((province: { value: string; label: string; }) => <Select.Option key={province.label} value={province.label}>{province.value}</Select.Option>)}</Select>
                      : <Input maxLength={60} />}
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? 'Ciudad' : "City"}
                    name='city'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce la Ciudad o Pueblo donde se ubica el Restaurante' : 'Enter the City or Town where the Restaurant is located' }]}
                  >
                    <Input maxLength={60} />
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? 'Calle' : 'Street'}
                    name='street'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce la calle o vía donde se ubica el Restaurante' : 'Enter the street or road where the restaurant is located' }]}
                  >
                    <Input maxLength={100} placeholder={configContext.language === 0 ? "Ej: Calle Gran Vía, Plaza de Perú" : 'Ex: Gran Vía Street, Plaza de Perú'} />
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? 'Número' : 'Number'}
                    name='number'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el número de la calle' : 'Enter the street number' }]}
                  >
                    <InputNumber min={0} maxLength={10} />
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? 'Código Postal' : 'Zip Code'}
                    name='postalCode'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el Código Postal de la ubicación del Restaurante' : 'Enter the Zip Code of the Restaurant location' }]}
                  >
                    <Input maxLength={5} style={{ width: 90 }} />
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? 'Teléfono' : "Telephone"}
                    name='telephone'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el teléfono de contacto' : "Enter the contact phone number" }]}
                  >
                    <InputNumber style={{ minWidth: 110 }} min={0} maxLength={12} />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span>
                        {configContext.language === 0 ? 'Código de Pedido' : "Order Code"}&nbsp;
                        <Tooltip title={configContext.language === 0 ? 'Introduce el código que emplearán los usuarios para realizar los pedidos. No puede contener números.' : "Enter the code that users will use to place orders. It cannot contain numbers."}>
                          <QuestionCircleTwoTone />
                        </Tooltip>
                      </span>}
                    name='orderCode'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el Código de Pedido' : "Enter Order Code" }]}
                  >
                    <Input maxLength={10} />
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? 'Número de Mesas' : "Number of tables"}
                    name='numTables'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el número de mesas' : "Enter the number of tables" }]}
                  >
                    <InputNumber min={0} max={99} />
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? 'Tipo de Moneda' : "Currency type"}
                    name='coin'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el tipo de moneda' : "Enter the type of currency" }]}
                  >
                    <Select>{coinList.map((coin: { value: number; label: string; }) => <Select.Option key={coin.label} value={coin.value}>{coin.label}</Select.Option>)}</Select>
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? "Tipo de Restaurante" : "Restaurant Type"}
                    name='restaurantType'
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el tipo de restaurante' : "Enter the restaurant type" }]}
                  >
                    <Select>{restaurantTypes.map(type => <Select.Option key={type.type} value={type.type}>{type.label}</Select.Option>)}</Select>
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? "IVA" : "VAT"}
                    name='iva'
                  >
                    <InputNumber
                      defaultValue={0}
                      min={0}
                      max={100}
                      formatter={value => `${value}%`}
                      parser={(value: any) => value!.replace('%', '')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={configContext.language === 0 ? 'Número de identificacion' : "Identification number"}
                    name='numIdRestaurant'
                  >
                    <Input maxLength={25} style={{ minWidth: 250 }} />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button type='primary' htmlType='submit'>
                      {configContext.language === 0 ? "Guardar" : "Save"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </React.Fragment>
      }
    </div>
  );
};

export default withRouter(CreateRestaurant);