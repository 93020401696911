import React from "react";
import { ResponsiveBar } from '@nivo/bar';
import { IGraph } from '../../Components/Basic/Utilities/interfaces';

export const BarVertical: React.FC<IGraph> = (props) => {
    const { dataTable, margin, keys, indexBy, colors, leyendInfo = true, leyendX = "", leyendY = "", coin, decimals } = props;
    return (
        <ResponsiveBar
            data={dataTable}
            keys={keys}
            indexBy={indexBy}
            margin={{ top: margin?.top || 40, right: margin?.right || 10, bottom: margin?.bottom || 60, left: margin?.left || 20 }}
            padding={0.3}
            colors={colors || { scheme: 'nivo' }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: leyendX,
                legendPosition: 'middle',
                legendOffset: 42
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: leyendY,
                legendPosition: 'middle',
                legendOffset: -50,
                format: value =>
                    `${Number(value).toLocaleString('de-DE', {
                        minimumFractionDigits: decimals || 0,
                    })} ${coin || ''}`
            }}
            labelSkipWidth={16}
            labelSkipHeight={16}
            legends={leyendInfo ? [
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ] : []}
            theme={{
                axis: {
                    legend: {
                        text: {
                            fontSize: "18px",
                        }
                    }
                },
            }}
        />
    )
}

export const BarHorizontal: React.FC<IGraph> = (props) => {
    const { dataTable, margin, keys, indexBy, colors, leyendInfo = true, leyendX = "", leyendY = "", coin, decimals } = props;
    return (
        <ResponsiveBar
            data={dataTable}
            keys={keys}
            indexBy={indexBy}
            layout="horizontal"
            margin={{ top: margin?.top !== undefined ? margin.top : 50, right: margin?.right !== undefined ? margin.right : 10, bottom: margin?.bottom !== undefined ? margin.bottom : 170, left: margin?.left !== undefined ? margin.left : 170 }}
            padding={0.3}
            colors={colors || { scheme: 'nivo' }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickValues: 4,
                tickRotation: 0,
                legend: leyendX,
                legendPosition: 'middle',
                legendOffset: 42
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: leyendY,
                legendPosition: 'middle',
                legendOffset: -50,
                format: value => isNaN(Number(value)) ?
                    value.toString()
                    : `${Number(value).toLocaleString('de-DE', { minimumFractionDigits: decimals || 0 })} ${coin || ''}`
            }}
            labelSkipWidth={16}
            labelSkipHeight={16}
            legends={leyendInfo ? [
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ] : []}
            theme={{
                axis: {
                    legend: {
                        text: {
                            fontSize: "18px",
                        }
                    }
                },
            }}
        />
    )
}