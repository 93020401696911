import React from 'react';
import { Table } from 'antd';
import { IStats, IBills, IDeletedProducts, IBasicProduct } from '../../Components/Basic/Utilities/interfaces';
import { numerToMonth } from '../../Components/Basic/Utilities/tools';
import { useRestaurant } from '../../Context/ContextRestaurant';
import DownloadExcel from '../../Components/Basic/ExportExcel';

interface IBalance {
    deletedProducts: IDeletedProducts[]
    bills: IBills[]
    stats: IStats[]
    language: number
    basicProduct: IBasicProduct[]
    coin: string
}

const getColumnsBalance = (coin: string, language: number) => {
    return [
        {
            title: language === 0 ? "Mes" : "Month",
            dataIndex: 'month',
            key: 'month',
            label: language === 0 ? "Mes" : "Month",
        },
        {
            title: language === 0 ? `Ingresos (${coin})` : `Income (${coin})`,
            dataIndex: 'income',
            key: 'income',
            label: language === 0 ? `Ingresos (${coin})` : `Income (${coin})`,
            sorter: (a: any, b: any) => a.income - b.income,
        },
        {
            title: language === 0 ? `IVA (${coin})` : `VAT (${coin})`,
            dataIndex: 'iva',
            key: 'iva',
            label: language === 0 ? `IVA (${coin})` : `VAT (${coin})`,
            sorter: (a: any, b: any) => a.iva - b.iva,
        },
        {
            title: language === 0 ? `Gasto en Productos (${coin})` : `Expenditure on Products (${coin})`,
            dataIndex: 'intake',
            key: 'intake',
            label: language === 0 ? `Gasto en Productos (${coin})` : `Expenditure on Products (${coin})`,
            sorter: (a: any, b: any) => a.intake - b.intake,
        },
        {
            title: language === 0 ? `Pérdidas de Producto (${coin})` : `Product Losses (${coin})`,
            dataIndex: 'losses',
            key: 'losses',
            label: language === 0 ? `Pérdidas de Producto (${coin})` : `Product Losses (${coin})`,
            sorter: (a: any, b: any) => a.losses - b.losses,
        },
        {
            title: `Total (${coin})`,
            dataIndex: 'total',
            key: 'total',
            label: `Total (${coin})`,
            sorter: (a: any, b: any) => a.total - b.total,
        },
    ];
}

const GetBalance: React.FC<IBalance> = ({ stats, bills, deletedProducts, basicProduct, language, coin }) => {
    const { restaurant } = useRestaurant()!;

    let summary: { month: string, income?: number, iva?: number, intake?: number, losses?: number, total?: number }[] = []

    stats.forEach(day => {
        let exists = false
        const currentMonth: string = numerToMonth(Number(day.day.split("/")[1]), language)
        summary.forEach(month => {
            if (month.month === currentMonth) {
                exists = true
                const inc = Number(((month.income || 0) + day.totalIncome).toFixed(2))
                month.income = inc
                month.iva = Number((inc * ((restaurant?.iva || 1) / 100)).toFixed(2))
            }
        })
        if (!exists) summary.push({ month: currentMonth, income: Number((day.totalIncome).toFixed(2)), iva: Number((day.totalIncome * ((restaurant?.iva || 1) / 100)).toFixed(2)) })
    })

    bills.forEach(bill => {
        let exists = false
        const currentMonth: string = numerToMonth(Number(bill.date.split("/")[1]), language)
        summary.forEach(month => {
            if (month.month === currentMonth) {
                exists = true
                month.intake = (month.intake || 0) - Number(bill.products.reduce((s: number, a: any) => { return s + Number(a.price) }, 0).toFixed(2))
            }
        })
        if (!exists) summary.push({ month: currentMonth, intake: - Number(bill.products.reduce((s: number, a: any) => { return s + Number(a.price) }, 0).toFixed(2)) })
    })

    deletedProducts.forEach(del => {
        let exists = false
        const currentMonth: string = numerToMonth(Number(del.date.split("/")[1]), language)
        summary.forEach(month => {
            if (month.month === currentMonth) {
                exists = true
                month.losses = (month.losses || 0) + Number((del.quantity * (basicProduct.find(e => e._id === del.idProduct)?.pricePerUnit || 0)).toFixed(2))
            }
        })
        if (!exists) summary.push({ month: currentMonth, losses: Number((del.quantity * (basicProduct.find(e => e._id === del.idProduct)?.pricePerUnit || 0)).toFixed(2)) })
    })

    summary.forEach(month => month.total = Number(((month.income || 0) + (month.intake || 0) + (month.losses || 0)).toFixed(2)))

    if (summary.length) {
        return (
            <>
                <DownloadExcel columns={getColumnsBalance(coin, language!)} dataSource={summary} name={"Balances"} />
                <Table
                    style={{ overflowX: "auto", maxWidth: 1000, margin: "auto" }}
                    columns={getColumnsBalance(coin, language!)}
                    dataSource={summary}
                    summary={pageData => {
                        let totalIncome = 0
                        let totalIva = 0
                        let totalIntake = 0
                        let totalLosses = 0
                        let totalTotal = 0

                        pageData.forEach(({ income, iva, intake, losses, total }) => {
                            totalIncome += (income || 0);
                            totalIva += (iva || 0);
                            totalIntake += (intake || 0);
                            totalLosses += (losses || 0);
                            totalTotal += (total || 0);
                        });

                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} className="bold">{language === 0 ? "Total" : "Summary"}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>{totalIncome}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>{totalIva}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>{totalIntake}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{totalLosses}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>{totalTotal}</Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }}
                />
            </>
        )
    }
    return <div className="p_10px">{language === 0 ? 'No se han registrado ingresos para las fechas seleccionadas' : 'No incomes have been registered for the selected dates.'}</div>
}

export default GetBalance;