import React from 'react';
import { Modal, Form, Input, message } from 'antd';
import { useConfigContext } from '../../Context/ContextConfig';
import envLocal from '../../env-local';
import { axiosCall } from '../../Components/Basic/Utilities/tools';

interface FormProps {
    visible: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
}

interface IProps {
    isVisible: boolean;
    setIsVisible: Function;
    isCompany: boolean;
}

const FormChangePass: React.FC<FormProps> = ({
    visible,
    onCreate,
    onCancel,
}) => {
    const [form] = Form.useForm();
    const { configContext } = useConfigContext()!;

    return (
        <Modal
            visible={visible}
            title={configContext.language === 0 ? "Cambiar Contraseña" : "Change Password"}
            okText={configContext.language === 0 ? "Guardar" : "Save"}
            cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="formChangePassword"
            >
                <Form.Item
                    label={configContext.language === 0 ? "Contraseña Actual" : "Current Password"}
                    name="oldPassword"
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce tu Contraseña actual' : "Enter your current Password" }]}
                >
                    <Input.Password maxLength={15} placeholder={configContext.language === 0 ? "Contraseña Actual" : "Current Password"} />
                </Form.Item>
                <Form.Item
                    label={configContext.language === 0 ? "Nueva Contraseña" : "New Password"}
                    name="password"
                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce tu nueva Contraseña' : "Enter your new Password" }]}
                >
                    <Input.Password maxLength={15} placeholder={configContext.language === 0 ? "Nueva Contraseña" : "New Password"} />
                </Form.Item>
                <Form.Item
                    label={configContext.language === 0 ? "Repetir Contraseña" : "Repeat Password"}
                    name="confirmPassword"
                    rules={[{ required: true, message: configContext.language === 0 ? 'Repite la nueva Contraseña' : "Repeat the new password" }]}
                >
                    <Input.Password maxLength={15} placeholder={configContext.language === 0 ? "Repetir Contraseña" : "Repeat Password"} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const ChangePassModal: React.FC<IProps> = (props) => {
    const { configContext } = useConfigContext()!;

    const onCreate = async (values: any) => {
        if (values.password !== values.confirmPassword) {
            message.error(configContext.language === 0 ? "Las contraseñas no coinciden" : "Passwords don't match");
        } else if (values.password.length < 8) {
            message.warning(configContext.language === 0 ? "La contraseña tiene que tener una longitud mínima de 8 caracteres" : "The password has to be at least 8 characters long");
        } else {
            const response = (await axiosCall("put", props.isCompany ? envLocal.endpointAuthenticationComp : envLocal.endpointAuthentication, props.isCompany ? envLocal.authComp : envLocal.authUser, "me", values));
            if (response.status === 401) {
                message.error(configContext.language === 0 ? "Contraseña incorrecta" : "Wrong Password");
            } else if (response.status === 200) {
                message.success(configContext.language === 0 ? "Contraseña cambiada" : "Password changed");
                props.setIsVisible(false);
            } else {
                message.error("Error");
            }
        };
    };

    return (
        <FormChangePass
            visible={props.isVisible}
            onCreate={onCreate}
            onCancel={() => {
                props.setIsVisible(false);
            }}
        />
    );
};

export default ChangePassModal;