import React, { useState, useEffect } from 'react';
import { Button, Row, Alert, Modal, Input, Form } from 'antd';
import { Success } from '../../Components/Basic/Results/Success';
import { IMenuOrder, ITask, IStateCreateMenu } from '../../Components/Basic/Utilities/interfaces';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { SearchOutlined, PlusOutlined, UserOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import envLocal from '../../env-local';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import { axiosCall, getDataTokenOrder } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';
import Bill from '../../Components/Basic/Bill';
import Header from '../../Components/Basic/Header';

const { confirm } = Modal;
const { TextArea } = Input;

const styleButton = { margin: "10px 10px 0px 10px", display: "block", marginLeft: "auto", marginRight: "auto" };
const styleBlue = { color: "white", backgroundColor: "#65B5E7", ...styleButton };
const styleAlert = { margin: "5px auto" };

interface CollectionCreateFormProps {
    visible: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
}

const OrderClient: React.FC<RouteComponentProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [bill, setBill] = useState<boolean>(false);
    const [customReq, setCustomReq] = useState<boolean>(false);
    const [customReqDisabled, setCustomReqDisabled] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(true);
    const [request, setRequest] = useState<Array<IMenuOrder>>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { configContext } = useConfigContext()!;
    const [orderParams] = useState<IStateCreateMenu | null>(getDataTokenOrder());

    useEffect(() => {
        setTimeout(() => setShowSuccess(false), 2000);
        if (!orderParams) {
            props.history.push('/');
        } else {
            fetchData();
        }
    }, []);

    const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
        visible,
        onCreate,
        onCancel,
    }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={configContext.language === 0 ? 'Dinos con qué te podemos ayudar' : "Tell us what we can help you with"}
                okText={configContext.language === 0 ? "Solicitar" : "Order"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Form.Item
                        name="observations"
                        label={configContext.language === 0 ? "¿Qué necesitas que le digamos al camarero?" : "What do you need us to tell the waiter?"}
                    >
                        <TextArea maxLength={100} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const getIsOpen = async () => {
        const response = await axiosCall("get", envLocal.endpointOrder, envLocal.tokenOrder);
        if (response.status === 200 && response.data.status > 1) {
            return false;
        } else {
            return true;
        }
    }

    const fetchData = async () => {
        const response = (await axiosCall("get", envLocal.endpointOrder, envLocal.tokenOrder)).data;
        if (response.status > 1) {
            props.history.push('/opinion');
        } else if (["-2", "-1"].includes(response.idTable)) {
            props.history.push('/waiting');
        } else {
            const idTable: string = (await axiosCall("get", envLocal.endpointOrder, envLocal.tokenOrder, "getMyTable")).data;
            orderParams!.idOrder !== undefined && evalPendingTask(idTable);
            setIsLoading(false);
        }
    }

    const isClosed = (status: number) => {
        if (status > 1 && props.history.action === "POP") props.history.push('/opinion');
    }

    const getRequest = async () => {
        const response = await axiosCall("get", envLocal.endpointOrder, envLocal.tokenOrder);
        if (response.status === 200) {
            setRequest(response.data.order);
            setShowModal(true);
        }
    };

    const evalPendingTask = async (idTable: string) => {
        const responseTask = await axiosCall("get", envLocal.endpointOrder, envLocal.tokenOrder);
        if (responseTask.status === 200) isClosed(responseTask.data.status);
        const response = (await axiosCall("post", envLocal.endpointTask, envLocal.tokenOrder, "client", {
            idTable,
            status: [1, 2, 3],
            type: [5, 6],
            idOrder: orderParams!.idOrder
        })).data;
        if (response.length) {
            response.forEach((task: ITask) => {
                let inputTask: any = {};
                switch (task.type) {
                    case 5:
                        inputTask.task = task._id;
                        inputTask.f = setBill;
                        break;
                    case 6:
                        inputTask.task = task._id;
                        inputTask.f = setCustomReqDisabled;
                        break;
                };
                evaluateStatus(inputTask);
            });
        };
    };

    const reqBill = async () => {
        const isOpenTmp = await getIsOpen();
        if (!isOpenTmp) {
            props.history.push('/');
            return
        }
        confirm({
            title: configContext.language === 0 ? '¿Quieres solicitar la cuenta?' : 'Do you want to apply for the bill?',
            icon: <ExclamationCircleOutlined />,
            okText: configContext.language === 0 ? 'Sí' : "Yes",
            okType: 'primary',
            cancelText: 'No',
            async onOk() {
                confirm({
                    title: configContext.language === 0 ? '¿Vas a pagar con tarjeta?' : 'Are you going to pay by card?',
                    icon: <ExclamationCircleOutlined />,
                    okText: configContext.language === 0 ? 'Sí' : "Yes",
                    okType: 'primary',
                    cancelText: 'No',
                    async onOk() {
                        await axiosCall("put", envLocal.endpointOrder, envLocal.tokenOrder, "setPaymentType", { idOrder: orderParams!.idOrder, orderTypePayment: 2 });
                        const response = await axiosCall("post", envLocal.endpointTask, envLocal.tokenOrder, "createClient", {
                            status: 0,
                            desiredStatus: 2,
                            type: 5,
                            language: configContext.language,
                            observations: configContext.language === 0 ? 'Pago con tarjeta' : "Card payment"
                        })
                        if (response.status === 200) {
                            evaluateStatus({ task: response.data, f: setBill })
                        }
                    },
                    async onCancel() {
                        await axiosCall("put", envLocal.endpointOrder, envLocal.tokenOrder, "setPaymentType", { idOrder: orderParams!.idOrder, orderTypePayment: 1 });
                        const response = await axiosCall("post", envLocal.endpointTask, envLocal.tokenOrder, "createClient", {
                            status: 0,
                            desiredStatus: 2,
                            type: 5,
                            language: configContext.language
                        })
                        if (response.status === 200) {
                            evaluateStatus({ task: response.data, f: setBill })
                        }
                    },
                });
            },
            onCancel() {
            },
        });
    };

    const customRequest = async (values: any) => {
        const isOpenTmp = await getIsOpen();
        if (!isOpenTmp) {
            props.history.push('/');
            return
        }
        const response = await axiosCall("post", envLocal.endpointTask, envLocal.tokenOrder, "createClient", {
            observations: values.observations,
            status: 0,
            desiredStatus: 2,
            type: 6,
            language: configContext.language,
        })
        if (response.status === 200) {
            evaluateStatus({ task: response.data, f: setCustomReqDisabled })
        }
        setCustomReq(false);
    };

    const evaluateStatus = (input: { task: string; f: Function }) => {
        input.f(true);
        const interval: any = setInterval(
            async () => {
                const response = await axiosCall("get", envLocal.endpointTask, envLocal.tokenOrder, "all/" + input.task);
                if (response.data.status === 4) {
                    clearInterval(interval);
                    input.f(false);
                    if (response.data.type === 5) {
                        props.history.push('/opinion');
                    }
                }
            }
            , 30000);
    };

    const moreFood = async () => {
        const idTable: string = (await axiosCall("get", envLocal.endpointOrder, envLocal.tokenOrder, "getMyTable")).data;
        const newOrderParams = {...orderParams, idTable}
        confirm({
            title: configContext.language === 0 ? '¿Quieres pedir algo más?' : "DDo you want to ask for anything else?",
            icon: <ExclamationCircleOutlined />,
            okText: configContext.language === 0 ? 'Sí' : "Yes",
            okType: 'primary',
            cancelText: 'No',
            onOk() {
                props.history.push({
                    pathname: '/Menu',
                    state: newOrderParams
                });
            },
            onCancel() {
            },
        });
    };

    return (
        <>
            <Header />
            {isLoading ?
                <LoadingSpin />
                :
                showSuccess ? <Success title={configContext.language === 0 ? "Pedido Recibido!" : "Order received!"} subTitle={configContext.language === 0 ? "Tu pedido está en camino. Podrás disfrutar de él con la mayor brevedad posible." : "Your order's on its way. You will be able to enjoy it as soon as possible."} />
                    :
                    <div className="basicContainerColor height_100">
                        <div className="sectionSquare" style={{ height: "90%", margin: "30px 25px 5px 25px" }}>
                            <div className="sectionSquareTitle">{configContext.language === 0 ? "¿Necesitas ayuda?" : "Do you need help?"}</div>
                            <div className="sectionSquareBody" style={{ padding: "15px 0px" }}>
                                <div className="mt_10px">
                                    <Row><Button style={styleBlue} shape="round" value="large" icon={<SearchOutlined />} onClick={() => getRequest()}>{configContext.language === 0 ? "Ver Pedido Actual" : "View Current Order"}</Button></Row>
                                    <Row><Button style={styleBlue} shape="round" value="large" icon={<PlusOutlined />} onClick={moreFood}>{configContext.language === 0 ? "Ampliar Pedido" : "Extend Order"}</Button></Row>
                                    <Row><Button style={{ ...styleBlue, backgroundColor: customReqDisabled ? "#746C5F" : styleBlue.backgroundColor }} shape="round" disabled={customReqDisabled} value="large" icon={<UserOutlined />} onClick={() => setCustomReq(true)}>{configContext.language === 0 ? "Avisar Personal" : "Notify Staff"}</Button>
                                        {customReqDisabled && <Alert style={styleAlert} message={configContext.language === 0 ? "Avisados! Te atenderán lo antes posible" : "Notified! You will be attended to as soon as possible"} type="success" showIcon />}</Row>
                                    <CollectionCreateForm
                                        visible={customReq}
                                        onCreate={customRequest}
                                        onCancel={() => { setCustomReq(false) }}
                                    />
                                    <Row><Button style={{ ...styleBlue, backgroundColor: bill ? "#746C5F" : styleBlue.backgroundColor }} shape="round" disabled={bill} value="large" icon={<FormOutlined />} onClick={reqBill}>{configContext.language === 0 ? "Solicitar la Cuenta" : "Apply for the Bill"}</Button>
                                        {bill && <Alert style={styleAlert} message={configContext.language === 0 ? "Pedida! Te la traeremos lo antes posible" : "Order! We'll bring it to you as soon as possible"} type="success" showIcon />}</Row>
                                </div>
                                <Modal
                                    title={<div className="textCenter">{configContext.language === 0 ? "Pedido Actual" : "Current Order"}</div>}
                                    visible={showModal}
                                    onOk={() => moreFood()}
                                    onCancel={() => setShowModal(false)}
                                    okText={configContext.language === 0 ? "Ampliar pedido" : "Extend order"}
                                    cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                                    centered
                                >
                                    <Bill
                                        order={request}
                                        coin={orderParams!.coin}
                                        discount={orderParams!.discountValueToApply}
                                        applyToMenu={orderParams!.applyToMenu}
                                        priceSpecialTable={orderParams!.priceSpecialTable}
                                    />
                                </Modal>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
};

export default withRouter(OrderClient);