import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Row, Col, Input, Button, Form, Modal, message } from 'antd';
import envLocal from '../../env-local';
import { useUser } from '../../Context/ContextUser';
import { layout } from '../../Components/Basic/Utilities/styles';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import RegisterForm from '../../Components/Basic/RegisterForm';
import ReCAPTCHA from "react-google-recaptcha";
import { useConfigContext } from '../../Context/ContextConfig';
import Header from '../../Components/Basic/Header';
import { useRestaurant } from '../../Context/ContextRestaurant';
import moment from 'moment';

let captchaResolved: boolean = false;

interface Props extends RouteComponentProps<
    {}, // this.props.match.params.myParamProp
    any, // history
    { company: boolean, toRegister?: boolean } // this.props.location.state.myStateProp
    > { }

interface CollectionCreateFormProps {
    visible: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
}

let emailToSend: string = "";
let idUserToSend: string = "";

const Login: React.FC<Props> = (props) => {
    const [existUser, setExistUser] = useState<boolean>(props.history.location.state?.toRegister !== undefined ? props.history.location.state?.toRegister : true);
    const { setUser } = useUser()!;
    const { configContext, setConfigContext } = useConfigContext()!;
    const { setRestaurant } = useRestaurant()!;
    const [visibleGetPass, setVisibleGetPass] = useState<boolean>(false);
    const [reSendValidateMail, setReSendValidateMail] = useState<boolean>(false);

    const CollectionCreateFormEmail: React.FC<CollectionCreateFormProps> = ({
        visible,
        onCreate,
        onCancel,
    }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={configContext.language === 0 ? "Introduce tu email para reiniciar la contraseña" : "Enter your email to reset your password"}
                okText={configContext.language === 0 ? "Aceptar" : "Ok"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch();
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modalMail"
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, type: 'email', message: configContext.language === 0 ? 'Introduce tu email' : "Enter your email" }]}
                    >
                        <Input maxLength={50} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const toLogin = async (values: any) => {
        if (configContext.showCaptcha && !captchaResolved) {
            message.error(configContext.language === 0 ? 'No se ha resuelto el captcha' : "Captcha not resolved")
        } else {
            const response = await axiosCall("post", props.location.state?.company ? envLocal.endpointAuthenticationComp : envLocal.endpointAuthentication, "", "signin", values);
            if (response.status === 200 && response.data?.auth) {
                if (!response.data.validated) {
                    message.error(configContext.language === 0 ? 'La cuenta aún no ha sido validada' : "The account has not yet been validated");
                    emailToSend = values.email;
                    idUserToSend = response.data.idUser;
                    setReSendValidateMail(true);
                } else {
                    window.localStorage.removeItem("restaurant");
                    window.localStorage.removeItem("user");
                    if (props.location.state?.company) {
                        setUser(JSON.parse(decodeURIComponent(escape(window.atob(response.data.token.split('.')[1])))).user);
                        const responseRestaurant = JSON.parse(decodeURIComponent(escape(window.atob(response.data.token.split('.')[1])))).company;
                        setRestaurant(responseRestaurant)
                        window.localStorage.setItem(envLocal.authComp, response.data.token);
                        window.localStorage.removeItem(envLocal.authUser);
                        props.history.push('/Home');
                        if (responseRestaurant.accountExpiry && moment().add(5, 'days') > moment(responseRestaurant.accountExpiry)) {
                            const expiryDays = moment(responseRestaurant.accountExpiry).diff(moment(), 'days');
                            message.warning((configContext.language === 0 ? 'Días para que caduque tu cuenta: ' : "Days until your account expires: ") + expiryDays);
                        }
                    } else {
                        setUser(JSON.parse(decodeURIComponent(escape(window.atob(response.data.token.split('.')[1])))));
                        window.localStorage.setItem(envLocal.authUser, response.data.token);
                        window.localStorage.removeItem(envLocal.authComp);
                        props.history.push('/');
                    }
                }
            } else if (response.status === 404) {
                message.error(configContext.language === 0 ? 'El mail introducido no existe' : "The mail entered does not exist")
            } else if (response.status === 429) {
                message.error(configContext.language === 0 ? 'Se ha excedido temporalmente el número máximo de intentos' : "The maximum number of attempts has been temporarily exceeded")
            } else if (response.status === 401) {
                message.error(configContext.language === 0 ? 'Contraseña incorrecta' : "Wrong password");
                setConfigContext({ showCaptcha: true });
            } else if (response.status === 403) {
                setUser(JSON.parse(decodeURIComponent(escape(window.atob(response.data.token.split('.')[1])))).user);
                const responseRestaurant = JSON.parse(decodeURIComponent(escape(window.atob(response.data.token.split('.')[1])))).company;
                setRestaurant(responseRestaurant)
                window.localStorage.setItem(envLocal.authComp, response.data.token);
                window.localStorage.removeItem(envLocal.authUser);
                props.history.push('/packs')
                message.error(configContext.language === 0 ? 'La cuenta ha caducado' : "The account has expired");
            } else {
                message.error('Error')
            }
        };
    };

    const changeExistUser = () => {
        setExistUser(!existUser);
    };

    const onChange = (value: any) => {
        if (value) {
            captchaResolved = true;
            setConfigContext({ showCaptcha: false });
        }
    }

    const sendPassMail = async (values: any) => {
        const resStatus = (await axiosCall("post", envLocal.endpointChangePass, "", "recovery/" + values.email, { isCompany: props.location.state?.company, language: configContext.language })).status;
        if (resStatus === 200) {
            message.success(configContext.language === 0 ? 'Email enviado' : "Email sended")
        } else if (resStatus === 403) {
            message.error(configContext.language === 0 ? 'Solicita a tu responsable que te modifique la contraseña' : "Ask your manager to change your password")
        } else if (resStatus === 404) {
            message.error(configContext.language === 0 ? 'El email introducido no existe' : "The entered email does not exist")
        } else {
            message.error("Error")
        }
        setVisibleGetPass(false);
    }

    const sendValidateMail = async () => {
        await axiosCall("post", envLocal.endpointAuthenticationComp, "", "sendMail", {
            email: emailToSend,
            idUser: idUserToSend,
            company: props.location.state?.company,
            language: configContext.language
        });
        setReSendValidateMail(false);
        message.success(configContext.language === 0 ? 'Email enviado' : 'Mail Sended')
    }

    return (
        <div className="mb_30px">
            <Header />
            <Row className="editMenuTitle">
                <Col>
                    <p className="titleAnt_3 m_35x15">
                        {existUser ? props.location.state?.company ?
                            (configContext.language === 0 ? "Introduce tus datos de acceso de tu Negocio" : "Enter your business login details")
                            : (configContext.language === 0 ? "Introduce tus datos de acceso" : "Enter your access data")
                            : (configContext.language === 0 ? "Introduce tus datos para registrarte" : "Enter your details to register")}
                    </p>
                </Col>
            </Row>
            <Col>
                <>
                    <div>
                        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 14, offset: 3 }} md={{ span: 10, offset: 5 }} lg={{ span: 8, offset: 6 }} xl={{ span: 8, offset: 7 }}>
                            {existUser ?
                                <Form {...layout}
                                    name="basic"
                                    onFinish={toLogin}
                                >
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[{ required: true, type: 'email', message: configContext.language === 0 ? 'Introduce tu correo electrónico' : "Enter your email" }]}
                                    >
                                        <Input maxLength={50} placeholder={configContext.language === 0 ? "Email de registro" : "Email registration"} />
                                    </Form.Item>
                                    <Form.Item
                                        label={configContext.language === 0 ? "Contraseña" : "Password"}
                                        name="password"
                                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce tu Contraseña' : "Enter Your Password" }]}
                                    >
                                        <Input.Password maxLength={15} placeholder={configContext.language === 0 ? "Tu Contraseña" : "Your Password"} />
                                    </Form.Item>
                                    <div className="textCenter mb_20px">
                                        <Col xs={{ span: 24, offset: 0 }} md={{ span: 10, offset: 10 }}>
                                            <Button type="link" onClick={() => setVisibleGetPass(true)}>{configContext.language === 0 ? "¿Olvidaste tu contraseña?" : "Forgot your password?"}</Button>
                                        </Col>
                                    </div>
                                    <Form.Item className="centerButtonForm">
                                        <Col xs={{ span: 24, offset: 0 }} md={{ span: 10, offset: 24 }}>
                                            <Button type="primary" htmlType="submit">
                                                {configContext.language === 0 ? "Entrar" : "Enter"}
                                            </Button>
                                        </Col>
                                    </Form.Item>
                                </Form>
                                :
                                <RegisterForm company={props.location.state?.company} originMainPage={true} />
                            }
                        </Col>
                    </div>
                    {configContext.showCaptcha && existUser &&
                        <Row justify="center">
                            <ReCAPTCHA
                                sitekey="6Ld7oaoZAAAAAEnLXFoueUYHHhK2YPlnQd8e3w60"
                                onChange={onChange}
                                hl={configContext.language === 0 ? "es" : "en"}
                            />
                        </Row>}
                    <div className="existOrder mb_20px"><Button style={{ textAlign: "center", fontSize: "large" }} onClick={() => changeExistUser()} type="link">{existUser ? (configContext.language === 0 ? "Quiero registrarme" : "I want to register") : (configContext.language === 0 ? "Ya estoy registrado" : "I'm already registered")}</Button></div>
                </>
            </Col>
            <CollectionCreateFormEmail
                visible={visibleGetPass}
                onCreate={sendPassMail}
                onCancel={() => {
                    setVisibleGetPass(false);
                }}
            />
            <Modal
                title={configContext.language === 0 ? "¿Reenviar email de validación?" : "Resend validation email?"}
                visible={reSendValidateMail}
                onOk={() => sendValidateMail()}
                okText={configContext.language === 0 ? "Reenviar" : "Resend"}
                onCancel={() => setReSendValidateMail(false)}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
            >
                {configContext.language === 0 ? "Es necesario que valides tu cuenta desde el mail que te hemos enviado al correo de registro. Si no lo encuentras te lo podemos reenviar si lo deseas." : "It is necessary to validate your account from the email we have sent you to the registration mail. If you do not find it, we can resend it to you if you wish."}
            </Modal>
        </div>
    )
};

export default Login;