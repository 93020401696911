import React from 'react';
import { Table } from 'antd';
import { IStockStats } from '../../Components/Basic/Utilities/interfaces';
import { groupBy } from '../../Components/Basic/Utilities/tools';
import DownloadExcel from '../../Components/Basic/ExportExcel';

const getColumnsSupplier = (coin: string, language: number) => {

    return [
        {
            title: language === 0 ? "Proveedor" : "Supplier",
            dataIndex: 'proveedor',
            key: 'proveedor',
            label: language === 0 ? "Proveedor" : "Supplier",
        },
        {
            title: language === 0 ? "Total Compras" : "Total Purchases",
            dataIndex: 'buys',
            key: 'buys',
            label: language === 0 ? "Total Compras" : "Total Purchases",
            sorter: (a: any, b: any) => a.buys - b.buys,
        },
        {
            title: language === 0 ? "Productos Comprados" : "Products Purchased",
            dataIndex: 'totalItems',
            key: 'totalItems',
            label: language === 0 ? "Productos Comprados" : "Products Purchased",
            sorter: (a: any, b: any) => a.totalItems - b.totalItems,
        },
        {
            title: language === 0 ? `Importe (${coin})` : `Price (${coin})`,
            dataIndex: 'prices',
            key: 'prices',
            label: language === 0 ? `Importe (${coin})` : `Price (${coin})`,
            sorter: (a: any, b: any) => a.prices - b.prices,
        },
    ];
}

const GetSuppliers: React.FC<IStockStats> = ({ bills, coin, language }) => {
    if (bills.length) {
        let totalProducts: any = []
        bills.forEach(e => e.products.forEach(i => totalProducts.push(i)))
        const groupBySupplier = groupBy(totalProducts, "proveedor")
        const dataTable = Object.keys(groupBySupplier).map(sup => ({ proveedor: sup, buys: bills.map(e => e.products.find(i => i.proveedor === sup)).filter(Boolean).length, totalItems: groupBySupplier[sup].length, prices: groupBySupplier[sup].reduce((s: number, a: any) => { return s + Number(a.price) }, 0) }))
        return (
            <>
                <DownloadExcel columns={getColumnsSupplier(coin, language!)} dataSource={dataTable} name={language === 0 ? "Proveedores" : "Suppliers"} />
                <Table
                    style={{ overflowX: "auto", maxWidth: 1000, margin: "auto" }}
                    columns={getColumnsSupplier(coin, language!)}
                    dataSource={dataTable}
                />
            </>
        )
    }
    return <div className="p_10px">{language === 0 ? 'No se han registrado compras para las fechas seleccionadas' : 'No purchases have been registered for the selected dates.'}</div>
}

export default GetSuppliers;