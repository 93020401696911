import React from 'react';
import { useConfigContext } from '../../Context/ContextConfig';

interface IProps {
  createdAt: number;
  formatReduced: boolean;
};

const Counter: React.FC<IProps> = (props) => {
    const hora = props.createdAt;  
    const total = Number(((Date.now()-hora)/1000).toFixed(0));
    const [counterSec, setCounterSec] = React.useState(total);
    const [counterMin, setCounterMin] = React.useState(0);
    const { configContext } = useConfigContext()!;

    React.useEffect(() => {
      let timer = setInterval(() => setCounterSec(counterSec + 1), 1000);
      if(counterSec > 59){
        setCounterMin(Math.floor(total/60));
        setCounterSec(counterSec % 60);
      }
      return () => clearInterval(timer);
    }, [counterSec]);
    
    return (
        <div style={{margin: "3px 12px", textAlign: "center"}}>{props.formatReduced ? ( ('0' + counterMin).slice(-2) + ":" + ('0' + counterSec).slice(-2)) : (counterMin > 0 ? counterMin + " min, " + counterSec + " s" : counterSec + (configContext.language! === 0 ? " segundos" : " seconds"))}</div>
    );
  }

export default Counter;