import React, { useState, useEffect } from 'react';
import { Button, Row, Col, message, Input, List, InputNumber, Form, Modal, Select, Tooltip, Space, DatePicker, ConfigProvider } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import SkeletonLoad from '../../Components/Basic/SkeletonLoad';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PhoneOutlined, MinusCircleOutlined, PlusOutlined, SearchOutlined, SettingOutlined, LineChartOutlined } from '@ant-design/icons';
import envLocal from '../../env-local';
import NavBar from '../../Components/Basic/NavBar';
import { axiosCall, round } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';
import { IBasicProduct, IItems } from '../../Components/Basic/Utilities/interfaces';
import SquareInfo from '../../Components/Basic/SquareInfo';
import { useUser } from '../../Context/ContextUser';
import { useRestaurant } from '../../Context/ContextRestaurant';
import moment from 'moment';
import coinList from '../../Components/Basic/Utilities/coinList';
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';
import localeEn from 'antd/lib/locale/en_GB';

const { confirm } = Modal;

let proveedores: IProveedores[] = [];
let setProveedorContact: IProveedores;
let basicProduct: IBasicProduct[] = [];
let drinks: IItems[] = [];
let dishes: IItems[] = [];
let inputQuantity: number;
let unitProduct: string | undefined;

interface IProveedores {
    _id: string;
    idRestaurant: string;
    name: string;
    telephone: number;
    email?: string;
}

interface IFormModal {
    visible: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
}

const Stock: React.FC<RouteComponentProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [skeletonLoad, setSkeletonLoad] = useState<boolean>(false);
    const [visibleList, setVisibleList] = useState<boolean>(false);
    const [visibleProveedoresShow, setVisibleProveedoresShow] = useState<boolean>(false);
    const [visibleProveedoresAdd, setVisibleProveedoresAdd] = useState<boolean>(false);
    const [visibleProveedoresContact, setVisibleProveedoresContact] = useState<boolean>(false);
    const [showPurchase, setShowPurchase] = useState<boolean>(false);
    const { configContext } = useConfigContext()!;
    let widthSize = window.innerWidth;
    const myUser = useUser()!.user;
    const [productSelected, setProductSelected] = useState<IBasicProduct | null>();
    const [proveedorSelected, setProveedorSelected] = useState<IProveedores | null>();
    const { restaurant } = useRestaurant()!;
    const coin = coinList.find(coin => coin.value === restaurant.coin)?.symbol;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const fetchBasicProducts = getBasicProducts();
        const fetchProveedores = getProveedores();
        const fetchDishes = getDishes();
        const fetchDrinks = getDrinks();
        await Promise.all([fetchBasicProducts, fetchProveedores, fetchDishes, fetchDrinks])
        setIsLoading(false);
    };

    const ProveedoresAdd: React.FC<IFormModal> = ({
        visible,
        onCreate,
        onCancel,
    }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={configContext.language === 0 ? "Registra tus proveedores" : "Register your suppliers"}
                okText={configContext.language === 0 ? "Guardar" : "Save"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    name="proveedoresList"
                    initialValues={{ _id: proveedorSelected?._id, name: proveedorSelected?.name, telephone: proveedorSelected && proveedorSelected.telephone > 0 ? proveedorSelected?.telephone : null, email: proveedorSelected?.email }}
                >
                    <Form.Item
                        name="_id"
                        label="_id"
                        style={{ display: "none" }}
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label={configContext.language === 0 ? "Nombre" : "Name"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el nombre del proveedor' : "Enter the name of the supplier" }]}
                    >
                        <Input maxLength={20} />
                    </Form.Item>
                    <Form.Item
                        name="telephone"
                        label={configContext.language === 0 ? "Teléfono" : "Telephone"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el teléfono del proveedor' : "Enter the provider's phone number" }]}
                    >
                        <InputNumber min={0} maxLength={15} style={{ width: "40%" }} />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ type: 'email' }]}
                    >
                        <Input maxLength={50} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const ProductList: React.FC<IFormModal> = ({
        visible,
        onCreate,
        onCancel,
    }) => {
        const [form] = Form.useForm();
        inputQuantity = 999999
        return (
            <Modal
                visible={visible}
                title={configContext.language === 0 ? "Introduce las cantidades" : "Enter the quantities"}
                okText={configContext.language === 0 ? "Guardar" : "Save"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    name="basicList"
                    initialValues={{
                        _id: productSelected?._id,
                        name: productSelected?.name,
                        quantity: productSelected?.quantity,
                        proveedor: productSelected?.proveedor,
                        unit: productSelected?.unit,
                        pricePerUnit: Number((productSelected?.pricePerUnit || 0)?.toFixed(2)),
                        totalPrice: (((productSelected?.quantity || 0) * (productSelected?.pricePerUnit || 0))).toFixed(2),
                        lastConsumption: productSelected?.lastConsumption ? moment(productSelected.lastConsumption).format('DD/MM/YYYY HH:mm').toString() : "",
                        lastLoad: productSelected?.lastLoad ? moment(productSelected.lastLoad).format('DD/MM/YYYY HH:mm').toString() : "",
                        unitsLoaded: productSelected?.unitsLoaded,
                        type: productSelected?.type,
                    }}
                >
                    <Form.Item
                        name="_id"
                        label="id"
                        style={{ display: "none" }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label={configContext.language === 0 ? "Nombre" : "Name"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el nombre del producto' : "Enter the name of the product" }]}
                        style={{ width: "90%" }}
                    >
                        <Input maxLength={25} />
                    </Form.Item>
                    <Form.Item
                        name="quantity"
                        label={configContext.language === 0 ? "Cantidad" : "Quantity"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce la cantidad de Stock' : "Enter the stock quantity" }]}
                    >
                        <InputNumber onChange={(e) => inputQuantity = (Number(e))} min={0} max={99999} />
                    </Form.Item>
                    <Form.Item
                        name="unit"
                        label={configContext.language === 0 ? "Unidades" : "Units"}
                    >
                        <Select style={{ width: "90%" }}>
                            <Select.Option value={configContext.language === 0 ? "unidad" : "unit"}>{configContext.language === 0 ? "Unidades" : "Units"}</Select.Option>
                            <Select.Option value="ml">ml</Select.Option>
                            <Select.Option value="l">l</Select.Option>
                            <Select.Option value="gr">gr</Select.Option>
                            <Select.Option value="kr">kr</Select.Option>
                        </Select>
                    </Form.Item>
                    <div className="red">{configContext.language === 0 ? "Este valor se irá ajustando dinámicamente con las compras" : "This value will be adjusted dynamically with purchases"}</div>
                    <Form.Item
                        name="pricePerUnit"
                        label={configContext.language === 0 ? `Precio por ${productSelected?.unit || "unidad"} (${coin})` : `Price per ${productSelected?.unit || "unit"} (${coin})`}
                    >
                        <InputNumber min={0} max={99999} />
                    </Form.Item>
                    <Form.Item
                        name="proveedor"
                        label={configContext.language === 0 ? "Proveedor" : "Supplier"}
                    >
                        <Select style={{ width: "90%" }}>
                            <Select.Option value={"-"}>{configContext.language === 0 ? "Seleccionar" : "Seleccionar"}</Select.Option>
                            {proveedores !== undefined && proveedores.map(proveedor => <Select.Option key={proveedor._id} value={proveedor._id}>{proveedor.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="type"
                        label={configContext.language === 0 ? "Tipo de Producto" : "Product Type"}
                    >
                        <Select style={{ width: "90%" }}>
                            <Select.Option value={configContext.language === 0 ? "Bebidas" : "Drinks"}>{configContext.language === 0 ? "Bebidas" : "Drinks"}</Select.Option>
                            <Select.Option value={configContext.language === 0 ? "Bebida Alcohólica" : "Alcoholic Beverages"}>{configContext.language === 0 ? "Bebida Alcohólica" : "Alcoholic Beverages"}</Select.Option>
                            <Select.Option value={configContext.language === 0 ? "Carnes" : "Meats"}>{configContext.language === 0 ? "Carnes" : "Meats"}</Select.Option>
                            <Select.Option value={configContext.language === 0 ? "Legumbres" : "Legumes"}>{configContext.language === 0 ? "Legumbres" : "Legumes"}</Select.Option>
                            <Select.Option value={configContext.language === 0 ? "Verduras" : "Vegetables"}>{configContext.language === 0 ? "Verduras" : "Vegetables"}</Select.Option>
                            <Select.Option value={configContext.language === 0 ? "Frutas" : "Fruits"}>{configContext.language === 0 ? "Frutas" : "Fruits"}</Select.Option>
                            <Select.Option value={configContext.language === 0 ? "Cereales" : "Cereals"}>{configContext.language === 0 ? "Cereales" : "Cereals"}</Select.Option>
                            <Select.Option value={configContext.language === 0 ? "Condimentos" : "Condiments"}>{configContext.language === 0 ? "Condimentos" : "Condiments"}</Select.Option>
                            <Select.Option value={configContext.language === 0 ? "Café, infusiones" : "Coffee"}>{configContext.language === 0 ? "Café, infusiones" : "Coffee"}</Select.Option>
                            <Select.Option value={configContext.language === 0 ? "Otros" : "Others"}>{configContext.language === 0 ? "Otros" : "Others"}</Select.Option>
                        </Select>
                    </Form.Item>
                    {productSelected?._id && <Form.Item
                        name="totalPrice"
                        label={configContext.language === 0 ? `Precio del Stock (${coin})` : `Stock price (${coin})`}
                    >
                        <Input disabled bordered={false} />
                    </Form.Item>}
                    {productSelected?._id && <Form.Item
                        name="lastConsumption"
                        label={configContext.language === 0 ? "Fecha último consumo" : "Last consumption date"}
                    >
                        <Input disabled bordered={false} />
                    </Form.Item>}
                    {productSelected?._id && <Form.Item
                        name="lastLoad"
                        label={configContext.language === 0 ? "Fecha última reposición" : "Last load date"}
                    >
                        <Input disabled bordered={false} />
                    </Form.Item>}
                    {productSelected?._id && <Form.Item
                        name="unitsLoaded"
                        label={configContext.language === 0 ? "Unidades Repuestas" : "Units Loaded"}
                    >
                        <Input disabled bordered={false} />
                    </Form.Item>}
                    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.quantity !== curValues.quantity}>
                        {() => {
                            const show: boolean = productSelected !== null && productSelected !== undefined && (inputQuantity - productSelected.quantity) < 0
                            return (
                                <Form.Item
                                    name="deleteReason"
                                    label={configContext.language === 0 ? "Motivo de la pérdida de producto" : "Reason for loss of product"}
                                    rules={[{ required: show, message: configContext.language === 0 ? 'Introduce el motivo' : "Enter the reason" }]}
                                    style={{ display: show ? "" : "none" }}
                                >
                                    <Select style={{ width: "90%" }}>
                                        <Select.Option value={configContext.language === 0 ? "Fecha de Caducidad" : "Expiry Date"}>{configContext.language === 0 ? "Fecha de Caducidad" : "Expiry Date"}</Select.Option>
                                        <Select.Option value={configContext.language === 0 ? "Consumo Interno" : "Internal Consumption"}>{configContext.language === 0 ? "Consumo Interno" : "Internal Consumption"}</Select.Option>
                                        <Select.Option value={configContext.language === 0 ? "Corregir Descuadre" : "Fix Balance"}>{configContext.language === 0 ? "Corregir Descuadre" : "Fix Balance"}</Select.Option>
                                        <Select.Option value={configContext.language === 0 ? "Robo" : "Theft"}>{configContext.language === 0 ? "Robo" : "Theft"}</Select.Option>
                                        <Select.Option value="Error">Error</Select.Option>
                                    </Select>
                                </Form.Item>
                            )
                        }}
                    </Form.Item>
                </Form>
                {productSelected?._id &&
                    <>
                        <div className="mb_10px">{configContext.language === 0 ? "Podrías servir hasta:" : "You could serve up to:"}</div>
                        <ul>
                            {[...drinks, ...dishes].filter(e => e.components.length).filter(item => item.components.find(e => e.idProduct === productSelected?._id)).map((racion: any) =>
                                <li key={racion.key}>{`${((productSelected?.quantity || 0) / racion.components.find((e: { idProduct: string, quantity: number, _id: string }) => e.idProduct === productSelected?._id)?.quantity).toFixed(2)} ${configContext.language === 0 ? "Raciones de " : "Rations of "} ${racion.product}`} </li>
                            )}
                        </ul>
                    </>}
            </Modal>
        );
    };

    const AddPurchase: React.FC<IFormModal> = ({
        visible,
        onCreate,
        onCancel,
    }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={configContext.language === 0 ? "Registra tus compras" : "Register your purchases"}
                width={800}
                okText={configContext.language === 0 ? "Guardar" : "Save"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    name="dynamic_form_nest_item"
                    form={form}
                >
                    <Form.Item
                        name="idBill"
                        label={configContext.language === 0 ? "Identificador / Factura" : "Identifier / Bill"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce un identificador de la compra' : "Enter a purchase identifier" }]}
                    >
                        <Input maxLength={25} />
                    </Form.Item>
                    <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                        <Form.Item
                            name="date"
                            label={configContext.language === 0 ? "Fecha" : "Date"}
                            rules={[{ required: true, message: configContext.language === 0 ? 'Introduce la fecha de la factura' : "Enter the invoice date" }]}
                        >
                            <DatePicker inputReadOnly format="DD/MM/YYYY" />
                        </Form.Item>
                    </ConfigProvider>
                    <div className="red mb_10px">{configContext.language === 0 ? 'Importante: Introduce la "Cantidad" indicada abajo en su unidad correspondiente de trabajo' : 'Important: Enter the "Quantity" indicated below in its corresponding unit of work'}</div>
                    <Form.List name="products">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(field => (
                                    <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                            {...field}
                                            name={[field.name, "product"]}
                                            fieldKey={[field.fieldKey, configContext.language === 0 ? "Producto" : "Product"]}
                                            rules={[{ required: true, message: configContext.language === 0 ? "Introduce un producto" : "Enter a product" }]}
                                        >
                                            <Select showSearch onChange={(e) => unitProduct = basicProduct.find(i => i.name === e?.toString())?.unit} placeholder={configContext.language === 0 ? "Producto" : "Product"} style={{ width: 200 }}>
                                                {basicProduct.map(e => <Select.Option key={e._id} value={e.name}>{e.name}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'proveedor']}
                                            fieldKey={[field.fieldKey, configContext.language === 0 ? "Proveedor" : "Supplier"]}
                                            rules={[{ required: true, message: configContext.language === 0 ? "Introduce el proveedor" : "Enter the supplier" }]}
                                        >
                                            <Select showSearch placeholder={configContext.language === 0 ? "Proveedor" : "Supplier"} style={{ width: 200 }}>
                                                {proveedores.map(e => <Select.Option key={e._id} value={e.name}>{e.name}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item shouldUpdate={(prevValues, curValues) => curValues.products !== prevValues.products}>
                                            {() => {
                                                return (
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'quantity']}
                                                        fieldKey={[field.fieldKey, configContext.language === 0 ? "Cantidad" : "Quantity"]}
                                                        rules={[{ required: true, message: configContext.language === 0 ? "Introduce la cantidad" : "Enter the quantity" }]}
                                                    >
                                                        <InputNumber placeholder={configContext.language === 0 ? `Cantidad en ${unitProduct || ""}` : `Quantity on ${unitProduct || ""}`} min={0} max={99999} style={{ width: 170 }} />
                                                    </Form.Item>
                                                )
                                            }}
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'price']}
                                            fieldKey={[field.fieldKey, configContext.language === 0 ? "Importe" : "Price"]}
                                            rules={[{ required: true, message: configContext.language === 0 ? "Introduce el importe" : "Enter the price" }]}
                                        >
                                            <InputNumber placeholder={configContext.language === 0 ? "Importe" : "Price"} min={0} max={9999999} style={{ width: 120 }} />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        {configContext.language === 0 ? "Añadir campo" : "Add field"}
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
        );
    };

    const getBasicProducts = async () => {
        basicProduct = (await axiosCall("post", envLocal.endpointBasicProduct, envLocal.authComp, "getProducts")).data;
    };

    const getProveedores = async () => {
        proveedores = (await axiosCall("post", envLocal.endpointProveedores, envLocal.authComp, "getProveedores")).data;
    };

    const getDishes = async () => {
        const response = (await axiosCall("post", envLocal.endpointDish, envLocal.authComp)).data;
        dishes = response.map((item: any) => { return { key: item._id, product: item.name, type: item.type, typeProduct: item.type === 1 ? (configContext.language === 0 ? "Cocina" : "Kitchen") : "Bar", components: item.components, componentsTable: item.components.map((item: any) => `x${item.quantity}_${basicProduct.find(basic => basic._id === item.idProduct)?.name}. `) } }).flat();
    };

    const getDrinks = async () => {
        const response = (await axiosCall("post", envLocal.endpointDrink, envLocal.authComp)).data;
        drinks = response.map((item: any) => { return { key: item._id, product: item.name, type: item.type, typeProduct: item.type === 1 ? (configContext.language === 0 ? "Cocina" : "Kitchen") : "Bar", components: item.components, componentsTable: item.components.map((item: any) => `x${item.quantity}_${basicProduct.find(basic => basic._id === item.idProduct)?.name}. `) } }).flat();
    };

    const onCreateList = async (values: any) => {
        setVisibleList(false);
        setSkeletonLoad(true);
        if (values._id) {
            delete values.lastConsumption
            if (values.quantity !== productSelected!.quantity) {
                values.lastLoad = new Date
                values.unitsLoaded = values.quantity - productSelected!.quantity
                if (values.quantity >= productSelected!.quantity) delete values.deleteReason
            } else {
                delete values.lastLoad
                delete values.unitsLoaded
            }
            await axiosCall("put", envLocal.endpointBasicProduct, envLocal.authComp, "actions/" + values._id, values);
        } else {
            await axiosCall("post", envLocal.endpointBasicProduct, envLocal.authComp, "", values);
        }
        await getBasicProducts();
        setSkeletonLoad(false);
    };

    const onCreateProveedoresAdd = async (values: any) => {
        setVisibleProveedoresAdd(false);
        setIsLoading(true);
        let response: number;
        if (values._id) {
            response = (await axiosCall("put", envLocal.endpointProveedores, envLocal.authComp, "actions/" + values._id, values)).status;
        } else {
            response = (await axiosCall("post", envLocal.endpointProveedores, envLocal.authComp, "", values)).status;
        }
        if (response === 200 && values._id) {
            message.success(configContext.language === 0 ? "Proveedor Actualizado!" : "Supplier Updated!");
        } else if (response === 200) {
            message.success(configContext.language === 0 ? "Proveedor Creado!" : "Supplier Created!");
        } else {
            message.error("Error");
        };
        await getProveedores();
        setIsLoading(false);
    };

    const createBill = async (values: any) => {
        setShowPurchase(false);
        values.date = moment(values.date).format("DD/MM/YYYY")
        const response = await axiosCall("post", envLocal.endpointBill, envLocal.authComp, "create", values)
        if (response.status === 200) {
            message.success(configContext.language === 0 ? "Factura Guardada!" : "Bill Saved!");
        } else {
            message.error("Error");
        };
        Modal.confirm({
            title: configContext.language === 0 ? '¿Quieres añadir al inventario los productos comprados?' : 'You want to add purchased products to the inventory',
            icon: <ExclamationCircleOutlined />,
            okText: configContext.language === 0 ? 'Sí' : 'Yes',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                setIsLoading(true);
                await axiosCall("post", envLocal.endpointBasicProduct, envLocal.authComp, "loadFromBill", values)
                await getBasicProducts()
                setIsLoading(false);
            },
            onCancel() {
            },
        });
    };

    const editProveedor = (inputProveedor: IProveedores) => {
        setProveedorSelected(inputProveedor);
        setVisibleProveedoresAdd(true);
    }

    const deleteProveedor = async (idProveedor: string) => {
        confirm({
            title: configContext.language === 0 ? '¿Quieres borrar el proveedor seleccionado?' : "Do you want to delete the selected supplier?",
            icon: <ExclamationCircleOutlined />,
            okText: configContext.language === 0 ? 'Sí' : "Yes",
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                setIsLoading(true);
                const responseStatus = (await axiosCall("delete", envLocal.endpointProveedores, envLocal.authComp, "actions/" + idProveedor)).status;
                if (responseStatus === 200) {
                    message.success(configContext.language === 0 ? "Proveedor Eliminado!" : "Supplier Deleted!");
                } else {
                    message.error("Error");
                };
                await getProveedores();
                setIsLoading(false);
            },
            onCancel() {
            },
        });
    };

    const editBasic = async (input?: IBasicProduct) => {
        setProductSelected(input);
        setVisibleList(true);
    };

    const deleteBasic = async (input: string) => {
        confirm({
            title: configContext.language === 0 ? '¿Quieres borrar el elemento seleccionado?' : "Do you want to delete the selected item?",
            icon: <ExclamationCircleOutlined />,
            okText: configContext.language === 0 ? 'Sí' : "Yes",
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                setSkeletonLoad(true);
                const responseStatus = (await axiosCall("delete", envLocal.endpointBasicProduct, envLocal.authComp, "actions/" + input)).status;
                if (responseStatus === 200) {
                    message.info(configContext.language === 0 ? "Elemento borrado" : "Item Deleted");
                } else {
                    message.error("Error");
                };
                await getBasicProducts();
                setSkeletonLoad(false);
            },
            onCancel() {
            },
        });
    };

    const contactWithProvider = (proveedorInput: string) => {
        setProveedorContact = proveedores.find(proveedor => proveedor._id === proveedorInput)!;
        setVisibleProveedoresContact(true);
    };

    const getSquareTask = () => {
        return [
            {
                squareStyle: { width: widthSize < 690 ? "100%" : 350, height: 175 },
                title: configContext.language === 0 ? "Proveedores" : "Suppliers",
                body:
                    <>
                        <div className="buttonActions">
                            <Button
                                style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white" }}
                                type="primary"
                                onClick={() => setVisibleProveedoresShow(true)}
                                icon={<SearchOutlined />}
                            >
                                {configContext.language === 0 ? "Ver Proveedores" : "View Suppliers"}
                            </Button>
                        </div>
                        <div className="buttonActions">
                            <Button
                                style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white" }}
                                type="primary"
                                onClick={() => setVisibleProveedoresAdd(true)}
                                icon={<PlusOutlined />}
                            >
                                {configContext.language === 0 ? "Añadir Proveedor" : "Add Supplier"}
                            </Button>
                        </div>
                    </>
            },
            {
                squareStyle: { width: widthSize < 690 ? "100%" : 350, height: 175 },
                title: configContext.language === 0 ? "Acciones" : "Actions",
                body:
                    <>
                        <div className="buttonActions">
                            <Button
                                style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white" }}
                                type="primary"
                                onClick={() => editBasic()}
                                icon={<PlusOutlined />}
                            >
                                {configContext.language === 0 ? "Nuevo Producto" : "New Product"}
                            </Button>
                        </div>
                        <div className="buttonActions">
                            <Button
                                style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white" }}
                                type="primary"
                                onClick={() => setShowPurchase(true)}
                                icon={<PlusOutlined />}
                            >
                                {configContext.language === 0 ? "Añadir Compra" : "Add Purchase"}
                            </Button>
                        </div>
                        <div className="buttonActions">
                            <Button
                                style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white" }}
                                type="primary"
                                onClick={() => props.history.push("/StockStats")}
                                icon={<LineChartOutlined />}
                            >
                                {configContext.language === 0 ? "Análisis Contable" : "Accounting Analysis"}
                            </Button>
                        </div>
                    </>
            },
            {
                squareStyle: { width: widthSize < 690 ? "100%" : "50%", height: "auto" },
                title: configContext.language === 0 ? "Configuración" : "Configuration",
                body:
                    <div className="buttonActions">
                        <Button
                            style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white", marginTop: 15 }}
                            type="primary"
                            icon={<SettingOutlined />}
                            onClick={() => props.history.push('/StockTable')}
                        >
                            {configContext.language === 0 ? "Configurar Stock" : "Configure Stock"}
                        </Button>
                    </div>
            }
        ]
    }

    return (
        <div className="basicContainerColor">
            <NavBar />
            <div className="titleSection">{configContext.language === 0 ? "Inventario" : "Stock"}</div>
            {isLoading ?
                <LoadingSpin />
                :
                <div className="mb_30px">
                    {myUser.userType === 1 && <SquareInfo squares={getSquareTask()} />}
                    <div className="sectionSquare" style={{ margin: widthSize < 690 ? "30px 0px 5px 0px" : "30px 15px 5px 15px", padding: widthSize < 690 ? "15px 0px" : 15 }}>
                        <div className="sectionSquareTitle">{configContext.language === 0 ? "Inventario" : "Inventory"}</div>
                        <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}>
                            <Row justify="center">
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 14, offset: 1 }} lg={{ span: 12, offset: 1 }} xl={{ span: 10, offset: 1 }}>
                                    {skeletonLoad ?
                                        <SkeletonLoad title={false} quantity={9} marginTop={15} />
                                        : <List
                                            itemLayout="horizontal"
                                            dataSource={basicProduct}
                                            header={<div className="textCenter boldLarge">{configContext.language === 0 ? "Productos Básicos" : "Basic Products"}</div>}
                                            renderItem={item => (
                                                <List.Item
                                                    actions={[
                                                        myUser.userType === 1 ? <Tooltip title={configContext.language === 0 ? 'Eliminar' : "Delete"}><Button type="link" onClick={() => deleteBasic(item._id)} style={{ color: "red" }} icon={<DeleteOutlined />} /></Tooltip> : null,
                                                        myUser.userType === 1 ? <Tooltip title={configContext.language === 0 ? 'Editar' : "Edit"}><Button type="link" onClick={() => editBasic(item)} icon={<EditOutlined />} /></Tooltip> : null,
                                                        item.proveedor !== '' && item.proveedor !== '-' && <Tooltip title={configContext.language === 0 ? 'Contactar Proveedor' : "Contact Supplier"}><Button type="link" onClick={() => contactWithProvider(item.proveedor)} style={{ color: "green" }} icon={<PhoneOutlined />} /></Tooltip>,
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        title={
                                                            <React.Fragment>
                                                                <span style={{ width: 80, marginRight: 10, fontSize: "130%", color: item.quantity < 10 ? "red" : "green" }}>{round(item.quantity)}</span> {item.name}
                                                            </React.Fragment>}
                                                    />
                                                </List.Item>
                                            )}
                                        />}
                                </Col>
                                <Modal
                                    title={configContext.language === 0 ? "Listado de Proveedores" : "Supplier List"}
                                    visible={visibleProveedoresShow}
                                    onOk={() => setVisibleProveedoresShow(false)}
                                    onCancel={() => setVisibleProveedoresShow(false)}
                                >
                                    {proveedores.length > 0 &&
                                        <List
                                            dataSource={proveedores}
                                            renderItem={proveedor => (
                                                <List.Item style={{ display: "block" }}>
                                                    <Row>
                                                        <Col span={16}>
                                                            <b>{proveedor.name}</b>
                                                            <Tooltip title={configContext.language === 0 ? 'Eliminar' : "Delete"}><Button type="link" onClick={() => deleteProveedor(proveedor._id)} style={{ color: "red" }} icon={<DeleteOutlined />} /></Tooltip>
                                                            <Tooltip title={configContext.language === 0 ? 'Editar' : "Edit"}><Button type="link" onClick={() => editProveedor(proveedor)} icon={<EditOutlined />} /></Tooltip>
                                                        </Col>
                                                        <Col span={7} offset={1}>
                                                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                                <div><b>{configContext.language === 0 ? "Teléfono:" : "Telephone:"}</b>&nbsp;</div>
                                                                <a href={`tel:${proveedor.telephone}`}>{proveedor.telephone}</a>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {proveedor.email && <Row><b>Email:</b>&nbsp;<a href={`mailto:${proveedor.email}`}>{proveedor.email}</a></Row>}
                                                </List.Item>
                                            )}
                                        />}
                                </Modal>
                                <Modal
                                    title={configContext.language === 0 ? "Contactar con Proveedor" : "Contact Supplier"}
                                    visible={visibleProveedoresContact}
                                    onOk={() => setVisibleProveedoresContact(false)}
                                    onCancel={() => setVisibleProveedoresContact(false)}
                                >
                                    {setProveedorContact !== undefined && <>
                                        <Row>
                                            <Col span={14}><b>{setProveedorContact.name}</b></Col>
                                            <Col span={9} offset={1}><b>{configContext.language === 0 ? "Teléfono:" : "Telephone:"}</b>&nbsp;<a href={`tel:${setProveedorContact.telephone}`}>{setProveedorContact.telephone}</a></Col>
                                        </Row>
                                        {setProveedorContact.email && <Row><b>Email:</b>&nbsp;<a href={`mailto:${setProveedorContact.email}`}>{setProveedorContact.email}</a></Row>}
                                    </>}
                                </Modal>
                                <ProductList
                                    visible={visibleList}
                                    onCreate={onCreateList}
                                    onCancel={() => setVisibleList(false)}
                                />
                                <ProveedoresAdd
                                    visible={visibleProveedoresAdd}
                                    onCreate={onCreateProveedoresAdd}
                                    onCancel={() => setVisibleProveedoresAdd(false)}
                                />
                                <AddPurchase
                                    visible={showPurchase}
                                    onCreate={createBill}
                                    onCancel={() => setShowPurchase(false)}
                                />
                            </Row>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Stock;