import React, { useState, useEffect } from 'react';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import envLocal from '../../env-local';
import { Success } from '../../Components/Basic/Results/Success';
import { Error404 } from '../../Components/Basic/Results/Error404';
import { useConfigContext } from '../../Context/ContextConfig';

const ValidateUser: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean>(false);
    const { configContext } = useConfigContext()!;
    const [isCompany, setIsCompany] = useState<boolean>(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const user = window.location.href.split('/').pop();
        setIsCompany(user?.split('-')[0] === "true");
        const responseStatus = (await axiosCall("put", user?.split('-')[0] === "true" ? envLocal.endpointAuthenticationComp : envLocal.endpointAuthentication, "", "validate/" + user?.split('-')[1])).status;
        if (responseStatus === 200) {
            setSuccess(true);
        }
        setIsLoading(false);
    };

    return (
        isLoading ?
            <LoadingSpin />
            :
            success ?
                <div>
                    <Success title={configContext.language === 0 ? "Usuario validado" : "Validated user"} subTitle={configContext.language === 0 ? "Ya tienes disponibles todas las ventajas que te ofrece Bliscop" : "All the advantages of Bliscop are now available to you"} goTo={isCompany ? undefined : (configContext.language === 0 ? "Ir a Bliscop" : "Go to Bliscop")} />
                    {isCompany &&
                        <>
                            <div className="textCenter">
                                <span><b>{configContext.language === 0 ? "Si necesitas más información de cómo configurar la aplicación, puedes consultar el siguiente enlace: " : "If you need more information on how to configure the application, you can check the following link: "}</b></span>
                                <div className="mt_10px">
                                    <a className="boldLarge" target="_blank" rel="noopener" href={configContext.language === 0 ? "https://www.bliscop.com/como-configurar-nuestro-software-tpv-para-hosteleria" : "https://www.bliscop.com/how-to-configure-our-management-software-for-restaurants"}>{configContext.language === 0 ? "Guía de Configuración" : "Configuration Guide"}</a>
                                </div>
                            </div>
                            <div className="textCenter mt_15px">
                                <span><b>{configContext.language === 0 ? 'Si lo deseas puedes ir directamente a la aplicación. Pincha en "Soy una empresa" y accede con tus datos.' : 'If you wish you can go directly to the application. Click on "I am a company" and log in with your details.'}</b></span>
                                <div className="mt_10px">
                                    <a className="boldLarge" target="_blank" rel="noopener" href="https://www.bliscop.app">{configContext.language === 0 ? "Ir a Bliscop.app" : "Go to Bliscop.app"}</a>
                                </div>
                            </div>
                        </>
                    }
                </div>
                :
                <Error404 title={configContext.language === 0 ? "No hemos podido validar el usuario" : "We have not been able to validate the user"} subTitle={configContext.language === 0 ? "Lo sentimos, inténtelo de nuevo más tarde" : "Sorry, try again later"} goTo={configContext.language === 0 ? "Ir a Bliscop" : "Go to Bliscop"} />
    );
};

export default ValidateUser;