import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Tooltip, Switch, InputNumber, Input, Checkbox, DatePicker, message, ConfigProvider } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import envLocal from '../../env-local';
import NavBar from '../../Components/Basic/NavBar';
import { useRestaurant } from '../../Context/ContextRestaurant';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';
import moment from 'moment';
import { IConfig } from '../../Components/Basic/Utilities/interfaces';
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';
import localeEn from 'antd/lib/locale/en_GB';

let hasSpecialTables = false;

const Config: React.FC = () => {
    const [config, setConfig] = useState<IConfig>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { restaurant, setRestaurant } = useRestaurant()!;
    const { configContext } = useConfigContext()!;
    const [discount, setDiscount] = useState<boolean>(false);
    const [pickOrdersFlag, setPickOrdersFlag] = useState<boolean>(false);
    const [homeOrdersFlag, setHomeOrdersFlag] = useState<boolean>(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const response = (await axiosCall("post", envLocal.endpointRestaurant, envLocal.authComp, "findRestaurant")).data.config;
        setConfig(response);
        setPickOrdersFlag(response?.pickOrders !== undefined ? response.pickOrders : false);
        setHomeOrdersFlag(response?.homeOrders !== undefined ? response.homeOrders : false);
        response.discountForCome && setDiscount(response.discountForCome);
        hasSpecialTables = !!response?.priceSpecialTable
        setIsLoading(false);
    };

    const onFinish = async (values: any) => {
        const resStatus = (await axiosCall("put", envLocal.endpointRestaurant, envLocal.authComp, "", { config: values })).status;
        if (resStatus === 200) {
            setRestaurant({ ...restaurant, config: values });
            message.info(configContext.language === 0 ? "Se han guardado los cambios" : "Changes have been saved")
        } else {
            message.error("Error")
        }
    };

    return (
        <div className="basicContainer">
            <NavBar />
            <div className="titleSection2">{configContext.language === 0 ? "Configuración del Restaurante" : "Restaurant Configuration"}</div>
            {isLoading ?
                <LoadingSpin />
                :
                <React.Fragment>
                    <Row className="mt_20px m_50px" justify="center">
                        <Form
                            onFinish={onFinish}
                            initialValues={config !== undefined ? {
                                manualValidation: config.manualValidation,
                                menuOnlyRead: config.menuOnlyRead,
                                pickOrders: config.pickOrders,
                                homeOrders: config.homeOrders,
                                remoteStartHour: config.remoteStartHour,
                                remoteEndHour: config.remoteEndHour,
                                discountForCome: config.discountForCome,
                                discountValue: config.discountValue,
                                applyToMenu: config.applyToMenu || false,
                                validDays: config.validDays,
                                expiryDate: moment(config.expiryDate),
                                minConsumption: config.minConsumption,
                                noShowRate: config.noShowRate,
                                taskVisibleAll: config.taskVisibleAll,
                                priceSpecialTable: config.priceSpecialTable,
                                startSpecialTable: config.startSpecialTable,
                                lastSpecialTable: config.lastSpecialTable,
                                specialTables: config.specialTables
                            } : {}}
                        >
                            <Form.Item
                                label={
                                    <span>
                                        {configContext.language === 0 ? "Validación manual de pedidos" : "Manual Order Validation"}&nbsp;
                                        <Tooltip title={configContext.language === 0 ? 'Cuando un cliente realice un pedido entrará al sistema automáticamente. Si está activado tendrán que ser validados desde la pestaña "Mi Restaurante".' : "When a customer places an order he will be logged in automatically. If it is activated they will have to be validated from the 'My Restaurant' tab."}>
                                            <QuestionCircleTwoTone />
                                        </Tooltip>
                                    </span>}
                                name='manualValidation'
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span>
                                        {configContext.language === 0 ? "Menú solo lectura" : "Menu only read"}&nbsp;
                                        <Tooltip title={configContext.language === 0 ? 'Los clientes verán la carta pero no podrán realizar pedidos con ella' : "Customers will see the menu but will not be able to place orders with it"}>
                                            <QuestionCircleTwoTone />
                                        </Tooltip>
                                    </span>}
                                name='menuOnlyRead'
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span>
                                        {configContext.language === 0 ? "Acepta pedidos para recoger" : "Accepts pickup orders"}&nbsp;
                                        <Tooltip title={configContext.language === 0 ? 'Se encargará de realizar el pedido y será el cliente quien lo recoja.' : "You'll take care of the order and the customer will pick it up"}>
                                            <QuestionCircleTwoTone />
                                        </Tooltip>
                                    </span>}
                                name='pickOrders'
                                valuePropName="checked"
                            >
                                <Switch onChange={e => setPickOrdersFlag(e)} />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span>
                                        {configContext.language === 0 ? "Realiza envíos a domicilio" : "You make home deliveries"}&nbsp;
                                        <Tooltip title={configContext.language === 0 ? 'Se encargará de realizar el pedido y enviarlo a la dirección facilitada por el cliente.' : "You will place the order and sending it to the address provided by the customer."}>
                                            <QuestionCircleTwoTone />
                                        </Tooltip>
                                    </span>}
                                name='homeOrders'
                                valuePropName="checked"
                            >
                                <Switch onChange={e => setHomeOrdersFlag(e)} />
                            </Form.Item>
                            {(pickOrdersFlag || homeOrdersFlag) && <div className="displayFlex">
                                <Form.Item
                                    label={
                                        <span>
                                            {configContext.language === 0 ? 'Desde la hora' : "From hour"}&nbsp;
                                            <Tooltip title={configContext.language === 0 ? 'Hora a la que empieza a aceptar pedidos externos' : "Time you start accepting external orders"}>
                                                <QuestionCircleTwoTone />
                                            </Tooltip>
                                        </span>}
                                    name='remoteStartHour'
                                >
                                    <InputNumber min={0} max={24} />
                                </Form.Item>
                                <Form.Item
                                    className="ml_10px"
                                    label={
                                        <span>
                                            {configContext.language === 0 ? 'Hasta' : "To"}&nbsp;
                                            <Tooltip title={configContext.language === 0 ? 'Hora a la que empieza a aceptar pedidos externos' : "Time you start accepting external orders"}>
                                                <QuestionCircleTwoTone />
                                            </Tooltip>
                                        </span>}
                                    name='remoteEndHour'
                                >
                                    <InputNumber min={0} max={24} />
                                </Form.Item>
                            </div>}
                            <Form.Item
                                label={
                                    <span>
                                        {configContext.language === 0 ? "Descuento para próxima visita" : "Discount for next visit"}&nbsp;
                                        <Tooltip title={configContext.language === 0 ? 'Si se realiza un consumo mínimo el cliente obtendrá un descuento.' : "If a minimum consumption is made the customer will get a discount."}>
                                            <QuestionCircleTwoTone />
                                        </Tooltip>
                                    </span>}
                                name='discountForCome'
                                valuePropName="checked"
                            >
                                <Switch onChange={(e) => setDiscount(e)} />
                            </Form.Item>
                            {discount && (
                                <>
                                    <Form.Item
                                        label={configContext.language === 0 ? 'Porcentaje de Descuento' : "Percentage Discount"}
                                        name='discountValue'
                                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el porcentaje a descontar' : "Enter the percentage to be deducted" }]}
                                    >
                                        <InputNumber min={5} max={100} step={5} />
                                    </Form.Item>
                                    <Form.Item
                                        label={configContext.language === 0 ? 'Aplica a menús' : "Applies to menus"}
                                        name='applyToMenu'
                                        rules={[{ required: true, message: configContext.language === 0 ? 'Indica si el descuento se aplica también a los menús' : "Indicates if the discount also applies to menus" }]}
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                    <Form.Item
                                        label={configContext.language === 0 ? 'Días válidos para usarse' : "Valid days to be used"}
                                        name="validDays"
                                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce los días en los que se puede usar el descuento' : "Enter the days on which the discount can be used" }]}
                                    >
                                        <Checkbox.Group>
                                            <div className="displayGrid">
                                                <Checkbox value={1} style={{ lineHeight: '32px', marginLeft: 8 }}>{configContext.language === 0 ? 'Lunes' : "Monday"}</Checkbox>
                                                <Checkbox value={2} className="lineHeight_32">{configContext.language === 0 ? 'Martes' : "Tuesday"}</Checkbox>
                                                <Checkbox value={3} className="lineHeight_32">{configContext.language === 0 ? 'Miércoles' : "Wednesday"}</Checkbox>
                                                <Checkbox value={4} className="lineHeight_32">{configContext.language === 0 ? 'Jueves' : "Thursday"}</Checkbox>
                                                <Checkbox value={5} className="lineHeight_32">{configContext.language === 0 ? 'Viernes' : "Friday"}</Checkbox>
                                                <Checkbox value={6} className="lineHeight_32">{configContext.language === 0 ? 'Sábado' : "Saturday"}</Checkbox>
                                                <Checkbox value={0} className="lineHeight_32">{configContext.language === 0 ? 'Domingo' : "Sunday"}</Checkbox>
                                            </div>
                                        </Checkbox.Group>
                                    </Form.Item>
                                    <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                                        <Form.Item
                                            label={configContext.language === 0 ? 'Fecha de caducidad' : "Expiry date"}
                                            name='expiryDate'
                                            rules={[{ required: true, message: configContext.language === 0 ? 'Introduce día en el que expira el descuento' : "Enter the day the discount expires" }]}
                                        >
                                            <DatePicker inputReadOnly format="DD/MM/YYYY" />
                                        </Form.Item>
                                    </ConfigProvider>
                                    <Form.Item
                                        label={configContext.language === 0 ? 'Consumo mínimo para generar el descuento' : "Minimum consumption to generate the discount"}
                                        name='minConsumption'
                                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el consumo mínimo para generar el descuento' : "Enter the minimum consumption to generate the discount" }]}
                                    >
                                        <InputNumber min={0} maxLength={5} />
                                    </Form.Item>
                                </>
                            )}
                            <Form.Item
                                label={
                                    <span>
                                        {configContext.language === 0 ? "No mostrar puntuación" : "Do not display score"}&nbsp;
                                        <Tooltip title={configContext.language === 0 ? 'Evita que se muestre la puntuación de los platos a los clientes' : "Prevents dish scores from being displayed to customers"}>
                                            <QuestionCircleTwoTone />
                                        </Tooltip>
                                    </span>}
                                name='noShowRate'
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span>
                                        {configContext.language === 0 ? "Tareas visibles por todos" : "Tasks visible to all"}&nbsp;
                                        <Tooltip title={configContext.language === 0 ? 'Todos los empleados pueden ver todas las tareas disponibles' : "All employees can see all available tasks"}>
                                            <QuestionCircleTwoTone />
                                        </Tooltip>
                                    </span>}
                                name='taskVisibleAll'
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span>
                                        {configContext.language === 0 ? "Incremento de precio en mesas" : "Tables with increased price"}&nbsp;
                                        <Tooltip title={configContext.language === 0 ? 'El porcentaje extra que se cobrará en las mesas indicadas. Ejemplo: Mesas de terraza' : "The extra % that will be applied to this tables"}>
                                            <QuestionCircleTwoTone />
                                        </Tooltip>
                                    </span>}
                                name='priceSpecialTable'
                            >
                                <InputNumber
                                    min={0}
                                    max={100}
                                    formatter={value => `${value}%`}
                                    parser={(value: any) => value!.replace('%', '')}
                                    onChange={(e) => hasSpecialTables = e > 0}
                                />
                            </Form.Item>
                            <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.priceSpecialTable !== curValues.priceSpecialTable}>
                                {() => {
                                    if (hasSpecialTables) {
                                        return (
                                            <Form.Item
                                                label={
                                                    <span>
                                                        {configContext.language === 0 ? "Mesas con precio extra" : "Tables with increased price"}&nbsp;
                                                        <Tooltip title={configContext.language === 0 ? 'Introduce las mesas separadas por comas, por ejemplo: 1,2,3,m2,t4,mesa7' : "Enter tables separated by commas, e.g.: 1,2,3,m2,t4,table7"}>
                                                            <QuestionCircleTwoTone />
                                                        </Tooltip>
                                                    </span>}
                                                name='specialTables'
                                            >
                                                <Input />
                                            </Form.Item>
                                        )
                                        /*                       return (
                                                                  <div className="displayFlex">
                                                                      <Form.Item
                                                                          label={
                                                                              <span>
                                                                                  {configContext.language === 0 ? 'Desde la mesa' : "From table"}&nbsp;
                                                                                  <Tooltip title={configContext.language === 0 ? 'Mesa desde la que cobraremos un recargo extra' : "Table from which we will charge an extra charge"}>
                                                                                      <QuestionCircleTwoTone />
                                                                                  </Tooltip>
                                                                              </span>}
                                                                          name='startSpecialTable'
                                                                          rules={[{ required: hasSpecialTables, message: configContext.language === 0 ? 'Introduce la mesa desde la que se incrementa el precio' : "Enter the table from which the price is to be increased" }]}
                                                                      >
                                                                          <InputNumber min={0} max={99} />
                                                                      </Form.Item>
                                                                      <Form.Item
                                                                          className="ml_10px"
                                                                          label={
                                                                              <span>
                                                                                  {configContext.language === 0 ? 'Hasta' : "To"}&nbsp;
                                                                                  <Tooltip title={configContext.language === 0 ? 'Mesa hasta la que cobraremos un recargo extra' : "Table up to which we will charge an extra charge"}>
                                                                                      <QuestionCircleTwoTone />
                                                                                  </Tooltip>
                                                                              </span>}
                                                                          name='lastSpecialTable'
                                                                          rules={[{ required: hasSpecialTables, message: configContext.language === 0 ? 'Introduce la mesa hasta la que se incrementa el precio' : "Enter the table up to which the price increases" }]}
                                                                      >
                                                                          <InputNumber min={0} max={99} />
                                                                      </Form.Item>
                                                                  </div>
                                                              ) */
                                    } else {
                                        return
                                    }
                                }}
                            </Form.Item>
                            <Form.Item>
                                <div className="displayFlex">
                                    <Button type='primary' htmlType='submit' className="marginAuto">
                                        {configContext.language === 0 ? "Guardar" : "Save"}
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Row>
                </React.Fragment>
            }
        </div>
    );
};

export default Config;