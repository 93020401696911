import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Row, Col, Button, ConfigProvider, Popconfirm, Modal, message } from 'antd';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import envLocal from '../../env-local';
import NavBar from '../../Components/Basic/NavBar';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';
import localeEn from 'antd/lib/locale/en_GB';
import moment from 'moment'

const { RangePicker } = DatePicker;

interface ITable {
    _id: string;
    username: string;
    day: string;
    registerDate: Date;
    entry?: string;
    close?: string;
};

interface IUsers {
    username: string;
    color: string;
    status: number;
    _id: string;
};

interface IEditDate {
    key?: number;
    day?: string;
    hour?: string;
    type?: string;
    username?: string;
    _id?: string;
}

let relUsernameId: any = [];
let columns: any;

const TimeHistoric: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dataTable, setDataTable] = useState<Array<ITable>>([]);
    const { configContext } = useConfigContext()!;
    const [filterDate, setFilterDate] = useState<string>(configContext.language === 0 ? "Registros de Hoy" : "Today's Records");
    const [showModalEditDate, setShowModalEditDate] = useState<boolean>(false)
    const [newDate, setNewDate] = useState<IEditDate | undefined>()
    let widthSize = window.innerWidth;

    const setDatas = (inputData: any) => {
        const returnData = inputData.map((time: any, index: number) => { if (relUsernameId[time.idUser] !== undefined) return { key: index, username: relUsernameId[time.idUser], day: time.day, type: configContext.language === 0 ? `${time.entry ? "Entrada" : "Salida"}` : `${time.entry ? "Entry" : "Exit"}`, hour: time.entry ? time.entry : time.close, _id: time._id } });
        return returnData.filter(Boolean);
    };

    const setColumns = (users: IUsers[]) => {
        let userList: any = [];
        users.forEach((user: any) => userList = [...userList, { text: user.username, value: user.username }]);
        columns = [
            {
                title: configContext.language === 0 ? 'Nombre' : "Name",
                dataIndex: 'username',
                key: 'username',
                filters: [...userList],
                onFilter: (value: any, record: any) => record.username.indexOf(value) === 0,
                render: (text: any, record: any) =>
                    <Popconfirm title={configContext.language === 0 ? '¿Editar registro?' : "Edit record?"} onConfirm={() => showModalEdit(record)}>
                        <a>{text}</a>
                    </Popconfirm>
            },
            {
                title: configContext.language === 0 ? 'Entrada / Salida' : "Entry / Exit",
                dataIndex: 'type',
                key: 'type',
                filters: [{ text: configContext.language === 0 ? "Entrada" : "Entry", value: configContext.language === 0 ? "Entrada" : "Entry" }, { text: configContext.language === 0 ? "Salida" : "Exit", value: configContext.language === 0 ? "Salida" : "Exit" }],
                onFilter: (value: any, record: any) => record.type.indexOf(value) === 0,
            },
            {
                title: configContext.language === 0 ? 'Día' : "Day",
                dataIndex: 'day',
                key: 'day'
            },
            {
                title: configContext.language === 0 ? 'Hora' : "Hour",
                dataIndex: 'hour',
                key: 'hour'
            }
        ];
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const day = moment().format('DD/MM/YYYY');
            const usrs = await axiosCall("post", envLocal.endpointAuthenticationComp, envLocal.authComp, "findUsers");
            if (usrs.status === 200) {
                setColumns(usrs.data);
                const times = await axiosCall("post", envLocal.endpointTimeRegister, envLocal.authComp, "today", { day });
                if (times.status === 200) {
                    await usrs.data.forEach((user: any) => relUsernameId = { ...relUsernameId, [user._id]: user.username });
                    setDataTable(setDatas(times.data));
                }
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const onChange = async (date: any, dateString: any) => {
        date && setFilterDate(configContext.language === 0 ? `Desde: ${dateString[0]}. Hasta: ${dateString[1]}` : `From: ${dateString[0]}. To: ${dateString[1]}`)
        if (date) {
            const res = await axiosCall("post", envLocal.endpointTimeRegister, envLocal.authComp, "", {
                low: date[0]._d,
                high: date[1]._d
            });
            if (res.status === 200) {
                setDataTable(setDatas(res.data));
            }
        }
    };

    const showModalEdit = (e: IEditDate) => {
        setNewDate(e)
        setShowModalEditDate(true)
    }

    const editDate = React.useCallback(async (inputDate: IEditDate) => {
        const response = await axiosCall("put", envLocal.endpointTimeRegister, envLocal.authComp, "", {...inputDate, registerDate: moment(`${inputDate.day} ${inputDate.hour}`, "DD/MM/YYYY HH:mm")});
        if (response.status === 200) {
            message.success(configContext.language === 0 ? "Registro actualizado" : "Updated register")
            fetchData()
        }
        setShowModalEditDate(false)
    }, [])

    return (
        <div className="basicContainerColor">
            <NavBar />
            <div style={{ margin: "15px 0px" }}>
                {isLoading ?
                    <LoadingSpin />
                    : <div className="mb_30px mt_60px">
                        <div className="sectionSquare" style={{ margin: widthSize < 690 ? "30px 0px 5px 0px" : "30px 15px 5px 15px", padding: widthSize < 690 ? "15px 0px" : 15 }}>
                            <div className="sectionSquareTitle">{configContext.language === 0 ? "Registros" : "Records"}</div>
                            <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}>
                                <div className="mb_20px large boldCenter">{filterDate}</div>
                                <div className="textCenter">
                                    <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                                        <RangePicker inputReadOnly onChange={onChange} showTime format="DD/MM/YYYY HH:mm:ss" style={{ margin: "20px auto" }} />
                                    </ConfigProvider>
                                </div>
                                <Row><Col xs={{ span: 24, offset: 0 }} sm={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}><Table columns={columns} dataSource={dataTable} /></Col></Row>
                                <Row justify="center" className="mt_20px"><Button type='primary' onClick={() => window.history.go(-1)}>{configContext.language === 0 ? "Atrás" : "Back"}</Button></Row>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Modal
                title={configContext.language === 0 ? "Introduce la nueva fecha" : "Enter the new date"}
                visible={showModalEditDate}
                onOk={() => editDate(newDate!)}
                onCancel={() => setShowModalEditDate(false)}
                okText={configContext.language === 0 ? "Aceptar" : "Accept"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
            >
                <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                    <DatePicker
                        inputReadOnly
                        value={moment(`${newDate?.day} ${newDate?.hour}`, "DD/MM/YYYY HH:mm")}
                        onChange={(a, e) => setNewDate({ ...newDate, day: e.split(" ")[0], hour: e.split(" ")[1] })}
                        format="DD/MM/YYYY HH:mm"
                        showTime
                    />
                </ConfigProvider>
            </Modal>
        </div>
    )
};

export default TimeHistoric;