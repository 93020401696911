import React from "react";
import { IStats, IGraph } from '../Components/Basic/Utilities/interfaces';
import { useWindowSize, dateWithoutYear } from '../Components/Basic/Utilities/tools';
import { BarVertical } from './Basic/Bar';
import { useConfigContext } from '../Context/ContextConfig';

const getDataOrderTypeIncome = (input: { stats: IStats[], language: number }) => {
    const ret = input.stats.map(item => {
        return {
            "day": dateWithoutYear(item.day),
            [input.language === 0 ? "Restaurante" : "Restaurant"]: Number((item.localOrderIncome || 0).toFixed(2)),
            [input.language === 0 ? "Recogida" : "Pickup"]: Number((item.pickOrderIncome || 0).toFixed(2)),
            [input.language === 0 ? "A domicilio" : "Delivery"]: Number((item.homeOrderIncome || 0).toFixed(2))
        }
    })
    return ret;
}

export const BarOrderTypeIncome: React.FC<IGraph> = (props) => {
    const width = useWindowSize();
    const { configContext } = useConfigContext()!;
    return (
        <div className="sectionSquareGraph">
            <div className="sectionSquareTitleGraph">{props.title}</div>
            <div className="sectionSquareBodyGraph">
                <BarVertical {...props} margin={{ left: width[0] < 700 ? 5 : 70 }} leyendInfo={width[0] < 700 ? false : true} dataTable={getDataOrderTypeIncome({ stats: props.data, language: configContext.language! })} />
            </div>
        </div>
    )
}