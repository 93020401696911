import React from 'react';
import { Table, Collapse, Popconfirm, message } from 'antd';
import { IBills, IStockStats, IFilterList } from '../../Components/Basic/Utilities/interfaces';
import { DeleteOutlined } from '@ant-design/icons';
import envLocal from '../../env-local';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import DownloadExcel from '../../Components/Basic/ExportExcel';

const deleteBill = async (idBill: string, language: number) => {
    const response = await axiosCall("delete", envLocal.endpointBill, envLocal.authComp, `actions/${idBill}`)
    if (response.status === 200) {
        message.success(language === 0 ? "Factura Borrada!" : "Bill Deleted!")
        window.location.reload()
    } else {
        message.error("Error")
    }
}

const getColumnsBills = (bill: IBills, coin: string, language: number) => {
    let productList: IFilterList[] = [];
    let proveedorList: IFilterList[] = [];
    bill.products.forEach(e => {
        if (!productList.find(i => i.text === e.product)) productList.push({ text: e.product, value: e.product })
        if (!proveedorList.find(i => i.text === e.proveedor)) proveedorList.push({ text: e.proveedor, value: e.proveedor })
    });
    return [
        {
            title: language === 0 ? "Producto" : "Product",
            dataIndex: 'product',
            key: 'product',
            label: language === 0 ? "Producto" : "Product",
            filters: [...productList],
            onFilter: (value: any, record: any) => record.product.indexOf(value) === 0,
        },
        {
            title: language === 0 ? "Proveedor" : "Supplier",
            dataIndex: 'proveedor',
            key: 'proveedor',
            label: language === 0 ? "Proveedor" : "Supplier",
            filters: [...proveedorList],
            onFilter: (value: any, record: any) => record.proveedor.indexOf(value) === 0,
        },
        {
            title: language === 0 ? "Cantidad" : "Quantity",
            dataIndex: 'quantity',
            key: 'quantity',
            label: language === 0 ? "Cantidad" : "Quantity",
            sorter: (a: any, b: any) => a.quantity - b.quantity,
        },
        {
            title: language === 0 ? "Unidades" : "Units",
            dataIndex: 'unit',
            key: 'unit',
            label: language === 0 ? "Unidades" : "Units",
        },
        {
            title: language === 0 ? `Importe (${coin})` : `Price (${coin})`,
            dataIndex: 'price',
            key: 'price',
            label: language === 0 ? `Importe (${coin})` : `Price (${coin})`,
            sorter: (a: any, b: any) => a.price - b.price,
        },
        {
            title: language === 0 ? `Precio por Unidad (${coin})` : `Price per Unit (${coin})`,
            dataIndex: 'pricePerUnit',
            key: 'pricePerUnit',
            label: language === 0 ? `Precio por Unidad (${coin})` : `Price per Unit (${coin})`,
        }
    ];
}

const GetBills: React.FC<IStockStats> = ({ bills, language, basicProduct, coin }) => {
    if (bills.length) {
        const newBills = bills.map(e => ({ ...e, products: e.products.map(i => ({ ...i, unit: basicProduct!.find(e => e.name === i.product)?.unit, pricePerUnit: (i.price / i.quantity).toFixed(2) })) }))
        return (
            <Collapse style={{ maxWidth: 1200, margin: "auto", backgroundColor: "#c4dbf0" }}>
                {newBills.map(bill =>
                    <Collapse.Panel
                        style={{ borderColor: "white" }}
                        key={bill._id}
                        header={
                            <span className="spaceBetween">
                                <span>{bill.idBill}</span>
                                <span>
                                    <span><b>{language === 0 ? 'Fecha:' : "Date:"}</b> {bill.date}</span>
                                    <Popconfirm title={language === 0 ? 'Borrar Factura?' : "Sure to Delete?"} onConfirm={() => deleteBill(bill._id, language!)}>
                                        <a><DeleteOutlined style={{ color: "red", margin: "0px 7px" }} /></a>
                                    </Popconfirm>
                                </span>
                            </span>
                        }>
                        <DownloadExcel columns={getColumnsBills(bill, coin, language!)} dataSource={bill.products} name={language === 0 ? "Compras" : "Purchases"} />
                        <Table
                            style={{ overflowX: "auto" }}
                            columns={getColumnsBills(bill, coin, language!)}
                            dataSource={bill.products}
                            summary={pageData => {
                                let totalQuantity = 0
                                let totalPrice = 0

                                pageData.forEach(({ quantity, price }) => {
                                    totalQuantity += quantity;
                                    totalPrice += price;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} className="bold">{language === 0 ? "Total" : "Summary"}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={1}>-</Table.Summary.Cell>
                                            <Table.Summary.Cell index={2}>{totalQuantity}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={3}>-</Table.Summary.Cell>
                                            <Table.Summary.Cell index={4}>{totalPrice}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </Collapse.Panel>)}
            </Collapse>
        )
    }
    return <div className="p_10px">{language === 0 ? 'No se han registrado compras para las fechas seleccionadas' : 'No purchases have been registered for the selected dates.'}</div>
}

export default GetBills;