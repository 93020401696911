import React, { useState, } from 'react';
import { Card, Button, Row, Col, Tabs, Modal, message } from 'antd';
import CarouselInstructions from '../Menu/Views/CarouselInstructions';
import ListMenu from './ListMenu';
import { PlusSquareOutlined, EditTwoTone, LeftOutlined, RightOutlined, CheckOutlined, ExclamationCircleOutlined, DeleteTwoTone } from '@ant-design/icons';
import { ISections, IItems } from '../Basic/Utilities/interfaces';
import envLocal from '../../env-local';
import coinList from '../../Components/Basic/Utilities/coinList';
import { useRestaurant } from '../../Context/ContextRestaurant';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';

const { TabPane } = Tabs;
const { confirm } = Modal;

interface IProps {
  createItem: Function;
  deleteItem: Function;
  editSection: Function;
  getMenu: Function;
  menu: ISections[];
  dishes: IItems[];
  drinks: IItems[];
  currentTab: string;
  setCurrentTab: Function;
};

interface IMoveItem {
  position: number;
  newPosition: number;
};

const styleButton = { marginBottom: 5, paddingBottom: 5, color: "#1890FF", backgroundColor: "white" };
let coin: any;

const CompMenu: React.FC<IProps> = (props) => {
  const [menu, setMenu] = useState<Array<ISections>>(props.menu);
  const [moved, setMoved] = useState<boolean>(false);
  const { restaurant } = useRestaurant()!;
  coin = coinList.find(coin => coin.value === restaurant.coin)?.symbol;
  const { configContext } = useConfigContext()!;

  const deleteSection = (input: { idSection: any, idMenu?: string }) => {
    if (input.idSection === "dishes" || input.idSection === "drinks") {
      Modal.info({
        title: configContext.language === 0 ? 'Esta sección no se puede borrar. El cliente no la verá en la carta.' : "This section cannot be deleted. The customer will not see it on the letter."
      });
    } else {
      confirm({
        title: configContext.language === 0 ? '¿Quieres borrar la sección?' : "Do you want to delete the section?",
        icon: <ExclamationCircleOutlined />,
        okText: configContext.language === 0 ? 'Sí' : "Yes",
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          input.idMenu === undefined ? deleteItem(input.idSection) : deleteSectionMenu(input);
        },
        onCancel() {
        },
      });
    }
  };

  const deleteItem = async (idSection: string) => {
    await axiosCall("delete", envLocal.endpointSection, envLocal.authComp, "actions/" + idSection);
    await props.getMenu();
  };

  const deleteSectionMenu = async (input: { idSection: string; idMenu?: string; }) => {
    await axiosCall("post", envLocal.endpointSection, envLocal.authComp, "", input);
    props.setCurrentTab(input.idMenu);
    await props.getMenu();
  };

  const moveItem = (from: number, to: number) => {
    let f = menu.splice(from, 1)[0];
    menu.splice(to, 0, f);
  };

  const sectionMove = (item: IMoveItem) => {
    moveItem(item.position, item.newPosition);
    let newSections = menu.map((menu, index) =>
      Object.assign({}, menu, { positionSec: index })
    );
    setMenu(newSections);
    setMoved(true);
  };

  const saveMove = async (idSection: any) => {
    const newPositions = menu.map((section: ISections) => { return { idSection: section.idSection, positionSec: section.positionSec } });
    await axiosCall("put", envLocal.endpointSection, envLocal.authComp, "moveSection", { newPositions });
    props.setCurrentTab(idSection);
    props.getMenu();
    setMoved(false);
    message.success(configContext.language === 0 ? 'Guardada nueva posición!' : "Saved new position!");
  };
  return (
    <React.Fragment>
      <CarouselInstructions />
      <Tabs
        animated
        hideAdd
        type="editable-card"
        defaultActiveKey={props.currentTab}
        onEdit={(e) => deleteSection({ idSection: e })}
      >
        {menu.map((newCard: ISections, index: number) =>
          <TabPane style={{ margin: 0, padding: 5 }} tab={newCard.title} key={newCard.idSection}>
            <Card key={newCard.idSection}
              bodyStyle={{ padding: 5 }} headStyle={{ backgroundColor: "#26c6da", color: "white", fontSize: "140%", textAlign: "center", margin: 5 }}
              title={
                <Row>
                  <Col xs={{ span: 0 }} sm={{ span: 4 }}>
                    <Button value="small" icon={<EditTwoTone />} onClick={() => props.editSection({ idSection: newCard.idSection, title: newCard.title, sectionType: newCard.sectionType, itemsMenu: newCard.itemsMenu, menuPrice: newCard.menuPrice, additionalInfo: newCard.additionalInfo, menuStartHour: newCard.menuStartHour, menuEndHour: newCard.menuEndHour, menuValidDays: newCard.menuValidDays })}>{configContext.language === 0 ? 'Editar' : "Edit"}</Button>
                  </Col>
                  <Col xs={{ span: 4 }} sm={{ span: 0 }}>
                    <Button type="primary" style={{ backgroundColor: "white" }} icon={<EditTwoTone />} onClick={() => props.editSection({ idSection: newCard.idSection, title: newCard.title, sectionType: newCard.sectionType, itemsMenu: newCard.itemsMenu, menuPrice: newCard.menuPrice, additionalInfo: newCard.additionalInfo, menuStartHour: newCard.menuStartHour, menuEndHour: newCard.menuEndHour, menuValidDays: newCard.menuValidDays })} />
                  </Col>
                  <Col xs={{ span: 12 }} sm={{ span: 14 }}>{newCard.title}</Col>
                  <Col xs={{ span: 8 }} sm={{ span: 6 }}>
                    <Button value="small" icon={<LeftOutlined />} disabled={index === 0 ? true : false} onClick={() => sectionMove({ position: newCard.positionSec, newPosition: newCard.positionSec - 1 })}></Button>
                    <Button value="small" icon={<RightOutlined />} disabled={index === menu.length - 1 ? true : false} onClick={() => sectionMove({ position: newCard.positionSec, newPosition: newCard.positionSec + 1 })}></Button>
                    {moved && <Button value="small" icon={<CheckOutlined />} onClick={() => saveMove(newCard.idSection)} style={{ marginLeft: 10 }}></Button>}
                  </Col>
                </Row>}
            >
              <React.Fragment>
                {newCard.sectionType === 3 ?
                  <React.Fragment>
                    {newCard.itemsMenu.map((item: any, index: number) =>
                      <div key={index}>
                        <div className="heading p_5px">
                          <Row align="middle">
                            <Col xs={{ span: 0 }} sm={{ span: 6 }}><Button value="small" icon={<EditTwoTone />} onClick={() => props.editSection({ idSection: item.idSection, idSectionMenu: newCard.idSection, title: item.title, sectionType: item.sectionType, itemsMenu: item.itemsMenu })}>{configContext.language === 0 ? 'Editar' : "Edit"}</Button></Col>
                            <Col xs={{ span: 5 }} sm={{ span: 0 }}><Button type="primary" style={{ backgroundColor: "white" }} icon={<EditTwoTone />} onClick={() => props.editSection({ idSection: item.idSection, idSectionMenu: newCard.idSection, title: item.title, sectionType: item.sectionType, itemsMenu: item.itemsMenu })} /></Col>
                            <Col xs={{ span: 14 }} sm={{ span: 12 }}><div>{item.title}</div></Col>
                            <Col xs={{ span: 0 }} sm={{ span: 6 }}><Button value="small" icon={<DeleteTwoTone />} onClick={() => deleteSection({ idSection: item.idSection, idMenu: newCard.idSection })}>{configContext.language === 0 ? 'Borrar' : "Delete"}</Button></Col>
                            <Col xs={{ span: 5 }} sm={{ span: 0 }}><Button type="primary" style={{ backgroundColor: "white" }} icon={<DeleteTwoTone />} onClick={() => deleteSection({ idSection: item.idSection, idMenu: newCard.idSection })} /></Col>
                          </Row>
                        </div>
                        <ListMenu coin={coin} country={restaurant.country} sectionType={item.sectionType} dataSource={item.itemsMenu} rate={item.sectionType === 4 ? true : false} />
                        <Button style={styleButton} ghost type="primary" value="small" icon={<PlusSquareOutlined />} onClick={() => props.createItem({ idSection: item.idSection, sectionType: 3, sectionMenu: item.sectionType, idSectionMenu: newCard.idSection, sectionTitle: item.title, itemsMenu: item.itemsMenu })} block>{item.sectionType === 4 ? (configContext.language === 0 ? "Añadir Plato" : "Add Dish") : (configContext.language === 0 ? "Añadir Producto" : "Add Item")}</Button>
                      </div>)}
                    <div className="footerMenu pt_7px">{newCard.additionalInfo}</div>
                    <div className="footerMenu p_5px">{configContext.language === 0 ? "Precio: " : "Price: "}{newCard.menuPrice} {coin}</div>
                  </React.Fragment>
                  : <ListMenu coin={coin} country={restaurant.country} sectionType={newCard.sectionType} dataSource={newCard.itemsMenu} rate={newCard.sectionType === 1 ? true : false} />}
                {newCard.sectionType !== 3 ?
                  <Button ghost type="primary" value="small" icon={<PlusSquareOutlined />} onClick={() => props.createItem({ idSection: newCard.idSection, sectionType: newCard.sectionType, sectionTitle: newCard.title, itemsMenu: newCard.itemsMenu })} block>{newCard.sectionType === 1 ? (configContext.language === 0 ? "Añadir Plato" : "Add Dish") : (configContext.language === 0 ? "Añadir Producto" : "Add Item")}</Button>
                  : <Button style={styleButton} type="primary" value="small" icon={<PlusSquareOutlined />} onClick={() => props.createItem({ sectionType: 5, positionSec: 0, idSection: newCard.idSection })} block>{configContext.language === 0 ? "Añadir sección al Menú" : "Add section to Menu"}</Button>}
              </React.Fragment>
            </Card>
            <Button style={styleButton} type="primary" value="small" icon={<PlusSquareOutlined />} onClick={() => props.createItem({ sectionType: 4, positionSec: menu.length })} block>{configContext.language === 0 ? "Nueva Sección" : "New Section"}</Button>
          </TabPane>
        )}
        <TabPane style={styleButton} tab={<span><i className="fas fa-wine-glass-alt"></i> Bar</span>} key="drinks">
          <Card key="drinks"
            title={configContext.language === 0 ? "Zona del Bar" : " Bar Area"}
            bodyStyle={{ padding: 5 }} headStyle={{ backgroundColor: "#A9BCF5", color: "white", fontSize: "140%", textAlign: "center", margin: 5 }}>
            <ListMenu coin={coin} country={restaurant.country} isDefault={true} createItem={props.createItem} deleteItem={props.deleteItem} sectionType={2} dataSource={props.drinks} rate={false} />
            <Button ghost type="primary" value="small" icon={<PlusSquareOutlined />} onClick={() => props.createItem({ sectionType: 2 })} block>{configContext.language === 0 ? "Nuevo Producto" : "New Item"}</Button>
          </Card>
          <Button style={styleButton} type="primary" value="small" icon={<PlusSquareOutlined />} onClick={() => props.createItem({ sectionType: 4, positionSec: menu.length })} block>{configContext.language === 0 ? "Nueva Sección" : "New Section"}</Button>
        </TabPane>
        <TabPane style={{ margin: 0, padding: 5 }} tab={<span><i className="fas fa-hamburger"></i>{configContext.language === 0 ? " Cocina" : " Kitchen"}</span>} key="dishes">
          <Card key="dishes"
            title={configContext.language === 0 ? "Zona de Cocina" : "Kitchen Area"}
            bodyStyle={{ padding: 5 }} headStyle={{ backgroundColor: "#A9BCF5", color: "white", fontSize: "140%", textAlign: "center", margin: 5 }}>
            <ListMenu coin={coin} country={restaurant.country} isDefault={true} createItem={props.createItem} deleteItem={props.deleteItem} sectionType={1} dataSource={props.dishes} rate={true} />
            <Button ghost type="primary" value="small" icon={<PlusSquareOutlined />} onClick={() => props.createItem({ sectionType: 1 })} block>{configContext.language === 0 ? "Nuevo Plato" : "New Dish"}</Button>
          </Card>
          <Button style={styleButton} type="primary" value="small" icon={<PlusSquareOutlined />} onClick={() => props.createItem({ sectionType: 4, positionSec: menu.length })} block>{configContext.language === 0 ? "Nueva Sección" : "New Section"}</Button>
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};

export default CompMenu;