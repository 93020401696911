import React, { useState } from "react";
import { IStats, IGraph } from '../Components/Basic/Utilities/interfaces';
import { useWindowSize, axiosCall, parsedDateString } from '../Components/Basic/Utilities/tools';
import { BarHorizontal } from './Basic/Bar';
import { Button, DatePicker, Modal, ConfigProvider } from 'antd';
import { useConfigContext } from '../Context/ContextConfig';
import { SearchOutlined } from '@ant-design/icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import envLocal from '../env-local';
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';
import localeEn from 'antd/lib/locale/en_GB';

const { RangePicker } = DatePicker;

interface IProps extends RouteComponentProps, IGraph { }

const getDataTypeOrder = (input: { stats: IStats[], filterType: number, language: number }) => {
    let data: { name: string, quantity: number, type: number }[] = [];
    input.stats.forEach(item =>
        item.itemOrdered.map(element => {
            if (data.find(itemSaved => itemSaved.name === element.name) === undefined) {
                data.push({ name: element.name, quantity: element.quantity, type: element.type })
            } else {
                const tmp = data.map(itemMap => {
                    return itemMap.name === element.name ? { name: element.name, quantity: itemMap.quantity + element.quantity, type: element.type } : itemMap;
                })
                data = tmp;
            }
        })
    )

    if (input.filterType > 0) {
        const tmp = data.filter(item => item.type === input.filterType);
        data = tmp;
    };

    const ret = data.sort((a, b) => Number(b.quantity) - Number(a.quantity)).map(item => {
        return {
            "item": item.name,
            [input.language === 0 ? "Cantidad" : "Quantity"]: Number(item.quantity || 0)
        }
    });
    return ret;
}

let rangeDate: string = "";

const BarTotalItems: React.FC<IProps> = (props) => {
    const [filterType, setFilterType] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [stats, setStats] = useState<IStats[]>([]);
    const { configContext } = useConfigContext()!;
    const datas = getDataTypeOrder({ stats: props.data, filterType: filterType, language: configContext.language! });
    const datasModal = getDataTypeOrder({ stats, filterType: filterType, language: configContext.language! });
    const width = useWindowSize();

    const changeRange = async (inputDates: any, stringRange: any) => {
        rangeDate = stringRange;
        if (inputDates !== null) {
            if (inputDates[0] && inputDates[1]) {
                const response = await axiosCall("post", envLocal.endpointStats, envLocal.authComp, "findItemOrdered", { inputDates: [parsedDateString(inputDates[0]), parsedDateString(inputDates[1])] });
                setStats(response.data);
            };
        };
    };

    return (
        <div className="sectionSquareGraph" style={{ maxWidth: "unset", height: 200 + (datas.length * 50) }}>
            <div className="sectionSquareTitleGraph">
                <span>{props.title}</span>
                <span><Button icon={<SearchOutlined />} type='primary' onClick={() => setShowModal(true)}></Button></span>
            </div>
            <div className="sectionSquareBodyGraph">
                <div className="textCenter mt_15px">
                    <Button className="buttonFilter" type="primary" onClick={() => setFilterType(2)}>Bar</Button>
                    <Button className="buttonFilter" type="primary" onClick={() => setFilterType(1)}>{configContext.language === 0 ? "Comidas" : "Meals"}</Button>
                    <Button className="buttonFilter" type="primary" onClick={() => setFilterType(0)}>{configContext.language === 0 ? "Todos" : "All"}</Button>
                </div>
                <BarHorizontal {...props} margin={{ left: width[0] < 700 ? 220 : 260, right: width[0] < 700 ? 10 : 50, bottom: 110 }} dataTable={datas} />
                <Modal
                    title={configContext.language === 0 ? "Seleccione el rango a buscar" : "Select the range to search"}
                    visible={showModal}
                    onOk={() => props.history.push({
                        pathname: '/ToPrint',
                        state: { itemOrdered: datasModal, coin: props.coin, language: configContext.language, rangeDate, type: 4 }
                    })}
                    okText={configContext.language === 0 ? "Imprimir" : "Print"}
                    onCancel={() => setShowModal(false)}
                    zIndex={1000}
                >
                    <div className="section-to-print">
                        <div className="textCenter">
                            <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                                <RangePicker
                                    inputReadOnly
                                    style={{ marginBottom: 20, }}
                                    format="DD/MM/YYYY"
                                    onCalendarChange={(value, rangeStr) => {
                                        changeRange(value, rangeStr);
                                    }}
                                />
                            </ConfigProvider>
                        </div>
                        {datasModal.length ? datasModal.map((item, index) => <div className="ml_50px" key={index}>{item.item}:  <b>{item.Cantidad}</b></div>) : null}
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default withRouter(BarTotalItems);