import React from 'react';
import { Result } from 'antd';

interface Props {
    title: string;
    subTitle: string;
    goTo?: string;
}

export const Error500: React.FC<Props> = (props) => {

    return (
        <Result
            status="500"
            title={props.title}
            subTitle={props.subTitle}
            extra={props.goTo ? <a className="boldLarge" href="https://www.bliscop.app">Ir al inicio</a> : null}
        />
    )
}
