import React from "react";
import { ResponsivePie } from '@nivo/pie';
import { IGraph } from '../../Components/Basic/Utilities/interfaces';

const Pie: React.FC<IGraph> = (props) => {
    const { dataTable, margin, colors, coin } = props;
    return (
        <ResponsivePie
            data={dataTable}
            margin={{ top: margin?.top || 40, right: margin?.right || 120, bottom: margin?.bottom || 60, left: margin?.left || 120 }}
            colors={colors || { scheme: 'nivo' }}
            animate={true}
            innerRadius={0.6}
            padAngle={0.5}
            cornerRadius={5}
            radialLabel={d => `${d.id}: ${d.value} ${coin || ''}`}
            radialLabelsLinkColor="inherit"
            radialLabelsLinkStrokeWidth={3}
            radialLabelsTextColor="inherit:darker(1.2)"
            enableSlicesLabels={false}
        />
    )
};

export default Pie;