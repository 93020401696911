import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Row, Col, Input, Button, Form, Modal, message, Select, InputNumber, AutoComplete } from 'antd';
import envLocal from '../../env-local';
import coinList from '../../Components/Basic/Utilities/coinList';
import { ITokenAuthData, IStateCreateMenu, IConfig } from '../../Components/Basic/Utilities/interfaces';
import { axiosCall, getDataTokenAuth, getDataTokenOrder } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';
import { isAuthenticated } from '../../Components/Auth/AuthService';
import Header from '../../Components/Basic/Header';
import Politics from '../Common/Politics';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { SelectProps } from 'antd/es/select';

let idRestaurant: string;
let coin: number;
let telephone: number;
let manualValidation: boolean;
let preToken: string;
let pickOrders: boolean;
let homeOrders: boolean;
let discountForCome: boolean;
let discountValue: number;
let restaurantName: string;
let minConsumption: number;
let expiryDate: Date;
let menuOnlyRead: any = [];
let priceSpecialTable: number;
let specialTables: string;
let country: string;
let noShowRate: boolean;
let province: string;

const getCode = (value: any) => {
    let table: string = "";
    for (let i = value.length - 1; i > 0; i--) {
        if (isNaN(value[i])) {
            return { code: value.substring(0, i + 1).toLowerCase(), table }
        } else {
            table = value[i] + table;
        };
    }
};

interface FormProps {
    visible: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
};

const HomeClient: React.FC<RouteComponentProps> = (props) => {
    const [modalRemoteOrder, setModalRemoteOrder] = useState<boolean>(false);
    const [isHomeOrder, setIsHomeOrder] = useState<any>();
    const [dataTokenAuth] = useState<ITokenAuthData | null>(getDataTokenAuth());
    const { configContext, setConfigContext } = useConfigContext()!;
    const [orderParams, setOrderParms] = useState<IStateCreateMenu | null>(getDataTokenOrder());
    const [politicsModal, setPoliticsModal] = useState<boolean>(false);
    const [options, setOptions] = useState<SelectProps<object>['options']>([]);

    useEffect(() => {
        if (orderParams) evalStatusOrder();

        const code = new URLSearchParams(props.location.search).get("code");
        if (code) toMenu({ code });
    }, []);

    const evalStatusOrder = async () => {
        const response = await axiosCall("get", envLocal.endpointOrder, envLocal.tokenOrder);
        if (response.status === 401 || response.data.status > 1) {
            setOrderParms(null);
            window.localStorage.removeItem(envLocal.tokenOrder);
        }
    }

    const RemoteOrderForm: React.FC<FormProps> = ({
        visible,
        onCreate,
        onCancel,
    }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={configContext.language === 0 ? "Pedido a Domicilio / Recoger" : "Home Delivery / Pickup"}
                okText={configContext.language === 0 ? "Pedir" : "Order"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="remoteOrderForm"
                    initialValues={{ orderType: isHomeOrder || (pickOrders ? -1 : -2), email: dataTokenAuth?.email || '', telephone: dataTokenAuth?.telephone || '', address: dataTokenAuth?.address || '' }}
                >
                    <Form.Item
                        name="orderType"
                        label={configContext.language === 0 ? "Tipo de Pedido" : "Order Type"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Indica el tipo de pedido' : "Mark the type of order" }]}
                    >
                        <Select style={{ width: "250px" }} onChange={(e) => setIsHomeOrder(e)}>
                            {pickOrders && <Select.Option value={-1}>{configContext.language === 0 ? "Recogida en Restaurante" : "Pickup Order"}</Select.Option>}
                            {homeOrders && <Select.Option value={-2}>{configContext.language === 0 ? "Envío a Domicilio" : "Home Order"}</Select.Option>}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, type: 'email', message: configContext.language === 0 ? 'Introduce un Email' : 'Enter an Email' }]}
                    >
                        <Input placeholder={configContext.language === 0 ? "Tu Email" : "Your Email"} disabled={dataTokenAuth?.email !== undefined} />
                    </Form.Item>
                    <Form.Item
                        name="telephone"
                        label={configContext.language === 0 ? "Número de Teléfono" : "Phone Number"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce tu número de teléfono' : "Enter your phone number" }]}
                    >
                        <InputNumber maxLength={15} style={{ width: "150px" }} />
                    </Form.Item>
                    {isHomeOrder === -2 && <Form.Item
                        name="address"
                        label={configContext.language === 0 ? "Direción de Entrega" : "Delivery Address"}
                        rules={[{ required: isHomeOrder === -2 ? true : false, message: configContext.language === 0 ? 'Indica la Dirección donde se entragará' : 'Mark the address where it will be delivered' }]}
                    >
                        <Input maxLength={50} />
                    </Form.Item>}
                </Form>
            </Modal>
        );
    };

    const toMenu = async (value: any) => {
        const paramsRestaurant: any = getCode(value.code);
        if (paramsRestaurant === undefined || paramsRestaurant.code === undefined) {
            message.error(configContext.language === 0 ? "El texto introducido no es válido" : "The text entered is not valid");
        } else if (menuOnlyRead.find((menu: any) => menu.cod === paramsRestaurant.code)?.val === true) {
            const responseOnlyRead = (await axiosCall("get", envLocal.endpointRestaurant, "", "code/" + paramsRestaurant.code)).data;
            ({ idRestaurant, coin, manualValidation, telephone, preToken, pickOrders, homeOrders, discountForCome, discountValue, restaurantName, minConsumption, expiryDate, country, noShowRate, province } = responseOnlyRead);
            const state: IStateCreateMenu = {
                idRestaurant,
                email: "default",
                coin: coinList.find(coinList => coinList.value === coin)!.symbol,
                idTable: "0",
                idOrder: undefined,
                manualValidation,
                remoteOrder: false,
                discountValue: discountForCome ? discountValue : 0,
                preToken,
                idUser: dataTokenAuth?._id,
                restaurantName,
                minConsumption,
                menuOnlyRead: true,
                country,
                noShowRate
            }
            if (["Barcelona", "Girona", "Lérida", "Tarragona"].includes(province)) {
                setConfigContext({ isCatalan: true })
            } else {
                setConfigContext({ isCatalan: false })
            }
            props.history.push({
                pathname: '/Menu',
                state
            })
        } else if (paramsRestaurant.table === "") {
            message.error(configContext.language === 0 ? "No se ha indicado el número de mesa" : "Table number not indicated");
        } else {
            const responseData = (await axiosCall("get", envLocal.endpointRestaurant, "", "code/" + paramsRestaurant.code)).data;
            if (responseData.idRestaurant === 0) {
                message.warning(configContext.language === 0 ? "Restaurante no encontrado" : "Restaurant not found");
            } else if (paramsRestaurant.table > responseData.numTables) {
                message.warning(configContext.language === 0 ? "Mesa no encontrada" : "Table not found");
            } else if (!responseData.pickOrders && !responseData.homeOrders && Number(paramsRestaurant.table) === 0) {
                message.info(configContext.language === 0 ? "El restaurante actualmente no acepta pedidos externos" : "The restaurant currently does not accept outside orders");
            } else if ((responseData.pickOrders || responseData.homeOrders) && Number(paramsRestaurant.table) === 0 && !((responseData.remoteStartHour === undefined && responseData.remoteEndHour === undefined) || (responseData.remoteStartHour !== undefined && moment().hours() >= responseData.remoteStartHour) && (responseData.remoteEndHour !== undefined && moment().hours() < responseData.remoteEndHour))) {
                message.info(configContext.language === 0 ? `El horario para realizar los pedidos es de ${responseData.remoteStartHour} a ${responseData.remoteEndHour}` : `The schedule in which they accept orders is from ${responseData.remoteStartHour} to ${responseData.remoteEndHour}`);
            } else {
                ({ idRestaurant, coin, manualValidation, telephone, preToken, pickOrders, homeOrders, discountForCome, discountValue, restaurantName, minConsumption, expiryDate, priceSpecialTable, specialTables, country, noShowRate, province } = responseData);
                if (discountForCome && moment(expiryDate).endOf('day') < moment().add(1, 'days')) {
                    discountForCome = false;
                }
                if (Number(paramsRestaurant.table) === 0) {
                    if (dataTokenAuth?.email) {
                        setModalRemoteOrder(true);
                    } else {
                        message.warning(configContext.language === 0 ? "Tienes que ser usuario registrado para realizar pedidos externos" : "You must be a registered user to place external orders")
                    }

                } else {
                    const existOrder = (await axiosCall("post", envLocal.endpointExistsOrder, "", "", {
                        idRestaurant: responseData.idRestaurant,
                        idTable: paramsRestaurant.table
                    })).data;
                    if (existOrder) {
                        message.warning(configContext.language === 0 ? "Ya existe un pedido en esa mesa" : "There's already an order on that table");
                    } else {           
                        const state: IStateCreateMenu = {
                            idRestaurant,
                            email: "default",
                            coin: coinList.find(coinList => coinList.value === coin)!.symbol,
                            idTable: paramsRestaurant.table,
                            idOrder: undefined,
                            manualValidation,
                            remoteOrder: false,
                            discountValue: discountForCome ? discountValue : 0,
                            preToken,
                            idUser: dataTokenAuth?._id,
                            restaurantName,
                            minConsumption,
                            menuOnlyRead: false,
                            priceSpecialTable: (priceSpecialTable > 0 && specialTables?.split(",").map(e => e.trim().toLowerCase()).includes(paramsRestaurant.table?.toLowerCase())) ? priceSpecialTable : undefined,
                            country,
                            noShowRate
                        }
                        if (["Barcelona", "Girona", "Lérida", "Tarragona"].includes(province)) {
                            setConfigContext({ isCatalan: true })
                        } else {
                            setConfigContext({ isCatalan: false })
                        }
                        props.history.push({
                            pathname: '/Menu',
                            state
                        })
                    }
                }
            }
        };
    };

    const onCreate = (values: any) => {
        const state: IStateCreateMenu = {
            idRestaurant,
            email: values.email,
            coin: coinList.find(coinList => coinList.value === coin)!.symbol,
            idTable: values.orderType,
            idOrder: undefined,
            manualValidation: true,
            remoteOrder: true,
            telephone: values.telephone,
            address: values.address,
            telephoneRestaurant: telephone,
            discountValue: discountForCome ? discountValue : 0,
            preToken,
            idUser: dataTokenAuth?._id,
            restaurantName,
            minConsumption,
            menuOnlyRead: false,
            country,
            noShowRate
        }
        if (["Barcelona", "Girona", "Lérida", "Tarragona"].includes(province)) {
            setConfigContext({ isCatalan: true })
        } else {
            setConfigContext({ isCatalan: false })
        }
        props.history.push({
            pathname: '/Menu',
            state
        })
        setModalRemoteOrder(false);
    };

    const goLogin = (company: boolean) => {
        props.history.push({
            pathname: '/login',
            state: { company, originMainPage: true }
        })
    }

    const goProfile = () => {
        props.history.push('/profile')
    }

    const handleSearch = async (inputValue: string) => {
        if (inputValue && inputValue.length === 3) {
            setOptions(await searchResult(inputValue.toLowerCase()));
        } else {
            setOptions(options?.filter(opt => opt.value.includes(inputValue.toLowerCase())));
        }
    };

    const onSelect = (value: string) => {
        if (menuOnlyRead.find((menu: any) => menu.cod === value)?.val !== true) message.info(configContext.language === 0 ? "Añade el número de mesa" : "Add the table number");
    };

    const searchResult = async (query: string) => {
        const response = await axiosCall("get", envLocal.endpointRestaurant, "", "getCodes/" + query);
        menuOnlyRead = response.data.map((res: any) => { return { cod: res.orderCode, val: res.config.menuOnlyRead } });
        return response.data.map((code: { name: string, orderCode: string, config: IConfig }) => {
            return {
                value: code.orderCode,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <strong>{code.orderCode}</strong>
                        <span>{code.name}</span>
                    </div>
                ),
            };
        });
    };

    return (
        <div className="mb_30px">
            <Header />
            <div className="mt_15px">
                <div className="goPosLink">
                    <Button type="primary">
                        <a href="https://bliscop.app/mini">{configContext.language === 0 ? "Ir a TPV Comercios" : "Go to Retail POS"}</a>
                    </Button>
                </div>
                {dataTokenAuth ?
                    <div className="textCenter">{configContext.language === 0 ? "Bienvenido" : "Welcome"}<Button type="link" onClick={() => goProfile()}>{dataTokenAuth.username}</Button></div>
                    :
                    <div className="buttonsLogin">
                        <Button type="link" onClick={() => goLogin(false)}>{configContext.language === 0 ? "Iniciar Sesión" : "Sign in"}</Button>
                        {isAuthenticated() ? <Button className="buttonLoginRigth" type="link" onClick={() => props.history.push('/Home')}>{configContext.language === 0 ? "Mi Empresa" : "My company"}</Button>
                            : <Button className="buttonLoginRigth" type="link" onClick={() => goLogin(true)}>{configContext.language === 0 ? "Soy una Empresa" : "I'm a Company"}</Button>}
                    </div>}
            </div>
            {orderParams ?
                <>
                    <Row className="editMenuTitle">
                        <Col><p className="titleAnt_3 m_35x15x20">{configContext.language === 0 ? "Ir a mi pedido en curso" : "Go to my current order"}</p></Col>
                        <Button type="primary" htmlType="submit" onClick={() => props.history.push('/order')}>
                            {configContext.language === 0 ? "Mi Pedido" : "My Order"}
                        </Button>
                    </Row>
                </>
                :
                <>
                    <Row className="editMenuTitle">
                        <Col><p className="titleAnt_3 m_35x15x20">{configContext.language === 0 ? "Introduce el código del Restaurante:" : "Enter the restaurant code:"}</p></Col>
                    </Row>
                    <Row justify="center">
                        <Col xs={{ span: 20, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 10, offset: 0 }} lg={{ span: 6, offset: 0 }}>
                            <Form
                                name="basic"
                                onFinish={toMenu}
                            >
                                <Form.Item
                                    name="code"
                                    rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el código de pedido del Restaurante' : "Enter the order code of the Restaurant" }]}
                                    className="textCenter"
                                >
                                    <AutoComplete
                                        dropdownMatchSelectWidth={252}
                                        style={{ width: 300 }}
                                        options={options}
                                        onSearch={handleSearch}
                                        onSelect={onSelect}
                                    >
                                        <Input style={{ borderRadius: 8 }} prefix={<SearchOutlined className="site-form-item-icon" />} placeholder={configContext.language === 0 ? " Código del Pedido" : " Order Code"} />
                                    </AutoComplete>
                                </Form.Item>
                                <Form.Item className="textCenter">
                                    <Button type="primary" htmlType="submit">
                                        {configContext.language === 0 ? "Comenzar Pedido" : "Start Order"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        {configContext.cookiesAccepted === undefined && <div className="cookies">
                            <span>{configContext.language === 0 ? "Utilizamos cookies para asegurar que damos la mejor experiencia al usuario en nuestra web. Si sigues utilizando este sitio asumiremos que estás de acuerdo." : "We use cookies to ensure that we provide the best user experience on our website. If you continue to use this site we will assume that you agree."}</span>
                            <Button className="buttonCookies" type="primary" onClick={() => setConfigContext({ cookiesAccepted: true })}>{configContext.language === 0 ? "Vale" : "Ok"}</Button>
                        </div>}
                    </Row>
                </>
            }
            <RemoteOrderForm
                visible={modalRemoteOrder}
                onCreate={onCreate}
                onCancel={() => {
                    setModalRemoteOrder(false);
                }}
            />
            <Modal
                title={configContext.language === 0 ? "Condiciones" : "Conditions"}
                visible={politicsModal}
                onOk={() => setPoliticsModal(false)}
                okText={configContext.language === 0 ? "Cerrar" : "Close"}
                onCancel={() => setPoliticsModal(false)}
                cancelButtonProps={{ style: { display: "none" } }}
            >
                <Politics />
            </Modal>
        </div >
    )
};

export default HomeClient;