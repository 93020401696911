import React from 'react';

interface ISquare {
    squareStyle?: any;
    title: string;
    titleStyle?: any;
    body: any;
    bodyStyle?: any;
}

interface Props {
    squares: ISquare[];
    propClassName?: string;
}

const SquareInfo: React.FC<Props> = (props) => {

    return (
        <div className={props.propClassName !== undefined ? props.propClassName : "containerInfo"}>
            {props.squares.map((square, index) =>
                <div key={index} className="square" style={{...square.squareStyle}}>
                    <div className="squareTitle" style={{...square.titleStyle}}>{square.title}</div>
                    <div className="squareBody" style={{...square.bodyStyle}}>{square.body}</div>
                </div>
            )}
        </div>
    )
};

export default SquareInfo;