import React, { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Collapse, Popconfirm, Modal, ConfigProvider } from 'antd';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import envLocal from '../../env-local';
import NavBar from '../../Components/Basic/NavBar';
import { useRestaurant } from '../../Context/ContextRestaurant';
import { IStats } from '../../Components/Basic/Utilities/interfaces';
import { BarOrderTypeIncome } from '../../Graphs/BarOrderTypeIncome';
import { BarOrderTypeCount } from '../../Graphs/BarOrderTypeCount';
import BarTotalItems from '../../Graphs/BarTotalItems';
import LineIncome from '../../Graphs/LineIncome';
import { PieMenuIncome } from '../../Graphs/PieMenuIncome';
import { BarOrdersByHours } from '../../Graphs/BarOrdersByHours';
import coinList from '../../Components/Basic/Utilities/coinList';
import { axiosCall, parsedDateString } from '../../Components/Basic/Utilities/tools';
import moment from 'moment';
import { useConfigContext } from '../../Context/ContextConfig';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';
import localeEn from 'antd/lib/locale/en_GB';

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

interface IComment {
    _id: string,
    idRestaurant: string,
    comment: string,
    date: Date,
    idOrder: string
}

const Reports: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { restaurant } = useRestaurant()!;
    const [stats, setStats] = useState<Array<IStats>>([]);
    const [dates, setDates] = useState<any>([]);
    const [comments, setComments] = useState<IComment[]>([]);
    const [showModalOrder, setShowModalOrder] = useState<boolean>(false);
    const [orderModal, setOrderModal] = useState<{ name: string, quantity: string }[]>([]);
    const disabledDate = (current: any) => {
        if (!dates || dates.length === 0) { return false }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
        return tooEarly || tooLate;
    };
    const { configContext } = useConfigContext()!;
    const [dateFinded, setDateFinded] = useState<string>(configContext.language === 0 ? "Mostrando valores de Hoy" : "Showing Today's Values");
    const coin = coinList.find(coin => coin.value === restaurant.coin)?.symbol;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await getStats()
        await getComments()
        setIsLoading(false)
    };

    const getStats = async () => {
        setStats((await axiosCall("post", envLocal.endpointStats, envLocal.authComp, "", { inputDates: [] })).data);
    }

    const getComments = async () => {
        setComments((await axiosCall("get", envLocal.endpointSetValoration, envLocal.authComp, "getComments")).data);
    }

    const changeRange = async (inputDates: any) => {
        setDates(inputDates);
        if (inputDates !== null) {
            inputDates[0] && inputDates[1] && setStats((await axiosCall("post", envLocal.endpointStats, envLocal.authComp, "", { inputDates: [parsedDateString(inputDates[0]), parsedDateString(inputDates[1])] })).data);
            setDateFinded(moment(inputDates[0]?._d).format('DD/MM/YYYY') + " - " + moment(inputDates[1]?._d).format('DD/MM/YYYY'));
        };
    };

    const deleteComment = async (idComment: string) => {
        await axiosCall("delete", envLocal.endpointSetValoration, envLocal.authComp, `deleteComment/${idComment}`)
        await getComments()
    }

    const showOrder = async (idOrder: string) => {
        const order = await axiosCall("post", envLocal.endpointOrder, envLocal.authComp, `actions/${idOrder}`)
        if (order.status === 200) {
            setOrderModal(order.data.order)
            setShowModalOrder(true)
        }
    }

    return (
        <div className="basicContainerColor">
            <NavBar />
            <div className="titleSection">{configContext.language === 0 ? "Informes" : "Reports"}</div>
            {isLoading ?
                <LoadingSpin />
                :
                <div className="mb_30px">
                    <Row justify="center" style={{ display: "grid", textAlign: "center", marginTop: 15 }}>
                        <div className="mb_20px boldLarge">{dateFinded}</div>
                        <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                            <RangePicker
                                inputReadOnly
                                disabledDate={disabledDate}
                                onCalendarChange={value => {
                                    changeRange(value);
                                }}
                            />
                        </ConfigProvider>
                    </Row>
                    {stats.length ?
                        <div className="overflowHidden">
                            <div className="graphContainer">
                                <LineIncome
                                    title={configContext.language === 0 ? "Ingresos Totales" : "Total Revenue"}
                                    data={stats}
                                    coin={coin}
                                    leyendInfo={false}
                                    colors={['#97e3d5']}
                                />
                                <PieMenuIncome
                                    title={configContext.language === 0 ? "Ingresos Menú vs Carta" : "Revenue Menu vs A la Carte"}
                                    data={stats}
                                    coin={coin}
                                />
                            </div>
                            <div className="graphContainer">
                                <BarOrderTypeIncome
                                    title={configContext.language === 0 ? "Ingresos por Tipo de Pedido" : "Revenue by Order Type"}
                                    data={stats}
                                    keys={configContext.language === 0 ? ['Restaurante', 'Recogida', 'A domicilio'] : ['Restaurant', 'Pickup', 'Delivery']}
                                    indexBy="day"
                                    coin={coin}
                                />
                                <BarOrderTypeCount
                                    title={configContext.language === 0 ? "Conteo de Tipos de Pedido" : "Order Type Count"}
                                    data={stats}
                                    keys={configContext.language === 0 ? ['Restaurante', 'Recogida', 'A domicilio'] : ['Restaurant', 'Pickup', 'Delivery']}
                                    indexBy="day"
                                />
                            </div>
                            <div className="graphContainer">
                                <BarOrdersByHours
                                    title={configContext.language === 0 ? "Pedidos por Hora" : "Hourly Orders"}
                                    data={stats}
                                    keys={configContext.language === 0 ? ['Clientes'] : ['Clients']}
                                    indexBy={configContext.language === 0 ? "Hora" : "Hour"}
                                    colors={['#97e3d5']}
                                    leyendInfo={false}
                                />
                            </div>
                            <div className="graphContainer">
                                <BarTotalItems
                                    title={configContext.language === 0 ? "Conteo de Platos" : "Dish Count"}
                                    data={stats}
                                    keys={configContext.language === 0 ? ['Cantidad'] : ['Quantity']}
                                    indexBy="item"
                                    coin={coin}
                                    colors={['#97e3d5']}
                                    leyendInfo={false}
                                />
                            </div>
                        </div>
                        : <div className="titleAnt_3 textCenter pt_20px">{configContext.language === 0 ? "No existen datos aún" : "No data available yet"}</div>
                    }
                    {comments.length && <div className="sectionSquare">
                        <div className="sectionSquareTitle">{configContext.language === 0 ? "Comentarios de los Clientes" : "Customer Comments"}</div>
                        <div className="sectionSquareBody">
                            <Col xs={{ span: 24 }} md={{ span: 14 }} style={{ margin: "auto", marginTop: 30 }}>
                                <Collapse>
                                    {comments.map(com =>
                                        <Panel header={<span>
                                            <b>{moment(com.date).format("DD/MM/YYYY HH:mm")}</b>
                                            <span style={{ position: "absolute", right: 10 }}>
                                                <Popconfirm title={configContext.language === 0 ? '¿Ver pedido?' : "View order?"} onConfirm={() => showOrder(com.idOrder)}>
                                                    <a><SearchOutlined style={{ color: "#1890ff", fontSize: "large", margin: "0px 7px" }} /></a>
                                                </Popconfirm>
                                                <Popconfirm title={configContext.language === 0 ? '¿Eliminar Comentario?' : "Sure to delete?"} onConfirm={() => deleteComment(com._id)}>
                                                    <a><DeleteOutlined style={{ color: "red", fontSize: "large", margin: "0px 7px" }} /></a>
                                                </Popconfirm>
                                            </span>
                                        </span>}
                                            key={com.idOrder}>
                                            {com.comment}
                                        </Panel>
                                    )}
                                </Collapse>
                            </Col>
                        </div>
                    </div>}
                </div>
            }
            <Modal
                title={configContext.language === 0 ? "Pedido " : "Order "}
                visible={showModalOrder}
                onOk={() => setShowModalOrder(false)}
                okText={configContext.language === 0 ? "Aceptar" : "Accept"}
                onCancel={() => setShowModalOrder(false)}
                cancelButtonProps={{ style: { display: "none" } }}
            >
                {orderModal.map(order =>
                    <div key={order?.name}><b>x{order?.quantity}</b> {order?.name}</div>
                )}
            </Modal>
        </div>
    );
};

export default Reports;