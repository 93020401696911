import React from "react";
import LoadingSpin from './LoadingSpin';
import envLocal from '../../env-local';
import { axiosCall } from './Utilities/tools';
import { Modal } from 'antd';

export default function ReactPayPal({ price, language, productCode, idOrderBBDD }) {
  const [paid, setPaid] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const paypalRef = React.useRef();

  // To show PayPal buttons once the component loads
  React.useEffect(() => {
    let orderId;
    window.paypal
      .Buttons({
        createOrder: async (data, actions) => {
          const response = await axiosCall("post", envLocal.endpointPayments, envLocal.authComp, "paypal/init", { price })
          orderId = response.data
          if (response.status !== 200) setError(true)
          return orderId
        },
        onApprove: async (data, actions) => {
          setIsLoading(true)
          const responsePaypal = await axiosCall("post", envLocal.endpointPayments, envLocal.authComp, "paypal/pay", {
            orderId,
            language,
            productCode,
            idOrderBBDD
          })
          if (responsePaypal.status !== 200){
            setError(true)
          } else {
            setPaid(true);
            window.localStorage.removeItem(envLocal.authComp);
            Modal.success({
                onOk: () => window.location.href = "/",
                content: language === 0 ? 'Pago realizado con éxito' : "Successful payment"
            });
          }
          setIsLoading(false)
          return
        },
        onError: (err) => {
          axiosCall("post", envLocal.endpointError, "", "", err);
          setError(true)
        },
      })
      .render(paypalRef.current);
  }, []);

  // If is loading
  if (isLoading) {
    return <LoadingSpin />
  }

  // If the payment has been made
  if (paid) {
    return <div className="large bold mb_10px">{language === 0 ? "Pago exitoso!" : "Payment successful!"}</div>;
  }

  // If any error occurs
  if (error) {
    return <div className="large bold mb_10px">{language === 0 ? "Ha ocurrido un error. Inténtalo de nuevo" : "Error Occurred in processing payment! Please try again"}</div>;
  }

  // Default Render
  return <div ref={paypalRef} />
}