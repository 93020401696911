import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Bill from './Bill';
import { Button, Transfer, Modal, InputNumber } from 'antd';
import { decideOrderType, axiosCall } from './Utilities/tools';
import envLocal from '../../env-local';
import QR from './QR';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { confirm } = Modal;

class ComponentToPrint extends React.Component {
  state = { ticketNum: 0 };

  async componentDidMount() {
    if (this.props.theProps.restaurant) {
      const response = await axiosCall("post", envLocal.endpointRestaurant, envLocal.authComp, "currentTicket")
      if (response.status === 200) this.setState({ ticketNum: response.data.ticketNum })
    }
  }

  render() {
    const { restaurant, coin, billModal, language, stats, rangeDate, itemOrdered, numTables, orderCode, clientName } = this.props.theProps;
    const { billOrder, type } = this.props;

    if (type === 1) {
      const isCostaRica = restaurant.country === "Costa Rica"
      return (
        <div>
          {billOrder.map(bill =>
            <div className="m_20px colorBlack">
              <p className="title colorBlack">{restaurant.name}</p>
              <div style={{ textAlign: "center", fontSize: "110%" }}>
                <div>{restaurant.street} {restaurant.number}</div>
                <div>{restaurant.postalCode} {restaurant.city}</div>
                <div>{language === 0 ? "Teléfono: " : "Telephone: "}{restaurant.telephone}</div>
                <div className="m_10px"><b>{language === 0 ? "Pedido " : "Order "}{decideOrderType({ idTable: billModal.idTable, language })}</b></div>
              </div>
              {billModal && <Bill order={bill} tip={billModal.tip} coin={coin} discount={billModal.discountValueToApply} priceSpecialTable={billModal.priceSpecialTable} />}
              <div className='mt_20px'>
                <div><b>{isCostaRica ? "Factura" : "Ticket"} Nº: </b>{this.state.ticketNum}</div>
                {restaurant.numIdRestaurant && <div><b>{language === 0 ? "Número de identificacion" : "Identification number"}: </b>{restaurant.numIdRestaurant}</div>}
                <div><b>{language === 0 ? "Fecha" : "Date"}: </b>{moment().format("DD/MM/YYYY HH:mm")}</div>
                {clientName && <div><b>{language === 0 ? "Cliente" : "Client"}: </b>{clientName}</div>}
              </div>
            </div>
          )}
        </div>
      )
    }

    if (type === 2) {
      return (
        <div className="m_20px colorBlack">
          <QR numTables={numTables} language={language} orderCode={orderCode} />
        </div>
      )
    }

    if (type === 3) {
      return (
        <div className="m_20px textCenter colorBlack">
          <div className="boldLarge">{language === 0 ? "Facturación" : "Billing"}</div>
          <div className="boldLarge mb_20px">{rangeDate.join(" - ")}</div>
          {stats.length ? stats.map(date => <div key={date.day}><b>{date.day}:  </b>{date.totalIncome.toFixed(2) + ' ' + coin}</div>) : null}
          <br />
          {stats.length ? <div><b>Total: </b>{stats.reduce(function (s, a) { return s + Number(a.totalIncome) }, 0).toFixed(2) + coin}</div> : null}
        </div>
      )
    }

    if (type === 4) {
      return (
        <div className="m_20px textCenter colorBlack">
          <div className="boldLarge">{language === 0 ? "Productos Solicitados" : "Items Ordered"}</div>
          <div className="boldLarge mb_20px">{rangeDate.join(" - ")}</div>
          {itemOrdered.length ? itemOrdered.map((item, index) => <div key={index}>{item.item}:  <b>{item.Cantidad}</b></div>) : null}
        </div>
      )
    }
  }
}

let quantityChange = 0

const ToPrint = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [billOrderPrint, setBillOrderPrint] = React.useState([props.location.state.billModal?.order], [])
  const [targetKeys, setTargetKeys] = React.useState([]);
  const [splitBillModal, setSplitBillModal] = React.useState(false);
  const [billOrder, setBillOrder] = React.useState(JSON.parse(JSON.stringify(props.location.state.billModal?.order || [])).map(e => ({ ...e, key: e._id, title: e.name })));

  const onChange = (nextTargetKeys, direction, currentKey) => {
    let originalBillOrder = []
    let newBillOrder = []
    billOrder.forEach(order => {
      if (nextTargetKeys.includes(order.key)) {
        if (currentKey[0] === order.key && order.quantity > 1) {
          confirm({
            title: props.location.state.language === 0 ? '¿Quieres separar los productos?' : "Do you want to separate the products?",
            icon: <InfoCircleOutlined />,
            okText: "Ok",
            okType: 'danger',
            cancelText: props.location.state.language === 0 ? 'Cancelar' : "Cancel",
            content:
              <>
                <div>{props.location.state.language === 0 ? 'Indica cuántos quieres en la nueva cuenta' : "Indicate how many"}</div>
                <div style={{ textAlign: "center", margin: "15px 0px" }}>
                  <InputNumber min={0} max={order.quantity} onChange={e => quantityChange = e} />
                </div>
                <div>{props.location.state.language === 0 ? 'Solo puedes dividirlos una vez' : "You can only split them once"}</div>
              </>,
            onOk() {
              if (quantityChange > 0) {
                const remainingQuantity = order.quantity - quantityChange
                let newOldItem
                if (remainingQuantity > 0) {
                  newOldItem = { ...order, key: Date.now(), quantity: remainingQuantity, disabled: true }
                  originalBillOrder.push(newOldItem)
                }
                const newItem = { ...order, quantity: quantityChange, disabled: true }
                newBillOrder.push(newItem)
                setBillOrder([...billOrder.filter(e => e.key !== order.key), newOldItem, newItem].filter(Boolean))
              } else {
                newBillOrder.push(order)
              }
            },
            onCancel() {
              newBillOrder.push(order)
            },
          });
        } else {
          newBillOrder.push(order)
        }
      } else {
        originalBillOrder.push(order)
      }
    });
    quantityChange = 0
    setTargetKeys(nextTargetKeys);
    setBillOrderPrint([originalBillOrder, newBillOrder])
  };

  return (
    <div>
      <ComponentToPrint type={props.location.state.type} theProps={props.location.state} billOrder={billOrderPrint} ref={componentRef} />
      <div className="mt_30px mb_40px textCenter">
        <div className="mb_30px boldLarge">{props.location.state.language === 0 ? 'Revisa el ticket antes de imprimirlo' : "Check the ticket before you print it"}</div>
        <div className="buttonsPrint">
          {props.location.state.restaurant && <Button type="primary" onClick={() => setSplitBillModal(true)}>{props.location.state.language === 0 ? 'Dividir Cuenta' : "Split Account"}</Button>}
          <Button type="primary" onClick={handlePrint}>{props.location.state.language === 0 ? 'Imprimir' : "Print"}</Button>
          <Button type="danger" onClick={() => window.history.back()}>{props.location.state.language === 0 ? 'Cancelar' : "Cancel"}</Button>
        </div>
      </div>
      <Modal
        title={props.location.state.language === 0 ? "Mueve los pedido para crear una nueva cuenta" : "Move the orders to create a new account"}
        visible={splitBillModal}
        onOk={() => setSplitBillModal(false)}
        okText={props.location.state.language === 0 ? "Cerrar" : "Close"}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setSplitBillModal(false)}
      >
        <div className="displayTable marginAuto">
          <Transfer
            className="splitBill"
            dataSource={billOrder}
            titles={props.location.state.language === 0 ? ['Original', 'Nueva'] : ['Original', 'New']}
            targetKeys={targetKeys}
            onChange={onChange}
            render={item => `x${item.quantity} ${item.title}`}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ToPrint;