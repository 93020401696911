import React from 'react';
import { Row, Col, Form, Input, InputNumber, Button, Tooltip, message } from 'antd';
import { layout } from '../../Basic/Utilities/styles';
import envLocal from '../../../env-local';
import { IItems, IRestaurant } from '../../../Components/Basic/Utilities/interfaces';
import { axiosCall } from '../../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../../Context/ContextConfig';
import OptionLisProducts from './OptionLisProducts';
import { QuestionCircleTwoTone } from '@ant-design/icons';

const { TextArea } = Input;

interface IProps {
  idSection?: string;
  getDrinks: Function;
  item?: IItems;
  restaurant: IRestaurant;
  setShowModalView: Function;
};

const Drink: React.FC<IProps> = (props) => {
  const { configContext } = useConfigContext()!;

  const onFinish = async (values: any) => {
    const newItem = { ...values, type: 2, rate: 0 };
    if (props.item?._id !== undefined) {

      const restStatus = (await axiosCall("put", envLocal.endpointItemMenu, envLocal.authComp, props.item._id, newItem)).status;
      if (restStatus === 200) {
        message.success(configContext.language === 0 ? "Producto actualizada con éxito!" : "Item updated successfully!");
      } else {
        message.error("Error");
      }
    } else {
      const restStatus = (await axiosCall("post", envLocal.endpointItemMenu, envLocal.authComp, "", newItem)).status;
      if (restStatus === 200) {
        message.success(configContext.language === 0 ? "Producto creada con éxito!" : "Item created with success!")
      } else {
        message.error("Error");
      }
    };
    await props.getDrinks();
    props.setShowModalView(false);
  };

  return (
    <div className="pt_3em">
      <Row className="editMenuTitle">
        <Col><p className="titleAnt_3">{configContext.language === 0 ? "Rellena los campos para guardar el producto" : "Fill in the fields to save the item"}</p></Col>
      </Row>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
          <Form {...layout}
            onFinish={onFinish}
            initialValues={{ ...props.item }}>
            <Form.Item
              label={configContext.language === 0 ? 'Nombre del Producto' : 'Item Name'}
              name='name'
              rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el nombre del producto de la carta' : 'Enter the name of the item from the menu' }]}
            >
              <Input maxLength={35} />
            </Form.Item>
            <Form.Item
              label={configContext.language === 0 ? 'Precio' : 'Price'}
              name='price'
              rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el precio del producto' : 'Enter the price of the item' }]}
            >
              <InputNumber min={0} maxLength={5} />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  {configContext.language === 0 ? 'Suplemento en menú' : 'Supplement to menu'}&nbsp;
                <Tooltip title={configContext.language === 0 ? 'Si se incluye en el menú, este plato tendrá un suplemento extra de la cantidad indicada' : 'If included in the menu, this dish will have an extra supplement of the indicated amount'}>
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </span>
              }
              name='supplementMenu'
            >
              <InputNumber min={0} maxLength={5} />
            </Form.Item>
            <Form.Item
              label={configContext.language === 0 ? 'Descripción del producto' : 'Item description'}
              name='description'
            >
              <TextArea maxLength={100} placeholder={configContext.language === 0 ? 'Opcional' : 'Optional'} />
            </Form.Item>
            <OptionLisProducts language={configContext.language!} />
            <Form.Item>
              <Col xs={{ span: 8, offset: 8 }} sm={{ span: 1, offset: 24 }}>
                <Button type='primary' htmlType='submit'>
                  {configContext.language === 0 ? 'Guardar' : 'Save'}
                </Button>
              </Col>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
};

export default Drink;
