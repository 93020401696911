import React, { useState, useEffect, useCallback } from 'react';
import NavBar from '../../Components/Basic/NavBar';
import { Input, Button, Row, Col, message, List, Popover, Modal, InputNumber, Form, Dropdown, Menu, Select, Collapse, DatePicker, Radio, ConfigProvider } from 'antd';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import { CloseOutlined, ExclamationCircleOutlined, PlusOutlined, FormOutlined, CheckOutlined, DollarOutlined } from '@ant-design/icons';
import envLocal from '../../env-local';
import coinList from '../../Components/Basic/Utilities/coinList';
import socketIOClient from "socket.io-client";
import { RouteComponentProps } from 'react-router-dom';
import { useRestaurant } from '../../Context/ContextRestaurant';
import { IUsers, IStateCreateMenu, IBasicProduct, IMenuOrder, ITask } from '../../Components/Basic/Utilities/interfaces';
import moment from 'moment';
import { decideOrderType, axiosCall, groupBy, round, getTotalPriceFromOrder } from '../../Components/Basic/Utilities/tools';
import { useUser } from '../../Context/ContextUser';
import { useConfigContext } from '../../Context/ContextConfig';
import Bill from '../../Components/Basic/Bill';
import SquareInfo from '../../Components/Basic/SquareInfo';
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';
import localeEn from 'antd/lib/locale/en_GB';

const { confirm } = Modal;
const { Panel } = Collapse;

interface IStatusComplete {
    idTable: string;
    pendingCook: IPendingCook[];
}

const StatusComplete = ({ idTable, pendingCook }: IStatusComplete) => {
    if (!["-2", "-1", "0"].includes(idTable)) {
        const pending = pendingCook.filter(e => e.table === idTable && e.total)
        if (pending.length) {
            return <span className="boldLarge textCenter">{`${Math.round(pending.reduce((s: number, a: any) => { return s + Number(a.complete) }, 0))} / ${Math.round(pending.reduce((s: number, a: any) => { return s + Number(a.total) }, 0))}`}</span>
        } else {
            return <span className="boldLarge textCenter">100%</span>
        }
    } else {
        return null
    }
}

const calculateTotalPrice = (order: IOrders) => getTotalPriceFromOrder(order.order, false)

const setPaymentType = (finisedOrders: IOrders[]) => {
    let objPayment: ISummaryPayments = { cash: 0, card: 0, ticket: 0, other: 0, und: 0, total: 0 }
    finisedOrders.forEach(order => {
        switch (order.orderTypePayment) {
            case 1:
                objPayment.cash += calculateTotalPrice(order)
                break;
            case 2:
                objPayment.card += calculateTotalPrice(order)
                break;
            case 3:
                objPayment.ticket += calculateTotalPrice(order)
                break;
            case 4:
                objPayment.other += calculateTotalPrice(order)
                break;
            default:
                objPayment.und += calculateTotalPrice(order)
                break;
        }
        objPayment.total += calculateTotalPrice(order)
    });
    return objPayment
}

const getPaymentType = (language: number, paymentType?: number) => {
    switch (paymentType) {
        case 1:
            return language === 0 ? "Efectivo" : "Cash"
        case 2:
            return language === 0 ? "Tarjeta" : "Card"
        case 3:
            return language === 0 ? "Ticket Restaurante" : "Ticket Restaurant"
        case 4:
            return language === 0 ? "Otro" : "Other"
        default:
            return language === 0 ? "Indicar tipo de pago" : "Choose payment yype"
    }
}

interface IOrders {
    _id: string;
    idRestaurant: string;
    idTable: string;
    createdAt: Date;
    order: IMenuOrder[];
    observations: string;
    remoteOrder: boolean;
    telephone?: number;
    address?: string;
    email?: string;
    discountValueToApply?: number;
    tip?: number;
    idDiscount?: string;
    priceSpecialTable?: number;
    orderTypePayment?: number;
};

interface IModal {
    [key: string]: IMenuOrder[];
};

interface IPendingCook {
    table: string,
    complete: number | undefined,
    total: number | undefined
}

interface ISummaryPayments {
    cash: number,
    card: number,
    ticket: number,
    other: number,
    und: number,
    total: number
}

let socket: any;
let idTask: string;
let idTaskNew: string;
let coin: any;
let orderValidateModal: IOrders;
let orderLocation: string;
const defaultTableOrder = { idOrder: "", desiredTable: "" };
let newTableOrder: { idOrder: string, desiredTable: string } = defaultTableOrder;
let orderTypePayment: number | undefined;
let summaryPayments: ISummaryPayments = { cash: 0, card: 0, ticket: 0, other: 0, und: 0, total: 0 }
let orderToSetPaymentType: IOrders | undefined;
const audioNewTask = new Audio("/audio/newtask.mp3")

interface CollectionCreateFormProps {
    visible: boolean;
    onCreate: (values: any) => void;
    onCancel: () => void;
}

interface IItemsDeduced {
    idItem: string;
    quantity: number;
}

let users: IUsers[] = [];

const getWidth = (type: string, width: number) => {
    if (type === 'orders') {
        if (width > 1150) {
            return "70%"
        }
        if (width > 690) {
            return "60%"
        }
        return "100%"
    }
    if (type === 'tasks') {
        if (width > 1150) {
            return "25%"
        }
        if (width > 690) {
            return "35%"
        }
        return "100%"
    }
    if (type === 'inventory') {
        if (width > 1150) {
            return "45%"
        }
        if (width > 690) {
            return 200
        }
        return "100%"
    }
}

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

const HomeCompany: React.FC<RouteComponentProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [orders, setOrders] = useState<Array<IOrders>>([]);
    const [inputTask, setInputTask] = useState<IOrders>();
    const [showOrder, setShowOrder] = useState<string>("-");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalOrder, setModalOrder] = useState<IModal>();
    const [showBillModal, setShowBillModal] = useState<boolean>(false);
    const [billModal, setBillModal] = useState<IOrders>();
    const [visible, setVisible] = useState<boolean>(false);
    const [visibleActions, setVisibleActions] = useState<boolean>(false);
    const { restaurant } = useRestaurant()!;
    const [ordersFinded, setOrdersFinded] = useState<Array<IOrders>>([]);
    const [orderToValidate, setOrderToValidate] = useState<IOrders>();
    const [orderToValidateList, setOrderToValidateList] = useState<Array<IOrders>>([]);
    const [showValidateModal, setShowValidateModal] = useState<boolean>(false);
    const [timeToPrepare, setTimeToPrepare] = useState<any>(0);
    const { user } = useUser()!;
    const { configContext, setConfigContext } = useConfigContext()!;
    const [dateSelected, setDateSelected] = useState<string>(configContext.language === 0 ? "Hoy" : "Today");
    const [basicProduct, setBasicProduct] = useState<Array<IBasicProduct>>([]);
    const [tasks, setTasks] = useState<Array<ITask>>([]);
    let widthSize = window.innerWidth;
    const [tip, setTip] = useState<number | undefined>();
    const [discountBill, setDiscountBill] = useState<number | undefined>();
    const [pendingCook, setPendingCook] = useState<IPendingCook[]>([])
    const [showChangeTableModal, setShowChangeTableModal] = useState<boolean>(false);
    const [showCheckboxBill, setShowCheckboxBill] = useState<boolean>(false);
    const [showInputNumber, setShowInputNumber] = useState<boolean>(false);
    const [itemsBillChecked, setItemsBillChecked] = useState<string[]>([]);
    const [itemsBillDeduced, setItemsBillDeduced] = useState<IItemsDeduced[]>([]);
    const [viewInvoicing, setViewInvoicing] = useState<boolean>(false);
    const [showPaymentTypeModal, setShowPaymentTypeModal] = useState<boolean>(false);
    const [clientName, setClientName] = useState<string>("")

    const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
        visible,
        onCreate,
        onCancel,
    }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={configContext.language === 0 ? 'Indica el tipo de pedido' : "Select type of order"}
                okText={configContext.language === 0 ? "Pedir" : "Order"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modalOrder"
                >
                    <Form.Item name="radioGroup">
                        <Radio.Group onChange={(e) => orderLocation = e.target.value.toString()}>
                            <Radio value="barra" style={radioStyle}>{configContext.language === 0 ? "Servir en la Barra" : "Serve at the Bar"}</Radio>
                            <Radio value="pickUp" style={radioStyle}>{configContext.language === 0 ? "Recogida en Restaurante" : "Pick Up Order"}</Radio>
                            <Radio value="delivery" style={radioStyle}>{configContext.language === 0 ? "Pedido a Domicilio" : "Delivery "}</Radio>
                            <Radio value="mesa" style={radioStyle}>{configContext.language === 0 ? "Servir en la Mesa" : "Serve at the Table"}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.radioGroup !== curValues.radioGroup}>
                        {() => {
                            return (
                                <Form.Item
                                    name="table"
                                    label={configContext.language === 0 ? "Introduce la Mesa" : "Introduce the Table"}
                                    rules={[{ required: orderLocation === "mesa", message: configContext.language === 0 ? 'Introduce el código de la mesa' : "Enter the table code" }]}
                                    style={{ display: orderLocation === "mesa" ? "" : "none" }}
                                >
                                    <Input maxLength={10} style={{ width: 100 }} />
                                </Form.Item>
                            )
                        }}
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const CollectionCreateFormTask: React.FC<CollectionCreateFormProps> = ({
        visible,
        onCreate,
        onCancel,
    }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={configContext.language === 0 ? "Introduce la tarea a realizar" : "Enter the task to be done"}
                okText={configContext.language === 0 ? "Guardar" : "Save"}
                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modalTask"
                >
                    <Form.Item
                        name="task"
                        label={configContext.language === 0 ? "Tarea" : "Task"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce el tipo de tarea' : "Enter task type" }]}
                    >
                        <Input maxLength={15} />
                    </Form.Item>
                    <Form.Item
                        name="observations"
                        label={configContext.language === 0 ? "Detalle de la tarea" : "Task detail"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce en qué consiste la tarea' : "Introduce what the task is" }]}
                    >
                        <Input maxLength={60} />
                    </Form.Item>
                    <Form.Item
                        name="priority"
                        label={configContext.language === 0 ? "Prioridad" : "Priority"}
                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce la prioridad de la tarea' : 'Enter task priority' }]}
                    >
                        <Select><Select.Option value={2}>{configContext.language === 0 ? "Prioritaria" : "Priority"}</Select.Option><Select.Option value={1}>{configContext.language === 0 ? "No Prioritaria" : "No Priority"}</Select.Option></Select>
                    </Form.Item>
                    <Form.Item
                        label={configContext.language === 0 ? 'Asignar a Empleado' : "Assign Employee"}
                        name='employee'
                    >
                        <Select>{users.map(user => <Select.Option key={user._id} value={user.username}>{user.username}</Select.Option>)}</Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    useEffect(() => {
        if (restaurant._id === undefined || restaurant._id === "pending") {
            props.history.push('/RestaurantData');
        }
    }, []);

    useEffect(() => {
        if (restaurant._id !== undefined && restaurant._id !== "pending") {
            if (["Barcelona", "Girona", "Lérida", "Tarragona"].includes(restaurant.province)) {
                setConfigContext({ isCatalan: true })
            } else {
                setConfigContext({ isCatalan: false })
            }
            [1, 4, 5].includes(user.userType) && getBasicProducts();
            getTask();
            fetchData();
            coin = coinList.find(coin => coin.value === restaurant.coin)?.symbol;
            socket = socketIOClient(envLocal.endpointSocketServer, { transports: ['websocket'], query: { token: window.localStorage.getItem(envLocal.authComp) } });
            socket.emit("joinRoom", restaurant._id);
            socket.on("newOrder", (item: IOrders) => {
                if (item._id !== idTask) {
                    idTask = item._id;
                    setInputTask(item);
                };
            });
            socket.on("orderToValidate", (item: IOrders) => {
                if (item._id !== idTaskNew) {
                    idTaskNew = item._id;
                    setOrderToValidate(item);
                };
            });
            socket.on("updateOrderCompleted", () => {
                fetchData();
            });
            return () => {
                socket.disconnect();
            };
        }
    }, [restaurant]);

    useEffect(() => {
        if (inputTask !== undefined) {
            setOrders([...orders, inputTask]);
            [1, 4, 5].includes(user.userType) && getBasicProducts();
            getTask();
        };
    }, [inputTask]);

    useEffect(() => {
        if (orderToValidate !== undefined) {
            setOrderToValidateList([...orderToValidateList, orderToValidate]);
            audioNewTask.play()
        };
    }, [orderToValidate]);

    const fetchData = async () => {
        const fetchOrders = getOrders();
        const fetchFindClosedOrders = findOrders();
        await Promise.all([fetchOrders, fetchFindClosedOrders])
        setIsLoading(false);
    };

    const getOrders = async () => {
        const response = (await axiosCall("post", envLocal.endpointOrder, envLocal.authComp, "find/OrdersByStatus", { status: [-1, 1] })).data;
        setOrders(response.filter((data: any) => data.status === 1));
        setOrderToValidateList(response.filter((data: any) => data.status === -1));
    };

    const findOrders = async (dateInput?: any) => {
        let low = moment().startOf('day').toDate()
        let high = moment().endOf('day').toDate()
        if (dateInput) {
            low = dateInput.startOf('day').toDate()
            high = dateInput.endOf('day').toDate()
        }
        const response = (await axiosCall("post", envLocal.endpointOrderDate, envLocal.authComp, "", {
            idRestaurant: restaurant._id,
            low,
            high
        }))
        if (response.status === 200) setOrdersFinded(response.data);
    };

    const moreFood = (input: IOrders) => {
        confirm({
            title: configContext.language === 0 ? '¿Quieres pedir algo más?' : "Do you want to ask for anything else?",
            icon: <ExclamationCircleOutlined />,
            okText: configContext.language === 0 ? 'Sí' : "Yes",
            okType: 'primary',
            cancelText: 'No',
            zIndex: 2000,
            onOk() {
                const state: IStateCreateMenu = {
                    idRestaurant: restaurant._id,
                    email: "internal",
                    coin,
                    idTable: input.idTable,
                    idOrder: input._id,
                    manualValidation: false,
                    remoteOrder: input.remoteOrder,
                    origin: '/Home',
                    menuOnlyRead: false,
                    idDiscount: input.idDiscount,
                    priceSpecialTable: input.priceSpecialTable,
                    country: restaurant.country
                }
                props.history.push({
                    pathname: '/Menu',
                    state
                })
            },
            onCancel() {
            },
        });
    };

    const modifyQuantities = (input: { request: IMenuOrder[], idOrder: string }) => {
        setModalOrder({ [input.idOrder]: input.request });
        setShowModal(true);
    };

    const updateOrder = (value: any) => {
        const tmpOrder = modalOrder;
        tmpOrder![value.idOrder][value.position].quantity = value.quantity;
        tmpOrder![value.idOrder][value.position].modified = true;
        setModalOrder(tmpOrder);
    };

    const saveOrder = () => {
        socket.emit("updateOrderHome", modalOrder);
        socket.emit("synchronizeHome", restaurant._id);
        setShowModal(false);
        message.success(configContext.language === 0 ? "Pedido Modificado" : "Modified Order");
    };

    const onCreate = async (values: any) => {
        if (values.radioGroup === undefined) {
            message.error(configContext.language === 0 ? "Tienes que indicar donde irá el pedido" : "You have to indicate where the order will go");
        } else {
            if (values.radioGroup === "pickUp" || values.radioGroup === "delivery") {
                const state: IStateCreateMenu = {
                    idRestaurant: restaurant._id,
                    email: "internal",
                    coin,
                    idTable: values.radioGroup === "pickUp" ? "-1" : "-2",
                    idOrder: undefined,
                    manualValidation: false,
                    remoteOrder: true,
                    origin: '/Home',
                    menuOnlyRead: false,
                    country: restaurant.country
                }
                props.history.push({
                    pathname: '/Menu',
                    state
                })
                setVisible(false);
            } else {
                const state: IStateCreateMenu = {
                    idRestaurant: restaurant._id,
                    email: "internal",
                    coin,
                    idTable: values.radioGroup === "mesa" ? values.table : "0",
                    idOrder: undefined,
                    manualValidation: false,
                    remoteOrder: false,
                    origin: '/Home',
                    menuOnlyRead: false,
                    priceSpecialTable: (restaurant.config.priceSpecialTable > 0 && restaurant.config.specialTables?.split(",").map((e: string) => e.trim().toLowerCase()).includes(values.table?.toLowerCase())) ? restaurant.config.priceSpecialTable : undefined,
                    country: restaurant.country
                }
                if (values.radioGroup === "barra") {
                    props.history.push({
                        pathname: '/Menu',
                        state
                    })
                    setVisible(false);
                } else {
                    const existOrder = (await axiosCall("post", envLocal.endpointExistsOrder, "", "", {
                        idRestaurant: restaurant._id,
                        idTable: values.table
                    })).data;
                    if (existOrder) {
                        message.warning(configContext.language === 0 ? 'Ya existe un pedido para esa mesa. Ciérralo antes!' : "There's already an order for that table. Close it first!");
                    } else {
                        props.history.push({
                            pathname: '/Menu',
                            state
                        })
                        setVisible(false);
                    }
                }
            }
        };
    };

    const onCreateTask = async (values: any) => {
        const response = await axiosCall("post", envLocal.endpointTask, envLocal.authComp, "createRestaurant", {
            content: values.task,
            observations: values.observations,
            status: 0,
            desiredStatus: values.priority,
            assignedTo: values.employee ? values.employee : undefined,
            type: 8
        });
        if (response.status === 200) {
            message.success(configContext.language === 0 ? "Tarea creada!" : "Task created!");
        } else {
            message.error("Error");
        }
        setVisibleActions(false);
    };

    const closeOrder = (inputOrder: IOrders) => {
        confirm({
            title: configContext.language === 0 ? '¿Quieres cerrar el pedido?' : "Do you want to close the order?",
            content:
                <>
                    <div>{configContext.language === 0 ? 'Indica el método de pago:' : "Choose the payment method:"}</div>
                    <Radio.Group onChange={(e) => orderTypePayment = e.target.value}>
                        <Radio value={1} style={radioStyle}>{configContext.language === 0 ? "Efectivo" : "Cash"}</Radio>
                        <Radio value={2} style={radioStyle}>{configContext.language === 0 ? "Tarjeta" : "Card"}</Radio>
                        <Radio value={3} style={radioStyle}>{configContext.language === 0 ? "Ticket Restaurante" : "Ticket Restaurant"}</Radio>
                        <Radio value={4} style={radioStyle}>{configContext.language === 0 ? "Otro" : "Other"}</Radio>
                    </Radio.Group>
                </>
            ,
            icon: <ExclamationCircleOutlined />,
            okText: configContext.language === 0 ? 'Sí' : "Yes",
            okType: 'primary',
            cancelText: 'No',
            zIndex: 2000,
            onOk() {
                setIsLoading(true);
                socket.emit("closeOrder", { ...inputOrder, language: configContext.language, closedDate: moment(inputOrder.createdAt).format('DD/MM/YYYY'), closedHour: moment(inputOrder.createdAt).hour(), orderTypePayment });
                socket.emit("synchronizeHome", restaurant._id);
            },
            onCancel() {
            },
        });
        orderTypePayment = undefined;
    };

    const setUsers = async () => {
        users = ((await axiosCall("post", envLocal.endpointAuthenticationComp, envLocal.authComp, "findUsers", { status: 1 })).data);
    };

    const onChangePicker = async (date: any, dateString: any) => {
        if (date !== undefined && date !== null) {
            findOrders(date);
            setDateSelected(dateString);
        };
    };

    const validateOrder = async (inputOrder: IOrders) => {
        confirm({
            title: configContext.language === 0 ? '¿Desea aceptar el pedido?' : "Do you wish to accept the order?",
            icon: <ExclamationCircleOutlined />,
            okText: configContext.language === 0 ? 'Sí' : "Yes",
            okType: 'primary',
            cancelText: 'No',
            zIndex: 2000,
            async onOk() {
                if (inputOrder.remoteOrder && timeToPrepare === 0) {
                    message.error(configContext.language === 0 ? "No has introducido un tiempo estimado de entrega!" : "You haven't entered an estimated time of delivery!")
                } else {
                    let foodRequested: any = [];
                    let drinkRequested: any = [];
                    let taskAssociated: any = [];
                    inputOrder.order.forEach(item => {
                        if (item.quantity > 0) {
                            if (item.type === 1) {
                                foodRequested = [...foodRequested, { name: item.name, options: item.options, quantity: item.quantity, groupOrder: item.groupOrder }]
                            }
                            if (item.type === 2) {
                                drinkRequested = [...drinkRequested, { name: item.name, options: item.options, quantity: item.quantity }]
                            };
                        };
                    });
                    if (foodRequested.length) {
                        taskAssociated.push({
                            content: (configContext.language === 0 ? "Pedido " : "Order ") + decideOrderType({ idTable: inputOrder.idTable, language: configContext.language! }),
                            observations: inputOrder.observations,
                            order: foodRequested,
                            type: 1,
                            status: 0,
                            desiredStatus: 5,
                            idTable: inputOrder.idTable,
                            idOrder: inputOrder._id,
                            timeToPrepare
                        })
                    };
                    if (drinkRequested.length && !["-2", "-1", "0"].includes(inputOrder.idTable)) {
                        taskAssociated.push({
                            content: configContext.language === 0 ? `Bebidas ${!["-2", "-1", "0"].includes(inputOrder.idTable) ? ("mesa " + inputOrder.idTable) : " barra"}` : `Drinks ${!["-2", "-1", "0"].includes(inputOrder.idTable) ? ("table " + inputOrder.idTable) : " bar"}`,
                            observations: inputOrder.observations,
                            order: drinkRequested,
                            type: 2,
                            status: 0,
                            desiredStatus: 2,
                            idTable: inputOrder.idTable
                        })
                    };
                    const responseStatus = (await axiosCall("put", envLocal.endpointOrder, envLocal.authComp, "actions/" + inputOrder._id, {
                        status: 0,
                        timeToPrepare: moment().add(timeToPrepare, 'minutes'),
                        taskAssociated,
                        stockDiscount: inputOrder.order,
                        mailClient: inputOrder.email,
                        orderType: inputOrder.idTable,
                        language: configContext.language,
                        sendMail: ["-2", "-1"].includes(inputOrder.idTable)
                    })).status;
                    if (responseStatus === 200) {
                        message.success(configContext.language === 0 ? "Pedido aceptado" : "Order accepted");
                    } else {
                        message.error(configContext.language === 0 ? "Ha ocurrido un error procesando la petición" : "An error has occurred in processing the request")
                    }
                    socket.emit("synchronizeHome", restaurant._id);
                }
            },
            onCancel() {
            },
        });
        setShowValidateModal(false);
        setTimeToPrepare(0);
    };

    const rejectOrder = async (inputOrder: IOrders) => {
        confirm({
            title: configContext.language === 0 ? '¿Desea rechazar el pedido?' : "Do you wish to refuse the order?",
            icon: <ExclamationCircleOutlined />,
            okText: configContext.language === 0 ? 'Sí' : "Yes",
            okType: 'primary',
            cancelText: 'No',
            zIndex: 2000,
            async onOk() {
                const responseStatus = (await axiosCall("put", envLocal.endpointOrder, envLocal.authComp, "actions/" + inputOrder._id, { status: 9, mailClient: inputOrder.email, sendMail: ["-2", "-1"].includes(inputOrder.idTable), language: configContext.language })).status;
                if (responseStatus === 200) {
                    message.success(configContext.language === 0 ? "Pedido Rechazado" : "Order Rejected");
                } else {
                    message.error("Error");
                }
                socket.emit("synchronizeHome", restaurant._id);
            },
            onCancel() {
            },
        });
        setTimeToPrepare(0);
        setShowValidateModal(false);
    };

    const openValidateModal = async (item: IOrders) => {
        setShowValidateModal(true);
        orderValidateModal = item;
    };

    const openDrawer = useCallback(() => {
        const width = 100
        const height = 100
        const leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
        const topPosition = (window.screen.height / 2) - ((height / 2) + 50);
        var mywindow = window.open("", "print",
            "status=no,height=" + height + ",width=" + width + ",resizable=yes,left="
            + leftPosition + ",top=" + topPosition + ",screenX=" + leftPosition + ",screenY="
            + topPosition + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no");
        if (mywindow) {
            mywindow.document.write("<div style={{visibility: hidden'}}>.</div>");
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.print();
            //mywindow.close();
        }
    }, [])

    const getSquares = () => {
        const sq = [
            {
                bodyStyle: { marginTop: 10 },
                title: configContext.language === 0 ? "Ocupación" : "Vacancy",
                body:
                    <>
                        <div className="mediumBold">{configContext.language === 0 ? "Mesas Ocupadas" : "Tables Occupied"}</div>
                        <div className="textRight"><span className="xxLarge">{orders.filter(order => !["-2", "-1", "0"].includes(order.idTable)).length}</span><span className="large"> / {restaurant.numTables}</span></div>
                    </>
            },
            {
                title: configContext.language === 0 ? "Acciones" : "Actions",
                body:
                    <>
                        <div className="buttonActions">
                            <Button
                                style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white" }}
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => setVisible(true)}
                            >{configContext.language === 0 ? "Pedido" : "Order"}
                            </Button>
                            <CollectionCreateForm
                                visible={visible}
                                onCreate={onCreate}
                                onCancel={() => {
                                    setVisible(false);
                                }}
                            />
                        </div>
                        <div className="buttonActions">
                            {[1, 4, 5].includes(user.userType) && <Button
                                style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white" }}
                                icon={<FormOutlined />}
                                onClick={async () => {
                                    await setUsers();
                                    setVisibleActions(true);
                                }}
                            >{configContext.language === 0 ? "Tarea" : "Task"}
                            </Button>}
                            <CollectionCreateFormTask
                                visible={visibleActions}
                                onCreate={onCreateTask}
                                onCancel={() => {
                                    setVisibleActions(false);
                                }}
                            />
                        </div>
                        {/*                         <div className="buttonActions">
                            <Button
                                style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white" }}
                                type="primary"
                                icon={<DollarOutlined />}
                                onClick={openDrawer}
                            >{configContext.language === 0 ? "Abrir Cajón" : "Open Drawer"}
                            </Button>
                        </div> */}
                    </>
            },
            {
                bodyStyle: { marginTop: 10 },
                title: configContext.language === 0 ? "Último Pedido" : "Last Order",
                body:
                    <div className="textCenter mt_25px">
                        {inputTask ?
                            <span style={{ color: "white", borderRadius: 4, background: "#4d8fcd", maxWidth: 350, width: "fit-content", height: 30, padding: 9 }}>
                                {decideOrderType({ idTable: inputTask.idTable, language: configContext.language! })}
                            </span>
                            :
                            <strong>{configContext.language === 0 ? "Esperando..." : "Waiting..."}</strong>
                        }
                    </div>
            },
            {
                visible: [1, 4, 5].includes(user.userType),
                squareStyle: { width: getWidth('inventory', widthSize) },
                bodyStyle: { marginTop: 10 },
                title: configContext.language === 0 ? "Inventario" : "Inventory",
                body:
                    basicProduct.length ?
                        <div className="inCols">
                            {basicProduct.slice(0, widthSize > 1150 ? 9 : (widthSize < 690 ? 6 : 3)).map(product => <div key={product._id}>{product.name}: <strong>{round(product.quantity)}</strong></div>)}
                        </div>
                        :
                        <div className="textCenter mt_25px">
                            <strong>{configContext.language === 0 ? "Inventario no registrado" : "Unregistered Inventory"}</strong>
                        </div>
            }
        ]
        return sq.filter(e => e.visible !== false)
    }

    const getValidated = () => {
        return orderToValidateList.map(order => {
            return {
                title: configContext.language === 0 ? "Validar Pedido" : "Validate Order",
                titleStyle: { background: "#fe9a00" },
                body:
                    <div className="textCenter">
                        <Button style={{ backgroundColor: "#4d8fcd", borderColor: "#4d8fcd", color: "white", width: "100%" }} onClick={() => openValidateModal(order)}>{decideOrderType({ idTable: order.idTable, language: configContext.language! })}</Button>
                        <div className="mt_10px">{moment(order.createdAt).format('LT')}</div>
                    </div>
            }
        })
    }

    const getBasicProducts = async () => {
        const response = await axiosCall("post", envLocal.endpointBasicProduct, envLocal.authComp, "getProducts");
        if (response.status === 200) setBasicProduct(response.data)
    };

    const getTask = async () => {
        const response = await axiosCall("post", envLocal.endpointTask, envLocal.authComp, "restaurant", {
            status: [1, 2, 3, 4, 5],
        });
        if (response.status === 200) {
            setTasks(response.data.filter((e: ITask) => [1, 2, 3, 4].includes(e.status)))
            const pendingCookPre: ITask[] = response.data.filter((e: ITask) => e.status === 5 && e.idTable && !["-2", "-1", "0"].includes(e.idTable))
            setPendingCook(pendingCookPre.map(e => { return { table: e.idTable || "0", complete: e.order?.filter(dish => dish.notified).length, total: e.order?.length } }))
        }
    }

    const changeTable = useCallback((idOrder: string) => {
        newTableOrder.idOrder = idOrder
        setShowChangeTableModal(true)
    }, [])

    const changeTableOrder = useCallback(async (inputTables: { desiredTable: string, idOrder: string }) => {
        const response = await axiosCall("post", envLocal.endpointOrder, envLocal.authComp, "changeTable", {
            idOrder: inputTables.idOrder,
            desiredTable: inputTables.desiredTable
        });
        if (response.status === 200) {
            message.success(configContext.language === 0 ? `Se ha movido el pedido a la mesa ${inputTables.desiredTable}` : `The order has been moved to table ${inputTables.desiredTable}`)
            await getOrders();
        } else if (response.status === 404) {
            message.error(configContext.language === 0 ? "La mesa destino está ocupada" : "The target table is occupied")
        } else {
            message.error("Error")
        }
        setShowChangeTableModal(false)
        newTableOrder = defaultTableOrder
    }, [])

    const assignTip = useCallback(async (idOrder: string, tip: number) => {
        setShowBillModal(false);
        const response = await axiosCall("put", envLocal.endpointOrder, envLocal.authComp, "actions/" + idOrder, { tip });
        if (response.status === 200) {
            message.success(configContext.language === 0 ? "Propina aplicada" : "Tip applied")
            await getOrders();
        }
        setTip(undefined);
    }, [])

    const assignDiscount = useCallback(async (idOrder: string, discountBill: number, itemsBillChecked: string[]) => {
        setShowBillModal(false);
        const response = await axiosCall("put", envLocal.endpointOrder, envLocal.authComp, "setDiscount", { idOrder, discountBill, itemsBillChecked });
        if (response.status === 200) {
            message.success(configContext.language === 0 ? "Descuento aplicado" : "Discount applied")
            await getOrders();
        }
        setDiscountBill(undefined);
        setItemsBillChecked([]);
        setShowCheckboxBill(false)
    }, [])

    const payItemsCheckedBill = useCallback(async (idOrder: string, itemsBillDeduced: IItemsDeduced[]) => {
        setShowBillModal(false);
        const response = await axiosCall("put", envLocal.endpointOrder, envLocal.authComp, "setPayedItem", { idOrder, itemsBillDeduced });
        if (response.status === 200) {
            message.success(configContext.language === 0 ? "Productos anotados" : "Items noted")
            await getOrders();
        }
        setItemsBillDeduced([]);
        setShowInputNumber(false)
    }, [])

    const showDiscountInput = useCallback((condition: boolean) => {
        if (condition) {
            setDiscountBill(0)
            setShowCheckboxBill(true)
        } else {
            setDiscountBill(undefined);
            setShowCheckboxBill(false)
        }
    }, [])

    const showTipInput = useCallback((condition: boolean) => {
        if (condition) {
            setTip(0)
        } else {
            setTip(undefined);
        }
    }, [])

    const evalCheckedBill = (inputItem: string) => {
        const index = itemsBillChecked.indexOf(inputItem);
        if (index > -1) {
            const tmpItems = itemsBillChecked;
            tmpItems.splice(index, 1);
            setItemsBillChecked([...tmpItems]);
        } else {
            setItemsBillChecked([...itemsBillChecked, inputItem]);
        }
    }

    const evalPayedBill = (quantity: number, idItem: string) => {
        let exist = false
        let tmpItems = itemsBillDeduced
        tmpItems.forEach(e => {
            if (e.idItem === idItem) {
                e.quantity = quantity
                exist = true
            }
        })
        if (!exist) tmpItems.push({ idItem, quantity })
        setItemsBillDeduced([...tmpItems])
    }

    const closeBillModal = useCallback(() => {
        setTip(undefined);
        setDiscountBill(undefined);
        setShowCheckboxBill(false)
        setShowInputNumber(false)
        setItemsBillChecked([]);
        setItemsBillDeduced([]);
        setShowBillModal(false)
        setClientName("")
    }, [])

    const showInvoice = useCallback((orders: IOrders[]) => {
        setViewInvoicing(true)
        summaryPayments = setPaymentType(orders)
    }, [])

    const changePaymentType = useCallback((orderSelected: IOrders) => {
        orderToSetPaymentType = orderSelected
        setShowPaymentTypeModal(true)
    }, [])

    const changePaymentTypeApi = useCallback(async (orderSelected?: IOrders, orderTypePayment?: number) => {
        if (orders && orderTypePayment) {
            const response = await axiosCall("put", envLocal.endpointOrder, envLocal.authComp, "setPaymentType", { idOrder: orderSelected?._id, orderTypePayment });
            if (response.status === 200) {
                message.success(configContext.language === 0 ? "Pago modificado" : "Modified payment")
                await findOrders();
            } else {
                message.error(configContext.language === 0 ? "Se ha producido un error" : "An error has occurred")
            }
            orderToSetPaymentType = undefined
            setShowPaymentTypeModal(false)
        }
    }, [])

    const showBill = useCallback(async (orderSelected: IOrders) => {
        const orderFinded = await axiosCall("post", envLocal.endpointOrder, envLocal.authComp, `actions/${orderSelected._id}`)
        if (orderFinded.status === 200) {
            setShowBillModal(true);
            setBillModal(orderFinded.data);
        } else {
            message.error("Error")
        }
    }, [])

    const pintrableBill =
        <>
            <p className="title">{restaurant.name}</p>
            <div style={{ textAlign: "center", fontSize: "110%", marginBottom: 10 }}>
                <div>{restaurant.street} {restaurant.number}</div>
                <div>{restaurant.postalCode} {restaurant.city}</div>
                <div>{configContext.language === 0 ? "Teléfono: " : "Telephone: "}{restaurant.telephone}</div>
            </div>
            {billModal && <Bill showCheckbox={showCheckboxBill} showInputNumber={showInputNumber} itemsChecked={itemsBillChecked} setChecked={evalCheckedBill} setDeduced={evalPayedBill} order={billModal.order} tip={billModal.tip} coin={coin} discount={billModal.discountValueToApply} priceSpecialTable={billModal.priceSpecialTable} />}
        </>

    let dailyTips = ordersFinded.map(orderFinded =>
        getTotalPriceFromOrder(orderFinded.order, false) * ((orderFinded.tip !== undefined ? orderFinded.tip : 0) / 100)
    ).reduce((a: number, b: any) => Number(a) + Number(b), 0);

    return (
        <div className="basicContainerColor" style={{ backgroundColor: orderToValidateList.length ? "#ff8d00" : "#eee" }}>
            <NavBar />
            <div className="titleSection">{configContext.language === 0 ? "Pedidos" : "Orders"}</div>
            {isLoading ?
                <LoadingSpin />
                :
                <div className="mb_30px">
                    <SquareInfo squares={getValidated()} />
                    <SquareInfo squares={getSquares()} />
                    <div className="containerTaskOrders">
                        <div className="sectionSquare" style={{ width: getWidth('orders', widthSize) }}>
                            <div className="sectionSquareTitle">{configContext.language === 0 ? "Pedidos" : "Orders"}</div>
                            <div className="sectionSquareBody" style={widthSize < 690 ? { padding: "10px 0px" } : undefined}>
                                <List
                                    size="large"
                                    dataSource={orders}
                                    renderItem={item =>
                                        <List.Item style={widthSize < 690 ? { padding: "16px 0px" } : undefined}>
                                            <Col span={8} className="textCenter"><div>{moment(item.createdAt).format('LT')}</div>
                                                {!["-2", "-1"].includes(item.idTable) && [1, 4, 5].includes(user.userType) ?
                                                    <Button type="link" onClick={() => changeTable(item._id)}><span className="boldLarge colorBlackAntd">{decideOrderType({ idTable: item.idTable, language: configContext.language! })}</span></Button>
                                                    :
                                                    <div className="boldLarge">{decideOrderType({ idTable: item.idTable, language: configContext.language! })}</div>
                                                }
                                            </Col>
                                            {widthSize > 1150 ?
                                                <Col span={16} >
                                                    <Popover
                                                        title={<React.Fragment>
                                                            <strong>{configContext.language === 0 ? "Pedido" : "Order"}</strong>
                                                            <Button type="link" style={{ position: "absolute", right: "0px", top: "0px", color: "red" }} icon={<CloseOutlined />} onClick={() => setShowOrder("-")} />
                                                        </React.Fragment>}
                                                        visible={showOrder === item._id}
                                                        content={<>
                                                            {item.order && item.order.map((item, index) => <div style={{ color: item.modified ? "red" : "inherit" }} key={index}>x{item.quantity} {item.name}</div>)}
                                                            {item.telephone && <div><b>{configContext.language === 0 ? "Teléfono: " : "Telephone: "}</b>{item.telephone}</div>}
                                                            {item.address && <div><b>{configContext.language === 0 ? "Dirección de entrega: " : "Delivery address: "}</b>{item.address}</div>}
                                                            <Button type="link" onClick={() => moreFood(item)}>{configContext.language === 0 ? "Ampliar Pedido" : "Enlarge Order"}</Button>
                                                            {[1, 3, 4, 5].includes(user.userType) && <Button type="link" onClick={() => modifyQuantities({ request: item.order, idOrder: item._id })}>{configContext.language === 0 ? "Reducir Pedido" : "Reduce Order"}</Button>}
                                                        </>}
                                                    >
                                                        <Button
                                                            style={{ color: "white", margin: "2px 20px", backgroundColor: "#1890FF" }}
                                                            onClick={() => showOrder === "-" ? setShowOrder(item._id) : setShowOrder("-")}
                                                        >{configContext.language === 0 ? "Ver Pedido" : "View Order"}</Button>
                                                    </Popover>
                                                    <Button
                                                        style={{ color: "white", margin: "2px 20px", backgroundColor: "#227301" }}
                                                        onClick={() => showBill(item)}
                                                    >{configContext.language === 0 ? "La Cuenta" : "The Bill"}</Button>
                                                    {[1, 3, 4, 5].includes(user.userType) && <Button
                                                        style={{ color: "white", margin: "2px 20px", backgroundColor: "#FE9A00" }}
                                                        onClick={() => closeOrder(item)}
                                                    >{configContext.language === 0 ? "Cerrar Pedido" : "Close Order"}</Button>}
                                                    <StatusComplete idTable={item.idTable} pendingCook={pendingCook} />
                                                </Col>
                                                :
                                                <Col span={16} className="textCenter">
                                                    <Dropdown trigger={["click"]} overlay={
                                                        <Menu>
                                                            <div className="buttonActions">
                                                                <Popover
                                                                    title={<React.Fragment>
                                                                        <strong>{configContext.language === 0 ? "Pedido" : "Order"}</strong>
                                                                        <Button type="link" style={{ position: "absolute", right: "0px", top: "0px", color: "red" }} icon={<CloseOutlined />} onClick={() => setShowOrder("-")} />
                                                                    </React.Fragment>}
                                                                    visible={showOrder === item._id}
                                                                    content={<>
                                                                        {item.order && item.order.map((item, index) => item.quantity > 0 ? <div key={index}>x{item.quantity} {item.name}</div> : null)}
                                                                        {item.telephone && <div><b>{configContext.language === 0 ? "Teléfono: " : "Telephone: "}</b>{item.telephone}</div>}
                                                                        {item.address && <div><b>{configContext.language === 0 ? "Dirección de entrega: " : "Delivery address: "}</b>{item.address}</div>}
                                                                        <Button type="link" onClick={() => moreFood(item)}>{configContext.language === 0 ? "Ampliar Pedido" : "Enlarge Order"}</Button>
                                                                        {[1, 3, 4, 5].includes(user.userType) && <Button type="link" onClick={() => modifyQuantities({ request: item.order, idOrder: item._id })}>{configContext.language === 0 ? "Reducir Pedido" : "Reduce Order"}</Button>}
                                                                    </>}
                                                                >
                                                                    <Button
                                                                        style={{ color: "white", margin: "2px 20px", backgroundColor: "#1890FF" }}
                                                                        onClick={() => showOrder === "-" ? setShowOrder(item._id) : setShowOrder("-")}
                                                                    >{configContext.language === 0 ? "Ver Pedido" : "View Order"}</Button>
                                                                </Popover>
                                                                <Button
                                                                    style={{ color: "white", margin: "2px 20px", backgroundColor: "#227301" }}
                                                                    onClick={() => showBill(item)}
                                                                >{configContext.language === 0 ? "La Cuenta" : "The Bill"}</Button>
                                                                {[1, 3, 4, 5].includes(user.userType) && <Button
                                                                    style={{ color: "white", margin: "2px 20px", backgroundColor: "#FE9A00" }}
                                                                    onClick={() => closeOrder(item)}
                                                                >{configContext.language === 0 ? "Cerrar Pedido" : "Close Order"}</Button>}
                                                                {item.remoteOrder && <span className="boldLarge textCenter">{item.telephone}</span>}
                                                                <StatusComplete idTable={item.idTable} pendingCook={pendingCook} />
                                                            </div>
                                                        </Menu>
                                                    } placement="bottomCenter">
                                                        <Button type="primary">{configContext.language === 0 ? "Gestionar" : "Manage"}</Button>
                                                    </Dropdown>
                                                </Col>
                                            }
                                        </List.Item>}
                                />
                            </div>
                            <Modal
                                title={configContext.language === 0 ? "Introduce las cantidades" : "Enter quantities"}
                                visible={showModal}
                                onOk={() => modalOrder && saveOrder()}
                                onCancel={() => setShowModal(false)}
                                zIndex={2000}
                            >
                                {modalOrder && Object.entries(modalOrder)[0][1].map((item, index) =>
                                    <Row key={index}>
                                        <Col span={12}>{item.name}</Col>
                                        <Col span={7} offset={5}><InputNumber min={0} max={item.quantity} onChange={(e) => updateOrder({ quantity: e, position: index, idOrder: Object.entries(modalOrder)[0][0] })} defaultValue={item.quantity} /></Col>
                                    </Row>
                                )}
                            </Modal>
                            <Modal
                                title={
                                    <>
                                        <div>
                                            <Button type="link" onClick={() => showDiscountInput(discountBill === undefined)}>{configContext.language === 0 ? "Añadir descuento" : "Add discount"}</Button>
                                            {discountBill !== undefined && discountBill > -1 && <InputNumber
                                                value={discountBill}
                                                min={0}
                                                max={100}
                                                formatter={value => `${value}%`}
                                                parser={(value: any) => value!.replace('%', '')}
                                                onChange={(e: any) => setDiscountBill(e)}
                                            />}
                                            {discountBill !== undefined && discountBill > -1 && <Button type="link" onClick={() => assignDiscount(billModal!._id, discountBill, itemsBillChecked)} style={{ color: "green" }} icon={<CheckOutlined />} />}
                                        </div>
                                        <div>
                                            <Button type="link" onClick={() => setShowInputNumber(!showInputNumber)}>{configContext.language === 0 ? "Pago parcial" : "Partly paid"}</Button>
                                            {itemsBillDeduced.length > 0 && <Button type="link" onClick={() => payItemsCheckedBill(billModal!._id, itemsBillDeduced)} style={{ color: "green" }} icon={<CheckOutlined />} />}
                                        </div>
                                        <div>
                                            <Button type="link" onClick={() => showTipInput(tip === undefined)}>{configContext.language === 0 ? "Añadir Propina" : "Add Tip"}</Button>
                                            {tip !== undefined && tip > -1 && <InputNumber
                                                value={tip}
                                                min={0}
                                                max={100}
                                                formatter={value => `${value}%`}
                                                parser={(value: any) => value!.replace('%', '')}
                                                onChange={(e: any) => setTip(e)}
                                            />}
                                            {tip !== undefined && tip > -1 && <Button type="link" onClick={() => assignTip(billModal!._id, tip)} style={{ color: "green" }} icon={<CheckOutlined />} />}
                                        </div>
                                        <div>
                                            <Input placeholder={configContext.language === 0 ? "Nombre del cliente" : "Client's name"} onChange={e => setClientName(e.target.value)} value={clientName} />
                                        </div>
                                    </>}
                                visible={showBillModal}
                                onOk={() => props.history.push({
                                    pathname: '/ToPrint',
                                    state: { billModal, coin, restaurant, language: configContext.language, type: 1, clientName }
                                })}
                                okText={configContext.language === 0 ? "Imprimir" : "Print"}
                                onCancel={closeBillModal}
                                zIndex={2000}
                            >
                                {pintrableBill}
                            </Modal>
                            <Modal
                                title={(configContext.language === 0 ? "Validación del Pedido " : "Validate Order ") + decideOrderType({ idTable: orderValidateModal?.idTable, language: configContext.language! })}
                                visible={showValidateModal}
                                onOk={() => validateOrder(orderValidateModal)}
                                okText={configContext.language === 0 ? "Aceptar" : "Accept"}
                                onCancel={() => rejectOrder(orderValidateModal)}
                                cancelText={configContext.language === 0 ? "Rechazar" : "Reject"}
                                maskClosable={false}
                                closable={false}
                                zIndex={2000}
                            >
                                {orderValidateModal && <Bill order={orderValidateModal.order} coin={coin} discount={orderValidateModal.discountValueToApply} priceSpecialTable={orderValidateModal.priceSpecialTable} isSummaryHome />}
                                <div className="mt_10px">
                                    {orderValidateModal?.telephone && <div><b>{configContext.language === 0 ? "Teléfono: " : "Telephone: "}</b>{orderValidateModal.telephone}</div>}
                                    {orderValidateModal?.address && <div><b>{configContext.language === 0 ? "Dirección de entrega: " : "Delivery address: "}</b>{orderValidateModal.address}</div>}
                                    {orderValidateModal?.observations && <div><b>{configContext.language === 0 ? "Observaciones: " : "Comments: "}</b>{orderValidateModal.observations}</div>}
                                    {orderValidateModal?.remoteOrder && <div className="displayGrid mt_15px"><b className="textCenter">{configContext.language === 0 ? "Tiempo estimado de preparación: " : "Estimated preparation time: "}<InputNumber style={{ width: "70px" }} value={timeToPrepare} onChange={(e) => setTimeToPrepare(e)} min={0} maxLength={3} /> Min.</b></div>}
                                </div>
                            </Modal>
                            <Modal
                                title={configContext.language === 0 ? "Mover pedido a otra mesa" : "Move order to another table"}
                                visible={showChangeTableModal}
                                onOk={() => changeTableOrder(newTableOrder)}
                                onCancel={() => setShowChangeTableModal(false)}
                                okText={configContext.language === 0 ? "Aceptar" : "Accept"}
                                cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                                zIndex={2000}
                            >
                                <>
                                    <p>{configContext.language === 0 ? "¿A qué mesa quieres mover el pedido?" : "Which table do you want to move the order to?"}</p>
                                    <Input maxLength={10} defaultValue={newTableOrder.desiredTable} disabled={["-2", "-1"].includes(newTableOrder.desiredTable)} onChange={(e) => newTableOrder.desiredTable = e.target.value} style={{ width: 100 }} />
                                </>
                            </Modal>
                        </div>
                        {[1, 4, 5].includes(user.userType) && <div className="sectionSquare" style={{ width: getWidth('tasks', widthSize) }}>
                            <div className="sectionSquareTitle">{configContext.language === 0 ? "Tareas" : "Tasks"}</div>
                            <div className="sectionSquareBody">
                                {tasks.length ?
                                    <>
                                        <div className="boldLarge mb_10px">{configContext.language === 0 ? "Resumen" : "Summary"}</div>
                                        <p className="textCenter">{configContext.language === 0 ? "Pendientes: " : "Pending: "}<strong>{tasks.filter(task => (task.status === 1 || task.status === 2)).length}</strong></p>
                                        <p className="textCenter">{configContext.language === 0 ? "En progreso: " : "In Progress: "}<strong>{tasks.filter(task => task.status === 3).length}</strong></p>
                                        <p className="textCenter">{configContext.language === 0 ? "Completadas: " : "Completed: "}<strong>{tasks.filter(task => task.status === 4).length}</strong></p>
                                        <div className="boldLarge mb_10px mt_20px">{configContext.language === 0 ? "Empleados" : "Employees"}</div>
                                        {Object.entries(groupBy(tasks, "assignedTo")).filter(e => !['Domicilio', 'Home', 'Recogida', 'Pickup', 'Barra', 'Bar'].includes(e[0])).map((user: any, index: number) =>
                                            <div key={index} className="mb_10px">
                                                <div><strong>{`${user[0]} (${user[1].length}):`}</strong></div>
                                                <div className="textCenter">Pen: <strong>{user[1].filter((t: ITask) => (t.status === 1 || t.status === 2)).length}</strong>. Pro: <strong>{user[1].filter((t: ITask) => t.status === 3).length}</strong>. Com: <strong>{user[1].filter((t: ITask) => t.status === 4).length}</strong></div>
                                            </div>
                                        )}
                                    </>
                                    :
                                    <strong>{configContext.language === 0 ? "No hay tareas pendientes" : "No pending tasks"}</strong>
                                }
                            </div>
                        </div>}
                    </div>
                    {[1, 4, 5].includes(user.userType) && <div className="sectionSquare">
                        <div className="sectionSquareTitle">{configContext.language === 0 ? "Historial de Pedidos" : "Order History"}</div>
                        <div className="sectionSquareBody" style={{ padding: 0 }} >
                            <div>
                                <Row style={{ marginTop: 15, textAlign: "center" }}>
                                    <Col xs={{ span: 20, offset: 2 }} md={{ span: 14, offset: 5 }}>
                                        <div className="mb_20px large">{configContext.language === 0 ? "Pedidos con fecha: " : "Dated orders: "}<b>{dateSelected}</b></div>
                                        <ConfigProvider locale={configContext.language === 0 ? locale : localeEn}>
                                            <DatePicker inputReadOnly onChange={onChangePicker} format="DD/MM/YYYY" style={{ margin: "20px auto" }} />
                                        </ConfigProvider>
                                    </Col>
                                </Row>
                                <Row justify="center">
                                    {ordersFinded.length ?
                                        <Col xs={{ span: 24 }} md={{ span: 14 }}>
                                            <Collapse>
                                                {ordersFinded.map(order =>
                                                    <Panel style={{ backgroundColor: order.order.find(item => item.modified) !== undefined ? "tomato" : (order.tip ? "lightgreen" : "inherit") }} key={order._id} header={
                                                        <Row>
                                                            <Col span={14}><b>{`${configContext.language === 0 ? "Pedido " : "Order "}${decideOrderType({ idTable: order.idTable, language: configContext.language! })}`}</b></Col>
                                                            <Col xs={{ span: 0 }} md={{ span: 6 }}>{configContext.language === 0 ? "Solicitado a las: " : "Requested at: "}</Col>
                                                            <Col xs={{ span: 10 }} md={{ span: 4 }}><b>{moment(order.createdAt).format('HH:mm')}</b></Col>
                                                        </Row>
                                                    }>
                                                        <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 4 }}>
                                                            <Bill order={order.order} tip={order.tip} coin={coin} discount={order.discountValueToApply} priceSpecialTable={order.priceSpecialTable} isSummaryHome />
                                                        </Col>
                                                        <div className="textCenter">
                                                            <Button type="link" onClick={() => changePaymentType(order)}>{getPaymentType(configContext.language!, order.orderTypePayment)}</Button>
                                                        </div>
                                                        {(order.tip && order.tip > 0) ? <div><b>{configContext.language === 0 ? "Propina: " : "Tip: "}</b>{order.tip}%</div> : null}
                                                        {order.telephone && <div><b>{configContext.language === 0 ? "Teléfono: " : "Telephone: "}</b>{order.telephone}</div>}
                                                        {order.address && <div><b>{configContext.language === 0 ? "Dirección de entrega: " : "Delivery address: "}</b>{order.address}</div>}
                                                    </Panel>)}
                                            </Collapse>
                                            {dailyTips > 0 && <div className="mt_15px textCenter">{configContext.language === 0 ? "Propinas recibidas: " : "Tipping summary: "}<b>{dailyTips} {coin}</b></div>}
                                            <div className="mt_20px textCenter">
                                                <Button type="primary" onClick={() => showInvoice(ordersFinded)}>{configContext.language === 0 ? "Ver facturación" : "View invoicing"}</Button>
                                            </div>
                                        </Col>
                                        : <b className="m_4x10">{configContext.language === 0 ? "No se ha recuperado ningún pedido para la fecha indicada" : "No order has been retrieved by the date indicated"}</b>}
                                </Row>
                                <Modal
                                    title={configContext.language === 0 ? "Facturación" : "Invoicing"}
                                    visible={viewInvoicing}
                                    onOk={() => setViewInvoicing(false)}
                                    cancelButtonProps={{ style: { display: "none" } }}
                                    zIndex={2000}
                                    okText={configContext.language === 0 ? "Cerrar" : "Close"}
                                >
                                    <>
                                        <div>
                                            <div>{configContext.language === 0 ? "Efectivo:" : "Cash:"} <b>{summaryPayments.cash.toFixed(2)} {coin}</b></div>
                                            <div>{configContext.language === 0 ? "Tarjeta:" : "Card:"} <b>{summaryPayments.card.toFixed(2)} {coin}</b></div>
                                            <div>{configContext.language === 0 ? "Ticket Restaurante:" : "Ticket Restaurant:"} <b>{summaryPayments.ticket.toFixed(2)} {coin}</b></div>
                                            <div>{configContext.language === 0 ? "Otro:" : "Other:"} <b>{summaryPayments.other.toFixed(2)} {coin}</b></div>
                                            <div>{configContext.language === 0 ? "Sin indicar:" : "Undefined:"} <b>{summaryPayments.und.toFixed(2)} {coin}</b></div>
                                        </div>
                                        <div className="textCenter">Total: <b>{summaryPayments.total.toFixed(2)} {coin}</b></div>
                                    </>
                                </Modal>
                                <Modal
                                    title={configContext.language === 0 ? "Indica el tipo de pago" : "Choose the payment method"}
                                    visible={showPaymentTypeModal}
                                    onOk={() => changePaymentTypeApi(orderToSetPaymentType, orderTypePayment)}
                                    onCancel={() => setShowPaymentTypeModal(false)}
                                    okText={configContext.language === 0 ? "Aceptar" : "Accept"}
                                    cancelText={configContext.language === 0 ? "Cancelar" : "Cancel"}
                                    zIndex={2000}
                                >
                                    <Radio.Group onChange={(e) => orderTypePayment = e.target.value}>
                                        <Radio value={1} style={radioStyle}>{configContext.language === 0 ? "Efectivo" : "Cash"}</Radio>
                                        <Radio value={2} style={radioStyle}>{configContext.language === 0 ? "Tarjeta" : "Card"}</Radio>
                                        <Radio value={3} style={radioStyle}>{configContext.language === 0 ? "Ticket Restaurante" : "Ticket Restaurant"}</Radio>
                                        <Radio value={4} style={radioStyle}>{configContext.language === 0 ? "Otro" : "Other"}</Radio>
                                    </Radio.Group>
                                </Modal>
                            </div>
                        </div>
                    </div>}
                </div>
            }
        </div >
    )
};

export default HomeCompany;