import React from 'react';
import { Row, Col, Tooltip, Rate, Button } from 'antd';
import { QuestionCircleTwoTone, StarFilled, EditTwoTone, DeleteOutlined } from '@ant-design/icons';
import { IItems } from '../Basic/Utilities/interfaces';
import { useConfigContext } from '../../Context/ContextConfig';
import CustomInputNumber from '../Basic/CustomInputNumber';

interface Props {
    dataSource: IItems[];
    rate: boolean;
    saveItemMenu?: Function;
    createItem?: Function;
    deleteItem?: Function;
    sectionType?: number;
    idSection?: string;
    isClient?: boolean;
    isDefault?: boolean;
    coin: string;
    menuOnlyRead?: boolean;
    country: string;
    noShowRate?: boolean
};

const ListMenu: React.FC<Props> = (props) => {
    const { configContext } = useConfigContext()!;

    return (
        <div>
            {props.dataSource.map((item: IItems) =>
                (props.sectionType === 1 || props.sectionType === 4) ?
                    <div key={item.name} className="listItem">
                        <Row className="text_bold_pd5">
                            <Col span={(props.sectionType === 4 && !item.supplementMenu) ? 24 : 19}>{item.name}{props.isDefault &&
                                <span>
                                    <Tooltip title={configContext.language === 0 ? 'Editar' : "Edit"}><Button type="link" value="small" icon={<EditTwoTone />} onClick={() => props.createItem !== undefined && props.createItem({ sectionType: props.sectionType, item })}></Button></Tooltip>
                                    <Tooltip title={configContext.language === 0 ? 'Eliminar' : "Delete"}><Button type="link" value="small" style={{ color: "red" }} icon={<DeleteOutlined />} onClick={() => props.deleteItem !== undefined && props.deleteItem(item._id, item.type)}></Button></Tooltip>
                                </span>}
                            </Col>
                            {(props.sectionType !== 4 || (item.supplementMenu && item.supplementMenu > 0)) && <Col span={5} className="boldLarge textCenter">{(props.sectionType === 4 && item.supplementMenu && item.supplementMenu > 0) ? `+${item.supplementMenu}` : item.price} {props.coin}</Col>}
                        </Row>
                        <Row style={{ paddingBottom: 5 }}>
                            {props.rate &&
                                <Col span={props.isClient ? 17 : 23} offset={1}>
                                    <Row style={{ padding: "0px 4px" }}>
                                        {item.description !== "" &&
                                            <span>
                                                <Tooltip title={item.description} trigger={["click"]}>
                                                    <QuestionCircleTwoTone />
                                                </Tooltip>
                                                &nbsp;{configContext.language === 0 ? 'Descripción' : "Description"}&nbsp;&nbsp;
                                            </span>}
                                        {item.alergenos !== "" &&
                                            <span>
                                                <Tooltip title={item.alergenos} trigger={["click"]}>
                                                    <QuestionCircleTwoTone />
                                                </Tooltip>
                                                &nbsp;{configContext.language === 0 ? 'Alérgenos' : "Allergens"}&nbsp;
                                            </span>}
                                    </Row>
                                    {props.noShowRate !== true && <Row>
                                        <Rate allowHalf disabled defaultValue={Number((Math.round(item.rate * 2) / 2).toFixed(1))} />
                                        <div className="m_4x10"><StarFilled style={{ color: "#FEEA00" }} />{item.rateCount}</div>
                                    </Row>}
                                </Col>}
                            <Col span={6} className="inputBottom">
                                {props.isClient && !props.menuOnlyRead &&
                                    <CustomInputNumber
                                        idSection={props.idSection}
                                        sectionType={props.sectionType}
                                        item={item}
                                        saveItemMenu={props.saveItemMenu}
                                        type={1}
                                        language={configContext.language!}
                                        coin={props.coin}
                                        country={props.country}
                                    />
                                }
                            </Col>
                        </Row>
                    </div>
                    :
                    <div key={item.name} style={{ padding: props.isClient ? 5 : 10, backgroundColor: "white", borderBottom: "1px solid #D5D5D5" }}>
                        <Row>
                            <Col span={(props.isClient && !props.menuOnlyRead) ? 17 : 23} offset={1}>
                                <Row className="text_bold_pdt3">
                                    <Col span={(props.sectionType === 5 && !item.supplementMenu) ? 24 : (props.menuOnlyRead ? 19 : 16)}>{item.name}{props.isDefault &&
                                        <span>
                                            <Tooltip title={configContext.language === 0 ? 'Editar' : "Edit"}><Button type="link" value="small" icon={<EditTwoTone />} onClick={() => props.createItem !== undefined && props.createItem({ sectionType: props.sectionType, item })}></Button></Tooltip>
                                            <Tooltip title={configContext.language === 0 ? 'Eliminar' : "Delete"}><Button type="link" value="small" style={{ color: "red" }} icon={<DeleteOutlined />} onClick={() => props.deleteItem !== undefined && props.deleteItem(item._id, item.type)}></Button></Tooltip>
                                        </span>}
                                    </Col>
                                    {(props.sectionType !== 5 || (item.supplementMenu && item.supplementMenu > 0)) && <Col span={props.menuOnlyRead ? 5 : 8}>{(props.sectionType === 5 && item.supplementMenu && item.supplementMenu > 0) ? `+${item.supplementMenu}` : item.price} {props.coin}</Col>}
                                </Row>
                                <Row>{item.description !== "" &&
                                    <span>
                                        <Tooltip title={item.description}>
                                            <QuestionCircleTwoTone />
                                        </Tooltip>
                                        &nbsp;{configContext.language === 0 ? 'Descripción' : "Description"}&nbsp;&nbsp;
                                    </span>}
                                </Row>
                            </Col>
                            {props.isClient && !props.menuOnlyRead &&
                                <Col span={6}>
                                    <CustomInputNumber
                                        idSection={props.idSection}
                                        sectionType={props.sectionType}
                                        item={item}
                                        saveItemMenu={props.saveItemMenu}
                                        type={2}
                                        language={configContext.language!}
                                        coin={props.coin}
                                        country={props.country}
                                    />
                                </Col>}
                        </Row>
                    </div>
            )}
        </div>
    )
};

export default ListMenu;