import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import NavBar from '../../Components/Basic/NavBar';
import { useConfigContext } from '../../Context/ContextConfig';
import { RouteComponentProps } from 'react-router-dom';
import { useUser } from '../../Context/ContextUser';
import ReactPayPal from '../../Components/Basic/PayPal';
import payPalImg from '../../Components/Basic/Utilities/Images/payPalImg.jpg'
import cryptojs from 'crypto-js'

interface InputProps {
    productCode: string;
    description?: string;
    total?: number;
    idOrderPayment: string;
    idOrderBBDD: string;
}

interface Props extends RouteComponentProps<
    {}, // this.props.match.params.myParamProp
    any, // history
    InputProps
// this.props.location.state.myStateProp
> { }


const Payment: React.FC<Props> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [checkout, setCheckout] = useState(false);
    const { configContext } = useConfigContext()!;
    const myUser = useUser()!.user;
    let windowWidth = window.innerWidth;

    useEffect(() => {
        if (props.location.state?.productCode === undefined) props.history.push("/Packs")
        const script = document.createElement('script');
        script.src = "https://www.paypal.com/sdk/js?client-id=AZ_sCceTbHRqboqV8onKC03gchtACS7QOu8AmKzqQuwCOmRdPDkZMuvAwywpuHaQW-28HH1izjP3PoWg&currency=EUR";
        /* TEST */ //script.src = "https://www.paypal.com/sdk/js?client-id=ARujelqFE6XRzywQxTMRSZxEIGio1VjkIV-sKsgCCT4O_-FIwQZIOPBTmDFh661yO8b9GjQLMxXVgxTG&currency=EUR";
        script.async = true;
        document.body.appendChild(script);
        setIsLoading(false);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const tpvdata = {
        "DS_MERCHANT_AMOUNT": props.location.state.total! * 100,
        "DS_MERCHANT_CURRENCY": "978",
        "DS_MERCHANT_MERCHANTCODE": "353433964",
        "DS_MERCHANT_MERCHANTURL": "https://www.bliscop.app:3100/api/payments/tpvVirtual/app",
        "DS_MERCHANT_ORDER": props.location.state.idOrderPayment,
        "DS_MERCHANT_TERMINAL": "001",
        "DS_MERCHANT_TRANSACTIONTYPE": "0",
        "DS_MERCHANT_URLKO": "https://bliscop.app/packs",
        "DS_MERCHANT_URLOK": "https://bliscop.app/pay-ok"
    }
    const merchantWordArray = cryptojs.enc.Utf8.parse(JSON.stringify(tpvdata));
    const merchantBase64 = merchantWordArray.toString(cryptojs.enc.Base64);
    const commerceKey = "WSjx8SFP0GEmi7W7ijd8UpXzbHGgHTbF"
    const commerceKeyBase64 = cryptojs.enc.Base64.parse(commerceKey);

    // Generate transaction key
    const iv = cryptojs.enc.Hex.parse("0000000000000000");
    const cipher = cryptojs.TripleDES.encrypt(tpvdata.DS_MERCHANT_ORDER, commerceKeyBase64, {
        iv: iv,
        mode: cryptojs.mode.CBC,
        padding: cryptojs.pad.ZeroPadding
    });

    // Sign
    const signature = cryptojs.HmacSHA256(merchantBase64, cipher.ciphertext);
    const signatureBase64 = signature.toString(cryptojs.enc.Base64);

    // Done, we can return response
    const paramsRedsys = {
        Ds_SignatureVersion: "HMAC_SHA256_V1",
        Ds_MerchantParameters: merchantBase64,
        Ds_Signature: signatureBase64
    };

    return (
        <div className="basicContainerColor overflowXHidden">
            {myUser.userType !== 5 && <NavBar />}
            <div className="titleSection">{configContext.language === 0 ? "Detalles de Pago" : "Payment Details"}</div>
            {isLoading ?
                <LoadingSpin />
                :
                <div>
                    <div className="paymentInfo">
                        <div className="large mb_10px"><b>{configContext.language === 0 ? "Producto: " : "Product: "}</b> {props.location.state.description}</div>
                        <div className="large mb_10px"><b>Total:</b> {configContext.language === 0 ? props.location.state.total!.toFixed(2).toString().replace(".", ",") : props.location.state.total!.toFixed(2)}{configContext.language === 0 ? " Euros. IVA Incluido" : "Euros. VAT Included"}</div>
                    </div>
                    <div className="paymentsOptions mb_30px">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="sectionSquare" style={{ width: windowWidth > 600 ? 500 : 350 }}>
                                <div className="sectionSquareTitle">{configContext.language === 0 ? "Tarjeta" : "Card"}</div>
                                <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}></div>
                                <form name="from" action="https://sis.redsys.es/sis/realizarPago" method="POST">
                                    <input type="hidden" name="Ds_SignatureVersion" value={paramsRedsys.Ds_SignatureVersion} />
                                    <input type="hidden" name="Ds_MerchantParameters" value={paramsRedsys.Ds_MerchantParameters} />
                                    <input type="hidden" name="Ds_Signature" value={paramsRedsys.Ds_Signature} />
                                    <div className="textCenter">
                                        <button className="buttonPrimary" type="submit">{configContext.language === 0 ? "Pagar con Tarjeta" : "Payment by Card"}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="sectionSquare" style={{ width: 500 }}>
                            <div className="sectionSquareTitle">PayPal</div>
                            <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}>
                                {checkout === true ?
                                    <div className="textCenter mt_25px">
                                        <ReactPayPal price={props.location.state.total} language={configContext.language!} productCode={props.location.state.productCode} idOrderBBDD={props.location.state.idOrderBBDD} />
                                    </div>
                                    :
                                    <div className="textCenter">
                                        <img src={payPalImg} alt="PayPal" />
                                        <div className="mt_10px"><Button type="primary" onClick={() => setCheckout(true)}>{configContext.language === 0 ? "Pago con PayPal" : "Payment with PayPal"}</Button></div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Payment;