import React, { useEffect, useReducer } from 'react';

type RestaurantContextType = {
    restaurant: any;
    setRestaurant: (value: any) => void;
};

export const RestaurantContext = React.createContext<RestaurantContextType | undefined>(undefined);

type Props = {
    children: React.ReactNode;
};

let reducer = (restaurant: any, newRestaurant: any) => {
    if (newRestaurant === null) {
      localStorage.removeItem("restaurant");
      return undefined;
    }
    return { ...restaurant, ...newRestaurant };
  };
  
const localState = JSON.parse(localStorage.getItem("restaurant") || '{}');

export const RestaurantProvider = ({ children }: Props) => {
    const [restaurant, setRestaurant] = useReducer(reducer, localState || undefined);

    useEffect(() => {
        localStorage.setItem("restaurant", JSON.stringify(restaurant));
      }, [restaurant]);

return <RestaurantContext.Provider value={{ restaurant, setRestaurant}}>
        {children}
    </RestaurantContext.Provider>
};

export const useRestaurant = () => React.useContext(RestaurantContext);