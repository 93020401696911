import React from 'react';
import { Result, Button } from 'antd';

interface IProps {
    title: string;
    subTitle: string;
    goTo?: string;
}

export const Success = (item: IProps) => {
    return (
        <Result
            status="success"
            title={item.title}
            subTitle={item.subTitle}
            extra={item.goTo ? <a className="boldLarge" href="https://www.bliscop.app">{item.goTo}</a> : null}
        />
    )
}
