const provincesList = [
    {value: "A Coruña", label: "A Coruña"},
    {value: "Álava", label: "Álava"},
    {value: "Albacete", label: "Albacete"},
    {value: "Alicante", label: "Alicante"},
    {value: "Almería", label: "Almería"},
    {value: "Asturias", label: "Asturias"},
    {value: "Ávila", label: "Ávila"},
    {value: "Badajoz", label: "Badajoz"},
    {value: "Baleares", label: "Baleares"},
    {value: "Barcelona", label: "Barcelona"},
    {value: "Burgos", label: "Burgos"},
    {value: "Cáceres", label: "Cáceres"},
    {value: "Cádiz", label: "Cádiz"},
    {value: "Cantabria", label: "Cantabria"},
    {value: "Castellón", label: "Castellón"},
    {value: "Ceuta", label: "Ceuta"},
    {value: "Ciudad Real", label: "Ciudad Real"},
    {value: "Córdoba", label: "Córdoba"},
    {value: "Cuenca", label: "Cuenca"},
    {value: "Gipuzkoa", label: "Gipuzkoa"},
    {value: "Girona", label: "Girona"},
    {value: "Granada", label: "Granada"},
    {value: "Guadalajara", label: "Guadalajara"},
    {value: "Huelva", label: "Huelva"},
    {value: "Huesca", label: "Huesca"},
    {value: "Jaén", label: "Jaén"},
    {value: "La Rioja", label: "La Rioja"},
    {value: "Las Palmas", label: "Las Palmas"},
    {value: "León", label: "León"},
    {value: "Lérida", label: "Lérida"},
    {value: "Lugo", label: "Lugo"},
    {value: "Madrid", label: "Madrid"},
    {value: "Málaga", label: "Málaga"},
    {value: "Melilla", label: "Melilla"},
    {value: "Murcia", label: "Murcia"},
    {value: "Navarra", label: "Navarra"},
    {value: "Ourense", label: "Ourense"},
    {value: "Palencia", label: "Palencia"},
    {value: "Pontevedra", label: "Pontevedra"},
    {value: "Salamanca", label: "Salamanca"},
    {value: "Santa Cruz de Tenerife", label: "Santa Cruz de Tenerife"},
    {value: "Segovia", label: "Segovia"},
    {value: "Sevilla", label: "Sevilla"},
    {value: "Soria", label: "Soria"},
    {value: "Tarragona", label: "Tarragona"},
    {value: "Teruel", label: "Teruel"},
    {value: "Toledo", label: "Toledo"},
    {value: "Valencia", label: "Valencia"},
    {value: "Valladolid", label: "Valladolid"},
    {value: "Vizcaya", label: "Vizcaya"},
    {value: "Zamora", label: "Zamora"},
    {value: "Zaragoza", label: "Zaragoza"}
];

export default provincesList;