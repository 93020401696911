import React from 'react';
import { Table } from 'antd';
import { groupBy } from '../../Components/Basic/Utilities/tools';
import { IBasicProduct, IBills, IFilterList } from '../../Components/Basic/Utilities/interfaces';
import DownloadExcel from '../../Components/Basic/ExportExcel';

interface ICategories {
    bills: IBills[]
    basicProduct: IBasicProduct[]
    language: number
    coin: string
}

interface IDataTable {
    category?: string,
    product: string,
    quantity: number,
    price: number,
    pricePerUnit: number
}

const getColumnsCategories = (categoriesList: IFilterList[], coin: string, language: number) => {
    return [
        {
            title: language === 0 ? "Categorias" : "Categories",
            dataIndex: 'category',
            key: 'category',
            label: language === 0 ? "Categorias" : "Categories",
            filters: [...categoriesList],
            onFilter: (value: any, record: any) => record.category.indexOf(value) === 0,
        },
        {
            title: language === 0 ? "Producto" : "Product",
            dataIndex: 'product',
            key: 'product',
            label: language === 0 ? "Producto" : "Product",
        },
        {
            title: language === 0 ? "Cantidad" : "Quantity",
            dataIndex: 'quantity',
            key: 'quantity',
            label: language === 0 ? "Cantidad" : "Quantity",
            sorter: (a: any, b: any) => a.quantity - b.quantity,
        },
        {
            title: language === 0 ? `Importe (${coin})` : `Price (${coin})`,
            dataIndex: 'price',
            key: 'price',
            label: language === 0 ? `Importe (${coin})` : `Price (${coin})`,
            sorter: (a: any, b: any) => a.price - b.price,
        },
        {
            title: language === 0 ? `Precio por Unidad (${coin})` : `Price per Unit (${coin})`,
            dataIndex: 'pricePerUnit',
            key: 'pricePerUnit',
            label: language === 0 ? `Precio por Unidad (${coin})` : `Price per Unit (${coin})`,
            sorter: (a: any, b: any) => a.pricePerUnit - b.pricePerUnit,
        },
    ];
}

const GetCategories: React.FC<ICategories> = ({ bills, basicProduct, coin, language }) => {

    let totalProducts: IDataTable[] = []
    bills.forEach(e => e.products.forEach(i => totalProducts.push({
        category: basicProduct.find(j => j.name === i.product)?.type,
        product: i.product,
        quantity: i.quantity,
        price: i.price,
        pricePerUnit: i.price / i.quantity
    })))

    const groupedByProduct = groupBy(totalProducts, "product")
    let categoriesList: IFilterList[] = []

    const dataTable: IDataTable[] = Object.keys(groupedByProduct).map(prod => {
        const currentCategory = groupedByProduct[prod][0].category
        if (!categoriesList.find(e => e.text === currentCategory)) categoriesList.push({ text: currentCategory, value: currentCategory })
        const totalQuantity = Number(groupedByProduct[prod].reduce((s: number, a: any) => { return s + a.quantity }, 0).toFixed(2))
        const totalPrice = Number((groupedByProduct[prod].reduce((s: number, a: any) => { return s + a.price }, 0)).toFixed(2))
        return {
            category: groupedByProduct[prod][0].category,
            product: prod,
            quantity: totalQuantity,
            price: totalPrice,
            pricePerUnit: Number((totalPrice / totalQuantity).toFixed(2))
        }
    })

    if (totalProducts.length) {
        return (
            <>
                <DownloadExcel columns={getColumnsCategories(categoriesList, coin, language!)} dataSource={dataTable} name={language === 0 ? "Categorias" : "Categories"} />
                <Table
                    style={{ overflowX: "auto", maxWidth: 1000, margin: "auto" }}
                    columns={getColumnsCategories(categoriesList, coin, language!)}
                    dataSource={dataTable}
                />
            </>
        )
    }
    return <div className="p_10px">{language === 0 ? 'No se han registrado compras para las fechas seleccionadas' : 'No purchases have been registered for the selected dates.'}</div>
}

export default GetCategories;