import React from 'react';
import { Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getDataTokenRestaurant } from '../Basic/Utilities/tools';

export const isAuthenticated = () => {
  if (window.localStorage.getItem("tokenAuthComp") == null) {
    return false;
  } else if (Date.now() / 1000 > getDataTokenRestaurant().exp) {
    message.warning("Session expiry");
    window.localStorage.removeItem("tokenAuthComp");
    window.localStorage.removeItem("tokenAuth");
    window.localStorage.removeItem("restaurant");
    window.localStorage.removeItem("user");
  } else {
    return true;
  }
};

export const logout = () => {
  const language = JSON.parse(window.localStorage.getItem("configContext")).language;
  Modal.confirm({
    title: language === 0 ? '¿Quieres desconectarte?': 'Do you want to disconnect?',
    icon: <ExclamationCircleOutlined />,
    okText: language === 0 ? 'Sí': 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      if (window.localStorage.getItem("tokenAuthComp") != null) {
        window.localStorage.removeItem("tokenAuthComp");
      }
      if (window.localStorage.getItem("restaurant") != null) {
        window.localStorage.removeItem("restaurant");
      }
      if (window.localStorage.getItem("tokenAuth") != null) {
        window.localStorage.removeItem("tokenAuth");
      }
      if (window.localStorage.getItem("user") != null) {
        window.localStorage.removeItem("user");
      }
      window.location.href = '/';
    },
    onCancel() {
    },
  });
};
