import React from "react";
import { IStats, IGraph } from '../Components/Basic/Utilities/interfaces';
import { useWindowSize, dateWithoutYear } from '../Components/Basic/Utilities/tools';
import { BarVertical } from './Basic/Bar';
import moment from 'moment';
import { useConfigContext } from '../Context/ContextConfig';

const getDataOrderTypeCount = (input: { stats: IStats[], language: number }) => {
    const ret = input.stats.sort((a, b) => Number(moment(a.createdAt).format("x")) - Number(moment(b.createdAt).format("x"))).map(item => {
        return {
            "day": dateWithoutYear(item.day),
            [input.language === 0 ? "Restaurante" : "Restaurant"]: Number((item.localOrder || 0).toFixed(2)),
            [input.language === 0 ? "Recogida" : "Pickup"]: Number((item.pickOrder || 0).toFixed(2)),
            [input.language === 0 ? "A domicilio" : "Delivery"]: Number((item.homeOrder || 0).toFixed(2))
        }
    })
    return ret;
}

export const BarOrderTypeCount: React.FC<IGraph> = (props) => {
    const width = useWindowSize();
    const { configContext } = useConfigContext()!;

    return (
        <div className="sectionSquareGraph">
            <div className="sectionSquareTitleGraph">{props.title}</div>
            <div className="sectionSquareBodyGraph">
                <BarVertical {...props} margin={{ left: width[0] < 700 ? 5 : 70 }} leyendInfo={width[0] < 700 ? false : true} dataTable={getDataOrderTypeCount({ stats: props.data, language: configContext.language! })} />
            </div>
        </div>
    )
}