import React, { useState, useEffect } from 'react';
import { Col, Rate, Button, Form, Input } from 'antd';
import envLocal from '../../env-local';
import { IMenuOrder, IStateCreateMenu } from '../../Components/Basic/Utilities/interfaces';
import { layout } from '../../Components/Basic/Utilities/styles'
import { axiosCall, getDataTokenOrder, getTotalPriceFromOrder } from '../../Components/Basic/Utilities/tools';
import SkeletonLoad from '../../Components/Basic/SkeletonLoad';
import RegisterForm from '../../Components/Basic/RegisterForm';
import { useConfigContext } from '../../Context/ContextConfig';
import Header from '../../Components/Basic/Header';

let orderPrice: number = 0;

const Opinion: React.FC = () => {
    const [skeletonLoad, setSkeletonLoad] = useState<boolean>(true);
    const [request, setRequest] = useState<Array<IMenuOrder>>([]);
    const [opinionSended, setOpinionSended] = useState<boolean>(false);
    const [noTokenFinded, setNoTokenFinded] = useState<boolean>(true);
    const { configContext } = useConfigContext()!;
    const [orderParams] = useState<IStateCreateMenu | null>(getDataTokenOrder());

    useEffect(() => {
        if (orderParams) {
            setNoTokenFinded(false);
            getRequest();
        }
        setSkeletonLoad(false);
    }, []);

    const getRequest = async () => {
        const response = (await axiosCall("get", envLocal.endpointOrder, envLocal.tokenOrder)).data;
        if (response.status === 3) setOpinionSended(true);
        orderPrice = getTotalPriceFromOrder(response.order, false);
        const tmpOrder = response.order;
        setRequest(tmpOrder.filter((elem: any, index: any, self: any) => self.findIndex((t: any) => { return (t.idItem === elem.idItem && t.type === 1) }) === index));
    };

    const onFinish = async (values: any) => {
        await axiosCall("put", envLocal.endpointSetValoration, envLocal.tokenOrder, "", { values });
        setOpinionSended(true);
    };

    return (
        <>
            <Header />
            <div className="basicContainerColor textCenter">
                <div className="titleSection">{configContext.language === 0 ? 'Gracias por su visita' : 'Thanks for your visit'}</div>
                <div className="sectionSquare" style={{ margin: "30px 25px 45px" }}>
                    <div className="sectionSquareTitle">{configContext.language === 0 ? "Tu Opinión" : "Your Opinion"}</div>
                    <div className="sectionSquareBody" style={{ padding: "15px 0px" }}>
                        {skeletonLoad ?
                            <div style={{ width: "80%", margin: "auto" }}><SkeletonLoad title={true} quantity={4} marginTop={6} /></div>
                            :
                            noTokenFinded ? null
                                : opinionSended ?
                                    <p style={{ margin: "15px auto", fontWeight: "bold" }}>{configContext.language === 0 ? '¡Muchas gracias por compartir tu opinión!' : 'Thank you so much for sharing your opinion!'}</p>
                                    :
                                    <>
                                        <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
                                            <Form {...layout}
                                                onFinish={onFinish}
                                                style={{ margin: "20px 5px" }}
                                            >
                                                <p className="footerMenu">{configContext.language === 0 ? 'Nos sería de mucha ayuda conocer su opinión de los platos. ¿Nos podrías ayudar?' : 'It would be very helpful for us to know your opinion of the dishes. Could you help us?'}</p>
                                                {request.map(item =>
                                                    <Form.Item
                                                        key={item.idItem}
                                                        label={item.name}
                                                        name={item.idItem}
                                                        rules={[{ required: true, message: configContext.language === 0 ? 'Introduce una puntuación' : 'Enter a score' }]}
                                                    >
                                                        <Rate allowHalf />
                                                    </Form.Item>
                                                )}
                                                <Form.Item
                                                    key="comment"
                                                    label={configContext.language === 0 ? "Comentarios" : "Comments"}
                                                    name="comment"
                                                >
                                                    <Input.TextArea maxLength={250} placeholder={configContext.language === 0 ? 'Opcional' : 'Optional'}/>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Col xs={{ span: 8, offset: 8 }} sm={{ span: 2, offset: 22 }}>
                                                        <Button type='primary' htmlType='submit'>
                                                            {configContext.language === 0 ? 'Enviar' : 'Send'}
                                                        </Button>
                                                    </Col>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                        {orderParams && orderParams.idUser && orderParams.discountValue! > 0 && orderParams.minConsumption && orderPrice > orderParams.minConsumption ?
                                            <p style={{ margin: "15px 20px", fontWeight: "bold" }}>{configContext.language === 0 ? `Enhorabuena has conseguido un descuento del ${orderParams!.discountValue}% en tu próxima visita. Desde tu perfil puedes ver los descuentos disponibles.` : `Congratulations you got a ${orderParams!.discountValue}% discount on your next visit. From your profile you can see the available discounts.`}</p>
                                            : null
                                        }
                                    </>
                        }
                    </div>
                </div>
                {!orderParams?.idUser && <div className="sectionSquare" style={{ margin: "30px 25px" }}>
                    <div className="sectionSquareTitle">{configContext.language === 0 ? "Regístrate!" : "Register!"}</div>
                    <div className="sectionSquareBody" style={{ padding: "15px 0px" }}>
                        <div style={{ width: "100%" }}>
                            <div style={{ margin: "20px 5px", textAlign: "center" }}><b>{configContext.language === 0 ? 'Regístrese en nuestra Web y acceda a numerosos descuentos en nuestros restaurantes y muchas más ventajas. ¡Es muy sencillo!' : "Register on our website and get access to numerous discounts in our restaurants and many more advantages - it's easy!"}</b></div>
                            <Col xs={{ span: 20, offset: 2 }} sm={{ span: 14, offset: 3 }} md={{ span: 10, offset: 5 }} lg={{ span: 8, offset: 7 }}>
                                <RegisterForm company={false} originMainPage={true} />
                            </Col>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
};

export default Opinion;