import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Select, InputNumber, Tooltip, message } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { formItemLayout } from '../../Components/Basic/Utilities/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import envLocal from '../../env-local';
import NavBar from '../../Components/Basic/NavBar';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';

interface Props extends RouteComponentProps<
    {}, // this.props.match.params.myParamProp
    any, // history
    { user?: { _id: string, email: string, username: string, userType: number, assignedTable: number, firstTable: number, lastTable: number, color: string } } // this.props.location.state.myStateProp
    > { }

const CreateUserEmp: React.FC<Props> = (props) => {
    const { configContext } = useConfigContext()!;
    const [hasAssignedTable, setHasAssignedTable] = useState<any>(props.history.location.state.user !== undefined ? props.history.location.state.user.assignedTable : 1);
    const [role, setRole] = useState<any>(props.history.location.state.user !== undefined ? props.history.location.state.user.userType : 2);

    const onFinish = async (values: any) => {
        if (props.history.location.state.user === undefined && values.password.length < 8) {
            message.warning(configContext.language === 0 ? "La contraseña tiene que tener una longitud mínima de 8 caracteres" : "The password has to be at least 8 characters long");
        } else {
            if (props.history.location.state.user !== undefined) {
                const response = await axiosCall("put", envLocal.endpointAuthenticationComp, envLocal.authComp, "updateEmp/" + props.history.location.state.user._id, values);
                if (response.status === 200) {
                    message.success(configContext.language === 0 ? "Usuario Actualizado!" : "User Updated!");
                    props.history.push("/Employees");
                } else if (response.status === 400) {
                    message.error(configContext.language === 0 ? "Ese email ya existe" : "The Email already exists");
                } else {
                    message.error("Error");
                };
            } else {
                const response = await axiosCall(
                    "post",
                    envLocal.endpointAuthenticationComp,
                    envLocal.authComp,
                    "",
                    { ...values, status: 3, validated: true }
                );
                if (response.status === 200) {
                    message.success(configContext.language === 0 ? "Usuario Creado!" : "User Created!");
                    props.history.push("/Employees");
                } else if (response.status === 400) {
                    message.error(configContext.language === 0 ? "Ese email ya existe" : "The Email already exists");
                } else {
                    message.error("Error");
                };
            };
        };
    };

    return (
        <div className="basicContainer">
            <NavBar />
            <div className="titleSection2">{configContext.language === 0 ? "Rellena los datos del Usuario" : "Fill in the user's data"}</div>
            <Row className="mt_20px mb_40px">
                <Col span={20} offset={2}>
                    <Form {...formItemLayout}
                        onFinish={onFinish}
                        initialValues={{
                            email: props.history.location.state.user?.email || null,
                            username: props.history.location.state.user?.username || null,
                            userType: props.history.location.state.user?.userType || null,
                            color: props.history.location.state.user?.color || "#3689FA",
                            assignedTable: props.history.location.state.user?.assignedTable || null,
                            firstTable: props.history.location.state.user?.firstTable || null,
                            lastTable: props.history.location.state.user?.lastTable || null,
                        }}
                    >
                        <Form.Item
                            label='Email'
                            name='email'
                            rules={[{ required: true, type: 'email', message: configContext.language === 0 ? 'Introduce un correo electrónico' : 'Enter an email' }]}
                        >
                            <Input maxLength={52} />
                        </Form.Item>
                        <Form.Item
                            label={configContext.language === 0 ? 'Nombre de usuario' : 'User name'}
                            name='username'
                            rules={[{ required: true, message: configContext.language === 0 ? 'Introduce tu nombre de usuario' : 'Enter your username' }]}
                        >
                            <Input maxLength={9} />
                        </Form.Item>
                        <Form.Item
                            label={configContext.language === 0 ? "Contraseña" : 'Password'}
                            name="password"
                            rules={[{ required: props.history.location.state.user === undefined, message: configContext.language === 0 ? 'Introduce la Contraseña' : 'Enter Password' }]}
                        >
                            <Input.Password maxLength={15} />
                        </Form.Item>
                        <Form.Item
                            label={configContext.language === 0 ? 'Tipo de usuario' : 'User type'}
                            name='userType'
                            rules={[{ required: props.history.location.state.user === undefined, message: configContext.language === 0 ? 'Introduce el tipo de usuario' : 'Enter the user type' }]}
                        >
                            <Select onChange={(e) => setRole(e)}>
                                <Select.Option value={3}>{configContext.language === 0 ? 'Camarero' : 'Waiter'}</Select.Option>
                                <Select.Option value={2}>{configContext.language === 0 ? 'Cocinero' : 'Chef'}</Select.Option>
                                <Select.Option value={1}>{configContext.language === 0 ? 'Administrador' : 'Admin'}</Select.Option>
                                <Select.Option value={4}>Maitre</Select.Option>
                            </Select>
                        </Form.Item>
                        {role === 3 && <Form.Item
                            label={
                                <span>
                                    {configContext.language === 0 ? 'Tiene mesas asignadas ' : 'He has tables assigned '}
                                    <Tooltip title={configContext.language === 0 ? 'Aquí indicarás si un camarero cubre un determiando número de mesas del local. Ej: Atiende sólo de la 5 a la 10.' : "Here you will indicate if a waiter covers a certain number of tables in the restaurant. E.g.: He only serves from 5 to 10."}>
                                        <QuestionCircleTwoTone />
                                    </Tooltip>
                                </span>
                            }
                            name='assignedTable'
                            rules={[{ required: true, message: configContext.language === 0 ? 'Introduce si tiene mesas asignadas' : 'Enter if he has tables assigned' }]}
                        >
                            <Select onChange={(e) => setHasAssignedTable(e)}>
                                <Select.Option value={1}>No</Select.Option>
                                <Select.Option value={2}>{configContext.language === 0 ? 'Sí' : 'Yes'}</Select.Option>
                            </Select>
                        </Form.Item>}
                        {hasAssignedTable === 2 && role === 3 && <div>
                            <Form.Item
                                label={configContext.language === 0 ? 'Desde la mesa' : "From table"}
                                name='firstTable'
                                rules={[{ required: true, message: configContext.language === 0 ? 'Introduce las mesas asignadas' : 'Enter the assigned tables' }]}
                            >
                                <InputNumber min={1} />
                            </Form.Item>
                            <Form.Item
                                label={configContext.language === 0 ? 'Hasta la mesa' : "To table"}
                                name='lastTable'
                                rules={[{ required: true, message: configContext.language === 0 ? 'Introduce las mesas asignadas' : 'Enter the assigned tables' }]}
                            >
                                <InputNumber min={1} />
                            </Form.Item>
                        </div>}
                        <Form.Item
                            label={configContext.language === 0 ? 'Color del usuario' : "User's color"}
                            name='color'
                        >
                            <Input type="color" />
                        </Form.Item>
                        <Row>
                            <Col xs={{ span: 12, offset: 0 }} sm={{ span: 4, offset: 8 }}>
                                <Form.Item>
                                    <Button type='primary' htmlType='submit'>
                                        {configContext.language === 0 ? 'Guardar' : "Save"}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default withRouter(CreateUserEmp);