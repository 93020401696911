import React from 'react';
import { Error500 } from '../../Components/Basic/Results/Error500';
import { useConfigContext } from '../../Context/ContextConfig';

const ErrorPage: React.FC = () => {
    const { configContext } = useConfigContext()!;
    return (
        <Error500 title={configContext.language === 0 ? "Ha ocurrido un error" : "An error has occurred"} subTitle={configContext.language === 0 ? "Si el problema persiste, por favor contacta con un administrador." : "If the problem persists, please contact an administrator."} />
    );
};

export default ErrorPage;