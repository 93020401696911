import React from 'react';
import { Col, Row } from 'antd';
import { useConfigContext } from '../../Context/ContextConfig';
import Header from '../../Components/Basic/Header';

const Politics: React.FC = () => {
       const { configContext } = useConfigContext()!;

       return (
              <div>
                     <Header />
                     <Col xs={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }}>
                            <h1><p className="textCenter mt_20px">{configContext.language === 0 ? "Política de Privacidad, Cookies y Condiciones de Servicio" : "Privacy Policy, Cookies and Terms of Service"}</p></h1>
                            {configContext.language === 0 ? <h4 className="textCenter">
                                   Esta página (junto con los documentos a los que se hace referencia en la misma) le indica los términos y condiciones en que nuestros restaurantes asociados suministran cualquiera de sus productos listados para Vd. en nuestro sitio web. Lea detenidamente los presentes términos y condiciones antes de realizar cualquier pedido desde nuestro sitio web. Al acceder a nuestro sitio web y realizar un pedido, Vd. acepta someterse a estas condiciones y a los términos de nuestra política de utilización.
                    <br />
                                   <br />
                                   <br />
                    Si tiene alguna pregunta relativa a los presentes términos y condiciones, póngase en contacto antes de realizar su pedido. Si no acepta estos términos y condiciones en su totalidad, absténgase de utilizar nuestro Servicio.
                    <br />
                                   <br />
                                   <br />
1. INFORMACIÓN SOBRE NOSOTROS
<br />
                                   <br />
                                   <br />
BLISCOP es una plataforma web encargada de la digitalización de los diversos procesos en el sector de la restauración. La comida está preparada por restaurantes independientes y nosotros efectuamos su proceso de pedido y gestión.
<br />
                                   <br />
                                   <br />
2. FINALIDAD
<br />
                                   <br />
                                   <br />
La finalidad de nuestro Servicio es proporcionarle un servicio sencillo y cómodo, poniendo al cliente en contacto con el Restaurante asociado y con el menú de su elección y ofreciéndole la posibilidad  de pedir las Comidas que desee. BLISCOP comercializa Comidas en nombre de nuestros Restaurantes asociados, concluye pedidos en nombre de éstos. Además ofrece a los restaurantes varias herramientas para facilitar su gestión.
<br />
                                   <br />
                                   <br />
2. PERFECCIÓN DEL CONTRATO
<br />
                                   <br />
                                   <br />
El contrato se entenderá perfeccionado entre BLISCOP y el usuario de la aplicación en el momento en que éste último, una vez realizado el pedido, reciba a través de la propia herramienta, una nota de confirmación del mismo. Para pedidos desde fuera del restaurante, también se confirmará con un email al correo facilitado. Para poder ser Cliente de la plataforma es indispensable que se cumplan los siguientes requisitos:
<br />
                                   <br />
                                   <br />
- Cumplimentar de manera veraz los campos obligatorios del formulario de registro, en el que se solicitan datos de carácter personal como nombre de usuario, correo electrónico, contraseña y de manera opcional número de teléfono y dirección.
<br />
                                   <br />
                                   <br />
- Aceptar las presentes Condiciones de uso, que incluyen la aceptación de la política de Privacidad y Protección de Datos.
<br />
                                   <br />
                                   <br />
- En el caso de tratarse de un restaurante, además se deberán indicar: Nombre del Restaurante, País en el que se ubica, Provincia, Ciudad, Calle, Número, Código Postal, Teléfono, Código de Pedido, Número de Mesas, Tipo de Moneda y Tipo de Restaurante.
                    <br />
                                   <br />
                                   <br />
El Usuario garantiza que todos los datos sobre su identidad y legitimidad facilitados a BLISCOP en sus formularios de registro de la Plataforma son veraces, exactos y completos. Asimismo se compromete a mantener actualizados sus datos.
<br />
                                   <br />
                                   <br />
En el supuesto en que el Usuario facilite cualquier dato falso, inexacto o incompleto o si BLISCOP considera que existen motivos fundados para dudar sobre la veracidad, exactitud e integridad de los mismos, BLISCOP podrá denegarle el acceso y uso presente o futuro de la Plataforma o de cualquiera de sus contenidos y/o servicios.
<br />
                                   <br />
                                   <br />
Al darse de alta en la Plataforma el usuario seleccionará un nombre de usuario (username) y una clave de acceso (password). Tanto el username como el password son estrictamente confidenciales, personales e intransferibles.
<br />
                                   <br />
                                   <br />
El usuario se compromete a no divulgar los datos relativos a su cuenta ni hacerlos accesibles a terceros. El usuario será el único responsable en caso de uso de dichos datos por terceros, incluidas las manifestaciones vertidas en la Plataforma, o cualquier otra actuación que se lleve a cabo mediante el uso del username y/o password.
<br />
                                   <br />
                                   <br />
BLISCOP no puede garantizar la identidad de los usuarios registrados, por tanto, no será responsable del uso de la identidad de un usuario registrado por terceros no registrados. Los usuarios se obligan a poner inmediatamente en conocimiento de BLISCOP la sustracción, divulgación o pérdida de su username o password, comunicándolo a la siguiente dirección de correo electrónico: contact@bliscop.com
<br />
                                   <br />
                                   <br />
Para poder completar el registro en la Plataforma el Usuario deberá proporcionar algunos datos como: nombre de Usuario, dirección de correo electrónico, teléfono, etc. Una vez completado el registro, todo Usuario podrá acceder a su perfil y completarlo y/o editarlo según estime conveniente.El Usuario podrá darse de baja de la Plataforma comunicándolo mediante un correo electrónico a través de la dirección: contact@bliscop.com
<br />
                                   <br />
                                   <br />
3. OBJETO. SERVICIOS OFRECIDOS. PEDIDOS
<br />
                                   <br />
                                   <br />
Cuando realice un pedido desde fuera del restaurante a través de nuestro Servicio, le enviaremos, en nombre del Restaurante asociado, un correo electrónico agradeciéndole el pedido y confirmando que éste ha sido recibido y aceptado por dicho Restaurante asociado. El contrato para el suministro de cualquier Comida que pida a través de nosotros se establecerá entre Vd. y el Restaurante asociado y se formalizará únicamente cuando le hayamos enviado el Correo electrónico de confirmación. Asegúrese de que nos ha facilitado una dirección de correo electrónico correcta, ya que será el modo de comunicarnos con Vd. respecto a su Pedido. Desde el propio restaurante, el modo de notificación es mediante la aplicación.
<br />
                                   <br />
                                   <br />
A través de esta aplicación el usuario, tras darse de alta en la plataforma, podrá efectuar pedidos en los Restaurantes asociados a BLISCOP. Utilizando estos servicios el usuario está aceptando las condiciones que figuran a continuación.Todos los productos que aparecen en la aplicación están condicionados a su disponibilidad.
<br />
                                   <br />
                                   <br />
Su Restaurante asociado podrá ofrecerle una alternativa a cualquier comida que no pueda proporcionarle. Es posible que nuestros Restaurantes asociados utilicen frutos secos en la preparación de ciertas comidas. Si Vd. padece alguna alergia, puede consultar en la carta los posibles alergenos. BLISCOP no puede garantizar que las Comidas vendidas por nuestros Restaurantes asociados estén libres de alergénicos.
<br />
                                   <br />
                                   <br />
4. VENTA DE ALCOHOL
<br />
                                   <br />
                                   <br />
Las personas que realicen un pedido de alcohol a nuestros Restaurantes asociados deben tener 18 años o más. Las bebidas alcohólicas sólo pueden venderse a personas mayores de 18 años. Al realizar un pedido que incluya alcohol, Vd. confirma que tiene al menos 18 años de edad. BLISCOP se reserva el derecho de negarse a entregar alcohol a cualquiera persona que no pueda probar tener al menos 18 años de edad.
<br />
                                   <br />
                                   <br />
5. PRECIO DE LOS PRODUCTOS
<br />
                                   <br />
                                   <br />
El precio de todas las Comidas figurará listado en nuestro Servicio. Los precios incluyen el IVA. Los precios variarán entre los menús. Los precios son susceptibles de modificación en cualquier momento, pero los cambios no afectarán a los pedidos con respecto a la información que se le presenta en la confirmación, salvo en caso de error obvio en el precio, en cuyo caso le informaremos con la mayor brevedad posible acerca del problema relativo al precio. No podrá cancelar su pedido una vez usted hay confirmado el pedido y se lo hayamos notificado. A pesar de nuestro mejor empeño, algunas de las Comidas listadas en nuestro Servicio pueden presentar precios incorrectos. Normalmente, el Restaurante asociado que corresponda comprobará los precios como parte del procedimiento del pedido.
<br />
                                   <br />
                                   <br />
6. FORMA Y CONDICIONES DE PAGO
<br />
                                   <br />
                                   <br />
Concluido el servicio, el restaurante será el encargado de la gestión del pago empleando los métodos de los que disponga de manera habitual.
<br />
                                   <br />
                                   <br />
7. DEVOLUCIONES Y REEMBOLSOS
<br />
                                   <br />
                                   <br />
Si, por cualquier causa que fuere, existiere un error en el cobro de la factura, el usuario deberá comunicarlo al restaurante debido a que son los responsables del cobro del pedido.
<br />
                                   <br />
                                   <br />
8. NUESTRA RESPONSABILIDAD
<br />
                                   <br />
                                   <br />
En la medida de lo permitido por la ley, BLISCOP ofrece nuestro Servicio y contenido "tal cual" y en función de la disponibilidad, y no realizamos en este sentido ninguna declaración ni garantía de ningún tipo, explícita ni implícita, con respecto al contenido o a la disponibilidad de nuestro Servicio, ni podemos garantizar que carezca de errores ni que cualquier defecto sea corregido de modo oportuno. Sin perjuicio de lo estipulado más adelante, ni BLISCOP ni ningún Restaurante asociado tendrán ninguna responsabilidad para con Vd. por ninguna pérdida o daño, directo, indirecto, especial o consecuencial, que se derive del contrato, de responsabilidad civil (incluida negligencia) o que de cualquier otro modo se derive de su utilización o de su imposibilidad de utilizar nuestro Servicio. En el supuesto de que BLISCOP o el Restaurante asociado fuesen hallados responsables para con Vd., el conjunto total de nuestras obligaciones se limitará al precio de compra de las Comidas que Vd. haya pagado en su pedido. Esto no excluye ni limita de ningún modo la responsabilidad de BLISCOP o de cualquier Restaurante asociado por cualquier elemento cuya exclusión por nuestra parte fuese ilegal, ni supone un intento de excluir tanto nuestra responsabilidad como la de los Restaurantes asociados, incluida la responsabilidad por fallecimiento o lesión personal causados por negligencia, fraude o declaración falsa y fraudulenta. BLISCOP no se hace responsable de posibles fallos o retrasos del establecimiento en la prestación del servicio, siendo únicamente responsable el adecuado funcionamiento de la aplicación durante todo el proceso de prestación de los mismos.
<br />
                                   <br />
                                   <br />
9. DERECHOS DE PROPIEDAD INTELECTUAL
<br />
                                   <br />
                                   <br />
Todos los derechos de propiedad intelectual que integran la aplicación son propiedad de BLISCOP y nada en estos Términos constituirá una cesión de los mismos. Los derechos de Propiedad Intelectual incluyen las patentes, los derechos sobre las invenciones, modelos de utilidad, derechos de autor y derechos conexos, marcas comerciales, marcas de servicio, comerciales, empresariales y de dominio, derechos de imagen comercial o presentación, los derechos de no competencia, competencia desleal, derechos de diseños, derechos de software, base de datos, derechos de topografía, derechos morales, derechos de información confidencial (incluyendo know-how y secretos comerciales ) y cualquier otro derecho de propiedad intelectual, que esté registrado o no, y que incluye todas las solicitudes y renovaciones o extensiones de tales derechos, y todos los derechos o formas de protección semejantes o equivalentes en cualquier parte del mundo.
<br />
                                   <br />
                                   <br />
10. SUBIR MATERIAL A NUESTRO SITIO Y NUESTRO SERVICIO
<br />
                                   <br />
                                   <br />
Cualquier material que suba a nuestro Servicio será considerado no confidencial y no privado, y Vd. reconoce y acepta que nosotros tenemos el derecho a usar, copiar, distribuir, vender y divulgar a terceros el material citado con cualquier finalidad ligada a nuestra actividad económica. En la medida en que dicho material esté protegido por derechos de propiedad intelectual, Vd. nos otorga una licencia perpetua, para todos los territorios del mundo y gratuita, para el uso, copia, modificación, distribución, venta y divulgación a terceros de dicho material o información para cualquier finalidad ligada a nuestra actividad económica.
<br />
                                   <br />
                                   <br />
11. COMUNICACIONES
<br />
                                   <br />
                                   <br />
Dando cumplimiento a la normativa aplicable, parte de la información o comunicaciones que se envíen al usuario se realizará por escrito. Al utilizar nuestro sitio Web y plataforma móvil  el usuario acepta que las comunicaciones con BLISCOP sean principalmente electrónicas. BLISCOP se pondrá en contacto con el usuario a través de la propia aplicación.
<br />
                                   <br />
                                   <br />
A efectos contractuales el usuario acepta estos medios de comunicación y reconoce la validez de los contratos, notificaciones, información y otras comunicaciones que le sean facilitadas por estos medios. Esta condición no afecta a los derechos que le pudieran corresponder.
<br />
                                   <br />
                                   <br />
Las comunicaciones a BLISCOP deberán realizarse a la siguiente dirección: contact@bliscop.com El usuario será notificado mediante el correo electrónico que proporcione al darse de alta en la aplicación. El uso de su información personal facilitada a través de la Web se regirá por nuestra Política de Privacidad y Política de Cookies.
<br />
                                   <br />
                                   <br />
12. POLITICA DE PRIVACIDAD
<br />
                                   <br />
                                   <br />
El Usuario, al proporcionar a BLISCOP sus datos de carácter personal a través de los formularios electrónicos de la Web o de la Aplicación, consiente expresamente que BLISCOP  pueda tratar esos datos en los términos de esta cláusula de Política de Privacidad y Protección de Datos y para los fines aquí expresados. Antes de registrase en BLISCOP, los usuarios deben leer la presente Política de Privacidad y Protección de Datos.Al marcar el botón “registrarse”, los usuarios afirman que han leído y que consienten expresamente las presente Política de Privacidad de Datos.
<br />
                                   <br />
                                   <br />
Al registrarse, los usuarios deberán proporcionar algunos datos para la creación de su cuenta y la edición de su perfil. Éstos deberán proporcionar los siguientes datos: nombre de Usuario, Contraseña y Correo Electrónico, de manera opcional Teléfono y Dirección.
<br />
                                   <br />
                                   <br />
- En el caso de tratarse de un restaurante, además se deberán indicar: Nombre del Restaurante, País en el que se ubica, Provincia, Ciudad, Calle, Número, Código Postal, Teléfono, Código de Pedido, Número de Mesas, Tipo de Moneda y Tipo de Restaurante.
<br />
                                   <br />
                                   <br />
Una vez completado el registro, todo usuario podrá acceder a su perfil y completarlo y/o editarlo según estime conveniente. La información y datos facilitados por el usuario estarán en todo momento disponibles en su cuenta de usuario y podrán ser modificados por el usuario a través de la opción editar perfil.
<br />
                                   <br />
                                   <br />
El usuario se compromete a introducir datos reales y veraces. Asimismo, será el único responsable de los daños y perjuicios que BLISCOP o terceros pudieran sufrir como consecuencia de la falta de veracidad, inexactitud, falta de vigencia y autenticidad de los datos facilitados. Los datos recabados por BLISCOP serán exclusivamente utilizados para la consecución del objeto definido en las presentes Condiciones Generales de Uso de la Web.
<br />
                                   <br />
                                   <br />
13. NULIDAD
<br />
                                   <br />
                                   <br />
Si cualquier estipulación, o parte de la misma, de las Condiciones de esta aplicación móvil fuera declarada ilegal, nula o no aplicable de otra forma, por cualquier juzgado o autoridad competente, dicha estipulación o parte de la misma será eliminada de las Condiciones de la aplicación, y el resto de Condiciones de la misma regirán como si la estipulación, o parte de la misma, ilegal, nula o no aplicable, nunca se hubiera acordado.
<br />
                                   <br />
                                   <br />
14. DERECHO A MODIFICAR LOS TÉRMINOS Y CONDICIONES
<br />
                                   <br />
                                   <br />
BLISCOP se reserva el derecho de revisar y modificar estos Términos y Condiciones periódicamente. El usuario estará sujeto a las políticas y los términos y condiciones vigentes en el momento en que efectúe un pedido.
<br />
                                   <br />
                                   <br />
15. PROTECCIÓN DE DATOS PERSONALES
<br />
                                   <br />
                                   <br />
Todos los datos personales que BLISCOP obtenga del usuario serán procesados conforme a nuestra política de privacidad, que se incorpora a estos Términos y está disponible aquí.
<br />
                                   <br />
                                   <br />
16. LEY APLICABLE Y JURISDICCIÓN
<br />
                                   <br />
                                   <br />
Las Condiciones de esta aplicación se regirán e interpretarán de conformidad con la ley española. BLISCOP y el usuario, con renuncia expresa a cualquier otro fuero o legislación aplicable que pudiere corresponderles, se someten a la legislación de Derecho común española y a la jurisdicción de los Juzgados y Tribunales de la ciudad de Madrid (España), con renuncia a cualquier otro fuero que pudiere corresponderles.
<br />
                                   <br />
                                   <br />
Por favor comprenda que si rechaza aceptarlos no podrá emplear nuestra aplicación.
<br />
                                   <br />
                                   <br />
Aviso Legal y Política de Privacidad
<br />
                                   <br />
                                   <br />
Objeto Social. BLISCOP es una empresa dedicada al desarrollo, diseño, y comercialización de un software a través del cual los usuarios pueden realizar pedidos desde su dispositivo móvil en los restaurantes colaboradores y facilita la gestión de los restaurantes.
<br />
                                   <br />
                                   <br />
Identificación. En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, los datos aquí consignados corresponden a la entidad titular del sitio web Bliscop.es, Bliscop.app y Bliscop.com
<br />
                                   <br />
                                   <br />
Denominación: BLISCOP
Domicilio: Madrid
Email: contact@bliscop.com
<br />
                                   <br />
                                   <br />
Propiedad intelectual e industrial. Todos los derechos de Propiedad Industrial e Intelectual de la totalidad de elementos contenidos en esta Web pertenecen a BLISCOP y se encuentran protegidos por las leyes españolas e internacionales sobre propiedad Intelectual e Industrial. Queda expresamente prohibida la reproducción total o parcial de este sitio Web y de cualquiera de sus contenidos sin el permiso expreso y por escrito de BLISCOP.
<br />
                                   <br />
                                   <br />
El acceso al sitio web no implica ningún tipo de renuncia, transmisión, licencia o cesión de dichos derechos por parte de BLISCOP o los titulares de los derechos sobre los signos distintivos que se muestran en esta página web, salvo que se establezca expresamente lo contrario.
<br />
                                   <br />
                                   <br />
Condiciones de uso. El acceso a este sitio Web implica la aceptación de estas condiciones de uso sin reservas que regulan el acceso y la utilización del mismo con el fin de poner a disposición de los usuarios información sobre nuestros servicios.
<br />
                                   <br />
                                   <br />
Se prohíbe expresamente la utilización de los contenidos de este sitio Web para su utilización con fines comerciales o para su distribución, transformación o comunicación.
<br />
                                   <br />
                                   <br />
BLISCOP, no responderá de ninguna consecuencia, daño o perjuicio que pudieran derivarse de dicha utilización o uso de la información
<br />
                                   <br />
                                   <br />
Tanto el acceso a esta Web como el uso que pueda hacerse de la información contenida en la misma es de la exclusiva responsabilidad de quien lo realiza.
<br />
                                   <br />
                                   <br />
El usuario se obliga a no utilizar la información que se publica en esta Web con fines o efectos ilícitos o lesivos, a no dañar o inutilizar la información y a no realizar cualquier otra acción que puedan ser contraria al contenido de este Aviso Legal.
<br />
                                   <br />
                                   <br />
BLISCOP se reserva el derecho a modificar los contenidos de la oferta comercial de sus servicios cuando lo estime oportuno y a mantener su contenido actualizado.
<br />
                                   <br />
                                   <br />
BLISCOP no puede asegurar la inexistencia de interrupciones o errores en el acceso a este sitio Web, aunque pondrá sus mayores esfuerzos para evitarlos.
<br />
                                   <br />
                                   <br />
Política de Protección de Datos. En cumplimiento del REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de Abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y su normativa de desarrollo, se le informa que los datos personales proporcionados por usted serán tratados con la finalidad de facilitarle la información solicitada acerca de nuestros servicios y proyectos de reforma, gestión técnica de edificios e interiorismo y gestionar nuestra relación comercial.
<br />
                                   <br />
                                   <br />
A través de la cumplimentación del formulario de la Web o cualquier otro tipo de solicitud de información remitida a BLISCOP, el interesado presta su consentimiento expreso para el tratamiento de sus datos personales.
<br />
                                   <br />
                                   <br />
En ningún caso BLISCOP utilizará los datos personales de los interesados para fines distintos de los anteriormente mencionados, ni los comunicará a terceros sin el consentimiento previo y expreso del afectado, y se compromete a guardar el debido secreto profesional y a establecer las medidas técnicas y organizativas necesarias para salvaguardar la información conforme a los requerimientos que establece el mencionado Reglamento.
<br />
                                   <br />
                                   <br />
Sus datos personales se conservarán en nuestros registros durante el tiempo necesario para el correcto desempeño de la herramienta. No obstante, en cualquier momento usted puede solicitar el acceso a sus datos personales y su rectificación o supresión. Así como, limitar su tratamiento, o directamente oponerse al tratamiento o ejercer el derecho a la portabilidad de los mismos. También le informamos de su derecho a presentar una reclamación ante la Agencia Española de Protección de Datos, si considera que en el tratamiento de sus datos no se están respetando sus derechos.
<br />
                                   <br />
                                   <br />
BLISCOP recuerda a las personas mayores de edad que tengan a su cargo menores, que será de su exclusiva responsabilidad si algún menor incorpora sus datos para solicitar algún servicio. También les informa que existen programas informáticos para acotar la navegación mediante el filtro o bloqueo a determinados contenidos.
<br />
                                   <br />
                                   <br />
Redes sociales. Al seguir nuestro perfil de las redes sociales consiente expresamente en el tratamiento de sus datos personales conforme a su política de privacidad. También consiente expresamente el acceso de BLISCOP al tratamiento de sus datos contenidos en la lista de amigos y a que a que las noticias publicadas sobre los servicios ofrecidos por BLISCOP aparezcan en  su muro.
<br />
                                   <br />
                                   <br />
De conformidad con el REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO, le informamos que los datos personales de los seguidores de BLISCOP en las redes sociales, serán incorporados a un fichero cuyo titular es BLISCOP con la finalidad de mantenerle informados a través de las redes sociales sobre noticias relativas a los servicios ofrecidos por BLISCOP a través de su aplicación. Su petición para conectar, implica necesariamente su consentimiento para los tratamientos señalados.
<br />
                                   <br />
                                   <br />
La publicación de comentarios y contenidos en las redes sociales se convertirán en información pública, por lo que los usuarios deberán tener especial cautela cuando decidan compartir su información personal. BLISCOP no se hace responsable por la información que los usuarios incorporen en la página. No obstante, las personas cuyos datos personales se encuentren publicados o estén incluidos en comentarios, podrán solicitar a BLISCOP la cancelación de los mismos.
<br />
                                   <br />
                                   <br />
En cualquier momento, usted puede ejercer los derechos de acceso, rectificación, supresión y oposición, limitar el tratamiento de sus datos, o ejercer el derecho a la portabilidad de los mismos. Todo ello, mediante escrito, acompañado de copia de documento oficial que le identifique, dirigido a BLISCOP En caso de disconformidad con el tratamiento, también tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos.
<br />
                                   <br />
                                   <br />
El usuario se obliga a no emplear la herramienta con fines dañinos para las empresas que ofrecen sus servicios en la aplicación. Será responsable de cualquier acción malintencionada independientemente del fin de esta.
<br />
                                   <br />
                                   <br />
Enlaces a otras Webs. Los enlaces (links) que puede encontrar en esta Web son un servicio a los usuarios. Estas páginas no son operadas ni controladas por BLISCOP por ello, BLISCOP no se hace responsable de los contenidos de esos sitios Web ni están cubiertas por el presente Aviso Legal. Si accede a estas páginas Webs deberá tener en cuenta que sus políticas de privacidad pueden ser diferentes a la nuestra.
<br />
                                   <br />
                                   <br />
Legislación aplicable y competencia jurisdiccional. El presente Aviso Legal se rige por la normativa española vigente que le es de aplicación.
<br />
                                   <br />
                                   <br />
Para la resolución de las controversias que pudieran derivarse como consecuencia de lo dispuesto en las presentes disposiciones y sobre su interpretación, aplicación y cumplimiento. El usuario, en virtud de la aceptación de las condiciones recogidas en este Aviso legal, renuncia expresamente a cualquier otro fuero que pudiera corresponderle.
<br />
                                   <br />
                                   <br />
En cualquier caso, dentro de la jurisdicción española, si la legislación permitiese someterse a un fuero en concreto, el usuario renuncia expresamente al fuero que pudiera corresponderle y se somete voluntariamente a la jurisdicción de los Juzgados y Tribunales de Madrid.
     </h4>
                                   :
                                   <h4 className="textCenter">
                                          This page (together with the documents referred to on it) tells you the terms and conditions under which our partner restaurants supply any of their products listed for you on our website. Please read these terms and conditions carefully before placing any order from our website. By accessing our website and placing an order, you agree to be bound by these terms and conditions and the terms of our use policy.
<br />
                                          <br />
                                          <br />
If you have any questions regarding these terms and conditions, please contact us before placing your order. If you do not accept these terms and conditions in full, please do not use our Service.
<br />
                                          <br />
                                          <br />
1. INFORMATION ABOUT US
<br />
                                          <br />
                                          <br />
BLISCOP is a web platform in charge of the digitalization of the different processes in the restaurant sector. The food is prepared by independent restaurants and we carry out their order and management process.
<br />
                                          <br />
                                          <br />
2. PURPOSE
<br />
                                          <br />
                                          <br />
The purpose of our Service is to provide a simple and comfortable service, putting the customer in contact with the associated Restaurant and with the menu of his choice and offering him the possibility to order the Meals he wants. BLISCOP markets meals on behalf of our partner restaurants, and concludes orders on their behalf. Bliscop also has tools to make easy the management in the restaurants.
<br />
                                          <br />
                                          <br />
2. COMPLETION OF THE CONTRACT
<br />
                                          <br />
                                          <br />
The contract will be understood to have been concluded between BLISCOP and the user of the application at the time when the menu, once the order has been placed, receives a confirmation note from the tool itself. In order to become a Client of the platform, the following requirements must be met:
<br />
                                          <br />
                                          <br />
- Fill in a truthful way the obligatory fields of the registration form, in which personal data are requested such as user name, e-mail, password number and maybe address and telephone.
<br />
                                          <br />
                                          <br />
- If you are a restaurant you must enter: Restaurant name, Country, Province, City, Street, Number, Zip number, Telephone, Order code, Table number, Currency y Restaurant type.
                    <br />
                                          <br />
                                          <br />
- Accept these Terms of Use, which include acceptance of the Privacy and Data Protection policy.
<br />
                                          <br />
                                          <br />
The User guarantees that all the information on his/her identity and legitimacy provided to BLISCOP in its Platform registration forms is true, accurate and complete, and undertakes to keep his/her data updated.
<br />
                                          <br />
                                          <br />
In the event that the User provides any false, inaccurate or incomplete information or if BLISCOP considers that there are reasonable grounds for doubting the truthfulness, accuracy and integrity of such information, BLISCOP may deny the User access and present or future use of the Platform or any of its contents and/or services.
<br />
                                          <br />
                                          <br />
When registering on the Platform, the user will select a username and a password. Both the username and the password are strictly confidential, personal and non-transferable.
<br />
                                          <br />
                                          <br />
       The user undertakes not to disclose the data relating to his account or make it accessible to third parties. The user will be solely responsible in the event of use of such data by third parties, including statements made on the Platform, or any other action carried out using the username and/or password.
<br />
                                          <br />
                                          <br />
BLISCOP cannot guarantee the identity of registered users, therefore, it will not be responsible for the use of a registered user's identity by unregistered third parties. Users are obliged to immediately inform BLISCOP of the theft, disclosure or loss of their username or password, by communicating this to the following e-mail address: contact@bliscop.com
<br />
                                          <br />
                                          <br />
In order to complete the registration on the Platform, the User must provide some data such as: user name, e-mail address, telephone number, etc. Once the registration is completed, all Users may access their profile and complete it and/or edit it as they see fit. Users may cancel their registration on the Platform by sending an e-mail to the following address: contact@bliscop.com
<br />
                                          <br />
                                          <br />
3. OBJECT. SERVICES OFFERED. ORDERS
<br />
                                          <br />
                                          <br />
When you place an order through our Service from outside the restaurant, we will send you, on behalf of the partner Restaurant, an email thanking you for the order and confirming that the order has been received and accepted by the partner Restaurant. The contract for the supply of any Meals you order through us will be between you and the partner Restaurant and will only be formed once we have sent you the confirmation email. Please ensure that you have provided us with a correct email address as this will be the means of communicating with you regarding your order. In the restaurant we will inform you through the application.
<br />
                                          <br />
                                          <br />
Through this application the user, after registering on the platform, will be able to place orders in BLISCOP's associated restaurants for consumption in the establishment itself. By using these services, the user accepts the following conditions: All the products that appear in the application are subject to availability.
<br />
                                          <br />
                                          <br />
Your partner restaurant will be able to offer you an alternative to any meal they cannot provide. Our partner restaurants may use nuts and dried fruit in the preparation of certain meals. If you have any allergies, you can check in the menu information about this. BLISCOP cannot guarantee that meals sold by our partner restaurants are free from allergens.
<br />
                                          <br />
                                          <br />
4. SALE OF ALCOHOL
<br />
                                          <br />
                                          <br />
Persons placing an alcohol order with our partner restaurants must be 18 years of age or older. Alcoholic beverages may only be sold to persons over the age of 18. By placing an order that includes alcohol, you confirm that you are at least 18 years old. BLISCOP reserves the right to refuse to deliver alcohol to anyone who cannot prove that they are at least 18 years of age.
<br />
                                          <br />
                                          <br />
5. PRICE OF PRODUCTS
<br />
                                          <br />
                                          <br />
The price of all meals will be listed in our Service. Prices include VAT. Prices will vary between menus. Prices are subject to change at any time, but changes will not affect orders with respect to the information presented to you in the confirmation, except in the case of an obvious price error, in which case we will inform you as soon as possible about the problem with the price. You will not be able to cancel your order once you have confirmed the order and we have notified you via the confirmation. Despite our best efforts, some of the meals listed on our Service may be incorrectly priced. Normally, the relevant partner restaurant will check the prices as part of the order procedure.
<br />
                                          <br />
                                          <br />
6. FORM AND CONDITIONS OF PAYMENT
<br />
                                          <br />
                                          <br />
Once the service has been completed, the restaurant will be responsible for managing the payment using its usual methods.
<br />
                                          <br />
                                          <br />
7. RETURNS AND REFUNDS
<br />
                                          <br />
                                          <br />
If, for any reason, there is an error in the collection of the bill, the user must inform the restaurant because they are responsible for the collection of the order.
<br />
                                          <br />
                                          <br />
8. OUR RESPONSIBILITY
<br />
                                          <br />
                                          <br />
To the extent permitted by law, BLISCOP provides our Service and content "as is" and on an "as available" basis, and we make no representations or warranties of any kind, express or implied, with respect to the content or availability of our Service, nor can we guarantee that it is error-free or that any defects will be corrected in a timely manner. Without prejudice to the provisions below, neither BLISCOP nor any associated Restaurant shall have any liability to you for any loss or damage, whether direct, indirect, special or consequential, arising in contract, tort (including negligence) or otherwise from your use or inability to use our Service. In the event that BLISCOP or the associated Restaurant is found to be liable to you, our total obligations shall be limited to the purchase price of the Meals that you have paid for in your order. This does not in any way exclude or limit BLISCOP's or any Partner Restaurant's liability for anything which it would be illegal for us to exclude, nor does it attempt to exclude both our and our Partner Restaurants' liability, including liability for death or personal injury caused by negligence, fraud or fraudulent misrepresentation. BLISCOP shall not be liable for any failure or delay of the establishment in providing the service, and shall be solely responsible for the proper functioning of the application throughout the process of providing the service.
<br />
                                          <br />
                                          <br />
9. INTELLECTUAL PROPERTY RIGHTS
<br />
                                          <br />
                                          <br />
All intellectual property rights in the application are owned by BLISCOP and nothing in these Terms shall constitute an assignment thereof. Intellectual Property rights include patents, rights in inventions, utility models, copyrights and related rights, trademarks, service marks, trade names, business and domain names, trade dress or display rights, non-competition rights, unfair competition, design rights, software rights, database rights, topography rights, moral rights, rights to confidential information (including know-how and trade secrets ) and any other intellectual property rights, whether registered or not, and including all applications and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection anywhere in the world.
<br />
                                          <br />
                                          <br />
10. UPLOADING MATERIAL TO OUR SITE AND OUR SERVICE
<br />
                                          <br />
                                          <br />
Any material you upload to our Service will be considered non-confidential and non-proprietary, and you acknowledge and agree that we have the right to use, copy, distribute, sell and disclose to others such material for any purpose connected with our business. To the extent that such material is protected by intellectual property rights, you grant us a perpetual licence, for all territories worldwide and free of charge, to use, copy, modify, distribute, sell and disclose to others such material or information for any purpose connected with our business.
<br />
                                          <br />
                                          <br />
11. COMMUNICATIONS
<br />
                                          <br />
                                          <br />
In compliance with applicable regulations, part of the information or communications sent to the user will be in writing. By using our web site and mobile platform you agree that communications with BLISCOP will be primarily electronic. BLISCOP will contact the user through the application itself.
<br />
                                          <br />
                                          <br />
For contractual purposes, the user accepts these means of communication and acknowledges the validity of the contracts, notices, information and other communications provided to him/her by these means. This condition does not affect the rights that may correspond to him/her.
<br />
                                          <br />
                                          <br />
       Communications to BLISCOP should be made to the following address: contact@bliscop.com. The user will be notified by means of the e-mail address provided when registering in the application. The use of your personal information provided through the Web will be governed by our Privacy Policy and Cookies Policy.
<br />
                                          <br />
                                          <br />
12. PRIVACY POLICY
<br />
                                          <br />
                                          <br />
The User, by providing BLISCOP with his/her personal data through the electronic forms on the Web or the Application, expressly consents to BLISCOP processing such data under the terms of this Privacy and Data Protection Policy clause and for the purposes expressed herein. Before registering with BLISCOP, users should read this Privacy and Data Protection Policy.
<br />
                                          <br />
                                          <br />
When registering, users must provide some data for the creation of their account and the editing of their profile. They must provide the following data: Username, Password, Email and maybe Telephone and Address.
<br />
                                          <br />
                                          <br />
If you are a restaurant you must enter: Restaurant name, Country, Province, City, Street, Number, Zip number, Telephone, Order code, Table number, Currency y Restaurant type.
                    <br />
                                          <br />
                                          <br />
Once registration is completed, all users can access their profile and complete and/or edit it as they see fit. The information and data provided by the user will be available at all times in their user account and can be modified by the user through the edit profile option.
<br />
                                          <br />
                                          <br />
The user agrees to enter real and true data. Likewise, he/she will be solely responsible for any damages that BLISCOP or third parties may suffer as a result of the lack of veracity, inaccuracy, lack of validity and authenticity of the data provided. The data collected by BLISCOP will be exclusively used for the purpose defined in the present General Conditions of Use of the Web.
<br />
                                          <br />
                                          <br />
13. NULLITY
<br />
                                          <br />
                                          <br />
If any provision, or portion thereof, of the Terms of this mobile application is found by any court or competent authority to be unlawful, void, or otherwise unenforceable, such provision or portion thereof shall be severed from the Terms of the application, and the remaining Terms of the application shall be governed as if the provision, or portion thereof, that is unlawful, void, or otherwise unenforceable, had never been agreed upon.
<br />
                                          <br />
                                          <br />
14. RIGHT TO CHANGE TERMS AND CONDITIONS
<br />
                                          <br />
                                          <br />
BLISCOP reserves the right to revise and amend these Terms and Conditions from time to time. The user will be subject to the policies and terms and conditions in effect at the time of placing an order.
<br />
                                          <br />
                                          <br />
15. PROTECTION OF PERSONAL DATA
<br />
                                          <br />
                                          <br />
All personal data obtained by BLISCOP from the user will be processed in accordance with our privacy policy, which is incorporated into these Terms and is available here.
<br />
                                          <br />
                                          <br />
16. APPLICABLE LAW AND JURISDICTION
<br />
                                          <br />
                                          <br />
The Conditions of this application shall be governed by and interpreted in accordance with Spanish law. BLISCOP and the user, expressly waiving any other jurisdiction or applicable legislation that may correspond to them, submit to the legislation of ordinary Spanish law and to the jurisdiction of the Courts and Tribunals of the city of Madrid (Spain), waiving any other jurisdiction that may correspond to them.
<br />
                                          <br />
                                          <br />
Please understand that if you refuse to accept them you will not be able to order through our application.
<br />
                                          <br />
                                          <br />
Legal Notice and Privacy Policy
<br />
                                          <br />
                                          <br />
Corporate purpose. BLISCOP is a company dedicated to the development, design, and marketing of a software through which users can place orders from their mobile device in the collaborating restaurants.
<br />
                                          <br />
                                          <br />
Identification. In compliance with the duty of information contained in Article 10 of Law 34/2002 of July 11, Services of the Information Society and Electronic Commerce, the data provided here corresponds to the owner of the website Bliscop.es, Bliscop.app and Bliscop.com
<br />
                                          <br />
                                          <br />
Title: BLISCOP
Address: Madrid
Email: contact@bliscop.com
<br />
                                          <br />
                                          <br />
       Intellectual and industrial property. All the Industrial and Intellectual Property rights of all the elements contained in this web site belong to BLISCOP and are protected by Spanish and international laws on Intellectual and Industrial Property. The total or partial reproduction of this web site and any of its contents is expressly prohibited without the express written permission of BLISCOP.
<br />
                                          <br />
                                          <br />
Access to the web site does not imply any kind of waiver, transfer, licence or assignment of such rights by BLISCOP or the owners of the rights to the distinctive signs shown on this web page, unless expressly stated otherwise.
<br />
                                          <br />
                                          <br />
Terms of use. Access to this website implies the acceptance of these conditions of use without reservation, which regulate access to and use of the website in order to make information about our services available to users.
<br />
                                          <br />
                                          <br />
The use of the contents of this website for commercial purposes or for distribution, transformation or communication is expressly prohibited.
<br />
                                          <br />
                                          <br />
BLISCOP shall not be liable for any consequence, damage or harm that may arise from such use or use of the information
<br />
                                          <br />
                                          <br />
Both the access to this website and the use that may be made of the information contained therein is the exclusive responsibility of the person carrying it out.
<br />
                                          <br />
                                          <br />
The user agrees not to use the information published on this website for illegal or harmful purposes or effects, not to damage or disable the information and not to take any other action that may be contrary to the content of this Legal Notice.
<br />
                                          <br />
                                          <br />
BLISCOP reserves the right to modify the contents of the commercial offer of its services when it deems it appropriate and to keep its contents updated.
<br />
                                          <br />
                                          <br />
BLISCOP cannot guarantee that there will be no interruptions or errors in accessing this web site, although it will make every effort to avoid them.
<br />
                                          <br />
                                          <br />
Data Protection Policy. In compliance with REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and its implementing regulations, you are hereby informed that the personal data provided by you will be processed in order to provide you with the requested information on our services and renovation projects, technical management of buildings and interior design and to manage our business relationship.
<br />
                                          <br />
                                          <br />
By completing the form on the web site or any other type of request for information sent to BLISCOP, the interested party gives his or her express consent to the processing of his or her personal data.
<br />
                                          <br />
                                          <br />
Under no circumstances will BLISCOP use the personal data of the interested parties for purposes other than those mentioned above, nor will it communicate them to third parties without the prior and express consent of the affected party, and it undertakes to maintain due professional secrecy and to establish the necessary technical and organisational measures to safeguard the information in accordance with the requirements established in the aforementioned Regulations.
<br />
                                          <br />
                                          <br />
Your personal data will be kept in our records for the time necessary for the correct performance of the tool. However, at any time you can request access to your personal data and its rectification or deletion. As well as, limit their treatment, or directly oppose to the treatment or exercise the right to the portability of them. We also inform you of your right to file a complaint with the Spanish Data Protection Agency if you consider that your rights are not being respected in the processing of your data.
<br />
                                          <br />
                                          <br />
BLISCOP reminds adults in charge of minors that it will be their sole responsibility if a minor enters their details to request a service. It also informs them that there are computer programs to limit navigation by filtering or blocking certain content.
<br />
                                          <br />
                                          <br />
Social networking. By following our social network profile you expressly consent to the processing of your personal data in accordance with their privacy policy. You also expressly consent to BLISCOP's access to the processing of your data.
<br />
                                          <br />
                                          <br />
       Social networks. By following our social network profile you expressly consent to the processing of your personal data in accordance with their privacy policy. You also expressly consent to BLISCOP's access to the processing of your data contained in the list of friends and to the publication of news about the services offered by BLISCOP on your wall.
<br />
                                          <br />
                                          <br />
In accordance with REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT, we inform you that the personal data of BLISCOP's followers on social networks will be incorporated into a file owned by BLISCOP with the aim of keeping you informed through social networks about news related to the services offered by BLISCOP through its application. Your request to connect, necessarily implies your consent to the indicated treatments.
<br />
                                          <br />
                                          <br />
The publication of comments and content on social networks will become public information, so users should be especially cautious when deciding to share their personal information. BLISCOP is not responsible for the information users enter on the site. However, persons whose personal information is published or included in comments may request BLISCOP to cancel their information.
<br />
                                          <br />
                                          <br />
At any time, you can exercise your rights of access, rectification, deletion and opposition, limit the processing of your data, or exercise the right to the portability of your data. All this must be done in writing, accompanied by a copy of an official document identifying you, addressed to BLISCOP. In the event of disagreement with the processing, you also have the right to file a complaint with the Spanish Data Protection Agency.
<br />
                                          <br />
                                          <br />
The user is obliged not to use the tool for purposes that are harmful to the companies that offer their services in the application. He will be responsible for any malicious action regardless of the purpose of it.
<br />
                                          <br />
                                          <br />
Links to other websites. The links you can find on this website are a service to users. These pages are not operated or controlled by BLISCOP and therefore BLISCOP is not responsible for the contents of those websites nor are they covered by this Legal Notice. If you access these web pages you should be aware that their privacy policies may differ from ours.
<br />
                                          <br />
                                          <br />
Applicable law and jurisdiction. This Legal Notice is governed by current Spanish legislation applicable to it.
<br />
                                          <br />
                                          <br />
For the resolution of any disputes which may arise as a result of the provisions of these provisions and their interpretation, application and enforcement. The user, by virtue of the acceptance of the conditions contained in this Legal Notice, expressly waives any other jurisdiction that may correspond to him/her.
<br />
                                          <br />
                                          <br />
In any case, within the Spanish jurisdiction, if the legislation allows to be submitted to a specific jurisdiction, the user expressly renounces to the jurisdiction that could correspond to him/her and voluntarily submits to the jurisdiction of the Courts and Tribunals of Madrid.
</h4>}
                     </Col>
              </div>
       )
};

export default Politics;



