import React, { useState } from "react";
import { IStats, IGraph } from '../Components/Basic/Utilities/interfaces';
import { useWindowSize } from '../Components/Basic/Utilities/tools';
import { BarVertical } from './Basic/Bar';
import { Button } from 'antd';
import { useConfigContext } from '../Context/ContextConfig';

const getData = (input: { stats: IStats[], filterType: number, language: number }) => {
    const ret = input.stats[input.filterType].hourOrdered.sort((a, b) => Number(a.hour) - Number(b.hour)).map(item => {
        return {
            [input.language === 0 ? "Hora" : "Hour"]: item.hour.toString(),
            [input.language === 0 ? "Clientes" : "Clients"]: Number(item.value || 0)
        }
    });
    return ret;
}

export const BarOrdersByHours: React.FC<IGraph> = (props) => {
    const [filterType, setFilterType] = useState<number>(0);
    const width = useWindowSize();
    const { configContext } = useConfigContext()!;
    const datas = getData({ stats: props.data, filterType: filterType, language: configContext.language! });

    return (
        <div className="sectionSquareGraph" style={{ maxWidth: "unset", height: 500 }}>
            <div className="sectionSquareTitleGraph">{props.title}</div>
            <div className="sectionSquareBodyGraph textCenter mt_15px">
                {props.data.map((item, index) =>
                    <Button key={item.day} className="buttonFilter" type="primary" onClick={() => setFilterType(index)}>{configContext.language === 0 ? "Día " : "Day "}{item.day.split('/')[0]}</Button>
                )}
                <BarVertical {...props} margin={{ left: 40, bottom: width[0] < 700 ? 160 : 120 }} dataTable={datas} />
            </div>
        </div>
    )
}