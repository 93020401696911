import React from 'react';
import { useConfigContext } from '../../Context/ContextConfig';
import { Row, Col, Checkbox, InputNumber } from 'antd';
import { IMenuOrder } from './Utilities/interfaces';
import { groupOrders, getTotalPriceFromOrder } from '../../Components/Basic/Utilities/tools';

interface IProps {
    order: IMenuOrder[];
    discount?: number;
    applyToMenu?: boolean;
    coin: string;
    extraText?: string;
    tip?: number;
    priceSpecialTable?: number;
    showCheckbox?: boolean;
    showInputNumber?: boolean;
    itemsChecked?: string[];
    setChecked?: Function;
    setDeduced?: Function;
    isSummaryHome?: boolean
}

const rowBill = (quantity: number, props: IProps, item: IMenuOrder, textDeleted: boolean, language: number, showDeleted: boolean) => {
    let extraOptions: { item: string, quantity: number, price: number }[] = []
    if (item.options?.length) {
        const filteredOptions = item.options.map(e => e.filter(i => i.typeOption === "multiOptions" || i.typeOption === "singleOption")).flat(2)
        if (filteredOptions.length) {
            filteredOptions.forEach(e => {
                let exists = false
                    extraOptions.forEach(i => {
                            if (i.item === e.valueOption) {
                                exists = true
                                i.quantity += 1
                            }
                    })
                    if (!exists) extraOptions.push({ item: e.valueOption, quantity: 1, price: e.priceOption! })
            });
        }
    }

    return (
        <>
            <Row style={{ color: item.modified ? "tomato" : "inherit" }}>
                <Col span={3}>
                    {props.showCheckbox && <Checkbox checked={props.itemsChecked?.includes(item.idItem)} onChange={() => props.setChecked && props.setChecked(item.idItem)} />}
                    {props.showInputNumber && <InputNumber style={{ width: 40, color: "red" }} size="small" min={0} max={quantity} onChange={(e) => props.setDeduced && props.setDeduced(e, item.idItem)} />}
                    <span> x{quantity}</span>
                </Col>
                <Col span={15}><span style={{ textDecoration: textDeleted ? "line-through" : "none" }}>{item.name}</span></Col>
                <Col span={6} className="textRight"><span style={{ textDecoration: (textDeleted || item.discount && item.discount > 0) ? "line-through" : "none" }}>{(item.price * quantity).toFixed(2)}</span> {props.coin}</Col>
            </Row>
            {extraOptions.length && showDeleted ?
                extraOptions.map(e =>
                    <Row key={e.item}>
                        <Col span={15} offset={3}>+{e.quantity} {e.item}</Col>
                        <Col span={6} className="textRight">{item.discount ? (e.price * (1 - (item.discount / 100))).toFixed(2) : e.price.toFixed(2)} {props.coin}</Col>
                    </Row>
                )
                :
                null}
            {item.discount && item.discount > 0 ?
                <Row>
                    <Col span={15} offset={3}>{language === 0 ? "Descuento: " : "Discount: "} {item.discount}%</Col>
                    <Col span={6} className="textRight">{(item.price * (1 - (item.discount / 100))).toFixed(2)} {props.coin}</Col>
                </Row>
                :
                null}
        </>
    )
}

const Bill: React.FC<IProps> = (props) => {
    const { configContext } = useConfigContext()!;
    const { order } = props

    const itemsOrder = groupOrders(order)
    const total = getTotalPriceFromOrder(order, props.isSummaryHome === undefined)

    return (
        <>
            <Row>
                <Col span={3}><b>{configContext.language === 0 ? "Uds." : "Units"}</b></Col>
                <Col span={15}><b>{configContext.language === 0 ? "Producto" : "Product"}</b></Col>
                <Col span={6} className="textRight"><b>{configContext.language === 0 ? "Precio" : "Price"}</b></Col>
            </Row>
            {itemsOrder.length && itemsOrder.map((item, index) => {
                if (props.isSummaryHome === undefined && item.payed !== undefined && item.payed > 0) {
                    const quantityPayed = item.payed
                    const quantityNoPayed = item.quantity - item.payed
                    return (
                        <div key={index}>
                            {quantityNoPayed > 0 && <div>{rowBill(quantityNoPayed, props, item, false, configContext.language!, true)}</div>}
                            {quantityPayed > 0 && <div>{rowBill(quantityPayed, props, item, true, configContext.language!, false)}</div>}
                        </div>
                    )
                } else {
                    return <div key={index}>{rowBill(item.quantity, props, item, false, configContext.language!, true)}</div>
                }
            }
            )}
            {props.isSummaryHome === undefined && <Row style={{ marginTop: 10 }}>{configContext.language === 0 ? "IVA Incluido" : "VAT included"}</Row>}
            {(props.tip && props.tip > 0) ?
                <Row>
                    <Col span={18}>{configContext.language === 0 ? "Incluida propina del " : "Including tip from the "}{props.tip}%</Col>
                    <Col span={6} className="textRight">{(total * (props.tip / 100)).toFixed(2)} {props.coin}</Col>
                </Row>
                : null}
            <div>{props.discount !== undefined ? (configContext.language === 0 ? `Descuento del ${props.discount}% aplicado ${props.applyToMenu ? "" : "en la carta"}` : `${props.discount}% discount applied ${props.applyToMenu ? "" : "in la Carte"}`) : ''}</div>
            <div>{props.priceSpecialTable !== undefined ? (configContext.language === 0 ? `Recargo del ${props.priceSpecialTable}% aplicado` : `${props.priceSpecialTable}% extra charge`) : ''}</div>
            <Row className="mt_10px">
                <Col span={18}><b>Total:</b></Col>
                {props.tip !== undefined ?
                    <Col span={6} className="textRight"><b>{(total * (1 + (props.tip / 100))).toFixed(2)} {props.coin}</b></Col>
                    : <Col span={6} className="textRight"><b>{total.toFixed(2)} {props.coin}</b></Col>}
            </Row>
            {props.extraText !== undefined && <div className="mt_20px"><b>{props.extraText}</b></div>}
        </>
    )
};

export default Bill;