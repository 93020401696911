const envLocal = {
    endpointServer: process.env.REACT_APP_API || 'https://bliscop.app:3100',
    endpointSocketServer: process.env.REACT_APP_SOCKETS || 'https://bliscop.app:8000',

    //CONST Endpoints
    endpointRestaurant: '/api/restaurant/',
    endpointSection: '/api/section/',
    endpointItemMenu: '/api/itemMenu/',
    endpointMenu: '/api/menu/',
    endpointDish: '/api/dish/',
    endpointDrink: '/api/drink/',
    endpointOrder: '/api/order/',
    endpointTask: '/api/task/',
    endpointTimeRegister: '/api/timeRegister/',
    endpointBasicProduct: '/api/basicProduct/',
    endpointProveedores: '/api/proveedores/',
    endpointStock: '/api/stock/',
    endpointOrderDate: '/api/orderDate/',
    endpointSetValoration: '/api/setValoration/',
    endpointExistsOrder: '/api/existsOrder/',
    endpointStats: '/api/stats/',
    endpointAuthentication: '/api/auth/',
    endpointAuthenticationComp: '/api/authComp/',
    endpointDiscounts: '/api/discounts/',
    endpointBookings: '/api/bookings/',
    endpointChangePass: '/api/changePass/',
    endpointError: '/api/error/',
    endpointPayments: '/api/payments/',
    endpointContact: '/api/nextFront/',
    endpointSchedules: '/api/schedules/',
    endpointWorkSchedules: '/api/workSchedules/',
    endpointBill: '/api/bill/',
    
    //CONST Tokens in cache
    authComp: "tokenAuthComp",
    authUser: "tokenAuth",
    tokenOrder: "tokenOrder",
    tokenConfig: "configContext"
};

export default envLocal;