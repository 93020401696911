import React from "react";
import { IStats, IGraph } from '../Components/Basic/Utilities/interfaces';
import Pie from './Basic/Pie';
import { useConfigContext } from '../Context/ContextConfig';

const getMenuIncome = (input: { stats: IStats[], language: number }) => {
    const incomeMenu = input.stats.reduce(function (s: number, a: IStats) { return s + Number(a.menuIncome) }, 0);
    const totalIncome = input.stats.reduce(function (s: number, a: IStats) { return s + Number(a.totalIncome) }, 0);
    const ret = [
        {
            "id": input.language === 0 ? "Menú" : "Menu",
            "label": input.language === 0 ? "Menú" : "Menu",
            "value": Number(incomeMenu.toFixed(2))
        },
        {
            "id": input.language === 0 ? "Carta" : "A la Carte",
            "label": input.language === 0 ? "Carta" : "A la Carte",
            "value": Number((totalIncome - incomeMenu).toFixed(2))
        }
    ]
    return ret;
}

export const PieMenuIncome: React.FC<IGraph> = (props) => {
    const { configContext } = useConfigContext()!;
    return (
        <div className="sectionSquareGraph">
            <div className="sectionSquareTitleGraph">{props.title}</div>
            <div className="sectionSquareBodyGraph">
                <Pie {...props} dataTable={getMenuIncome({ stats: props.data, language: configContext.language! })} />
            </div>
        </div>
    )
}
