import { useState, useLayoutEffect } from "react";
import axios from 'axios';
import envLocal from '../../../env-local';
import { ITokenAuthData, IRestaurant, IUsers, IStateCreateMenu, IMenuOrder, IOptionsOutput } from './interfaces';
import { message } from "antd";

interface IResponse {
    status: number;
    data: any;
}

interface IDataTokenRestaurant {
    user: IUsers;
    company: IRestaurant;
}


//Devuelve el tipo de pedido en función del idTable facilitado
export const decideOrderType = (input: { idTable: string, language: number }) => {
    switch (input.idTable) {
        case "-2":
            return input.language === 0 ? "A Domicilio" : "Home Order";
        case "-1":
            return input.language === 0 ? "Recogida" : "Pickup";
        case "0":
            return input.language === 0 ? "Barra" : "Bar";
        default:
            return (input.language === 0 ? "Mesa " : "Table ") + input.idTable;
    }
};


//Devuelve el width de la pantalla de manera dinámica
export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};


//Hace una llamda con axios con los parámetros facilitados
export const axiosCall = async (method: any, url: string, token?: string, param?: string, dataBody?: any): Promise<IResponse> => {
    let response: IResponse = { status: 500, data: {} };
    await axios({
        method,
        headers: {
            'Access-Control-Allow-Headers': 'Content-Type, x-access-token',
            "x-access-token": (token !== undefined && token !== "" && [envLocal.authComp, envLocal.authUser, envLocal.tokenOrder].includes(token)) ? window.localStorage.getItem(token)
                : token !== undefined && token !== "" ? token : ""
        },
        url: envLocal.endpointServer + url + (param || ""),
        data: dataBody
    })
        .then(res => {
            response.status = res.status;
            response.data = res.data;
        })
        .catch(error => {
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 429) {
                    message.error("Too many requests. Please slow down!")
                }
                response.status = error.response.status;
                response.data = error.response.data;
            } else if (error.request) {
                // The request was made but no response was received
                response.status = 500;
                message.error("Conexion error!")
                response.data = [];
            } else {
                // Something happened in setting up the request that triggered an Error
                response.status = 500;
                response.data = [];
            }
        })
    return response;
}

//Transforma el formato fecha en función del tamaño de la pantalla
export const dateWithoutYear = (date: string) => {
    if (window.innerWidth < 700) {
        return date.slice(0, date.lastIndexOf('/'));
    } else {
        return date;
    }
}


export const getDataTokenAuth = (): ITokenAuthData | null => {
    if (!window.localStorage.getItem(envLocal.authUser)) return null;
    if (Date.now() / 1000 > JSON.parse(decodeURIComponent(escape(window.atob(window.localStorage.getItem(envLocal.authUser)!.split('.')[1])))).exp) {
        window.localStorage.removeItem(envLocal.authUser);
        window.localStorage.removeItem("user");
        return null
    }
    const dataTokenAuth: ITokenAuthData = window.localStorage.getItem(envLocal.authUser) !== undefined && window.localStorage.getItem(envLocal.authUser) !== null ?
        JSON.parse(decodeURIComponent(escape(window.atob(window.localStorage.getItem(envLocal.authUser)!.split('.')[1]))))
        : null;
    return dataTokenAuth;
}


export const getDataTokenRestaurant = (): IDataTokenRestaurant => {
    const dataTokenRestaurant: IDataTokenRestaurant = window.localStorage.getItem(envLocal.authComp) !== undefined && window.localStorage.getItem(envLocal.authComp) !== null ?
        JSON.parse(decodeURIComponent(escape(window.atob(window.localStorage.getItem(envLocal.authComp)!.split('.')[1]))))
        : {};
    return dataTokenRestaurant;
}


export const getDataTokenOrder = (): IStateCreateMenu | null => {
    if (!window.localStorage.getItem(envLocal.tokenOrder)) return null;
    if (Date.now() / 1000 > JSON.parse(decodeURIComponent(escape(window.atob(window.localStorage.getItem(envLocal.tokenOrder)!.split('.')[1])))).exp) {
        window.localStorage.removeItem(envLocal.tokenOrder);
        return null
    }
    const dataTokenOrder: IStateCreateMenu | null = window.localStorage.getItem(envLocal.tokenOrder) !== undefined && window.localStorage.getItem(envLocal.tokenOrder) !== null ?
        JSON.parse(decodeURIComponent(escape(window.atob(window.localStorage.getItem(envLocal.tokenOrder)!.split('.')[1])))).order
        : null;
    return dataTokenOrder;
}

//Transforma el día en formato número por el día de la semana
export const numerToDay = (input: { day: number, language: number }) => {
    switch (input.day) {
        case 1:
            return input.language === 0 ? 'Lunes' : "Monday";
        case 2:
            return input.language === 0 ? 'Martes' : "Tuesday";
        case 3:
            return input.language === 0 ? 'Miércoles' : "Wednesday";
        case 4:
            return input.language === 0 ? 'Jueves' : "Thursday";
        case 5:
            return input.language === 0 ? 'Viernes' : "Friday";
        case 6:
            return input.language === 0 ? 'Sábado' : "Saturday";
        case 0:
            return input.language === 0 ? 'Domingo' : "Sunday";
        default:
            return "-";
    }
}

//Transforma el mes en formato número por el mes en string
export const numerToMonth = (month: number, language: number) => {
    switch (month) {
        case 1:
            return language === 0 ? 'Enero' : "January";
        case 2:
            return language === 0 ? 'Febrero' : "February";
        case 3:
            return language === 0 ? 'Marzo' : "March";
        case 4:
            return language === 0 ? 'Abril' : "April";
        case 5:
            return language === 0 ? 'Mayo' : "May";
        case 6:
            return language === 0 ? 'Junio' : "June";
        case 7:
            return language === 0 ? 'Julio' : "July";
        case 8:
            return language === 0 ? 'Agosto' : "August";
        case 9:
            return language === 0 ? 'Septiembre' : "September";
        case 10:
            return language === 0 ? 'Octubre' : "October";
        case 11:
            return language === 0 ? 'Noviembre' : "November";
        case 12:
            return language === 0 ? 'Diciembre' : "December";
        default:
            return "-";
    }
}

export const hasNumber = (myString: any) => {
    return /\d/.test(myString);
}

export const groupBy = function (xs: any, key: string) {
    return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const countItemsArray = function (myArray: any[]) {
    let result = {};
    myArray.forEach(function (x) {
        result[x] = (result[x] || 0) + 1;
    });
    return result;
};

export const round = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;

export const groupOrders = (order: IMenuOrder[]) => {
    const groupedIdItem = groupBy(order, "idItem");
    return Object.keys(groupedIdItem).map(key => ({ ...groupedIdItem[key][0], options: groupedIdItem[key][0].options !== undefined ? [groupedIdItem[key].map((e: any) => e.options).flat(2)] : undefined, quantity: groupedIdItem[key].reduce((s: number, a: any) => { return s + a.quantity }, 0) }))
}

export const getTotalPriceFromOrder = (order: IMenuOrder[], discountPayed: boolean) => {
    const groupedIdItem = groupOrders(order);
    const total = groupedIdItem.reduce(function (s: number, a: any) {
        let priceOptions = 0
        if (a.options?.length) priceOptions = a.options?.reduce((d: number, e: any) => d + (e.reduce((c: number, b: any) => c + (((a.discount && a.discount > 0 ? (1 - (a.discount / 100)) : 1)) * b?.priceOption || 0), 0)), 0)
        return s + (((a.price) * (a.discount && a.discount > 0 ? (1 - (a.discount / 100)) : 1)) * (a.quantity - ((discountPayed ? a.payed : 0) || 0)) + priceOptions);
    }, 0)
    return total
}

export const calculateExtra = (inpPoint: IOptionsOutput[][]) => {
    let extras: string[][] = []
    inpPoint.forEach((e, idx) => {
        extras.push([])
        e.forEach(i => {
            if (i.typeOption === "multiOptions" || i.typeOption === "singleOption") extras[idx].push(`+${i.valueOption}`)
        })
    })
    return extras
}

export const calculateExtraAll = (inpPoint: IOptionsOutput[][], country: string, language: number) => {
    let extras: string[][] = []
    inpPoint.forEach((e, idx) => {
        extras.push([])
        e.forEach(i => {
            if (i.typeOption === "multiOptions" || i.typeOption === "singleOption") extras[idx].unshift(`+${i.valueOption}`)
            if (i.typeOption === "pointCook") extras[idx].push(translatePoints(i.valueOption, country, language))
        })
    })
    return extras
}

export const translatePoints = (point: string, country: string, language: number) => {
    switch (point) {
        case "0":
            return country === "Costa Rica" ? 'Rojo' : (language === 0 ? "Poco Hecho" : "Undercooked");
        case "1":
            return country === "Costa Rica" ? 'Término Medio' : (language === 0 ? "Al Punto" : "Medium");
        case "2":
            return country === "Costa Rica" ? 'Tres Cuartos' : (language === 0 ? "Hecho" : "Medium well");
        case "3":
            return country === "Costa Rica" ? 'Muy Cocido' : (language === 0 ? "Muy Hecho" : "Well done");
        default:
            return point;
    }
}

export const randomId = (length: number) => {
    let result = [];
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        if (i < 4) {
            result.push(characters.substring(10).charAt(Math.floor(Math.random() * charactersLength)));
        } else {
            result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
        }
    }
    return result.join('');
}


export const parsedDateString = (inputDate: any): string | null => {
    if (!inputDate) return null
    const returnedDate = `${inputDate.format('YYYY')}-${inputDate.format('MM')}-${inputDate.format('DD')}`
    return returnedDate
}