import React, { useCallback } from 'react';
import NavBar from '../../Components/Basic/NavBar';
import { useConfigContext } from '../../Context/ContextConfig';
import { useRestaurant } from '../../Context/ContextRestaurant';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'antd';
import QR from '../../Components/Basic/QR';

const QRCodes: React.FC<RouteComponentProps> = (props) => {
    const { configContext } = useConfigContext()!;
    const { restaurant } = useRestaurant()!;

    const printCodes = useCallback(() => {
        props.history.push({
            pathname: '/ToPrint',
            state: { numTables: restaurant.numTables, language: configContext.language, orderCode: restaurant.orderCode, type: 2 }
        })
    }, [])

    return (
        <div className="basicContainerColor" style={{ backgroundColor: "white" }}>
            <NavBar />
            <div className="titleSection" style={{ backgroundColor: "white" }}>{configContext.language === 0 ? "Códigos QR" : "QR Codes"}</div>
            <div className="textCenter"><Button type="primary" onClick={printCodes}>{configContext.language === 0 ? "Imprimir Códigos" : "Print Codes"}</Button></div>
            <QR numTables={restaurant.numTables} language={configContext.language!} orderCode={restaurant.orderCode} />
        </div>
    );
};

export default QRCodes;