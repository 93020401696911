import React, { useState } from 'react';
import { Row, Col, List, Checkbox, Button, message } from 'antd';
import { IItems, IAddItem, IItemCheck } from '../../Basic/Utilities/interfaces';
import envLocal from '../../../env-local';
import { axiosCall } from '../../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../../Context/ContextConfig';

interface IProps {
    getMenu: Function;
    section: IAddItem;
    dishes?: IItems[];
    drinks?: IItems[];
    setShowModalView: Function;
};

const AddItemSection: React.FC<IProps> = (props) => {
    const [itemsChecked, setItemsChecked] = useState<Array<string>>(props.section.itemsMenu.map(item => item._id));
    const { configContext } = useConfigContext()!;

    const check = async (item: IItemCheck) => {
        item.event.target.checked && message.success(configContext.language === 0 ? `Se mostrará el ${itemsChecked.length + 1}º en la carta!` : `It will be shown on the position ${itemsChecked.length + 1}`)
        let newArray = item.event.target.checked ?
            [...itemsChecked, item.idItem] :
            [...itemsChecked].filter((e: string) => e !== item.idItem);
        await setItemsChecked(newArray);
    };

    const addItems = async () => {
        await axiosCall("put", envLocal.endpointSection, envLocal.authComp, "actions/" + props.section.idSection, {
            itemsMenu: itemsChecked.map((item, index) => { return { idItem: item, position: index } })
        });
        props.getMenu();
        props.setShowModalView(false);
        message.success(configContext.language === 0 ? "Modificaciones realizadas!" : "Modifications made!");
    };

    return (
        <div className="pt_3em">
            <Row className="editMenuTitle">
                <Col><p className="titleAnt_3">{configContext.language === 0 ? "Añade los elementos a tu sección" : "Add the elements to your section"}</p></Col>
            </Row>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 20, offset: 2 }}>
                    <List
                        size="small"
                        header={<strong>{configContext.language === 0 ? `Selecciona los productos que quieras añadir a la sección ${props.section.sectionTitle}.` : `Select the items you want to add to the section ${props.section.sectionTitle}.`}</strong>}
                        footer={<strong>Total: {itemsChecked.length}</strong>}
                        bordered
                        dataSource={props.section.sectionType === 1 || props.section.sectionMenu === 4 ? props.dishes : props.drinks}
                        renderItem={(item: IItems) => <List.Item><Checkbox defaultChecked={props.section.itemsMenu.find(itemSection => itemSection._id === item._id) !== undefined} onChange={(e: any) => check({ idItem: item._id, event: e })}></Checkbox>&nbsp;&nbsp;{item.name}</List.Item>}
                    />
                </Col>
            </Row>
            <Row className="mt_15px">
                <Col xs={{ span: 8, offset: 8 }} sm={{ span: 8, offset: 10 }}><Button type="primary" onClick={() => addItems()}>{configContext.language === 0 ? "Guardar" : "Save"}</Button></Col>
            </Row>
        </div>
    )
};

export default AddItemSection;