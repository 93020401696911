const coinList = [
    {value: 1, symbol: "$", label: "United States Dollar"},
    {value: 2, symbol: "€", label: "Euro"},
    {value: 3, symbol: "¥", label: "Yen"},
    {value: 4, symbol: "£", label: "Pound"},
    {value: 5, symbol: "CHF", label: "Swiss Franc"},
    {value: 6, symbol: "$", label: "Peso Mexicano"},
    {value: 7, symbol: "$", label: "Other"},
    {value: 8, symbol: "₡", label: "Colón Costarricense"},
];

export default coinList;