import React from 'react';
import { Button, message } from 'antd';
import NavBar from '../../Components/Basic/NavBar';
import { useConfigContext } from '../../Context/ContextConfig';
import SquareInfo from '../../Components/Basic/SquareInfo';
import software from '../../Components/Basic/Utilities/Images/software.png';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useRestaurant } from '../../Context/ContextRestaurant';
import { useUser } from '../../Context/ContextUser';
import { randomId, axiosCall } from '../../Components/Basic/Utilities/tools'
import envLocal from '../../env-local';


const packThreeMonths = { price: 39.5, months: 3 };
const packSixMonths = { price: 34.5, months: 6 };
const packOneYear = { price: 29.5, months: 12 };

const SistecContact = () => {
    return (
        <div className="textCenter">
            <div className="titleSection">Para cualquier consulta contacte con nuestro Partner especializado en Costa Rica</div>
            <div className="m_20x0 xxLarge"><a href="http://www.sisteccr.com" rel="nofollow" target="_blank">Sistec</a></div>
            <div className="mb_10px"><b>Teléfono:</b> (506) 64393240</div>
            <div><b>Email:</b> info@sisteccr.com</div>
        </div>
    )
}

const Packs: React.FC<RouteComponentProps> = (props) => {
    const { configContext } = useConfigContext()!;
    const { restaurant } = useRestaurant()!;
    const myUser = useUser()!.user;

    const pushea = async (productCode: string, description: string, total: number) => {
        const idOrderPayment = randomId(12)
        const response = await axiosCall("post", envLocal.endpointPayments, envLocal.authComp, "savePreOrderApp", {
            productCode,
            idOrderPayment,
            email: myUser.email
        });
        if (response.status === 200) {
            props.history.push({
                pathname: "payments",
                state: {
                    productCode,
                    description,
                    total,
                    idOrderPayment,
                    idOrderBBDD: response.data
                }
            })
        } else {
            message.error("Error")
        }
    }


    const linkToRegister = configContext.language === 0 ? `https://bliscop.com/registro?me=${myUser.username}&code=${restaurant._id}` : `https://bliscop.com/register?me=${myUser.username}&code=${restaurant._id}`;

    const getSquareTask = () => {

        return [
            {
                squareStyle: { width: 300, height: 450 },
                title: configContext.language === 0 ? `${packThreeMonths.months} Meses` : `${packThreeMonths.months} Months`,
                body:
                    <div className="textCenter pt_15px">
                        <img className="mb_20px borderR_6px" src={software} alt={"software"} />
                        <div><span className="xxLarge bold">{configContext.language === 0 ? packThreeMonths.price.toFixed(2).toString().replace(".", ",") : packThreeMonths.price.toFixed(2)}  {configContext.language === 0 ? "€ / Mes" : "€ / M"}</span></div>
                        <div>{configContext.language === 0 ? "Paquete que incluye tres meses de uso de la aplicación" : "Package that includes three months of use of the application"}</div>
                        <div className="bold">Total: {configContext.language === 0 ? (packThreeMonths.price * packThreeMonths.months).toFixed(2).toString().replace(".", ",") : (packThreeMonths.price * packThreeMonths.months).toFixed(2)} {configContext.language === 0 ? "€ (IVA Inc)" : "€ (VAT Inc)"}</div>
                        <Button className="textCenter mt_35px" onClick={() => pushea("AAA", configContext.language === 0 ? "Pack 3 Meses" : "3 Months Pack", packThreeMonths.price * packThreeMonths.months)} type="primary">{configContext.language === 0 ? "Adquirir" : "Buy"}</Button>
                    </div>
            },
            {
                squareStyle: { width: 300, height: 450 },
                title: configContext.language === 0 ? `${packSixMonths.months} Meses` : `${packSixMonths.months} Months`,
                body:
                    <div className="textCenter pt_15px">
                        <img className="mb_20px borderR_6px" src={software} alt={"software"} />
                        <div><span className="xxLarge bold">{configContext.language === 0 ? packSixMonths.price.toFixed(2).toString().replace(".", ",") : packSixMonths.price.toFixed(2)}  {configContext.language === 0 ? "€ / Mes" : "€ / M"}</span></div>
                        <div>{configContext.language === 0 ? "Paquete que incluye seis meses de uso de la aplicación" : "Package that includes six months of use of the application"}</div>
                        <div className="bold">Total: {packSixMonths.price * packSixMonths.months} {configContext.language === 0 ? "€ (IVA Inc)" : "€ (VAT Inc)"}</div>
                        <Button className="textCenter mt_35px" onClick={() => pushea("AAB", configContext.language === 0 ? "Pack 6 Meses" : "6 Months Pack", packSixMonths.price * packSixMonths.months)} type="primary">{configContext.language === 0 ? "Adquirir" : "Buy"}</Button>
                    </div>
            }, {
                squareStyle: { width: 300, height: 450 },
                title: configContext.language === 0 ? `${packOneYear.months} Meses` : `${packOneYear.months} Months`,
                body:
                    <div className="textCenter pt_15px">
                        <img className="mb_20px borderR_6px" src={software} alt={"software"} />
                        <div><span className="xxLarge bold">{configContext.language === 0 ? packOneYear.price.toFixed(2).toString().replace(".", ",") : packOneYear.price.toFixed(2)}  {configContext.language === 0 ? "€ / Mes" : "€ / M"}</span></div>
                        <div>{configContext.language === 0 ? "Paquete que incluye doce meses de uso de la aplicación" : "Package that includes twelve months of use of the application"}</div>
                        <div className="bold">Total: {packOneYear.price * packOneYear.months} {configContext.language === 0 ? "€ (IVA Inc)" : "€ (VAT Inc)"}</div>
                        <Button className="textCenter mt_35px" onClick={() => pushea("AAC", configContext.language === 0 ? "Pack 12 Meses" : "12 Months Pack", packOneYear.price * packOneYear.months)} type="primary">{configContext.language === 0 ? "Adquirir" : "Buy"}</Button>
                    </div>
            }
        ]
    }

    return (
        <div className="basicContainerColor">
            {myUser.userType !== 5 && <NavBar />}
            {restaurant.country === "Costa Rica" ?
                <SistecContact />
                :
                <>
                    <div className="titleSection">{configContext.language === 0 ? "Nuestras Ofertas" : "Our Offers"}</div>
                    <div className="mb_30px">
                        <div className="sectionSquare">
                            <div className="sectionSquareTitle">{configContext.language === 0 ? "Promoción" : "Promotion"}</div>
                            <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}>
                                <div className="textCenter">
                                    <div className="boldLarge">{configContext.language === 0 ? "Invita a un amigo y si contrata algún producto ganaréis un mes gratis cada uno!" : "Invite a friend and if he hires a product you will both win one month free!"}</div>
                                    <div className="mt_20px">
                                        <div className="mb_10px"><b>{configContext.language === 0 ? "Enlace: " : "Link: "}</b> {linkToRegister}</div>
                                        <Button type="primary" onClick={() => {
                                            navigator.clipboard.writeText(linkToRegister);
                                            message.success(configContext.language === 0 ? "Texto Copiado!" : "Text Copied!")
                                        }}>{configContext.language === 0 ? "Copiar Enlace" : "Copy Link"}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SquareInfo propClassName="packContainer" squares={getSquareTask()} />
                    </div>
                </>
            }
        </div>
    );
};

export default withRouter(Packs);