import React from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

interface IProps {
    value: number;
    language: number;
    changeGroupOrder: Function;
}

const GroupOrder: React.FC<IProps> = ({ value, language, changeGroupOrder }) => {
    const [groupOrder, setGroupOrder] = React.useState(value)

    const changeState = (newValue: number) => {
        changeGroupOrder(newValue)
        setGroupOrder(newValue)
    }

    return (
        <div className="sectionOrder">
            <Button type="primary" onClick={() => changeState(groupOrder - 1)} shape="circle" icon={<MinusOutlined />} size='large' />
            <span>{language === 0 ? "Grupo: " : "Group: "} {groupOrder}</span>
            <Button type="primary" onClick={() => changeState(groupOrder + 1)} shape="circle" icon={<PlusOutlined />} size='large' />
        </div>
    )
}

export default GroupOrder;