import React, { useEffect, useReducer } from 'react';
import { IConfigContext } from '../Components/Basic/Utilities/interfaces';
import axios from 'axios';

type ConfigContextType = {
    configContext: IConfigContext;
    setConfigContext: (value: IConfigContext) => void;
};

export const ConfigContext = React.createContext<ConfigContextType | undefined>(undefined);

type Props = {
    children: React.ReactNode;
};

let reducer = (configContext: any, newConfigContext: any) => {
    if (newConfigContext === null) {
        localStorage.removeItem("configContext");
        return undefined;
    }
    return { ...configContext, ...newConfigContext };
};

let defaultValues: IConfigContext = { language: 0, showCaptcha: false, isCatalan: false };

const getLocation = async () => {
    try{
        const response = await axios({
            method: 'get',
            url: 'https://freegeoip.app/json/'
        });
        const location = response.data?.country_code;
        const returnLocation = (location !== null && location !== undefined && location.toString().toLocaleLowerCase() === 'es') ? 0 : 1;
        const firstValues: IConfigContext = { language: returnLocation, showCaptcha: false };
        localStorage.setItem("configContext", JSON.stringify(firstValues));
    } catch(e){
        localStorage.setItem("configContext", JSON.stringify(defaultValues));
    }
}

const localState = (localStorage.getItem("configContext") !== null && localStorage.getItem("configContext") !== undefined) ? JSON.parse(localStorage.getItem("configContext")!) : defaultValues;

export const ConfigProvider = ({ children }: Props) => {
    const [configContext, setConfigContext] = useReducer(reducer, localState);

    useEffect(() => {
        if(!localStorage.getItem("configContext")){
            //getLocation(); desactivado temporalmente
            localStorage.setItem("configContext", JSON.stringify(defaultValues));
        } else {
            localStorage.setItem("configContext", JSON.stringify(configContext));
        }
    }, [configContext]);

    return <ConfigContext.Provider value={{ configContext, setConfigContext }}>
        {children}
    </ConfigContext.Provider>
};

export const useConfigContext = () => React.useContext(ConfigContext);