import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Table, InputNumber, Form, Modal, Select, Tooltip, Input } from 'antd';
import LoadingSpin from '../../Components/Basic/LoadingSpin';
import { MinusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import envLocal from '../../env-local';
import NavBar from '../../Components/Basic/NavBar';
import { axiosCall } from '../../Components/Basic/Utilities/tools';
import { useConfigContext } from '../../Context/ContextConfig';
import { IBasicProduct, IBasicMeal } from '../../Components/Basic/Utilities/interfaces';

const { confirm } = Modal;

let columns: any;

interface IComponents {
    idProduct: string;
    quantity: number;
}

let drinks: IBasicMeal[] = [];
let dishes: IBasicMeal[] = [];
let basicProduct: IBasicProduct[] = [];

const StockTable: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [visibleTable, setVisibleTable] = useState<boolean>(false);
    const [itemChecked, setItemChecked] = useState<Array<IComponents>>([]);
    const [itemIdChecked, setItemIdChecked] = useState<string>();
    const [filterByProduct, setFilterByProduct] = useState<string>("")
    const [form] = Form.useForm();
    const { configContext } = useConfigContext()!;
    let widthSize = window.innerWidth;

    columns = [
        {
            title: configContext.language === 0 ? 'Producto' : "Product",
            dataIndex: 'product'
        },
        {
            title: configContext.language === 0 ? 'Cocina / Bar' : "Kitchen / Bar",
            dataIndex: 'typeProduct',
            filters: [{ text: configContext.language === 0 ? "Cocina" : "Kitchen", value: configContext.language === 0 ? "Cocina" : "Kitchen" }, { text: "Bar", value: "Bar" }],
            onFilter: (value: any, record: any) => record.typeProduct === value
        },
        {
            title: configContext.language === 0 ? 'Componentes' : "Components",
            dataIndex: 'componentsTable'
        }
    ];

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await getBasicProducts();
        const fetchDrinks = getDrinks();
        const fetchDishes = getDishes();
        await Promise.all([fetchDrinks, fetchDishes])
        setIsLoading(false);
    };

    const getBasicProducts = async () => {
        basicProduct = (await axiosCall("post", envLocal.endpointBasicProduct, envLocal.authComp, "getProducts")).data;
    };

    const getDishes = async () => {
        const response = (await axiosCall("post", envLocal.endpointDish, envLocal.authComp)).data;
        dishes = response.map((item: any) => { return { key: item._id, product: item.name, type: item.type, typeProduct: item.type === 1 ? (configContext.language === 0 ? "Cocina" : "Kitchen") : "Bar", components: item.components, componentsTable: item.components.map((item: any) => `x${item.quantity}_${basicProduct.find(basic => basic._id === item.idProduct)?.name}. `) } }).flat();
    };

    const getDrinks = async () => {
        const response = (await axiosCall("post", envLocal.endpointDrink, envLocal.authComp)).data;
        drinks = response.map((item: any) => { return { key: item._id, product: item.name, type: item.type, typeProduct: item.type === 1 ? (configContext.language === 0 ? "Cocina" : "Kitchen") : "Bar", components: item.components, componentsTable: item.components.map((item: any) => `x${item.quantity}_${basicProduct.find(basic => basic._id === item.idProduct)?.name}. `) } }).flat();
    };

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            selectedRows[0].components.length > 0 && setItemChecked(selectedRows[0].components);
            setItemIdChecked(selectedRowKeys[0]);
            setVisibleTable(true);
        }
    };

    const setSelected = async () => {
        await axiosCall("put", envLocal.endpointItemMenu, envLocal.authComp, itemIdChecked, {
            components: itemChecked
        });
        drinks.find(drink => drink.key === itemIdChecked) === undefined ? await getDishes() : await getDrinks();
        setItemChecked([]);
        setVisibleTable(false);
    };

    const addProduct = (values: any) => {
        const newItem: IComponents[] = [...itemChecked, values];
        setItemChecked(newItem);
    };

    const deleteComponent = (index: number) => {
        confirm({
            title: configContext.language === 0 ? '¿Quieres borrar el elemento seleccionado?' : 'Do you want to delete the selected item?',
            icon: <ExclamationCircleOutlined />,
            okText: configContext.language === 0 ? 'Sí' : 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                const newItemChecked = [...itemChecked];
                newItemChecked.splice(index, 1);
                setItemChecked(newItemChecked);
            },
            onCancel() {
            },
        });
    };

    let dataSource = [...drinks, ...dishes]
    if (filterByProduct !== "" && dataSource?.length) dataSource = dataSource.filter(e => e.product.toLowerCase().indexOf(filterByProduct.toLowerCase()) > -1)

    return (
        <div className="basicContainerColor">
            <NavBar />
            {isLoading ?
                <LoadingSpin />
                :
                <div className="mb_30px mt_60px">
                    <div className='textCenter'>
                        <Input placeholder={configContext.language === 0 ? "Filtro por producto" : "Filter by product"} onChange={e => setFilterByProduct(e.target.value)} value={filterByProduct} allowClear style={{ width: "400px" }} />
                    </div>
                    <div className="sectionSquare" style={{ margin: widthSize < 690 ? "30px 0px 5px 0px" : "30px 15px 5px 15px", padding: widthSize < 690 ? "15px 0px" : 15 }}>
                        <div className="sectionSquareTitle">{configContext.language === 0 ? 'Configuración del Stock' : 'Stock Configuration'}</div>
                        <div className="sectionSquareBody" style={{ padding: "20px 0px 10px 0px" }}>
                            <Row justify="center">
                                <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 18 }} xl={{ span: 16 }}>
                                    <Table
                                        columns={columns}
                                        dataSource={dataSource}
                                        rowSelection={{
                                            type: "radio",
                                            ...rowSelection,
                                        }}
                                    />
                                </Col>
                                <Modal
                                    title={configContext.language === 0 ? "Introduce las Proporciones" : "Enter the proportions"}
                                    visible={visibleTable}
                                    onOk={() => setSelected()}
                                    onCancel={() => {
                                        setItemChecked([]);
                                        setVisibleTable(false);
                                    }}
                                >
                                    <p>{configContext.language === 0 ? "Introduce la cantidad que se descontará de cada producto por ración. Ej: 1 plato de croquetas, 8 unidades de producto." : "Enter the amount to be deducted from each product per serving. E.g: 1 plate of croquettes, 8 units of product."}</p>
                                    {itemChecked.length > 0 &&
                                        itemChecked.map((component, index) =>
                                            <Row key={index}>
                                                <Col span={16}>{basicProduct.find(item => item._id === component.idProduct)?.name}</Col>
                                                <Col span={6} offset={2}>
                                                    {component.quantity} uds.
                                                    {<Tooltip title={configContext.language === 0 ? 'Eliminar' : "Delete"}><Button type="link" onClick={() => deleteComponent(index)} icon={<MinusCircleOutlined style={{ color: "red" }} />} /></Tooltip>}</Col>
                                            </Row>)}
                                    <Row style={{ marginTop: 10 }}>
                                        <Col span={16}>{configContext.language === 0 ? 'Producto:' : "Product:"}</Col>
                                        <Col span={6} offset={2}>{configContext.language === 0 ? 'Cantidad:' : "Quantity:"}</Col>
                                    </Row>
                                    <Row>
                                        <Form
                                            form={form}
                                            onFinish={(e) => {
                                                addProduct(e);
                                                form.resetFields();
                                            }}
                                            layout="horizontal"
                                            style={{ width: "100%" }}
                                        >
                                            <Row>
                                                <Col span={16}>
                                                    <Form.Item name="idProduct" rules={[{ required: true, message: configContext.language === 0 ? 'Introduce un producto' : "Enter a product" }]}>
                                                        <Select
                                                            style={{ width: "90%" }}
                                                            placeholder={configContext.language === 0 ? "Seleccionar" : "Select"}
                                                            showSearch
                                                            filterOption={(input, option: any) => (option?.children.toLowerCase()).includes(input.toLowerCase())}
                                                        >
                                                            {basicProduct.map((product, index) => <Select.Option key={index} value={product._id}>{product.name}</Select.Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6} offset={2}>
                                                    <Form.Item name="quantity" rules={[{ required: true, message: configContext.language === 0 ? 'Introduce una cantidad' : "Enter an amount" }]}>
                                                        <InputNumber defaultValue={0} min={0} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row style={{ display: "block", textAlign: "center", paddingTop: 20 }}><Button type="primary" htmlType="submit">{configContext.language === 0 ? 'Añadir' : "Add"}</Button></Row>
                                        </Form>
                                    </Row>
                                </Modal>
                            </Row>
                            <div className="textCenter mb_20px">
                                <Button type='primary' onClick={() => window.history.go(-1)}>{configContext.language === 0 ? 'Atrás' : "Back"}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default StockTable;