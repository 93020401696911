import React, { useEffect, useReducer } from 'react';

type UserContextType = {
    user: any;
    setUser: (value: any) => void;
};

export const UserContext = React.createContext<UserContextType | undefined>(undefined);

type Props = {
    children: React.ReactNode;
};

let reducer = (user: any, newUser: any) => {
    if (newUser === null) {
      localStorage.removeItem("user");
      return undefined;
    }
    return { ...user, ...newUser };
  };
  
const localState = JSON.parse(localStorage.getItem("user") || '{}');

export const UserProvider = ({ children }: Props) => {
    const [user, setUser] = useReducer(reducer, localState || undefined);

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(user));
      }, [user]);

return <UserContext.Provider value={{ user, setUser}}>
        {children}
    </UserContext.Provider>
};

export const useUser = () => React.useContext(UserContext);