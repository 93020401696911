import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useConfigContext } from '../../Context/ContextConfig';

interface Props {
    size?: number;
}

const LoadingSpin: React.FC<Props> = (props) => {
    const { configContext } = useConfigContext()!;
    const antIcon = <LoadingOutlined style={{ fontSize: props.size || 60 }} spin />;

    return (
        <div className="spiner">
            <Spin indicator={antIcon} />
            <p><strong>{configContext.language === 0 ? "Cargando..." : "Loading..."}</strong></p>
        </div>
    )
};

export default LoadingSpin;



